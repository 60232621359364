import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { reducerConstants, alertConstants } from "../_constants";
import { ShiftsAdd } from '../Shifts';
import { shiftsActions } from '../_actions';
import { apiConstants } from '../_constants';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BlockBookingcalnedar = props => {
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
    isCalendarShifts: true,
    from_date: new Date().toISOString().slice(0, 10),
    // new Date().toISOString().slice(0, 10)
    //----set default date filter as current date ("2022-05-23")YYYY-MM-DD-----
  });
  const [isLoading, setIsLoading] = useState(false);
  const [ShiftData, setShiftData] = useState([]);
  const [showListViewShiftsAddModal, setshowListViewShiftsAddModal] =
    useState(false);

  const [Today, setToday] = useState('');
  // const [candidateItems, setcandidateItems] = useState('');

  const [showbookingModal, setshowbookingModal] = useState(false);
  const [selectedDate, setselectedDate] = useState(null); //on cliick a date in calendar the selected date is kept here---
  // eslint-disable-next-line no-unused-vars
  const [showsection, setshowsection] = useState(true);

  // const [listViewShiftAddDate, setlistViewShiftAddDate] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [listViewShiftAdd, setlistViewShiftAdd] = useState(false);

  const [nightcandidate, setnightcandidate] = useState([]);
  const [morningcandidate, setmorningcandidate] = useState([]);
  const [selctedCol, setselctedCol] = useState(null); //selected col date is kept here and highkighted blue color
  const [customecandidate, setcustomecandidate] = useState([]);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [shiftid, setshiftid] = useState([]);

  //--------TO CHECK WHAT DAY IS IT
  const showBlockbookingview = (day, month, year, i, getdate) => {
    // console.log("getdateee", getdate)
    setshowbookingModal(true);
    setselectedDate({ day: day, month: month, year: year, i: i });
    if (i == 1) {
      setToday('Monday');
    } else if (i == 0) {
      setToday('Sunday');
    } else if (i == 2) {
      setToday('Tuesday');
    } else if (i == 3) {
      setToday('Wednesday');
    } else if (i == 4) {
      setToday('Thursday');
    } else if (i == 5) {
      setToday('Friday');
    } else if (i == 6) {
      setToday('Saturday');
    }

    let queryNew = [];
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        setQuery(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
        }));
        queryNew.employer_id = user.employer_id;
      }
    }

    setIsLoading(true);
    const { dispatch } = props;
    //  set from date------------------------

    queryNew.from_date = props.shifts_from_date;

    dispatch(
      shiftsActions.shiftsList({
        employer_id: queryNew.employer_id,
        check_in_date: getdate,
        isShiftsCalendarSingle: true,
      }),
    ).then(
      res => {
        // setIsLoading(false);

        // setcalenderbloackbookingdate(res.data.rows);

        // dayshift(res.data.rows)

        let night = [];
        let morning = [];
        let custome = [];

        for (
          let i = 0;
          res && res.data && res.data.rows && i < res.data.rows.length;
          i++
        ) {
          if (res.data.rows[i].section_type == 'Night') {
            night.push(res.data.rows[i]);
          }
          if (res.data.rows[i].section_type == 'Morning') {
            morning.push(res.data.rows[i]);
          }
          if (
            res.data.rows[i].section_type != 'Night' &&
            res.data.rows[i].section_type != 'Morning'
          ) {
            custome.push(res.data.rows[i]);
          }
        }
        setnightcandidate(night);
        setcustomecandidate(custome);
        setmorningcandidate(morning);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  console.log('nightttttt', isLoading);

  const showAddshiftmodal = () => {
    setshowListViewShiftsAddModal(false);
    getAllShifts(1);
  };

  //and it ends here ---new shift asd modal

  const deleteshiftmodal = id => {
    setdeleteconfirmationmodal(true);
    setshiftid({ _id: id, status: 0 });
  };

  const deleteshift = () => {
    const { dispatch } = props;

    dispatch(shiftsActions.shiftsDelete(shiftid));
    setdeleteconfirmationmodal(false);
    setshowbookingModal(false);
    // shiftdetails()
    getAllShifts(1);
  };
  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    // setQuery(query);

    //-----set employer id from front end---------
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        setQuery(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
        }));
        queryNew.employer_id = user.employer_id;
      }
    }

    setIsLoading(true);
    const { dispatch } = props;
    //  set from date------------------------
    queryNew.from_date = props.shifts_from_date;
    queryNew.business_unit = props.selectedbusinessunit;

    dispatch(shiftsActions.shiftsListCalendar(queryNew)).then(
      res => {
        // console.log(res, "reeeeesp")
        setIsLoading(false);
        setShiftData(res.data[0]);

        //  setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  // ---CALENDAR SINGLE ITEM------VIEW
  const getColoumItem = (day, month, year, i) => {
    let cday = day.toString().length == 1 ? '0' + day : day;
    let cmonth = month.toString().length == 1 ? '0' + month : month;
    let getdate = year + '-' + cmonth + '-' + cday;

    let value = '';

    let morning = '';
    let night = '';
    let custome = '';
    let total = '';

    if (ShiftData) {
      for (let i = 0; i < ShiftData.length; i++) {
        var today = new Date(ShiftData[i].day);

        var date =
          today.getFullYear() +
          '-' +
          ((new Date(today).getMonth() + 1).toString().length == 1
            ? '0' + (new Date(today).getMonth() + 1)
            : new Date(today).getMonth() + 1) +
          '-' +
          (new Date(today).getDate().toString().length == 1
            ? '0' + new Date(today).getDate()
            : new Date(today).getDate());

        // console.log("length", date, getdate)
        // ------check  shifft with each date of month-------------------
        if (date == getdate) {
          // console.log("valueee", value)
          value = true;
          morning =
            ShiftData[i].total_morning.toString().length == 1
              ? '0' + ShiftData[i].total_morning
              : ShiftData[i].total_morning;
          // morning=ShiftData[i].total_morning
          night =
            ShiftData[i].total_night.toString().length == 1
              ? '0' + ShiftData[i].total_night
              : ShiftData[i].total_night;
          custome =
            ShiftData[i].total_custome.toString().length == 1
              ? '0' + ShiftData[i].total_custome
              : ShiftData[i].total_custome;
          total =
            ShiftData[i].total.toString().length == 1
              ? '0' + ShiftData[i].total
              : ShiftData[i].total;
        }
        // console.log("valueee after", value)
        // else {
        //   value = false
        // }
      }
    }

    props.selectedCalendarCol(selctedCol);
    // console.log(date,getdate,"this is selected col day--")
    return (
      <div
        className={
          selctedCol == getdate
            ? ' col  bg-light-blue pl-0 pr-0 p-1'
            : 'col  Blockbooking pl-0 pr-0 p-1 '
        }
        onClick={() => {
          setselctedCol(getdate), console.log(getdate, 'kl72');
        }}
      >
        <div className="row d-flex align-items-center ">
          <div className="col f18">
            {day.toString().length == 1 ? '0' + day : day}
          </div>
          <div className="col d-flex justify-content-end">
            <div>
              <i
                onClick={() => {
                  props.showAllocateShift(day, cmonth, year, getdate);
                }}
                className="fa fa-plus-circle"
                style={{
                  fontSize: '16px',
                  color: ' #11918A',
                  cursor: 'pointer',
                }}
              ></i>
            </div>
          </div>
        </div>

        {showsection && (
          <div className="row">
            {value && (
              <div className="col mt-3">
                <div
                  className="date-div block-booking-tag"
                  onClick={() => {
                    // setbloackbookingviewdate(getdate),
                    showBlockbookingview(day, month, year, i, getdate);
                  }}
                >
                  block bookings - {total}
                </div>
                <div className="row">
                  <div className="col">
                    <div className="fs7">night</div>
                    <div className="shiftitem-div">{night}</div>
                  </div>
                  <div className="col">
                    <div className="fs7">Morning</div>
                    <div className="shiftitem-div2">{morning}</div>
                  </div>
                  <div className="col">
                    <div className="fs7">Others</div>
                    <div className="shiftitem-div3">{custome}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  //--GET LIST OF CANDIDATES BASED ON SHIFTS------DISPLAYED IN BLOCK BOOKING VIEW MODAL---

  function daysOfMonth() {
    let a = new Date(props.shifts_from_date);
    let month = a.getMonth() + 1;

    let year = a.getFullYear();
    // console.log("enter cal function----");

    var rows = 8;
    var cols = 6;
    var c = 1;

    let daysMonth = new Date(year, month, 0).getDate();
    let firstWeekno = new Date(month + '/1/' + year).getDay();
    let index = 0;

    console.log(daysMonth, '--gg457--', firstWeekno);

    let tableRows = [];

    //first row of calendar
    tableRows.push(
      <tr className="col-md-12 p-2 mt-2  bg-fff">
        <td className="col-md-1 calendar-day bg-fff">Sunday</td>
        <td className="col-md-1 calendar-day bg-fff ">monday</td>
        <td className="col-md-1 calendar-day bg-fff ">Tuesday</td>
        <td className="col-md-1 calendar-day bg-fff ">Wednesday</td>
        <td className="col-md-1 calendar-day bg-fff ">Thursday</td>
        <td className="col-md-1 calendar-day bg-fff ">Friday</td>
        <td className="col-md-1 calendar-day bg-fff ">Saturday</td>
      </tr>,
    );

    for (var r = 0; r < rows; r++) {
      let tabeColoms = [];
      for (var i = 0; i <= cols; i++) {
        if (c <= daysMonth) {
          if (index >= firstWeekno) {
            //  _table += <td> {c} </td>;
            console.log(setselctedCol, i, '---selected col');
            tabeColoms.push(
              <td className={'c h80 bg-fff'}>
                {getColoumItem(c, month, year, i)}
              </td>,
            );
          } else {
            tabeColoms.push(<td className={'c h80 bg-fff'}></td>);
          }
        } else {
          break;
        }

        if (index >= firstWeekno) {
          c++;
        }
        index++;
      }
      {
        tabeColoms.length !== 0 &&
          tableRows.push(<tr className="h80 bg-fff ">{tabeColoms}</tr>);
      }
    }

    return tableRows;
  }

  useEffect(() => {
    /**
     * Edit data using url query
     */

    if (props.relaodShifts) {
      getAllShifts();
    }

    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        // console.log("enter localstorage user", user.employer_id)
        setQuery(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
        }));
      }
    }

    getAllShifts(1);
  }, []);

  return (
    <div className="Calendar">
      <div className="">
        <table className="table customtable_calendar table-hover">
          {daysOfMonth()}

          {/* {daysOfMonth(4, 2022)} */}
        </table>
      </div>

      {/* ---SINGLE BLOCK BOOKING VIEW INSIDE CALENDAR SINGLE ITEM----- */}
      {showbookingModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setshowbookingModal(false)}>
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
              </div>
              {/* ---TIME AND DAY BLOCK----------- */}
              <div className="modal-body m-3" style={{ paddingTop: '0px' }}>
                <div className="bag">
                  <div>
                    <div className="m-2">
                      <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-md-6">
                          <div className=" f20-bold h30">
                            {' '}
                            {selectedDate.day.toString().length == 1
                              ? '0' + selectedDate.day
                              : selectedDate.day}
                            -
                            {selectedDate.month.toString().length == 1
                              ? '0' + selectedDate.month
                              : selectedDate.month}
                            -{selectedDate.year}
                          </div>
                        </div>
                      </div>

                      <div className="today">{Today}</div>
                    </div>
                  </div>

                  {morningcandidate.length != 0 && (
                    <div className=" mt-4">
                      <div className="shift-title">
                        Morning
                        {/* </div> */}
                        <div className="dummy-view50"> </div>
                        {morningcandidate.map(i => {
                          return (
                            <>
                              <div
                                className="d-flex "
                                style={{ marginTop: '15px' }}
                              >
                                {' '}
                                <div className="d-flex align-items-center font-12">
                                  {' '}
                                  {i['Candidate.profileimage'] ? (
                                    <img
                                      style={{ height: '30px', width: '30px' }}
                                      // className="employer-image"
                                      src={
                                        apiConstants.imgUrl +
                                        i['Candidate.profileimage']
                                      }
                                      // src="/assets/img/emp_image.png"
                                    />
                                  ) : i['Agency.profileimage'] ? (
                                    <img
                                      style={{ height: '30px', width: '30px' }}
                                      // className="employer-image"
                                      src={
                                        apiConstants.imgUrl +
                                        i['Agency.profileimage']
                                      }
                                      // src="/assets/img/emp_image.png"
                                    />
                                  ) : (
                                    <img
                                      style={{ height: '25px', width: '25px' }}
                                      // className="employer-image"
                                      src="/assets/img/recsy-fav-icon.png"
                                    />
                                  )}
                                </div>
                                <div className="d-flex align-items-center font-12">
                                  {i['Candidate.name']
                                    ? i['Candidate.name']
                                    : i['Agency.company_name']
                                    ? i['Agency.company_name'] + '(Agency)'
                                    : ''}
                                </div>
                                {i.is_attended == 1 && (
                                  <div className="d-flex align-items-center font-12">
                                    <img
                                      className=" float-right"
                                      style={{
                                        marginTop: '8px',
                                      }}
                                      src="/assets/img/Subtract.png"
                                    />
                                  </div>
                                )}
                                {i.is_attended != 1 && (
                                  <div className="d-flex align-items-center font-12">
                                    <img
                                      src="/assets/img/delete.png"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        deleteshiftmodal(i._id);
                                      }}
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {nightcandidate.length != 0 && (
                    <div className=" mt-4">
                      <div className="shift-title">
                        Night
                        {/* </div> */}
                        <div className="dummy-view50"> </div>
                        {nightcandidate.map(i => {
                          return (
                            <>
                              <div
                                className="d-flex "
                                style={{ marginTop: '15px' }}
                              >
                                {' '}
                                <div className="d-flex align-items-center font-12">
                                  {' '}
                                  {i['Candidate.profileimage'] ? (
                                    <img
                                      style={{ height: '30px', width: '30px' }}
                                      // className="employer-image"
                                      src={
                                        apiConstants.imgUrl +
                                        i['Candidate.profileimage']
                                      }
                                      // src="/assets/img/emp_image.png"
                                    />
                                  ) : i['Agency.profileimage'] ? (
                                    <img
                                      style={{ height: '30px', width: '30px' }}
                                      // className="employer-image"
                                      src={
                                        apiConstants.imgUrl +
                                        i['Agency.profileimage']
                                      }
                                      // src="/assets/img/emp_image.png"
                                    />
                                  ) : (
                                    <img
                                      style={{ height: '25px', width: '25px' }}
                                      // className="employer-image"
                                      src="/assets/img/recsy-fav-icon.png"
                                    />
                                  )}
                                </div>
                                <div className="d-flex align-items-center font-12">
                                  {i['Candidate.name']
                                    ? i['Candidate.name']
                                    : i['Agency.company_name']
                                    ? i['Agency.company_name'] + '(Agency)'
                                    : ''}
                                </div>
                                {i.is_attended == 1 && (
                                  <div className="d-flex align-items-center font-12">
                                    <img
                                      className=" float-right"
                                      style={{
                                        marginTop: '8px',
                                      }}
                                      src="/assets/img/Subtract.png"
                                    />
                                  </div>
                                )}
                                {i.is_attended != 1 && (
                                  <div className="d-flex align-items-center font-12">
                                    <img
                                      style={{ cursor: 'pointer' }}
                                      src="/assets/img/delete.png"
                                      onClick={() => {
                                        deleteshiftmodal(i._id);
                                      }}
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {customecandidate.length != 0 && (
                    <div className=" mt-4">
                      <div className="shift-title">
                        Custome
                        {/* </div> */}
                        <div className="dummy-view50"> </div>
                        {customecandidate.map(i => {
                          return (
                            <>
                              <div
                                className="d-flex "
                                style={{ marginTop: '15px' }}
                              >
                                {' '}
                                <div className="d-flex align-items-center font-12">
                                  {' '}
                                  {i['Candidate.profileimage'] ? (
                                    <img
                                      style={{ height: '30px', width: '30px' }}
                                      // className="employer-image"
                                      src={
                                        apiConstants.imgUrl +
                                        i['Candidate.profileimage']
                                      }
                                      // src="/assets/img/emp_image.png"
                                    />
                                  ) : i['Agency.profileimage'] ? (
                                    <img
                                      style={{ height: '30px', width: '30px' }}
                                      // className="employer-image"
                                      src={
                                        apiConstants.imgUrl +
                                        i['Agency.profileimage']
                                      }
                                      // src="/assets/img/emp_image.png"
                                    />
                                  ) : (
                                    <img
                                      style={{ height: '25px', width: '25px' }}
                                      // className="employer-image"
                                      src="/assets/img/recsy-fav-icon.png"
                                    />
                                  )}
                                </div>
                                <div className="d-flex align-items-center font-12">
                                  {i['Candidate.name']
                                    ? i['Candidate.name']
                                    : i['Agency.company_name']
                                    ? i['Agency.company_name'] + '(Agency)'
                                    : ''}
                                </div>
                                {i.is_attended == 1 && (
                                  <div className="d-flex align-items-center font-12">
                                    <img
                                      className=" float-right"
                                      style={{
                                        marginTop: '8px',
                                      }}
                                      src="/assets/img/Subtract.png"
                                    />
                                  </div>
                                )}
                                {i.is_attended != 1 && (
                                  <div className="d-flex align-items-center font-12">
                                    <img
                                      src="/assets/img/delete.png"
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        deleteshiftmodal(i._id);
                                      }}
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setdeleteconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>

              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="d-flex justify-content-center">
                        <img
                          className="delete-icon"
                          src="/assets/img/bin.png"
                        ></img>
                      </div>

                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure you want to delete shift?{' '}
                      </p>

                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setdeleteconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>

                        <button
                          className="button_delete_close width_100"
                          onClick={() => deleteshift()}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showListViewShiftsAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowListViewShiftsAddModal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftsAdd
                  showAddshiftmodal={showAddshiftmodal}
                  // allocateshift={true}
                  listViewShiftsAdd={true}
                  // daymonthyear={daymonthyear}
                  listViewShiftAddDate={listViewShiftAdd}
                  isSelectUsershow={true}
                  iscarehome={true}
                  isHideuser={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedBlockBookingcalnedar =
  connect(mapStateToProps)(BlockBookingcalnedar);
export { connectedBlockBookingcalnedar as BlockBookingcalnedar };
