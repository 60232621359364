import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AgencyCandidateProfile } from '../AgencyCandidate/AgencyCandidateProfile';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { bookingActions, sectionActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';
let employer_id = '';
let carehomename = '';

const BookingList = props => {
  let vacancy_id = useParams().id;
  let dt = useParams().date;
  let { id } = useParams();
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  if (id == 'newbookings') {
    query.booking_status = 0;
  }
  const [isLoading, setIsLoading] = useState(false);
  const [bookingData, setBookingData] = useState({ rows: [] });
  const [sectionData, setsectionData] = useState([]);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [isBunit, setisBunit] = useState(false);
  const [bookingRows, setbookingRows] = useState([]);
  const [shiftsoptionsData, setshiftsoptionsData] = useState([]);
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [viewdetails, setviewdetails] = useState([]);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [SelectedBookingId, setSelectedBookingId] = useState([]);
  const [confirmLoadingid, setconfirmLoadingid] = useState();
  const [confirmloading, setconfirmloading] = useState(false);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [showAgencycandidateprofileModal, setshowAgencycandidateprofileModal] =
    useState(false);
  const currentDate = new Date();
  const dayAfterTomorrow = currentDate.getTime() + 2 * 24 * 60 * 60 * 1000;

  //--- debouncing for searchkey only - start ---
  let isfirsttime = useRef(true);
  const debouncedSearchDate = useDebounce(query.date, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearchDate) {
        getAllBooking(1);
      }
    }
  }, [debouncedSearchDate]);
  //--- debouncing end ---
  // effect change component at query items or confirmLoadingid
  // get section business unit
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
      carehomename = user.name;
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 7) {
        setisBunit(true);
      }
      if (user.role == 6) {
        setisStaff(true);
      }
    }
    if (employer_id) {
      getAllSection();
      getbusinessunit();
    }
    getAllBooking(1);
  }, [
    query.pageVo.sort_order,
    query.pageVo.sort,
    query.booking_status,
    query.shift,
    query.business_unit,
    query.vacancy_status,
    confirmLoadingid,
  ]);
  // get bookings
  const getAllBooking = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (query.date != new Date().toISOString().slice(0, 10)) {
      queryNew.date = query.date;
    }
    if (query.date == new Date().toISOString().slice(0, 10) && dt != '') {
      queryNew.date = dt;
    }
    if (query.date == new Date().toISOString().slice(0, 10) && dt == '') {
      queryNew.date = query.date;
    }
    if (dt && dt != '0' && dt != undefined) {
      queryNew.date = dt;
    }
    if (
      vacancy_id &&
      vacancy_id != '0' &&
      vacancy_id != undefined &&
      typeof vacancy_id != String
    ) {
      queryNew.vacancy_id = vacancy_id;
    }
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    if (queryNew.date == '') {
      delete queryNew.date;
    }
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(bookingActions.bookingList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // filter sort order handler
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // to update booking status confirm or reject
  const updatebooking = (
    booking_status,
    booking_id,
    candidate_user_id,
    vacancy_id,
    candidate_id,
    agency_id,
    agency_user_id,
    agency_candidate_id,
  ) => {
    setconfirmloading(true);
    setconfirmLoadingid(booking_id);
    let querynew = {};
    if (booking_status) {
      querynew.booking_status = booking_status;
    }
    if (booking_id) {
      querynew.booking_id = booking_id;
    }
    if (vacancy_id) {
      querynew.vacancy_id = vacancy_id;
    }
    if (agency_id && agency_user_id) {
      querynew.agency_id = agency_id;
      querynew.agency_user_id = agency_user_id;
    }
    if (candidate_id) {
      querynew.candidate_id = candidate_id;
    }
    if (candidate_user_id) {
      querynew.candidate_user_id = candidate_user_id;
    }
    if (agency_candidate_id) {
      querynew.agency_candidate_id = agency_candidate_id;
    }
    const { dispatch } = props;
    dispatch(bookingActions.confirmBooking(querynew)).then(
      res => {
        console.log(res);
        setIsLoading(false);
        setconfirmLoadingid(0);
        setconfirmloading(false);
      },
      error => {
        console.log(error);
        setIsLoading(false);
        setconfirmLoadingid(0);
        setconfirmloading(false);
      },
    );
  };
  // handler for change in filter
  const handleChange = event => {
    const { name, value } = event.target;
    isfirsttime.current = false;
    setQuery({
      ...query,
      [name]: value,
    });
  };
  // get all section  if employer is logged in
  const getAllSection = () => {
    setIsLoading(true);
    const { dispatch } = props;
    if (employer_id !== 0) {
      dispatch(
        sectionActions.sectionList({
          employer_id: employer_id,
          attributes: ['_id', 'name'],
        }),
      ).then(
        res => {
          setIsLoading(false);
          let shift_option_rows = [];
          for (let s = 0; s < res.data.rows.length; s++) {
            shift_option_rows.push(
              <option value={res.data.rows[s]._id}>
                {res.data.rows[s].name}
              </option>,
            );
          }
          setsectionData(shift_option_rows);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  };
  // get all businessunit if employer is logged in
  const getbusinessunit = () => {
    if (employer_id) {
      let business_unit_response = fetch(
        apiConstants.apiUrl + '/getbusinessunitlist',
        {
          method: 'post',
          headers: authHeader(),
          body: JSON.stringify({
            status: 1,
            employer_id: employer_id,

            select: ['_id', 'name'],
          }),
        },
      ).then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows) {
            let shift_option_rows = [];
            for (let s = 0; s < data.rows.length; s++) {
              shift_option_rows.push(
                <option value={data.rows[s]._id}>{data.rows[s].name}</option>,
              );
            }
            setshiftsoptionsData(shift_option_rows);
          }
        }),
      );
      console.log(business_unit_response);
    }
  };
  // to get for a candidate
  function getAge(dateString) {
    if (dateString) {
      // converting dateString in the format 'DD-MM-YYYY'
      const dateMoment = moment(dateString, 'DD-MM-YYYY');
      // Define the birthdate in a format like 'YYYY-MM-DD'
      const birthdate = dateMoment.format('YYYY-MM-DD');
      // Calculate the current date
      const currentDate = moment();
      // Calculate the age
      const age = currentDate.diff(moment(birthdate), 'years');
      return age;
    } else {
      return '--';
    }
  }
  // to list booking and pagination function
  const setListAndPagination = bookingData => {
    let bookingList = [];
    let applied_user_role = undefined;
    let user_role = undefined;
    for (
      let j = 0;
      bookingData && bookingData.rows.length && j < bookingData.rows.length;
      j = j + 1
    ) {
      user_role = bookingData.rows[j]['User.role'];
      if (user_role == 1) {
        applied_user_role = 'Admin';
      } else if (user_role == 7) {
        applied_user_role = 'Businesss unit';
      } else if (user_role == 5) {
        applied_user_role = 'Agency';
      } else if (user_role == 3) {
        applied_user_role = 'Candidate';
      } else if (user_role == 4) {
        applied_user_role = 'Employer';
      } else if (user_role == 6) {
        applied_user_role = 'Staff';
      }
      bookingList.push(
        <tr key={j}>
          <td>
            {bookingData.rows[j].agency_candidate_id && (
              <div
                data-cy="viewprofile"
                className="pointer"
                onClick={() => {
                  setshowAgencycandidateprofileModal(true),
                    setSelectedBookingId(bookingData.rows[j]._id),
                    setSelectedCandidateId(
                      bookingData.rows[j].agency_candidate_id,
                    );
                }}
              >
                {bookingData.rows[j]['Agency.company_name']
                  ? bookingData.rows[j]['Agencycandidate.name'] +
                    '(' +
                    bookingData.rows[j]['Agency.company_name'] +
                    ')'
                  : ''}
              </div>
            )}
            {bookingData.rows[j]['Candidate.name'] && (
              <div
                data-cy="viewprofile"
                className="pointer txt_up"
                onClick={() => {
                  setshowCandidateprofileModal(true),
                    setSelectedBookingId(bookingData.rows[j]._id),
                    setSelectedCandidateId(
                      bookingData.rows[j]['Candidate._id'],
                    );
                }}
              >
                {bookingData.rows[j]['Candidate.name'] + ' '}
                {bookingData.rows[j]['Candidate.last_name']
                  ? bookingData.rows[j]['Candidate.last_name']
                  : ''}
              </div>
            )}
            {bookingData.rows[j].shiftscount > 0 && (
              <div className="d-flex">
                <div className="workedcandidate">
                  {' '}
                  <p style={{ fontWeight: '', fontSize: 10 }}>Already worked</p>
                </div>
                <div>
                  {' '}
                  <img
                    style={{ height: 10, width: 10 }}
                    className="tick-mark"
                    src="/assets/img/tick-mark2.png"
                  />
                </div>
              </div>
            )}
          </td>
          <td
            data-cy="viewvacancy"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setviewdetails(bookingData.rows[j]), setviewdetailsmodal(true);
            }}
          >
            {'VAC0' + bookingData.rows[j].vacancy_id}{' '}
          </td>
          <td>
            {bookingData.rows[j]['Candidate.nationality']
              ? bookingData.rows[j]['Candidate.nationality']
              : bookingData.rows[j]['Agency.nationality']
              ? bookingData.rows[j]['Agency.nationality']
              : ''}
          </td>
          <td>
            {bookingData.rows[j]['User.name']}
            {'(' + applied_user_role + ')'}
          </td>
          <td>
            {bookingData.rows[j]['Vacancy.from_date_string']
              ? moment(
                  bookingData.rows[j]['Vacancy.from_date_string'],
                  'YYYY-MM-DD',
                ).format('DD-MMM-YYYY')
              : ''}
            <div
              className={
                'font-bold' +
                (new Date(bookingData.rows[j]['Vacancy.from_date_string']) <
                currentDate
                  ? ' font-red'
                  : new Date(
                      bookingData.rows[j]['Vacancy.from_date_string'],
                    ).getTime() <= dayAfterTomorrow &&
                    new Date(
                      bookingData.rows[j]['Vacancy.from_date_string'],
                    ).getTime() >= currentDate
                  ? ' font-green'
                  : '')
              }
            >
              {new Date(bookingData.rows[j]['Vacancy.from_date_string']) <
              currentDate
                ? 'Expired'
                : new Date(
                    bookingData.rows[j]['Vacancy.from_date_string'],
                  ).getTime() <= dayAfterTomorrow &&
                  new Date(
                    bookingData.rows[j]['Vacancy.from_date_string'],
                  ).getTime() >= currentDate
                ? 'Upcoming'
                : ''}
            </div>
          </td>
          <td>
            {moment
              .unix(bookingData.rows[j].createdtime / 1000)
              .format('DD MMM YYYY hh:mm A')}
          </td>
          <td>
            {bookingData.rows[j]['Candidate.dob']
              ? getAge(bookingData.rows[j]['Candidate.dob'])
              : bookingData.rows[j]['Agency.dob']
              ? getAge(bookingData.rows[j]['Agency.dob'])
              : '--'}
          </td>
          <td>
            <p style={{ fontWeight: 'bold', color: '#183245' }}>
              {bookingData.rows[j]['Vacancy.Section.section_type']}
            </p>
            <br />
            {bookingData.rows[j]['Vacancy.Section.from_time'] +
              '-' +
              bookingData.rows[j]['Vacancy.Section.to_time']}
          </td>
          <td style={{ textTransform: 'capitalize' }}>
            {bookingData.rows[j]['Category.name']}
          </td>
          <td>{'BK0' + bookingData.rows[j]._id}</td>
          <td>
            <div className="mb-10">
              {bookingData.rows[j].booking_status == 0 &&
              new Date(bookingData.rows[j]['Vacancy.from_date_string']) >=
                currentDate ? (
                <div className="d-flex flex-column">
                  <div>
                    <div
                      data-cy="confirmbooking"
                      className="confirmm mb-10"
                      onClick={() => {
                        updatebooking(
                          1,
                          bookingData.rows[j]._id,
                          bookingData.rows[j]['Candidate.user_id'],
                          bookingData.rows[j].vacancy_id,
                          bookingData.rows[j].candidate_id,
                          bookingData.rows[j].agency_id,

                          bookingData.rows[j]['Agency.user_id'],
                          bookingData.rows[j].agency_candidate_id,
                        );
                      }}
                    >
                      Confirm
                      {confirmLoadingid == bookingData.rows[j]._id &&
                        confirmloading && (
                          <div
                            id="loading-bar-spinner"
                            className=" ml-2 spinner"
                          >
                            <div className="spinner-icon"></div>
                          </div>
                        )}
                    </div>
                    <div>
                      <div
                        data-cy="rejectbooking"
                        className="reject"
                        onClick={() => {
                          updatebooking(
                            2,
                            bookingData.rows[j]._id,
                            bookingData.rows[j]['Candidate.user_id'],
                          );
                        }}
                      >
                        Reject
                      </div>
                    </div>
                  </div>
                </div>
              ) : bookingData.rows[j].booking_status == 0 &&
                new Date(bookingData.rows[j]['Vacancy.from_date_string']) <
                  currentDate ? (
                <div className="rejected">Expired</div>
              ) : bookingData.rows[j].booking_status == 1 ? (
                <div className="confirmed ">
                  <img className="mr-1" src="/assets/img/Subtract.png"></img>
                  Confirmed
                </div>
              ) : bookingData.rows[j].booking_status == 2 ? (
                <div className="rejected">
                  <i
                    className=" fa fa-times-circle fs-18"
                    style={{
                      color: '#D13A3A',
                      cursor: 'auto',
                      paddingRight: '3px',
                    }}
                  ></i>
                  Rejected
                </div>
              ) : bookingData.rows[j].booking_status == -1 ? (
                <div className="rejected">
                  <i
                    className=" fa fa-times-circle fs-18"
                    style={{
                      color: '#D13A3A',
                      cursor: 'auto',
                      paddingRight: '3px',
                    }}
                  ></i>
                  Cancelled
                </div>
              ) : (
                ''
              )}
            </div>
          </td>
        </tr>,
      );
    }
    setbookingRows(bookingList);
    setBookingData(bookingData);
  };
  return (
    <div className="CandidateList ">
      <div className="col-md-12 col-sm-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8">
                <div className="page-title">Booking</div>
              </div>
            </div>
            <div className="row">
              {!isAdmin && !isBunit && !isStaff && (
                <div className="col-md-2 pr-1">
                  <div className="">
                    {' '}
                    <label className="ml-10" htmlFor="type">
                      Business unit
                    </label>
                  </div>
                  <div className={'form-group '}>
                    <select
                      className="select col-md-12 selectdesing2"
                      name="business_unit"
                      value={query.business_unit}
                      onChange={handleChange}
                    >
                      <option value="" hidden={true}>
                        {' '}
                        select business unit
                      </option>
                      <option value=""> All</option>
                      {shiftsoptionsData}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-md-2 pr-1">
                <div className="">
                  {' '}
                  <label className="ml-10" htmlFor="type">
                    Date
                  </label>
                </div>
                <div className={'form-group '}>
                  <input
                    type="date"
                    className="form-control selectdesign-report"
                    name="date"
                    placeholder=""
                    value={query.date}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {!isAdmin && !isStaff && (
                <div className="col-md-2 pr-1">
                  <div className="">
                    {' '}
                    <label className="ml-10" htmlFor="type">
                      Shift Type
                    </label>
                  </div>
                  <div className={'form-group '}>
                    <select
                      className="select col-md-12 selectdesing2"
                      name="shift"
                      value={query.shift}
                      onChange={handleChange}
                    >
                      <option value=""> All</option>
                      {sectionData}
                    </select>
                  </div>
                </div>
              )}
              <div className="col-md-2 pr-1">
                <div className="">
                  {' '}
                  <label className="ml-10" htmlFor="type">
                    Approval Status
                  </label>
                </div>
                <div className={'form-group '}>
                  <select
                    className="select col-md-12 selectdesing2"
                    name="booking_status"
                    value={query.rejected}
                    onChange={handleChange}
                  >
                    <option value={''}> All </option>
                    <option value={1}>All confirmed</option>
                    <option value={2}>All rejected</option>
                  </select>
                </div>
              </div>
              <div className="col-md-2 pr-1">
                <div className="">
                  {' '}
                  <label className="ml-10" htmlFor="type">
                    Vacancy Status
                  </label>
                </div>
                <div className={'form-group '}>
                  <select
                    className="select col-md-12 selectdesing2"
                    name="vacancy_status"
                    value={query.vacancy_status}
                    onChange={handleChange}
                  >
                    <option value={''}> All </option>
                    <option value={1}>Expired</option>
                    <option value={2}>Upcoming</option>
                  </select>
                </div>
              </div>
              <div className="col-md-2 pr-1"></div>
            </div>
          </div>
          {id && id !== 0 && id !== '0' && id !== 'newbookings' && (
            <div className="bookingid">Bookings for vacancy ID : {id} </div>
          )}
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover flx-custom-table">
                <thead>
                  <tr>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('candidate_name')}
                      className="sortHead border-top1"
                    >
                      Name
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Vacancy ID</th>
                    <th>Nationality</th>
                    <th>Who applied</th>
                    <th>Shift date</th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('applied_date_string')}
                      className="sortHead"
                    >
                      Applied Date & Time
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'applied_date_string' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'applied_date_string' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Age</th>
                    <th>Section</th>
                    <th>Jobrole</th>
                    <th>Booking No</th>
                    <th className="border-top2" />
                  </tr>
                </thead>
                <tbody>{bookingRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingImg />}
            {bookingRows && bookingRows.length == 0 && !isLoading && (
              <p className="margin-top-20 text-center"> No result found </p>
            )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllBooking(data);
              }}
              paginationData={bookingData}
            />
          </div>
        </div>
        {viewdetailsmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-sm ">
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div
                      className="close"
                      data-cy="close-vacancy-details-modal"
                    >
                      <a onClick={() => setviewdetailsmodal(false)}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <div className="bag">
                    <div className="d-flex flex-column ">
                      <div
                        className=" text-secondary"
                        style={{ lineHeight: '0px' }}
                      >
                        VACANCY ID
                      </div>
                      <div className="">
                        <label>{'FLX00' + viewdetails.vacancy_id}</label>
                      </div>
                    </div>
                    <div className="d-flex flex-row align-item-center ">
                      <div className={'icon-div mt-1'}>
                        <img
                          src="/assets/img/calendar1.png"
                          style={{ height: '20px', width: '20px' }}
                        ></img>
                      </div>
                      <div className="d-flex align-items-center justify-content-center ml-10 font-weight-bold">
                        {new Date(
                          viewdetails['Vacancy.from_date_string'],
                        ).toLocaleDateString('en-us', {
                          day: 'numeric',
                          month: 'long',
                          weekday: 'long',
                        })}
                      </div>
                    </div>
                    <div className="d-flex flex-row bd-highlight mb-3 align-items-center">
                      <div className=" col-md-9 p-2 bd-highlight d-flex flex-row bd-highlight mb-3">
                        <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                          {/* <div>Enter your bidding amount</div> */}
                          <div className="p-2 bd-highlight bluetag ">
                            {viewdetails['Vacancy.Section.section_type']}
                          </div>
                        </div>
                        <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                          <div className="p-2 bd-highlight compny_name_agency">
                            {isAdmin
                              ? viewdetails['Employer.company_name']
                              : carehomename}
                          </div>
                        </div>
                        <div className="p-2 bd-highlight d-flex flex-column bd-highlight mb-3">
                          <div className="p-2 bd-highlight vacancy_type">
                            {viewdetails['Vacancy.is_bidding_option_needed'] ==
                            1
                              ? 'Bidding'
                              : viewdetails[
                                  'Vacancy.is_auto_booking_confirm'
                                ] == 1
                              ? 'Auto booking confirm'
                              : 'Manual confirm'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="dummy-view"></div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Vacancy ID</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{'FLX00' + viewdetails['Vacancy._id']} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Shift</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails['Vacancy.Section.section_type']} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Business Unit</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails['Vacancy.Businessunit.name']}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Time</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>
                          {viewdetails['Vacancy.Section.from_time'] +
                            ' to ' +
                            viewdetails['Vacancy.Section.to_time']}{' '}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>No of Vacancy</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails['Vacancy.no_of_openings']} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>City</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails['Vacancy.city']} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>County</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails['Vacancy.county']} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Allocated Staff</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails['Vacancy.Staff.name']} </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Employer pay</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>{viewdetails['Vacancy.employer_to_pay']} </p>
                      </div>
                    </div>
                    {isAdmin && (
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <label>Candidate price</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col-6">
                          <p>{viewdetails['Vacancy.price']} </p>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Publish Status</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col-6">
                        <p>
                          {viewdetails['Vacancy.publish_status'] == 1
                            ? 'public'
                            : 'draft'}{' '}
                        </p>
                      </div>
                    </div>
                    {viewdetails['Vacancy.break_time'] > 0 && (
                      <div className="row">
                        <div className="col-md-3 col-sm-6">
                          <label>Break time</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col-6">
                          <p>{viewdetails['Vacancy.break_time']} </p>
                        </div>
                      </div>
                    )}
                    {viewdetails['Vacancy.is_bidding_option_needed'] == 1 && (
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Maximum candidate bidding amount</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col-3">
                          <p>
                            {
                              viewdetails[
                                'Vacancy.maximum_candidate_bidding_amount'
                              ]
                            }{' '}
                          </p>
                        </div>
                      </div>
                    )}
                    {viewdetails['Vacancy.is_bidding_option_needed'] == 1 && (
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Bidding expire date</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>
                        <div className="col-3">
                          <p>{viewdetails['Vacancy.bidding_expire_date']} </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          data-cy="bookinedcandidate"
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    data-cy="close-view-profile-modal"
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                  booking_id={SelectedBookingId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAgencycandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          data-cy="bookinedcandidate"
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    data-cy="close-view-profile-modal"
                    onClick={() => {
                      setshowAgencycandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <AgencyCandidateProfile
                  agency_candidate_id={SelectedCandidateId}
                  booking_id={SelectedBookingId}
                ></AgencyCandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
function mapStateToProps() {
  return {};
}
const connectedBookingList = connect(mapStateToProps)(BookingList);
export { connectedBookingList as BookingList };
