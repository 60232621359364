/* eslint-disable no-self-assign */
import React, { useEffect, useRef, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { PayoutAdd2 } from '../Payout/PayoutAdd2';
import { candidateActions, payoutActions, shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';

// eslint-disable-next-line no-unused-vars
let staffRows = [];

const PayoutList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [payoutData, setPayoutData] = useState({ rows: [] });
  const [payoutRows, setPayoutRows] = useState([]);
  const [showblock1, setshowblock1] = useState(false);
  const [showblock2, setshowblock2] = useState(false);
  const [shiftidsCount, setshiftidsCount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [printpdfLoading, setprintpdfLoading] = useState(false);
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  const [selected_id, setselected_id] = useState('');
  const [selected_payout_data, setselected_payout_data] = useState([]);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  //--- debouncing for searchkey only - start ---
  let isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query?.from_date_string, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch) {
        getAllPayout(1);
      }
    }
  }, [debouncedSearch]);
  //--- debouncing end ---
  useEffect(() => {
    getAllPayout(1);
  }, []);
  //-----to show edit invoice modal ----------
  const setEditPayoutModal = (value, _id) => {
    if (value == false) {
      getAllPayout(query.pageVo.pageNo);
    }
    setselected_id(_id);
    showPayoutModal(value);
  };
  //---------payout modal view show-----
  const showPayoutModal = value => {
    if (value == false) {
      window.location.reload();
    }
    setshowblock1(value);
    getAllPayout(query.pageVo.pageNo);
  };
  /**
   * Get all payout data
   **/
  const getAllPayout = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(payoutActions.payoutList(queryNew)).then(
      res => {
        setIsLoading(false);
        console.log('ressssss', res.data.rows);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //-------get all candidate data ----------
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    // setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(candidateActions.candidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination({ rows: res.data.rows });
        // setCandidateData({ rows: res.data.rows });
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for filters
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
      getAllCandidate();
    } else {
      isfirsttime.current = false;
      setQuery(prevstate => ({ ...prevstate, [name]: value }));
    }
  };
  //-------function to check if shift is selected ---------
  const check_ifSelected = _id => {
    for (let s = 0; s < selected_shiftIds.length; s++) {
      if (selected_shiftIds[s] == _id) {
        return true;
      }
    }
  };
  // shift check mark handler
  const handleChange_checked = (event, i, shift_id, payoutdata) => {
    // event.stopPropagation()
    const { name, checked } = event.target;
    if (name == 'shift_checked') {
      const { checked } = event.target;
      let new_selectedShiftIds = selected_shiftIds;
      let new_selected_payout_data = selected_payout_data;
      let csv_file_data = {
        'Employee first name': payoutdata['Candidate.name'],
        'Employee surname': '',
        'Employee NINO': payoutdata['Candidate.ni_number'],
        'Employee works number': payoutdata._id,
        'Employee ni_hour': payoutdata['Candidate.ni_hour'],
        // "Number of hours at an hourly rate set up within the Employer utility": payoutdata["Candidate.day_price"],
        'Total hours':
          payoutdata.total_hours > payoutdata['Candidate.ni_hour']
            ? payoutdata['Candidate.ni_hour']
            : payoutdata.total_hours,
        // "Total hours":  payoutdata.total_hours,
        'Payment 1 - Rate per hour (if different from standard rate)': (
          payoutdata.total_amount / payoutdata.total_hours
        ).toFixed(2),
        'UnPaid hours':
          payoutdata.total_hours > payoutdata['Candidate.ni_hour']
            ? payoutdata.unpaid_hours
            : 0,
        'UnPaid Amount':
          payoutdata.total_hours > payoutdata['Candidate.ni_hour']
            ? (
                payoutdata.unpaid_hours *
                (payoutdata.total_amount / payoutdata.total_hours)
              ).toFixed(2)
            : 0,
      };
      if (checked) {
        new_selectedShiftIds.push(shift_id);
        new_selected_payout_data.push(csv_file_data);
      } else {
        for (let k = 0; k < new_selectedShiftIds.length; k++) {
          if (new_selectedShiftIds[k] == shift_id) {
            new_selectedShiftIds.splice(k, 1);
            new_selected_payout_data.splice(k, 1);
          }
        }
      }
      setshiftidsCount(new_selectedShiftIds.length);
      setselected_shiftIds(new_selectedShiftIds);
      setselected_payout_data(new_selected_payout_data);
    } else if (name == 'trash') {
      if (checked) {
        setQuery({ ...query, status: 0 });
      } else {
        setQuery({ ...query, status: 1 });
      }
    }
  };
  // list payout data and pagination items
  const setListAndPagination = payoutData => {
    let payoutList = [];
    for (
      let j = 0;
      payoutData && payoutData.rows && j < payoutData.rows.length;
      j = j + 1
    ) {
      var d = new Date(parseInt(payoutData.rows[j].createdtime, 10));
      var ds = d.toString('MM/dd/yy HH:mm:ss');
      var a = d.toLocaleString('en-US', { timeZone: 'America/New_York' });
      console.log(ds);
      payoutData.rows[j].total_amount = payoutData.rows[j].total_amount;
      payoutList.push(
        <tr key={j}>
          <td>
            <div>
              <input
                data-cy="selectfordownloadcsv"
                type="checkbox"
                style={{ cursor: 'pointer' }}
                className=" checkboxheight "
                name="shift_checked"
                placeholder=""
                checked={check_ifSelected(payoutData.rows[j]._id)}
                onChange={e => {
                  handleChange_checked(
                    e,
                    j,
                    payoutData.rows[j]._id,
                    payoutData.rows[j],
                  );
                }}
              />
            </div>
          </td>
          <td>PY0{payoutData.rows[j]._id}</td>
          <td className="username">
            <div
              onClick={() => {
                setSelectedCandidateId(payoutData.rows[j].candidate_id),
                  setshowCandidateprofileModal(true);
              }}
              style={{ color: '#000', cursor: 'pointer' }}
            >
              {' '}
              {payoutData.rows[j]['Candidate.profileimage'] != null ? (
                <img
                  className=" round-emp-image"
                  src={
                    apiConstants.imgUrl +
                    payoutData.rows[j]['Candidate.profileimage']
                  }
                />
              ) : (
                <img
                  className=" round-emp-image"
                  src="/assets/img/recsy-fav-icon.png"
                />
              )}
              {payoutData.rows[j]['Candidate.name'] + ' '}{' '}
              {payoutData.rows[j]['Candidate.last_name']
                ? payoutData.rows[j]['Candidate.last_name']
                : ''}
            </div>
          </td>
          <td>{a} </td>
          <td>{payoutData.rows[j].from_date_string} </td>
          <td>{payoutData.rows[j].to_date_string} </td>
          <td>{payoutData.rows[j].total_hours.toFixed(2)}&nbsp;hrs </td>
          <td style={{ color: '#2DA463' }}>
            {payoutData.rows[j].paid_status == 1
              ? 'paid'
              : '£' +
                ' ' +
                (payoutData.rows[j].total_amount % 1 == 0
                  ? payoutData.rows[j].total_amount
                  : payoutData.rows[j].total_amount.toFixed(2))}
          </td>
          <td className="eventCell  eventView" data-cy="viewpayout">
            <a href={'/payout/' + payoutData.rows[j]._id}> view </a>
          </td>
          <td
            className="eventCell event Edit"
            data-cy="editpayout"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setEditPayoutModal(true, payoutData.rows[j]._id);
            }}
          >
            <div className="editButton">edit</div>
          </td>
        </tr>,
      );
    }
    setPayoutRows(payoutList);
    setPayoutData(payoutData);
  };
  // to show blocks
  const showblocks = () => {
    setshowblock1(false), setshowblock2(true);
  };
  // eslint-disable-next-line no-unused-vars
  const printShiftpdf = () => {
    setprintpdfLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.printShiftPdf({ Ids: selected_shiftIds })).then(
      res => {
        setprintpdfLoading(false);
        if (res && res.data && res.data.output_file_name) {
          window.open(apiConstants.shiftpdfUrl + res.data.output_file_name, '');
        }
      },
      () => {
        setprintpdfLoading(false);
      },
    );
  };
  // handler for sort payout list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  return (
    <div className="StaffList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row mt-20">
              <div className="col-md-8">
                <div className="page-title"> Payout</div>
              </div>
              <div className="col-md-4">
                <div
                  data-cy="payoutadd"
                  className="btn btn-primary-blue float-right"
                  onClick={() => {
                    setselected_id(''), setshowblock1(true);
                  }}
                >
                  <span> Generate Payout</span>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              {/* ======FROM DATE PICKER======== */}
              <div className="col-md-2">
                <div className={''}>
                  <input
                    type="date"
                    className="form-control2"
                    name="from_date_string"
                    placeholder="from"
                    value={query.from_date_string}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            <div className="card-header page-header">
              <div className="row d-flex justify-content-between">
                <div> {shiftidsCount} items selected</div>
                <div>
                  {selected_shiftIds.length > 0 && (
                    <div>
                      <div className="row">
                        <CsvDownload
                          style={{
                            border: 'none',
                            padding: '4px',
                            backgroundColor: '#f7f9fb',
                          }}
                          data={selected_payout_data}
                        >
                          Download CSV
                        </CsvDownload>
                        <div>
                          <img
                            style={{ height: 15, width: 15 }}
                            src="/assets/img/csv1.png"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-top1">#</th>
                    <th>Id</th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('candidate_name')}
                      className="sortHead"
                    >
                      Candidate
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('createdtime')}
                      className="sortHead"
                    >
                      Created date and time
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'createdtime' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'createdtime' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>From</th>
                    <th>To</th>
                    <th>Total hrs</th>
                    <th>Amount Paid</th>
                    <th></th>
                    <th className="border-top2"></th>
                  </tr>
                </thead>
                <tbody>{payoutRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {payoutData &&
              payoutData.rows &&
              payoutData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllPayout(data);
              }}
              paginationData={payoutData}
            />
          </div>
          {showblock2 && (
            <div
              className="modal "
              style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
            >
              <div className="modal-dialog ">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="close">
                      <a onClick={() => setshowblock1(false)}>
                        <i className=" fa fa-times-circle red fs-18"></i>
                      </a>
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12 col-sm-12">
                        <div className="card regular-table-with-color">
                          <div className="card-header page-header ">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="page-title">
                                  Generate timesheet
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 ">
                                <p>Candidate search</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-7 ">
                                <p>
                                  <div className="input-outer-div">
                                    <input
                                      className="search-input-div"
                                      value={query.searchKey}
                                      onChange={handleChange}
                                      type="text"
                                      name="searchKey"
                                      aria-label="Search"
                                      placeholder=" Search here..."
                                    />
                                    <a className="search_bar">
                                      <i
                                        className="fa fa-search in_bar"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </div>
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mt-20 ">
                                <a
                                  className="btn btn-primary float-right"
                                  href="/payoutadd/0"
                                >
                                  <div onClick={() => showblocks()}> Next</div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showblock1 && (
            <div
              className="modal "
              style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
            >
              <div className="modal-dialog modal-dialog-large">
                <div className="modal-content ">
                  <div className="modal-header">
                    <div className="close"></div>
                  </div>
                  <div className="modal-body">
                    <div className="bag">
                      <PayoutAdd2
                        showPayoutModal={showPayoutModal}
                        editId={selected_id}
                      ></PayoutAdd2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showCandidateprofileModal && (
            <div
              className="modal "
              style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
            >
              <div className="modal-dialog modal-dialog-90 ">
                <div className="modal-content ">
                  <div className="modal-header">
                    <div className="close">
                      <div
                        onClick={() => {
                          setshowCandidateprofileModal(false);
                        }}
                      >
                        <i className=" fa fa-times-circle red fs-18"></i>
                      </div>
                    </div>
                  </div>
                  <div className="modal-body">
                    <CandidateProfile
                      candidate_id={SelectedCandidateId}
                    ></CandidateProfile>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, staffData } = state.staff;
  return {
    requestStatus,
    staffData,
  };
}

const connectedPayoutList = connect(mapStateToProps)(PayoutList);
export { connectedPayoutList as PayoutList };
