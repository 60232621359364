import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text, 'infactio', data);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function shiftsAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addshifts', requestOptions).then(
    handleResponse,
  );
}
function shiftsEdit(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/editshifts', requestOptions).then(
    handleResponse,
  );
}
function updateShift(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/updateshift', requestOptions).then(
    handleResponse,
  );
}
function employerreportdownload(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/employerreportdownload',
    requestOptions,
  ).then(handleResponse);
}
function printShiftPdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/shiftpdfprint', requestOptions).then(
    handleResponse,
  );
}
function printShift(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/shiftpdfprintnew', requestOptions).then(
    handleResponse,
  );
}
function printInvoicePdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/invoicepdfprint', requestOptions).then(
    handleResponse,
  );
}

function printAgencyinvoicePdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/agencyinvoicepdfprint',
    requestOptions,
  ).then(handleResponse);
}

function printPayoutPdf(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/payoutpdfprint', requestOptions).then(
    handleResponse,
  );
}

//---add attendance-----
function addAttendance(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addattendance', requestOptions).then(
    handleResponse,
  );
}
function shiftsList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getshiftsList', requestOptions).then(
    handleResponse,
  );
}

function shiftsListforviewinvoice(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getshiftsListforviewinvoice',
    requestOptions,
  ).then(handleResponse);
}

function Allshiftreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/allshiftreport', requestOptions).then(
    handleResponse,
  );
}
function getAllCandiateWithShiftExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getallcandiatewithshiftexist',
    requestOptions,
  ).then(handleResponse);
}

function getAllCandiateWithShiftscount(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getAllCandiateWithShiftscount',
    requestOptions,
  ).then(handleResponse);
}
function getAllEmployerWithShiftExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getallemployerwithshiftexist',
    requestOptions,
  ).then(handleResponse);
}
function getAllAgencyShiftExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getallagencyshiftexist',
    requestOptions,
  ).then(handleResponse);
}
function upcomingandcompleteshiftsList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getUpcomingandCompleteShiftsList',
    requestOptions,
  ).then(handleResponse);
}

function shiftsListCalendar(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getshiftslistcalendar',
    requestOptions,
  ).then(handleResponse);
}
function shiftsListWeekly(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getshiftslistweekly',
    requestOptions,
  ).then(handleResponse);
}
function shiftsDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteshifts', requestOptions).then(
    handleResponse,
  );
}

function shiftsCancel(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/webcancelshift', requestOptions).then(
    handleResponse,
  );
}

function shiftsRestore(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/restoreshift', requestOptions).then(
    handleResponse,
  );
}
function timesheetShiftList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/timesheetShiftList',
    requestOptions,
  ).then(handleResponse);
}
function timesheetShiftListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/timesheetShiftListv2',
    requestOptions,
  ).then(handleResponse);
}

function reportShiftList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getreportShiftList',
    requestOptions,
  ).then(handleResponse);
}
function reportShiftListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getreportShiftListv2',
    requestOptions,
  ).then(handleResponse);
}

function getorganizationreportShiftList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getorganizationreportShiftList',
    requestOptions,
  ).then(handleResponse);
}
function Candidateshiftreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidatereport',
    requestOptions,
  ).then(handleResponse);
}

function Agencyshiftreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getagencyreport', requestOptions).then(
    handleResponse,
  );
}
const getShiftsCsvData = shifts => {
  let shiftList = [];
  if (!shifts) {
    return shiftList;
  }

  //for (let c = 0; c < shifts.length; c++) {
  // shifts.map(shift => {
  //shift is object
  Object.keys(shifts).map(shiftid => {
    // new_selectedShiftIds.push(shifts[shiftid]._id);
    const csv_file_data = {
      'Shift ID': shifts[shiftid]._id,
      'Candidate Name': shifts[shiftid]['Candidate.name'],
      'Candidate Last Name': shifts[shiftid]['Candidate.last_name'],
      'Check In Date': shifts[shiftid].check_in_date,
      'Check Out Date': shifts[shiftid].check_out_date,
      'Company Name': shifts[shiftid]['Employer.company_name'],
      'Employer Location': shifts[shiftid]['Businessunit.postcode'],
      'Signature Employer Name': shifts[shiftid].signature_employer_name,
      'Section Type': shifts[shiftid].section_type,
      'Employer To Pay': shifts[shiftid].employer_to_pay,
      'Is Break Time Pay': shifts[shiftid].is_break_time_pay,
      'Break Time': shifts[shiftid].break_time,
      'Actual Hours After Break Time':
        shifts[shiftid].actual_hours_after_break_time,
      Price: shifts[shiftid].price,
      'Total Price': shifts[shiftid].total_price,
    };
    shiftList.push(csv_file_data);
  });

  return shiftList;
};

export const shiftsService = {
  shiftsAdd,
  shiftsEdit,
  shiftsList,
  shiftsDelete,
  shiftsListCalendar,
  shiftsListWeekly,
  upcomingandcompleteshiftsList,
  timesheetShiftList,
  timesheetShiftListv2,
  reportShiftList,
  reportShiftListv2,
  Candidateshiftreport,
  Agencyshiftreport,
  addAttendance,
  updateShift,
  printShiftPdf,
  getorganizationreportShiftList,
  printInvoicePdf,
  Allshiftreport,
  shiftsCancel,
  shiftsRestore,
  getAllCandiateWithShiftExist,
  getAllEmployerWithShiftExist,
  printPayoutPdf,
  getAllAgencyShiftExist,
  printAgencyinvoicePdf,
  getAllCandiateWithShiftscount,
  employerreportdownload,
  shiftsListforviewinvoice,
  printShift,
  getShiftsCsvData,
};
