import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { BlockBookinglist } from '../Block_booking';
import { CandidateList } from '../Candidate';
import { NotificationList } from '../Notification';
import { AgencyUpcomingShift } from '../Shifts';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

const HomePage = props => {
  let navigate = useNavigate();
  const [isAdmin, setisAdmin] = useState(false);
  const [isAgency, setisAgency] = useState(false);
  const [isEmployer, setisEmployer] = useState(false);
  const [usercounts, setusercounts] = useState({});
  const [isStaff, setStaff] = useState(false);
  let id = '';
  // effect load to get dashboard data and ui
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        const { dispatch } = props;
        dispatch({
          type: 'isSidebarOpen',
          data: true,
        });
      }
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 4) {
        navigate('/dashboardemployer');
      }
      if (user.role == 4 || user.role == 7) {
        setisEmployer(true);
        id = user.employer_id;
      }
      if (user.role == 5) {
        setisAgency(true);
      }
      if (user.role == 6) {
        setStaff(true);
      }
      dashboarddata({ id: id });
    }
  }, []);
  // get dashboard counts data
  const dashboarddata = data => {
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/dashboardadmin', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(({ data }) => {
          setusercounts(data.data);
          resolve();
        })
        .catch(reject);
    });
  };

  return (
    <div className="col-md-12 pl-0 pr-0">
      <div></div>
      <div className=" homebg ">
        <div className="row">
          <div className="col-md-8">
            <div className="p-2">
              <div className="row ">
                {(isAdmin || isStaff) && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/candidateslist"
                      className="card border-left-primary shadow dashboard-box bg-gradient1"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon1">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/Group 37200.svg"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Candidates</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div1">
                              <p className="text-card-top">
                                {usercounts && usercounts.totalcandidatecount
                                  ? usercounts.totalcandidatecount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isEmployer && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/shiftmenu"
                      className="card border-left-primary shadow dashboard-box bg-gradient1"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon1">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/vac1.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Total Shifts</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div1">
                              <p className="text-card-top">
                                {usercounts && usercounts.carehome_total_shift
                                  ? usercounts.carehome_total_shift
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isAgency && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/agencyShifts/0"
                      className="card border-left-primary shadow  dashboard-box bg-gradient4"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon4">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/file-white.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Number of Shifts</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div4">
                              <p className="text-card-top">
                                {usercounts && usercounts.count_agency_shifts
                                  ? usercounts.count_agency_shifts
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isAgency && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/agencyShifts/upcomingshifts"
                      className="card border-left-primary shadow  dashboard-box bg-gradient2"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon2">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/file-white.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Upcoming Shifts</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div2">
                              <p className="text-card-top">
                                {usercounts &&
                                usercounts.count_agencyupcomming_shift
                                  ? usercounts.count_agencyupcomming_shift
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {(isAdmin || isStaff) && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/employerlist"
                      className="card border-left-primary shadow  dashboard-box bg-gradient2"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon2">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/Group 37199.svg"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Employers</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div2">
                              <p className="text-card-top">
                                {usercounts && usercounts.totalemployercount
                                  ? usercounts.totalemployercount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isEmployer && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/allshiftlist/unverified"
                      className="card border-left-primary shadow  dashboard-box bg-gradient2"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon2">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/file-white.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Unverified Shifts</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div2">
                              <p className="text-card-top">
                                {usercounts &&
                                usercounts.carehome_unverified_shift
                                  ? usercounts.carehome_unverified_shift
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
              {/* /=====SECOND CARD  ROW HERE ============= */}
              <div className="row">
                {(isAdmin || isStaff) && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/invoicelist"
                      className="card border-left-primary shadow  dashboard-box bg-gradient3"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon3">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/Group 37201.svg"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Invoices</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div3">
                              <p className="text-card-top">
                                {usercounts && usercounts.totalinvoicecount
                                  ? usercounts.totalinvoicecount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isEmployer && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/bookinglist/0"
                      className="card border-left-primary shadow  dashboard-box bg-gradient3"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon3">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/file-white.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Bookings</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div3">
                              <p className="text-card-top">
                                Total Booking{' '}
                                {usercounts && usercounts.booking_count
                                  ? usercounts.booking_count
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isAgency && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/agencybookinglist"
                      className="card border-left-primary shadow dashboard-box bg-gradient1"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon1">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/file-white.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Total Bookings</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div1">
                              <p className="text-card-top">
                                {usercounts && usercounts.agency_booking
                                  ? usercounts.agency_booking
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isAgency && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/AgencyvacancyList"
                      className="card border-left-primary shadow  dashboard-box bg-gradient3"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon3">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/vac1.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Total Vacancies</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div3">
                              <p className="text-card-top">
                                {usercounts && usercounts.vacancycount
                                  ? usercounts.vacancycount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {isEmployer && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      // to="/bookinglist/newbookings"
                      className="card border-left-primary shadow  dashboard-box bg-gradient4"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon4">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/file-white.png"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label"> New Bookings</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div4">
                              <p className="text-card-top">
                                {usercounts && usercounts.new_bookings
                                  ? usercounts.new_bookings
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
                {(isAdmin || isStaff) && (
                  <div className="col-xl-6 col-md-6 mb-4">
                    <Link
                      to="/vacancyshiftlist"
                      className="card border-left-primary shadow  dashboard-box bg-gradient4"
                    >
                      <div className="card-body card-body2">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="round-icon4">
                              <img
                                width="40"
                                height="40"
                                src="/assets/img/Group 37202.svg"
                              ></img>
                            </div>
                            <div className="text-xs font-weight-bold text-primary  ">
                              <p className="card-label">Vacancies</p>
                            </div>
                          </div>
                          <div className="col-auto align-self-start">
                            <div className="h3 mb-0 font-weight-bold card-top-div4">
                              <p className="text-card-top">
                                {usercounts && usercounts.vacancycount
                                  ? usercounts.vacancycount
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
            {(isAdmin || isStaff) && (
              <div>
                <div className="">
                  <CandidateList isHomepage={true}></CandidateList>
                </div>
              </div>
            )}
            {isEmployer && (
              <div>
                <div className="">
                  <BlockBookinglist isHomepage={true}></BlockBookinglist>
                </div>
              </div>
            )}
            {isAgency && (
              <div>
                <div className="">
                  <h4 className="font-weight-bold">Upcoming Shifts</h4>
                  <AgencyUpcomingShift></AgencyUpcomingShift>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4 vh100">
            <div className="pt-2 fh100">
              {(isAdmin || isStaff) && (
                <div className="notification-list fh100">
                  <NotificationList isAdmin={true} />
                </div>
              )}
              {isEmployer && (
                <div className="notification-list fh100">
                  <NotificationList iscarehome={true} />
                </div>
              )}
              {isAgency && (
                <div className="notification-list fh100">
                  <NotificationList isAgency={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  return {
    user,
    users,
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
