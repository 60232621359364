import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import { candidateActions, payoutActions, shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';

import AsyncSelect from 'react-select/async';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';
// import { candidateActions } from '../_actions';
import CsvDownload from 'react-json-to-csv';
import { useParams } from 'react-router-dom';
import { miscService } from '../_services';

// eslint-disable-next-line no-unused-vars
let employer_id = null;

const PayoutAdd2 = props => {
  const { id } = useParams();
  const [payout, setpayout] = useState({
    name: '',
    description: '',
    employer_id: '',
    candidate_id: '',
    employer: '',
    business_unit: 0,
    from: '',
    to: '',
    from_date_string: '',
    to_date_string: '',
    total_hours: 0,
    unpaid_hours: 0,
    shifts_Ids: [],
    payment_status: 'not paid',
  });
  const [candidateRows, setCandidateRows] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [selectedcandidate, setselectedcandidate] = useState([]);
  const [selected_payout_data, setselected_payout_data] = useState([]);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
    is_verified: 1,
    is_payout: 0,
  });
  const [submitted, setSubmitted] = useState(false);
  const [showblock1, setshowblock1] = useState(true);
  const [showblock2, setshowblock2] = useState(false);
  const [csvDownloadModal, setcsvDownloadModal] = useState(false);

  const [difCandidatefound, setdifCandidatefound] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [payoutRows, setpayoutRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [shiftdetails, setshiftdetails] = useState({});
  const [error, setError] = useState({ common: '' });
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
    }

    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
    }
    if (payout.from && payout.to && payout.user) {
      gettotalshifts();
    }

    //--------<<<for invoice edit >>>>>>------------
    // <<<<<<props.editId is propsed from invoicelist>>>>---------
    if (props.editId && props.editId !== 0 && props.editId !== '0') {
      let newquery = {};
      newquery._id = props.editId;
      (newquery.pageVo = {
        pageNo: 1,
        noOfItems: 15,
      }),
        // setQuery(query);
        setshowblock1(false);
      setshowblock2(true);

      getAllShiftsPayoutEdit(1);
      getAllPayout(1, newquery);
    }

    getAllCandiateWithShiftExist();
  }, [query, payout.from]);

  //-------------all shift generate invoice -----------
  useEffect(() => {
    console.log('useff 2', props.shifts_Ids);
    if (props.shifts_Ids) {
      setshowblock1(false);
      setshowblock2(true);
      getAllShifts();
    }
  }, [props.shifts_Ids]);

  //----------get all payout for edit purpose --------
  const getAllPayout = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    console.log(queryNew, 'kkok', queryTemp);
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(payoutActions.payoutList(queryNew)).then(
      res => {
        if (res && res.data.rows && res.data.rows.length > 0) {
          console.log('kalash started', res.data.rows[0]);
          // setpayout(res.data.rows[0]);
          setpayout(prevState => ({
            ...prevState,
            candidate_id: res.data.rows[0].candidate_id,
            employer_id: res.data.rows[0].employer_id,
            from_date_string: res.data.rows[0].from_date_string,
            to_date_string: res.data.rows[0].to_date_string,
            from: res.data.rows[0].from,
            to: res.data.rows[0].to,
            total_hours: res.data.rows[0].total_hours,
            total_amount: res.data.rows[0].total_amount.toFixed(2),
            payout_note: res.data.rows[0].payout_note,
            unpaid_hours: res.data.rows[0].unpaid_hours,
            additional_hours: res.data.rows[0].additional_hours,
            additional_hour_payment: res.data.rows[0].additional_hour_payment,
          }));
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //-----generate payout bulk-------
  const generatePayoutBulk = async () => {
    if (!payout.from_date_string && !payout.to_date_string) {
      setError({ common: ' choose a date ' });
      setTimeout(() => {
        setError({ common: '' });
      }, 5000);
      return;
    }

    let bulk_query = {
      from_date_string: payout.from_date_string,
      to_date_string: payout.to_date_string,
    };

    if (payout.candidate_id) {
      bulk_query.candidate_id = payout.candidate_id;
    }

    const { dispatch } = props;
    await dispatch(payoutActions.payoutBulkGenerate(bulk_query)).then(
      res => {
        console.log(res, 'reponse bulk pay gen');
        if (res && res.length && res.length > 0) {
          let payoutdata = res;
          let csv_array = [];
          for (let c = 0; c < payoutdata.length; c++) {
            console.log('enter loop csv');
            let csv_file_data = {
              'Employee first name': payoutdata[c].candidate.name,
              'Employee surname': '',
              'Employee NINO': payoutdata[c].candidate.ni_number,
              'Employee works number': payoutdata[c]._id,
              'Employee ni_hous': payoutdata[c].candidate.ni_hour,
              'Total hours':
                payoutdata[c].total_hours > payoutdata[c].candidate.ni_hour
                  ? payoutdata[c].candidate.ni_hour
                  : payoutdata[c].total_hours,
              'Payment 1 - Rate per hour (if different from standard rate)': (
                payoutdata[c].total_amount / payoutdata[c].total_hours
              ).toFixed(2),
              'UnPaid hours':
                payoutdata[c].total_hours > payoutdata[c].candidate.ni_hour
                  ? payoutdata[c].unpaid_hours
                  : 0,
              'UnPaid Amount':
                payoutdata[c].total_hours > payoutdata[c].candidate.ni_hour
                  ? payoutdata[c].unpaid_hours *
                    (
                      payoutdata[c].total_amount / payoutdata[c].total_hours
                    ).toFixed(2)
                  : 0,
            };
            csv_array.push(csv_file_data);
          }

          setselected_payout_data(csv_array);
          setcsvDownloadModal(true);
        }
      },
      err => {
        console.log(err);
      },
    );
  };

  const getAllShiftsPayoutEdit = async pageNo => {
    const { dispatch } = props;
    let query = {
      pageVo: {
        pageNo: pageNo,
        noOfItems: 150,
      },
    };
    if (props.editId) {
      query.payout_id = props.editId;
    }
    //-----------if shift_ids from allshift screen -----------
    if (props.shifts_Ids) {
      query.pageVo._ids = props.shifts_Ids;
    }
    setIsLoading(true);

    console.log(query, '-->>>query b4 shifpayout>>>');
    await dispatch(shiftsActions.shiftsList(query)).then(
      res => {
        setShiftList(res.data.rows);
        setListAndPagination(res.data.rows, false);

        if (res.data.rows && res.data.rows[0]) {
          //-------this is to set min and max dates from the res list to date filters-------------
          let max_date = '';
          let min_date = '';
          let All_shifts_ids = [];
          for (let l = 0; l < res.data.rows.length; l++) {
            All_shifts_ids.push(res.data.rows[l]._id);
            if (l == 0) {
              max_date = res.data.rows[0].check_in_date_string;
              min_date = res.data.rows[0].check_in_date_string;
            } else {
              console.log(max_date, 'drate');
              if (
                Date.parse(max_date) <
                Date.parse(res.data.rows[l].check_in_date_string)
              ) {
                max_date = res.data.rows[l].check_in_date_string;
              }
              if (
                Date.parse(min_date) >
                Date.parse(res.data.rows[l].check_in_date_string)
              ) {
                min_date = res.data.rows[l].check_in_date_string;
              }
            }
          }
          setpayout(prevState => ({
            ...prevState,
            all_shifts_ids: All_shifts_ids,
          }));
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //----------for consolidated payout details candidate shifts list -----
  const getAllCandiateWithShiftExist = async (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    const { dispatch } = props;
    queryNew.is_payout = 0;
    queryNew.invoice_generated = 1;

    setIsLoading(true);
    await dispatch(shiftsActions.getAllCandiateWithShiftExist(queryNew)).then(
      res => {
        console.log(res.data.rows, 'reeeeesp');

        setConsolidateListAndPagination(res.data);
        setCandidateData(res.data);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //---------bunt listssssss---------
  // const getAllBusinessUnit = employer_id => {
  //   const { dispatch } = props;
  //   dispatch(
  //     businessunitActions.businessunitList({
  //       status: 1,
  //       employer_id: employer_id,
  //     }),
  //   ).then(res => {
  //     let businessUnitData = res.data.rows;
  //     let businessUnitlist = [];
  //     for (let i = 0; i < businessUnitData.length; i++) {
  //       businessUnitlist.push(
  //         <option className="custom_option" value={businessUnitData[i]._id}>
  //           {businessUnitData[i].name}
  //         </option>,
  //       );
  //     }
  //     // setBusinessUnitrows(businessUnitlist);
  //   });
  // };

  //----------pchange 24 ti 12 hours format------f

  //-----async select employer -----
  const selectCandidate = (selectedOption, event) => {
    console.log(selectedOption.value, 'whosi ');
    setError({ common: '' });
    employer_id = selectedOption.value;
    // getAllBusinessUnit(selectedOption.value);
    // getAllCandiateWithShiftExist(1,selectedOption.value)
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      candidate_id: selectedOption.value,
    }));
    setpayout(prevState => ({
      ...prevState,
      [name]: selectedOption,
      candidate_id: selectedOption.value,
      user: selectedOption.value,
    }));
  };

  //---------get shfits data of candidate ---------
  const gettotalshifts = () => {
    const { dispatch } = props;

    dispatch(candidateActions.candidateshifts(payout)).then(
      res => {
        setIsLoading(false);
        console.log('shiftttt', res);
        setshiftdetails(res.data[0][0]);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };

  //----------get allshfit data ----------
  const getAllShifts = async candidate_id => {
    // setpayout({ ...payout, candidate_id: candidate_id, user: candidate_id })
    console.log(candidate_id, '>>>koolap');
    const { dispatch } = props;
    let query = {
      is_verified: 1,
      invoice_generated: 1,
      is_payout: 0,
      candidate_id: candidate_id,

      pageVo: {
        pageNo: 1,
        noOfItems: 150,
      },
    };
    if (payout.from_date_string) {
      query.from_date = payout.from_date_string;
    }
    if (payout.to_date_string) {
      query.end_date = payout.to_date_string;
    }
    //-----------if shift_ids from allshift screen -----------
    if (props.shifts_Ids) {
      query.pageVo._ids = props.shifts_Ids;
    }
    setIsLoading(true);

    console.log(query, '-->>>query b4 shifpayout>>>');
    await dispatch(shiftsActions.shiftsList(query)).then(
      res => {
        console.log(res.data.rows, 'reeeeesp');

        setShiftList(res.data.rows);
        setListAndPagination(res.data.rows, true);

        if (res.data.rows && res.data.rows[0]) {
          //-------this is to set min and max dates from the res list to date filters-------------
          let max_date = '';
          let min_date = '';
          let difCandidatefound = false;
          for (let l = 0; l < res.data.rows.length; l++) {
            if (l == 0) {
              max_date = res.data.rows[0].check_in_date_string;
              min_date = res.data.rows[0].check_in_date_string;
            } else {
              console.log(max_date, 'drate');
              if (
                Date.parse(max_date) <
                Date.parse(res.data.rows[l].check_in_date_string)
              ) {
                max_date = res.data.rows[l].check_in_date_string;
              }
              if (
                Date.parse(min_date) >
                Date.parse(res.data.rows[l].check_in_date_string)
              ) {
                min_date = res.data.rows[l].check_in_date_string;
              }
            }

            //---------check all the candidates are same
            if (
              res.data.rows[l].candidate_id !== res.data.rows[0].candidate_id
            ) {
              difCandidatefound = true;
            }
          }
          if (difCandidatefound) {
            setdifCandidatefound(true);
          }
          setpayout(prevState => ({
            ...prevState,
            from_date_string: min_date,
            to_date_string: max_date,
            candidate: res.data.rows[0].candidate_id,
            candidate_id: res.data.rows[0].candidate_id,
          }));
          console.log(max_date, 'mmaximum drate>>eh ehe ');
          console.log(min_date, ',minnnn drate>>eh ehe ');
        }
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //-------------pagination and data view consolidated list view ------------
  const setConsolidateListAndPagination = candidateData => {
    console.log('cannnnnn', candidateData);
    let ShiftExistList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      console.log('dataaccv---', candidateData);
      ShiftExistList.push(
        <tr key={j}>
          <td>
            {' '}
            {candidateData &&
            candidateData.rows &&
            candidateData.rows[j].profileimage ? (
              <img
                className=" round-emp-image"
                src={apiConstants.imgUrl + candidateData.rows[j].profileimage}
              />
            ) : (
              <img
                className=" round-emp-image"
                src="/assets/img/recsy-fav-icon.png"
              />
            )}
            {candidateData.rows[j].candidate[0]}{' '}
          </td>
          <td className="topay_text">{candidateData.rows[j].min_date}</td>
          <td className="topay_text">{candidateData.rows[j].max_date}</td>
          <td className="topay_text">
            {candidateData.rows[j].to_generate_payout}
          </td>
          <td className="topay_text ">
            <div
              className="allshift-viewdt "
              onClick={() => {
                showblocks2(candidateData.rows[j].candidate_id);
              }}
            >
              {' '}
              next{' '}
            </div>
          </td>
        </tr>,
      );
    }
    console.log('+++++candissss', ShiftExistList);
    setCandidateRows(ShiftExistList);
  };

  //--------------remove a shift item from payout list ------------
  const removePayoutItem = (data, j) => {
    console.log('kabi', j);
    let newshiftData = data;
    newshiftData.splice(j, 1);
    setListAndPagination(newshiftData, true);
  };

  //--------multipel date picker handle change ---------------
  const dateshandleChange = value => {
    console.log(value, '>>>>>valooo');
    console.log(value[0]?.format('YYYY-MM-DD'), '>>>>>valooo');
    console.log(value[1]?.format('YYYY-MM-DD'), '>>>>>valooo');
    let from_date_string = value[0]?.format('YYYY-MM-DD');
    let to_date_string = value[1]?.format('YYYY-MM-DD');

    setpayout(prevState => ({
      ...prevState,
      from_date_string: from_date_string,
      to_date_string: to_date_string,
    }));

    setQuery(prevState => ({
      ...prevState,
      from_date_string: from_date_string,

      to_date_string: to_date_string,
    }));
  };

  //----------set list of shfit rows ---------
  //<<<<<isedit = true ,,,,isedit parameter is passed for setting default  total_hour and total_amount of payout table (if isedit = true)>>>>>>>.
  //--<<<<<<<<<,isedit =false>>>>>.isedit parameter is passed for setting sum values of total_hours and total_amount from shfits >>>>>>.
  const setListAndPagination = (shiftDataList, isedit) => {
    let shiftList = [];
    let total_amount = 0;
    let total_hours_sum = 0;
    let total_price = 0;
    let shifts_ids = [];
    let difCandidatefound = false;
    for (
      let j = 0;
      shiftDataList && shiftDataList.length && j < shiftDataList.length;
      j = j + 1
    ) {
      shiftList.push(
        <tr key={j}>
          <td>
            <div className="row">
              <div className="col-md-4 e ">
                {' '}
                <div>
                  <img
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '55px',
                    }}
                    src={
                      shiftDataList[j]['Candidate.profile_image']
                        ? shiftDataList[j]['profile_image']
                        : '/assets/img/recsy-fav-icon.png'
                    }
                  ></img>
                </div>
              </div>
              <div className="col-md-5 d-flex align-items-center">
                <div className=" align-items-center">
                  {shiftDataList[j]['Candidate.name']}
                </div>
              </div>
            </div>
          </td>
          <td>{shiftDataList[j].check_in_date_string} </td>
          <td>{shiftDataList[j].price}</td>
          <td>
            {miscService.time24to12(shiftDataList[j].attendance_check_in)}
          </td>
          <td>
            {miscService.time24to12(shiftDataList[j].attendance_check_out)}
          </td>
          <td>{shiftDataList[j].break_time} min</td>
          <td>{shiftDataList[j].total_hours} hr</td>
          <td>
            {shiftDataList[j].actual_hours_after_break_time.toFixed(2)} hr
          </td>
          <td>{shiftDataList[j].total_price} </td>
          <td className="eventCell  event Edit">
            <div
              className="ml-2"
              onClick={() => {
                removePayoutItem(shiftDataList, j);
              }}
            >
              <i className=" fa fa-times-circle red fs-18"></i>
            </div>
          </td>
        </tr>,
      );

      //---------check all the candidates are same
      if (shiftDataList[j].candidate_id !== shiftDataList[0].candidate_id) {
        difCandidatefound = true;
      }
      shifts_ids.push(shiftDataList[j]._id);
      total_price =
        parseFloat(total_price) + parseFloat(shiftDataList[j].total_price);
      total_amount =
        parseFloat(total_amount) +
        parseFloat(shiftDataList[j].actual_hours_after_break_time) *
          parseFloat(shiftDataList[j].price);
      total_hours_sum =
        parseFloat(total_hours_sum) +
        parseFloat(shiftDataList[j].actual_hours_after_break_time);
    }
    //-----------set different candidate different found as true --------
    if (difCandidatefound) {
      setdifCandidatefound(true);
    } else {
      setdifCandidatefound(false);
    }

    //---------set unpaid hours for candidate -----------
    let unpaid_hours = 0;
    let paid_hours = 0;
    let candidate_ni_hour = 0;

    if (shiftDataList[0]) {
      candidate_ni_hour = parseInt(shiftDataList[0]['Candidate.ni_hour']);
    }
    total_hours_sum = parseFloat(total_hours_sum);

    if (total_hours_sum > candidate_ni_hour) {
      unpaid_hours = total_hours_sum - candidate_ni_hour;
      paid_hours = candidate_ni_hour;
    } else {
      paid_hours = total_hours_sum;
    }

    total_hours_sum = total_hours_sum.toFixed(2);
    total_price = total_price.toFixed(2);
    total_amount = total_amount.toFixed(2);

    // setTotalHoursSum(total_hours_sum);
    console.log(shifts_ids, 'thufan', total_amount);
    if (isedit) {
      setpayout(prevState => ({
        ...prevState,
        total_hours: total_hours_sum,
        total_amount: total_amount,
        shifts_ids: shifts_ids,
        total_price: total_price,
        unpaid_hours: unpaid_hours,
        paid_hours: paid_hours,
        _id:
          shiftDataList[0] &&
          shiftDataList[0].payout_id &&
          shiftDataList[0].payout_id !== 0 &&
          shiftDataList[0].payout_id !== '0'
            ? shiftDataList[0].payout_id
            : null,
      }));
    } else {
      setpayout(prevState => ({
        ...prevState,
        shifts_ids: shifts_ids,
        total_price: total_price,
        _id:
          shiftDataList[0] &&
          shiftDataList[0].payout_id &&
          shiftDataList[0].payout_id !== 0 &&
          shiftDataList[0].payout_id !== '0'
            ? shiftDataList[0].payout_id
            : null,
      }));
    }
    console.log('babu', shiftDataList);

    setpayoutRows(shiftList);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'additional_hour_payment') {
      let total_amount = 0;
      for (
        let j = 0;
        shiftList && shiftList.length && j < shiftList.length;
        j = j + 1
      ) {
        total_amount =
          parseFloat(total_amount) +
          parseFloat(shiftList[j].actual_hours_after_break_time) *
            parseFloat(shiftList[j].price);
      }
      total_amount = parseFloat(total_amount) + parseFloat(value ? value : 0);
      setpayout({
        ...payout,
        [name]: value,
        total_amount: total_amount,
      });
      return;
    }

    if (name == 'from_date_string' || name == 'to_date_string') {
      setQuery(prevState => ({ ...prevState, [name]: value }));
    }
    setpayout({
      ...payout,
      [name]: value,
    });
  };

  console.log(payout, 'kalash');
  const handleSubmit = event => {
    console.log(payout, '====payyyyyy');

    setSubmitted(true);
    event.preventDefault();
    const { dispatch } = props;
    let query_payout = payout;
    if (difCandidatefound) {
      setError({ common: 'this shifts belongs to different candidates' });
      setSubmitted(false);
      setTimeout(() => {
        setError({ common: '' });
      }, 5000);
      return false;
    }

    query_payout.candidate_id
      ? (query_payout.user = query_payout.candidate_id)
      : (query_payout.user = selectedcandidate.candidate_id);
    query_payout.user
      ? // eslint-disable-next-line no-self-assign
        (query_payout.user = query_payout.user)
      : (query_payout.user = query_payout.candidate_id),
      console.log(query_payout, 'querypa   ');
    if (
      query_payout.from_date_string &&
      query_payout.to_date_string &&
      query_payout.user &&
      query_payout.candidate_id &&
      query_payout.shifts_ids &&
      query_payout.total_hours &&
      query_payout.total_amount
    ) {
      // let queryNew = {}
      // queryNew.from = payout.from_date_string
      // queryNew.to = payout.to_date_string
      // queryNew.user = selectedcandidate.user
      // queryNew.total_hours = shiftdetails.total_hours

      query_payout.avg_price =
        query_payout.total_amount / query_payout.total_hours;
      setIsLoading(true);
      dispatch(payoutActions.payoutAdd(query_payout)).then(
        res => {
          if (res) {
            let payoutdata = res;
            let new_selected_payout_data = [];
            if (props.csvDownloadBulk) {
              //----------downlaod csv if payout add is via bulk -----
              let csv_file_data = {
                'Employee first name': payoutdata.Candidate.name,
                'Employee surname': '',
                'Employee NINO': payoutdata.Candidate.ni_number,
                'Employee works number': payoutdata._id,
                'Employee ni_hous': payoutdata.Candidate.ni_hour,
                'Number of hours at an hourly rate set up within the Employer utility':
                  payoutdata.Candidate.day_price,
                'Total hours':
                  payoutdata.total_hours > payoutdata.Candidate.ni_hour
                    ? payoutdata.Candidate.ni_hour
                    : payoutdata.total_hours,
                'Payment 1 - Rate per hour (if different from standard rate)': (
                  payoutdata.total_amount / payoutdata.total_hours
                ).toFixed(2),
                'Payment 1 - Description': payoutdata.payout_note,
                'Payment 1 - Number of normal hours': payoutdata.total_hours,
                'UnPaid hours':
                  payoutdata.total_hours > payoutdata.Candidate.ni_hour
                    ? payoutdata.unpaid_hours
                    : 0,
                'UnPaid Amount':
                  payoutdata.total_hours > payoutdata.Candidate.ni_hour
                    ? payoutdata.unpaid_hours *
                      (
                        payoutdata.total_amount / payoutdata.total_hours
                      ).toFixed(2)
                    : 0,
              };
              new_selected_payout_data.push(csv_file_data);

              setselected_payout_data(new_selected_payout_data);
              setcsvDownloadModal(true);
            } else {
              props.showPayoutModal(false);
            }
            // props.showPayoutModal(false)
            setIsLoading(false);
          }
        },
        err => {
          setError({
            common:
              err && err.message ? err.message : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'Something went wrong' });
      setTimeout(() => {
        setError({ common: '' });
      }, 5000);
    }
  };

  //------show block 2 on press next ------and hide block 1
  const showblocks2 = candidate_id => {
    console.log(candidate_id, '>>koolap');
    setpayout(prevState => ({
      ...prevState,
      candidate_id: candidate_id,
      user: candidate_id,
    }));
    setselectedcandidate({ candidate_id: candidate_id, user: candidate_id });

    setpayoutRows([]); //----to set invoice rows empty so going back won't show previous data-----

    getAllShifts(candidate_id);
    setshowblock1(false);
    setshowblock2(true);
  };

  //------show block 1 on press back icon in modal ------and hide block 2
  const showblocks1 = () => {
    setpayout(prevState => ({
      ...prevState,
      from: '',
      from_date_string: '',
      to: '',
      to_date_string: '',
    }));
    setshowblock1(true);
    setshowblock2(false);
  };

  return (
    <div className="InvoiceAdd">
      <div className="col-md-12 col-md-offset-2">
        <div className="card">
          <div className="top-bar">
            {showblock2 && !props.editId && (
              <div className="back-btn">
                <i
                  className="fa fa-chevron-circle-left fs-22"
                  onClick={() => {
                    showblocks1();
                  }}
                />
              </div>
            )}
            <div className="close-btn">
              <i
                data-cy="close-add-payout-modal"
                className="fa fa-times-circle red fs-18"
                onClick={() => {
                  props.showPayoutModal(false);
                }}
              />
            </div>
          </div>
          <div className="card-header page-header ">
            <div className="page-title-content">
              <h4 className="card-title mb-0 border-bottom">Add payout </h4>
            </div>
          </div>
          <div className="card-body">
            <form name="form">
              {showblock1 && (
                <div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">Select candidate </label>
                        <AsyncSelect
                          name="candidate_id"
                          onChange={selectCandidate}
                          // value={payout.employer}
                          cacheOptions
                          defaultOptions
                          loadOptions={getcandidatelist}
                          className="candidateselect"
                        />
                        {/*Required*/}
                        {submitted && !payout.employer && (
                          <div className="help-block">employer is required</div>
                        )}{' '}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="row"
                        style={{ marginBottom: '10px', marginTop: '15px' }}
                      >
                        <div className="col-md-6 pr-1">
                          <label htmlFor="type">Date</label>
                          <div
                            className={
                              'form-group ' +
                              (submitted &&
                              !payout.from_date_string &&
                              !payout.to_date_string
                                ? ' has-error'
                                : '')
                            }
                          >
                            <DatePicker
                              placeholder="select.."
                              range
                              onChange={dateshandleChange}
                              style={{
                                // backgroundColor: "aliceblue",
                                height: '44px',
                                borderRadius: '4px',
                                fontSize: '14px',
                                padding: '4px 10px',
                              }}
                              // value={Dates}
                              format="YYYY-MM-DD"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* here show  block 2 */}

                  <div className="ShiftExistList ">
                    <div>
                      <div className="payoutee">
                        <div className="">
                          <div className="">
                            {!isLoading && (
                              <table className="table table-hover">
                                <thead>
                                  <tr>
                                    <th className="border-top1">candidate</th>
                                    {/* <th>candidate</th> */}
                                    <th>From</th>
                                    <th>To</th>
                                    <th> To generate payout</th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>{candidateRows}</tbody>
                              </table>
                            )}
                            {!!isLoading && <LoadingImg />}

                            {candidateRows &&
                              candidateRows.length == 0 &&
                              !isLoading && (
                                <p className="margin-top-20 text-center">
                                  {' '}
                                  No available shifts to generate payout{' '}
                                </p>
                              )}
                          </div>
                          <div className="card-footer">
                            <Pagination
                              pageOnClick={data => {
                                getAllCandiateWithShiftExist(data);
                              }}
                              paginationData={candidateData}
                            />
                          </div>
                          {!!candidateData &&
                            !!candidateData.rows &&
                            !!candidateData.rows.length && (
                              <div>
                                <div
                                  data-cy="generatePayoutBulk"
                                  className="btn btn-primary-blue float-right"
                                  onClick={() => {
                                    generatePayoutBulk();
                                  }}
                                >
                                  <span> Generate Payout</span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* //------download csv block ---------- */}

                  {csvDownloadModal && (
                    <div
                      className="modal "
                      style={{
                        display: 'block',
                        background: ' rgb(10 11 11 / 50%)',
                      }}
                    >
                      <div className="modal-dialog modal-dialog-xsm">
                        <div className="modal-content">
                          <div className="modal-body">
                            <div className="bag">
                              <div className="row d-flex justify-content-center">
                                <div className="col-md-8">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      className="csv-icon"
                                      src="/assets/img/csv.png"
                                    ></img>
                                  </div>

                                  <p
                                    className="delete_string"
                                    style={{ color: 'black' }}
                                  >
                                    {' '}
                                    Do you want to download csv?{' '}
                                  </p>

                                  <div className="d-flex justify-content-center">
                                    <button
                                      className="button_delete width_100"
                                      onClick={() => {
                                        setcsvDownloadModal(false);
                                        props.showPayoutModal(false);
                                      }}
                                    >
                                      Close
                                    </button>

                                    <div className="row">
                                      <CsvDownload
                                        className="button_delete_close width_100"
                                        style={{
                                          border: 'none',
                                          padding: '4px',
                                          // backgroundColor: "#f7f9fb"
                                        }}
                                        data={selected_payout_data}
                                      >
                                        Download CSV
                                      </CsvDownload>
                                      {/* <div><img style={{ height: 15, width: 15, }} src="/assets/img/csv1.png" /></div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {error && error.common && (
                    <div className="d-flex justify-content-start red">
                      {error.common}
                    </div>
                  )}
                </div>
              )}
              {/* ===on click next show next block============= */}
              {showblock2 && (
                <div className="col-md-12">
                  <div className="row dflex align-items-center">
                    <div className="col-md-6">
                      <h4 className="cl-green">
                        {payout.employer &&
                          payout.employer &&
                          payout.employer.label &&
                          payout.employer.label}
                      </h4>
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="type">from</label>
                      <input
                        type="date"
                        className="form-control"
                        name="from_date_string"
                        placeholder="from"
                        value={payout.from_date_string}
                        // onChange={handleChange}
                        disabled
                      />
                      {submitted && !payout.from_date_string && (
                        <div className="help-block">from date is required</div>
                      )}{' '}
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="type" className="bold">
                        {' '}
                        to{' '}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        name="to_date_string"
                        placeholder="to"
                        value={payout.to_date_string}
                        disabled
                      />
                      {submitted && !payout.to_date_string && (
                        <div className="help-block">to date is required</div>
                      )}{' '}
                    </div>
                  </div>

                  <div className=" mt-2 rounded border">
                    <div className="  table-responsive main-card-body ">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th className="border-top1">name</th>
                            <th>Date</th>
                            <th>Price</th>
                            <th>Check in</th>
                            <th>Check out</th>
                            <th>Break time</th>
                            <th>Total hours</th>
                            <th>Payable hours</th>
                            <th>Total price</th>
                            <th className="border-top2"></th>
                          </tr>
                        </thead>
                        <tbody>{payoutRows}</tbody>
                      </table>
                      {payoutRows.length == 0 && !isLoading && (
                        <div className="d-flex justify-content-center">
                          {' '}
                          no signed shifts available
                        </div>
                      )}
                      {isLoading && <LoadingImg />}
                    </div>
                    <div className="p-2">
                      <div className="row d-flex justify-content-end ml-0 mr-0">
                        <div className="mr-3">
                          {' '}
                          <label htmlFor="type" className="bold">
                            {' '}
                            Total hr{' '}
                          </label>{' '}
                        </div>
                        <div>
                          <input
                            className="form-control"
                            type="number"
                            name="total_hours"
                            placeholder="Total hours"
                            value={payout.total_hours}
                            onChange={handleChange}
                          />
                          {submitted && !payout.total_hours && (
                            <div className="help-block">
                              {' '}
                              total hours is required{' '}
                            </div>
                          )}{' '}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-end ml-0 mr-0">
                        <div className="mr-3">
                          <label htmlFor="type" className="bold">
                            {' '}
                            Unpaid hrs{' '}
                          </label>
                        </div>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            name="unpaid_hours"
                            placeholder="Unapid hours"
                            value={payout.unpaid_hours}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-end ml-0 mr-0">
                        <div className="mr-3">
                          <label htmlFor="type" className="bold">
                            {' '}
                            Additional hours payment{' '}
                          </label>
                        </div>
                        <div>
                          <input
                            type="number"
                            className="form-control"
                            name="additional_hour_payment"
                            placeholder="Additional hour payment"
                            value={payout.additional_hour_payment}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* ===total price====== */}
                      <div className="row d-flex justify-content-end ml-0 mr-0">
                        <div className="mr-3">
                          <label htmlFor="type" className="bold">
                            {' '}
                            Total Amount{' '}
                          </label>
                        </div>
                        <div>
                          <input
                            className="form-control"
                            name="total_amount"
                            type="number"
                            placeholder="Total price"
                            value={payout.total_amount}
                            onChange={handleChange}
                          />
                          {submitted && !payout.total_amount && (
                            <div className="help-block">
                              total price is required
                            </div>
                          )}{' '}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-end ml-0 mr-0">
                        <div className="mr-3">
                          <label htmlFor="type" className="bold">
                            {' '}
                            Additional hours{' '}
                          </label>
                        </div>
                        <div>
                          <input
                            className="form-control"
                            name="additional_hours"
                            type="number"
                            placeholder="Additional hours"
                            value={payout.additional_hours}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* =======payout_note==== */}
                      <div className="row d-flex justify-content-end ml-0 mr-0">
                        <div className="mr-3">
                          <label htmlFor="type" className="bold">
                            {' '}
                            payout note{' '}
                          </label>
                        </div>
                        <div>
                          <input
                            type="to"
                            className="form-control"
                            name="payout_note"
                            placeholder="payout note"
                            value={payout.payout_note}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row d-flex justify-content-end ml-0 mr-0">
                        <div className="mr-3">
                          {' '}
                          {shiftList[0] &&
                            shiftList[0]['Candidate.working_limit'] && (
                              <>
                                *Right to work :{' '}
                                {shiftList[0]['Candidate.working_limit']}
                              </>
                            )}
                        </div>
                      </div>
                      {!submitted && !isLoading ? (
                        <div className="mt-4">
                          <div className="form-group d-flex justify-content-end">
                            <div
                              data-cy="add-payout"
                              className="btn btn-primary btn-wd"
                              onClick={handleSubmit}
                            >
                              {' '}
                              submit
                              {props.requestStatus ===
                                reducerConstants.INVOICE_REQUEST && (
                                <div className="lds-ring ml-2">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="mt-4">
                          <div className="form-group d-flex justify-content-end">
                            <div className="btn btn-primary btn-wd">
                              submit
                              {props.requestStatus ===
                                reducerConstants.INVOICE_REQUEST && (
                                <div className="lds-ring ml-2">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {error && error.common && (
                        <div className="d-flex justify-content-end red">
                          {error.common}
                        </div>
                      )}
                    </div>
                  </div>

                  {selected_payout_data && selected_payout_data.length && (
                    <div>
                      <div className="row">
                        <CsvDownload
                          style={{
                            border: 'none',
                            padding: '4px',
                            backgroundColor: '#f7f9fb',
                          }}
                          data={selected_payout_data}
                        >
                          Download CSV
                        </CsvDownload>
                        <div>
                          <img
                            style={{ height: 15, width: 15 }}
                            src="/assets/img/csv1.png"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const getcandidatelist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputValue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          for (let d = 0; d < data.candidate.data.rows.length; d++) {
            data.candidate.data.rows[d].cat_name =
              data.candidate.data.rows[d]['Category.name'];
          }

          resolve(
            data?.candidate?.data?.rows?.map(
              ({ _id, name, last_name, cat_name }) => ({
                value: _id,
                label:
                  name +
                  ' ' +
                  (last_name ? last_name : '') +
                  ' ' +
                  (cat_name ? '(' + cat_name + ')' : ''),
              }),
            ),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  return {};
}

const connectedPayoutAdd2 = connect(mapStateToProps)(PayoutAdd2);
export { connectedPayoutAdd2 as PayoutAdd2 };
