import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AllShiftsList } from '../Shifts';
import { agencycandidateActions, bookingActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants } from '../_constants';

const AgencyCandidateProfile = props => {
  const { id } = useParams();
  const [candidate, setcandidate] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
  });
  const [Item, setItem] = useState('details');
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [bookingData, setbookingData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [confirmLoading, setconfirmloading] = useState(false);
  const [rejectLoading, setrejectloading] = useState(false);

  useEffect(() => {
    if (props.agency_candidate_id) {
      getAllCandidate(1, query);
    }
    if (props.booking_id) {
      getAllBooking(props.bookingid);
    }
    if (props.candidate_id) {
      getAllCandidate(1, query);
    }
    if (id !== '0') {
      getAllCandidate(1, { ...query, status: 1, _id: id });
    }
    if (props.booking_id) {
      getAllBooking(props.bookingid);
    }
  }, []);

  const updtaebooking = (
    booking_status,
    booking_id,
    vacancy_id,
    agency_id,
    agency_user_id,
    agency_candidate_id,
  ) => {
    if (booking_status == 1) {
      setconfirmloading(true);
    } else {
      setrejectloading(true);
    }
    let querynew = {};
    if (booking_status) {
      querynew.booking_status = booking_status;
    }
    if (booking_id) {
      querynew.booking_id = booking_id;
    }
    if (vacancy_id) {
      querynew.vacancy_id = vacancy_id;
    }
    if (agency_id && agency_user_id) {
      querynew.agency_id = agency_id;
      querynew.agency_user_id = agency_user_id;
    }
    if (agency_candidate_id) {
      querynew.agency_candidate_id = agency_candidate_id;
    }
    const { dispatch } = props;
    ///-- update booking_status=0 to booking_status=1  (booking tbl)----------------
    dispatch(bookingActions.confirmBooking(querynew)).then(
      res => {
        console.log(res);
        setconfirmloading(false);
        setrejectloading(false);
        getAllBooking();
      },
      error => {
        console.log(error);
        setconfirmloading(false);
        setrejectloading(false);
      },
    );
  };
  // call for get bookings
  const getAllBooking = () => {
    const { dispatch } = props;
    dispatch(
      bookingActions.bookingList({ status: 1, _id: props.booking_id }),
    ).then(
      res => {
        setbookingData(res.data.rows[0]);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  const listItemClick = item => {
    setItem(item);
  };
  /**
   * Get user
   * */
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    // setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    if (props.agency_candidate_id) {
      queryNew._id = props.agency_candidate_id;
    }
    dispatch(agencycandidateActions.agencycandidateList(queryNew)).then(
      res => {
        setIsLoading(false);

        if (res && res.data) {
          setcandidate(res.data.rows[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // to get age from dob
  function getAge(dateString) {
    if (dateString) {
      // converting dateString in the format 'DD-MM-YYYY'
      const dateMoment = moment(dateString, 'DD-MM-YYYY');
      // Define the birthdate in a format like 'YYYY-MM-DD'
      const birthdate = dateMoment.format('YYYY-MM-DD');
      // Calculate the current date
      const currentDate = moment();
      // Calculate the age
      const age = currentDate.diff(moment(birthdate), 'years');
      return age;
    } else {
      return '--';
    }
  }
  return (
    <div className="Candidate" data-cy="agencycandidtaeprofile">
      {isLoading && <LoadingModal />}
      {!isLoading && (
        <div className="col-md-12">
          <div className="card regular-table-with-color">
            <div className="card-header page-header ">
              <div className="page-titile-content"></div>
            </div>
            <div className="card-body disp">
              <div className="row d-flex mb-10 ">
                <div className="col-md-12  dis-profile p-3">
                  <div className="col-md-3  ">
                    <div
                      onClick={() => {
                        listItemClick('details');
                      }}
                      className={
                        Item == 'details'
                          ? 'listBoxItemtop pointer activeListItem'
                          : 'listBoxItemtop pointer InactiveListItem'
                      }
                      data-cy="Details"
                    >
                      Details
                    </div>
                    <div
                      onClick={() => {
                        listItemClick('history');
                      }}
                      className={
                        Item == 'history'
                          ? 'listBoxItem pointer activeListItem'
                          : 'listBoxItem pointer InactiveListItem'
                      }
                      data-cy="Shift_History"
                    >
                      Shift History
                    </div>
                  </div>
                  <div className="col-md-9 col-sm-12">
                    <div className="item_div border-1">
                      {Item == 'details' && (
                        <>
                          <div className="profile_image"></div>
                          {/* //-----new image section -------------- */}
                          <div className="row">
                            <div className="col d-flex align-items-center">
                              {candidate.profileimage ? (
                                <img
                                  className=" round-emp-image-single"
                                  src={
                                    apiConstants.imgUrl + candidate.profileimage
                                  }
                                />
                              ) : (
                                <img
                                  className=" round-emp-image-single"
                                  src="/assets/img/recsy-fav-icon.png"
                                />
                              )}
                              <h4>
                                {' '}
                                {candidate.name +
                                  '(' +
                                  candidate?.Agency?.company_name +
                                  ')'}{' '}
                              </h4>
                            </div>
                            <div className="col d-grid-end">
                              <div className="">
                                <i className="fas fa-phone"></i>{' '}
                                {candidate.contact_number}
                              </div>
                              <div className="">
                                <i className="fas fa-envelope"></i>{' '}
                                {candidate.email}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className=""></div>
                            {bookingData.shiftscount > 0 && (
                              <div className="d-flex">
                                <div className="workedcandidate">
                                  {' '}
                                  <p style={{ fontWeight: 'bold' }}>
                                    Already worked
                                  </p>
                                </div>
                                <div>
                                  {' '}
                                  <img
                                    className="tick-mark"
                                    src="/assets/img/tick-mark2.png"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Ni number :</label>
                            </div>
                            <div className="col-6">
                              <p>
                                {/* {candidate.age} */}
                                {candidate.ni_number}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Age :</label>
                            </div>
                            <div className="col-6">
                              <p>{getAge(candidate.dob)}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Nationality :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.nationality}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Jobrole :</label>
                            </div>
                            <div className="col-6">
                              <p>
                                {candidate.Category && candidate.Category.name}
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Address :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.address}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>City :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.city}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Postcode :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.postcode}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Experience :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.experience}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className=" col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Vaccination status :</label>
                            </div>
                            <div className="col-6 ">
                              <p>{candidate.vaccination_status}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Visa type :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.visatype}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Gender :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.gender}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Working limit :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.working_limit}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Ni hour :</label>
                            </div>
                            <div className="col-6">
                              <p>{candidate.ni_hour}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>premium :</label>
                            </div>
                            <div className="col">
                              <p>{candidate.is_premium ? 'yes' : 'No'}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Service Sector :</label>
                            </div>
                            <div className="col">
                              <p>{candidate.service_type}</p>
                            </div>
                          </div>
                          {candidate.about && (
                            <div className="row">
                              <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                                <label>About :</label>
                              </div>
                              <div className="col">
                                <p>{candidate.about}</p>
                              </div>
                            </div>
                          )}
                          {candidate.lat && candidate.long && (
                            <div className="row">
                              <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                                <label>Location :</label>
                              </div>
                              <div className="col">
                                <a
                                  href={
                                    'https://www.google.com/maps/search/?api=1&query=' +
                                    candidate.lat +
                                    ',' +
                                    candidate.long
                                  }
                                >
                                  Open location
                                </a>
                              </div>
                            </div>
                          )}
                          {bookingData.booking_status != undefined && (
                            <div className="col">
                              {bookingData.booking_status == 0 && (
                                <div className="row">
                                  <div
                                    onClick={() => {
                                      updtaebooking(
                                        1,
                                        bookingData._id,
                                        bookingData.vacancy_id,
                                        bookingData.agency_id,
                                        bookingData['Agency.user_id'],
                                        bookingData.agency_candidate_id,
                                      );
                                    }}
                                    className="confirmm mr-2"
                                  >
                                    Confirm{' '}
                                    {confirmLoading && (
                                      <div className="lds-ring ml-2">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    onClick={() => {
                                      updtaebooking(2, bookingData._id);
                                    }}
                                    className="reject"
                                  >
                                    Reject
                                    {rejectLoading && (
                                      <div className="lds-ring ml-2">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              {bookingData.booking_status == 1 ? (
                                <div className="confirmed">Confirmed</div>
                              ) : (
                                bookingData.booking_status == 2 && (
                                  <div className="rejected">Rejected</div>
                                )
                              )}
                            </div>
                          )}
                        </>
                      )}
                      {/* //=====cv division ---------------- */}
                      {Item == 'history' && (
                        <div>
                          <AllShiftsList
                            isHistory={true}
                            agency_candidate_id={props?.match?.params?.id}
                            is_verified="1"
                            status="1"
                          ></AllShiftsList>
                        </div>
                      )}
                      {/* //=====cv division ---------------- */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, userData } = state.user;
  return {
    requestStatus,
    userData,
  };
}

const connectedAgencyCandidateProfile = connect(mapStateToProps)(
  AgencyCandidateProfile,
);
export { connectedAgencyCandidateProfile as AgencyCandidateProfile };
