import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { employerService } from '../_services';

const PhoneNumber = () => {
  const [error, setError] = useState({ common: '' });

  const [user, setUser] = useState({
    contact_number: '',
    company_name: '',
  });
  const [loading, setLoading] = useState(false);

  // Use useEffect to initialize the user state from localStorage
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
    }
  }, []);
  console.log(user, 'user from the phone number');
  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'contact_number' && value.length > 11) {
      return;
    }
    setUser(user => ({
      ...user,
      [name]: value,
    }));
    error[name] = '';
    setError(error);
    console.log(error);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (loading) return;
    setLoading(true);

    error.isError = false;
    console.log(user, 'event from the phone number');
    if (!user.contact_number || user.contact_number.length != 10) {
      error.contact_number = 'Please enter a valid phone Number';
      error.isError = true;
    }
    if (!user.company_name) {
      error.company_name = 'Company Name is required';
      error.isError = true;
    }
    if (error.isError) {
      setError(error);
      return;
    } else {
      error.isError = { common: '' };
      setError(error);
    }

    // Retrieve data from localStorage
    const userData = JSON.parse(localStorage.getItem('user'));
    console.log(userData, 'userData');
    //userData.phone = user.phone;
    // Now you can use the userData in the current component
    //console.log('User Data:', userData);
    try {
      const res = await employerService.employerAdd({
        contact_number: user.contact_number,
        _id: userData.employer_id,
        company_name: user.company_name,
      });
      if (res.error_code == 0) {
        window.location.href = '/dashboardemployer';
      } else {
        error.contact_number = res.message
          ? res.message
          : 'Phone number not updated';
        setError(error);
      }
    } catch (error) {
      setLoading(false);
      setError({
        common:
          typeof error == 'string'
            ? error
            : error.message
            ? error.message
            : 'Something went wrong',
      });
      console.log(error);
    }

    setLoading(false);
  };

  // // checking the contact number is valid
  // const valid_contact_number = phone_number => {
  //   phone_number = phone_number.toString().replace(/\s/g, '');
  //   // eslint-disable-next-line no-useless-escape
  //   var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  //   return regex.test(phone_number);
  // };

  return (
    <div className="container">
      <div className="d-flex flex-row justify-content-center mt-5">
        <div className="col-md-5">
          <h2
            className="fw-bold"
            style={{
              fontWeight: '700',
            }}
          >
            Enter Your Phone Number & Company Name
          </h2>
          <h2
            className="fw-bold"
            style={{
              fontWeight: '700',
            }}
          >
            make it more faster
          </h2>
          <div
            className={'form-group' + (error.company_name ? ' has-error' : '')}
          >
            <label htmlFor="company_name" className="mt-4">
              Company Name <span className="help-block"> * </span>
            </label>
            <input
              type="text"
              // className="input-textarea"
              className="form-control flx-common-input-fields"
              name="company_name"
              placeholder="Your company name"
              value={user.company_name}
              onChange={handleChange}
            />
            {error.company_name && (
              <div className="help-block">{error.company_name}</div>
            )}
          </div>

          <div
            className={
              'form-group' + (error.contact_number ? ' has-error' : '')
            }
          >
            <label htmlFor="contact_number">
              Phone Number <span className="help-block"> * </span>
            </label>
            <div className="d-flex flex-row input-group input-wrap-with-btn">
              <span className="fw-bold m-auto border-right px-2">+44</span>
              <input
                type="number"
                step="any"
                className="form-control border border-0 pl-1"
                placeholder=""
                name="contact_number"
                value={user.contact_number}
                onChange={handleChange}
              />
            </div>
            {error.contact_number && (
              <div className="help-block">{error.contact_number}</div>
            )}
          </div>
          <div className="d-flex flex-row justify-content-right">
            <Link to="/dashboardemployer" className="send-btn ">
              Skip
            </Link>

            <button onClick={handleSubmit} className={'send-btn  blue ml-2'}>
              {!loading ? 'Next' : 'Saving...'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return {
    requestStatus,
  };
}

const connectedPhoneNumber = connect(mapStateToProps)(PhoneNumber);
export { connectedPhoneNumber as PhoneNumber };
