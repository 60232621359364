import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const employerService = {
  employerAdd,
  employerList,
  employerListv2,
  employerDelete,
  dashbordemployerreport,
  checkemailExist,
  permanentemployerAdd,
  dashbordorganizationreport,
  getEmployerListforasync,
  addSubscription,
};
function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function employerAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addemployer', requestOptions).then(
    handleResponse,
  );
}
function addSubscription(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addsubscription', requestOptions).then(
    handleResponse,
  );
}
function permanentemployerAdd(data) {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: authHeader(),
  //   body: JSON.stringify(data),
  // };
  // return fetch(
  //   apiConstants.apiUrl + '/addpermanentemployer',
  //   requestOptions,
  // ).then(handleResponse);

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addpermanentemployer', requestOptions)
    .then(handleResponse)
    .then(user => {
      console.log(user.data);

      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
      }

      return user;
    });
}

function checkemailExist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/checkemployeremailexist',
    requestOptions,
  ).then(handleResponse);
}

function employerList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getemployerList', requestOptions).then(
    handleResponse,
  );
}

function getEmployerListforasync(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getEmployerListforasync',
    requestOptions,
  ).then(handleResponse);
}
function dashbordemployerreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordemployerreport',
    requestOptions,
  ).then(handleResponse);
}

function employerListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getemployerListv2', requestOptions).then(
    handleResponse,
  );
}
function employerDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteemployer', requestOptions).then(
    handleResponse,
  );
}

function dashbordorganizationreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordorganizationreport',
    requestOptions,
  ).then(handleResponse);
}
