import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { shiftsService } from '../_services';
export const shiftsActions = {
  shiftsAdd,
  shiftsList,
  shiftsDelete,
  shiftsListCalendar,
  shiftsListWeekly,
  timesheetShiftList,
  timesheetShiftListv2,
  getAllCandiateWithShiftscount,
  upcomingandcompleteshiftsList,
  getorganizationreportShiftList,
  printShift,
  reportShiftList,
  reportShiftListv2,
  Candidateshiftreport,
  Agencyshiftreport,
  employerreportdownload,
  addAttendance,
  updateShift,
  shiftsEdit,
  printShiftPdf,
  printInvoicePdf,
  printPayoutPdf,
  Allshiftreport,
  shiftsCancel,
  shiftsRestore,
  getAllCandiateWithShiftExist,
  getAllEmployerWithShiftExist,
  getAllAgencyShiftExist,
  printAgencyinvoicePdf,
  shiftsListforviewinvoice,
};

function shiftsAdd(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.SHIFTS_REQUEST, shiftsObj });

      shiftsService.shiftsAdd(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });

          if (data.error_code == 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function shiftsEdit(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.SHIFTS_REQUEST, shiftsObj });

      shiftsService.shiftsEdit(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });

          if (data.error_code == 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function updateShift(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.SHIFTS_REQUEST, shiftsObj });

      shiftsService.updateShift(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            reject(data.data);

            dispatch(alertActions.error(data.message.toString()));
          }
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function shiftsListforviewinvoice(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.shiftsListforviewinvoice(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function shiftsList(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.shiftsList(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function Allshiftreport(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.Allshiftreport(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function getAllCandiateWithShiftscount(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.getAllCandiateWithShiftscount(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function getAllCandiateWithShiftExist(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.getAllCandiateWithShiftExist(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function getAllEmployerWithShiftExist(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.getAllEmployerWithShiftExist(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function employerreportdownload(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.employerreportdownload(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
//----print shift s---------
function printShiftPdf(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.printShiftPdf(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function printShift(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.printShift(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
//----print invoice s---------
function printInvoicePdf(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.printInvoicePdf(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function printAgencyinvoicePdf(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.printAgencyinvoicePdf(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

//----print payout s---------
function printPayoutPdf(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.printPayoutPdf(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function upcomingandcompleteshiftsList(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.upcomingandcompleteshiftsList(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

//----get calendar based data from shifts---------
function shiftsListCalendar(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.shiftsListCalendar(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
//----get weekly shift  data from shifts---------
function shiftsListWeekly(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.shiftsListWeekly(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function shiftsDelete(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.shiftsDelete(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          console.log(data, '==>Delete data');
          dispatch(alertActions.success(data.message.toString()));
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function shiftsCancel(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.shiftsCancel(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          console.log(data, '==>CAncel data');
          dispatch(alertActions.success(data.message.toString()));
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function shiftsRestore(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.shiftsRestore(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          console.log(data, '==>restore data');
          dispatch(alertActions.success(data.message.toString()));
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

//---ad attendance----
function addAttendance(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.SHIFTS_REQUEST, shiftsObj });

      shiftsService.addAttendance(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function timesheetShiftList(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.timesheetShiftList(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function timesheetShiftListv2(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.timesheetShiftListv2(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function reportShiftList(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.reportShiftList(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function reportShiftListv2(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.reportShiftListv2(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function getorganizationreportShiftList(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.getorganizationreportShiftList(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
function Candidateshiftreport(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.Candidateshiftreport(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function Agencyshiftreport(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.Agencyshiftreport(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function getAllAgencyShiftExist(shiftsObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.SHIFTS_REQUEST,
        shiftsObj,
      });

      shiftsService.getAllAgencyShiftExist(shiftsObj).then(
        data => {
          dispatch({
            type: reducerConstants.SHIFTS_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.SHIFTS_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
