import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const payoutService = {
  payoutAdd,
  payoutList,
  payoutDelete,
  payoutBulkGenerate,
  generatenewpayout,
  getcandidatedetails,
  generatesinglepayout,
};
function handleResponse(response) {
  console.log(response, 'loopa');
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text, 'hecti');
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function payoutAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addpayout2', requestOptions).then(
    handleResponse,
  );
}
function payoutBulkGenerate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/payoutbulkgenerate',
    requestOptions,
  ).then(handleResponse);
}
function payoutList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getpayoutList', requestOptions).then(
    handleResponse,
  );
}
function payoutDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletepayout', requestOptions).then(
    handleResponse,
  );
}

function generatenewpayout(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/generatenewpayout', requestOptions).then(
    handleResponse,
  );
}

function generatesinglepayout(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/generatesinglepayout',
    requestOptions,
  ).then(handleResponse);
}

function getcandidatedetails(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidatedetails',
    requestOptions,
  ).then(handleResponse);
}
