import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
// const moment = require('moment');
// import { candidateActions } from '../_actions';
import { useParams } from 'react-router-dom';
import { payoutActions, shiftsActions } from '../_actions';
// eslint-disable-next-line no-unused-vars
let candidate_id = null;
const PayoutNew = props => {
  const { id } = useParams();

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);
  const [currentuser, setcurrentuser] = useState({});

  //   const [difCandidatefound, setdifCandidatefound] = useState(false);
  //   const [shiftList, setShiftList] = useState([]);
  const [candidateRows, setCandidateRows] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  //   const [showblock2, setshowblock2] = useState(false);
  const [payout, setpayout] = useState({});
  //   const [selected_payout_data, setselected_payout_data] = useState([]);
  //   const [payoutRows, setpayoutRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },

    payout_type: 'biweekly',
    from_date_string: formattedDate,
  });
  const [details, setdetails] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },

    payout_type: 'biweekly',
    from_date_string: formattedDate,
  });
  //   const [selectedcandidate, setselectedcandidate] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [upload, setUpload] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [candidates, setcandidates] = useState([]);
  //   const [error, setError] = useState({ common: '' });
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
    }
    console.log(isLoading);
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
    }
    getAllCandiateWithShiftExist();
    getcandidatedetails();
    // getAllPayout()
  }, [details, query.candidate_id]);

  const selectedCandidate = (selectedOption, event) => {
    console.log(selectedOption.value, 'whosi ');
    // setError({ common: '' });
    candidate_id = selectedOption.value;
    // getAllBusinessUnit(selectedOption.value);
    // getAllCandiateWithShiftExist(1,selectedOption.value)
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      candidate_id: selectedOption.value,
    }));
    setpayout(prevState => ({
      ...prevState,
      [name]: selectedOption,
      candidate_id: selectedOption.value,
      user: selectedOption.value,
    }));
  };
  const generatepayout = () => {
    setSubmitted(true);
    setUpload(true);
    const { dispatch } = props;

    console.log('length candidate ', candidates.length);
    // for (let i = 0; i < 1; i++) {
    for (let i = 0; i < candidates.length; i++) {
      setcurrentuser({
        user: candidates[i].first_name[0] + ' ' + candidates[i].last_name[0],

        index: i + 1,
      });

      let user = {};
      user.firstName = candidates[i].first_name[0];
      user.lastName = candidates[i].last_name[0];
      user.email = candidates[i].email[0];
      user.gender = candidates[i].gender[0] == 'Female' ? 'F' : 'M';
      user.NationalInsuranceNumber = candidates[i].ni_number[0];
      user.dateOfBirth = candidates[i].dob[0];
      user.phoneNumber = candidates[i].contact_number[0];
      console.log('candidate name --- 133', candidates[i]);
      user.candidate_id = candidates[i].candidate_id[0];
      user.Postcode = candidates[i].postcode[0];
      user.AddressLine1 = candidates[i].address[0];
      user.city = candidates[i].city[0];
      user.payout_type = details.payout_type;
      user.from_date = details.from_date_string;
      console.log('candidate name ---', user);
      dispatch(payoutActions.generatenewpayout(user)).then(
        res => {
          console.log(res, 'aasasassa');
          //   setcurrentuser({ user: '', index: '' });
          setIsLoading(false);
          props.showPayoutModal(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
    // setUpload(false);
  };

  const generatesinglepayout = candidate_id => {
    console.log('candiadte_iddd', candidate_id);
    setSubmitted(true);
    // setUpload(true);
    const { dispatch } = props;

    let data = {};
    data.candidate_id = candidate_id;
    data.from_date_string = details.from_date_string;
    data.payout_type = details.payout_type;

    dispatch(payoutActions.generatesinglepayout(data)).then(
      res => {
        console.log(res, 'aasasassa');
        //   setcurrentuser({ user: '', index: '' });
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );

    // setUpload(false);
  };

  const getcandidatedetails = () => {
    setSubmitted(true);

    const { dispatch } = props;
    if (details.payout_type && details.from_date_string) {
      dispatch(payoutActions.getcandidatedetails(details)).then(
        res => {
          console.log(res, 'res candidatre details');
          // let data=res.data

          setcandidates(res.data);
          // setIsLoading(false);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  };
  console.log(candidates, 'candidates state ------');
  //   const hideError = () => {
  //     setTimeout(() => {
  //       setError({ common: '' });
  //     }, 10000);
  //   };

  const handleChange = event => {
    const { name, value } = event.target;

    setdetails({
      ...details,
      [name]: value,
    });
  };

  // function getDateRange(date, option) {
  //   const currentDate = moment(date, 'YYYY-MM-DD');

  //   let start, end;
  //   console.log('hhhh wenter', start, end, date, option);
  //   if (option === 'weekly') {
  //     start = currentDate.clone().startOf('week');
  //     end = currentDate.clone().endOf('week');
  //   } else if (option === 'biweekly') {
  //     start = currentDate.clone().subtract(1, 'week').startOf('week');
  //     end = currentDate.clone().add(1, 'week').endOf('week');
  //   } else if (option === 'monthly') {
  //     start = currentDate.clone().startOf('month');
  //     end = currentDate.clone().endOf('month');
  //   } else {
  //     // Handle invalid option
  //     console.error('Invalid option');
  //     console.log('hhhh', 'invaid');
  //     return;
  //   }
  //   console.log('hhhh', start, end);
  //   return {
  //     start,
  //     end,
  //   };
  // }
  // function getDateRange(currentDay, type) {
  //   console.log('currentDay', currentDay);
  //   console.log('type', type);
  //   // console.log("calendarStart", calendarStart);
  //   const inputDate = new Date(currentDay);
  //   const calendarStartDate = new Date('2023 - 05 - 05');

  //   let weekStart, weekEnd, start, end;

  //   if (type === 'weekly') {
  //     weekStart = new Date(
  //       inputDate.setDate(inputDate.getDate() - inputDate.getDay() + 1),
  //     );
  //     weekEnd = new Date(inputDate.setDate(inputDate.getDate() + 6));
  //     end = weekEnd;
  //     start = weekStart;
  //   } else if (type === 'monthly') {
  //     const year = inputDate.getFullYear();
  //     const month = inputDate.getMonth();
  //     const monthEnd = new Date(year, month + 1, 0);
  //     const monthStart = new Date(year, month, 1);

  //     end = monthEnd;
  //     start = monthStart;
  //   } else if (type === 'biweekly') {
  //     const daysDiff = Math.floor(
  //       (inputDate - calendarStartDate) / (1000 * 60 * 60 * 24),
  //     );
  //     const weeksDiff = Math.floor(daysDiff / 14);
  //     const biweeklyStart = new Date(
  //       calendarStartDate.setDate(calendarStartDate.getDate() + weeksDiff * 14),
  //     );
  //     start = new Date(biweeklyStart);

  //     const biweeklyEnd = new Date(
  //       biweeklyStart.setDate(biweeklyStart.getDate() + 13),
  //     );

  //     end = biweeklyEnd;
  //   }

  //   console.log('start', start);
  //   console.log('end', end);
  //   // Format the dates as YYYY-MM-DD
  //   const formattedStart = start.toISOString().split('T')[0];

  //   const formattedEnd = end.toISOString().split('T')[0];

  //   // Return the start and end dates
  //   return { formattedStart, formattedEnd };
  // }
  function getDateRange(currentDay, type) {
    console.log('currentDay', currentDay);
    console.log('type', type);
    // console.log("calendarStart", calendarStart);
    const inputDate = new Date(currentDay);
    const calendarStartDate = new Date('2023-05-05');

    let weekStart, weekEnd, start, end;

    if (type === 'weekly') {
      weekStart = new Date(
        inputDate.setDate(inputDate.getDate() - inputDate.getDay() + 1),
      );
      weekEnd = new Date(inputDate.setDate(inputDate.getDate() + 6));
      end = weekEnd;
      start = weekStart;
    } else if (type === 'monthly') {
      const year = inputDate.getFullYear();
      const month = inputDate.getMonth();
      const monthEnd = new Date(year, month + 1, 0);
      const monthStart = new Date(year, month, 1);

      end = monthEnd;
      start = monthStart;
    } else if (type === 'biweekly') {
      const daysDiff = Math.floor(
        (inputDate - calendarStartDate) / (1000 * 60 * 60 * 24),
      );
      const weeksDiff = Math.floor(daysDiff / 14);
      const biweeklyStart = new Date(
        calendarStartDate.setDate(calendarStartDate.getDate() + weeksDiff * 14),
      );
      start = new Date(biweeklyStart);

      const biweeklyEnd = new Date(
        biweeklyStart.setDate(biweeklyStart.getDate() + 13),
      );

      end = biweeklyEnd;
    }

    console.log('start', start);
    console.log('end', end);

    // Format the dates as YYYY-MM-DD
    const formattedStart = formatDate(start);
    const formattedEnd = formatDate(end);

    // Function to format the date as 'YYYY-MM-DD'
    function formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    // Return the start and end dates
    return { formattedStart, formattedEnd };
  }

  //----------for consolidated payout details candidate shifts list -----
  const getAllCandiateWithShiftExist = async (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    const { dispatch } = props;
    queryNew.is_payout = 0;
    // queryNew.invoice_generated = 1;
    // queryNew.is_verified = 1;
    // delete queryNew.from_date_string;
    // delete queryNew.payout_type;
    const daterange = await getDateRange(
      details.from_date_string,
      details.payout_type,
    );
    console.log('hhhh', daterange);
    if (daterange.formattedStart) {
      // queryNew.from_date_string = daterange.start.format('YYYY-MM-DD');
      queryNew.from_date_string = daterange.formattedStart;
    }
    if (daterange.formattedEnd) {
      // queryNew.to_date_string = daterange.end.format('YYYY-MM-DD');
      queryNew.to_date_string = daterange.formattedEnd;
    }

    if (details.payout_type == 'biweekly') {
      queryNew.payout_date = 2;
    } else if (details.payout_type == 'weekly') {
      queryNew.payout_date = 1;
    } else {
      queryNew.payout_date = 3;
    }
    setIsLoading(true);
    await dispatch(shiftsActions.getAllCandiateWithShiftscount(queryNew)).then(
      res => {
        console.log(res.data.rows, 'reeeeesp');

        setConsolidateListAndPagination(res.data);
        setCandidateData(res.data);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  //-------------pagination and data view consolidated list view ------------
  const setConsolidateListAndPagination = candidateData => {
    console.log('cannnnnn', candidateData);
    let ShiftExistList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      console.log('dataaccv---', candidateData);
      ShiftExistList.push(
        <tr key={j}>
          <td>
            {' '}
            {candidateData &&
            candidateData.rows &&
            candidateData.rows[j].profileimage ? (
              <img
                className=" round-emp-image"
                src={apiConstants.imgUrl + candidateData.rows[j].profileimage}
              />
            ) : (
              <img
                className=" round-emp-image"
                src="/assets/img/recsy-fav-icon.png"
              />
            )}
            {candidateData.rows[j].candidate[0]}{' '}
          </td>

          <td className="topay_text">
            {candidateData.rows[j].to_generate_payout}
          </td>
          <td className="topay_text ">
            <div
              className="allshift-viewdt "
              onClick={() => {
                generatesinglepayout(candidateData.rows[j].candidate_id);
              }}
            >
              {' '}
              generate{' '}
            </div>
          </td>
        </tr>,
      );
    }
    console.log('+++++candissss', ShiftExistList);
    setCandidateRows(ShiftExistList);
  };
  //------show block 2 on press next ------and hide block 1
  //   const showblocks2 = candidate_id => {
  //     console.log(candidate_id, '>>koolap');
  //     setpayout(prevState => ({
  //       ...prevState,
  //       candidate_id: candidate_id,
  //       user: candidate_id,
  //     }));
  //     setselectedcandidate({ candidate_id: candidate_id, user: candidate_id });

  //     setpayoutRows([]); //----to set invoice rows empty so going back won't show previous data-----

  //     // getAllShifts(candidate_id);
  //     // setshowblock1(false);
  //     setshowblock2(true);
  //   };
  console.log(candidate_id, '>>koolap');

  return (
    <div className="InvoiceAdd">
      <div className="col-md-12 col-md-offset-2">
        <div className="card">
          <div className="top-bar">
            <div className="close-btn">
              <i
                className="fa fa-times-circle red fs-18"
                onClick={() => {
                  props.showPayoutModal(false);
                }}
              />
            </div>
          </div>
          <div className="card-header page-header ">
            <div className="page-title-content">
              <h4 className="card-title mb-0 border-bottom">Add payout </h4>
            </div>
          </div>
          <div className="card-body">
            <form name="form">
              {/* {showblock1 && ( */}
              <div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Select candidate</label>
                      <AsyncSelect
                        name="employer"
                        onChange={selectedCandidate}
                        value={payout.candidate}
                        cacheOptions
                        defaultOptions
                        loadOptions={getcandidatelist}
                      />
                      {/*Required*/}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Select Payout type</label>
                      <select
                        className="select col-md-6 selectdesign"
                        name="payout_type"
                        value={details.payout_type}
                        onChange={handleChange}
                      >
                        <option value="" hidden={true}>
                          {' '}
                          select
                        </option>
                        <option value="weekly">Weekly</option>
                        <option value="biweekly">Biweekly</option>
                        <option value="monthly">Monthly</option>
                      </select>
                      {submitted && !details.payout_type && (
                        <div className="help-block">
                          payout type is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="row"
                      style={{ marginBottom: '10px', marginTop: '15px' }}
                    >
                      <div className="col-md-6 pr-1">
                        <label htmlFor="type">Date</label>
                        {/* <div
                          className={
                            'form-group ' +
                            (submitted &&
                            !details.from_date_string &&
                            !details.to_date_string
                              ? ' has-error'
                              : '')
                          }
                        > */}
                        <input
                          type="date"
                          className="form-control2"
                          name="from_date_string"
                          placeholder="from"
                          value={details.from_date_string}
                          onChange={handleChange}
                        />
                      </div>
                      {submitted && !details.from_date_string && (
                        <div className="help-block">from date is required</div>
                      )}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="ShiftExistList ">
                  <div>
                    <div>
                      <div className="">
                        <div className="">
                          {!isLoading && (
                            <table className="table table-hover">
                              <thead>
                                <tr>
                                  <th className="border-top1">candidate</th>
                                  {/* <th>candidate</th> */}
                                  {/* <th>From</th>
                                  <th>To</th> */}
                                  <th> To generate payout</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>{candidateRows}</tbody>
                            </table>
                          )}
                          {!!isLoading && <LoadingImg />}

                          {candidateRows &&
                            candidateRows.length == 0 &&
                            !isLoading && (
                              <p className="margin-top-20 text-center">
                                {' '}
                                No available shifts to generate payout{' '}
                              </p>
                            )}
                        </div>
                        <div className="card-footer">
                          <Pagination
                            pageOnClick={data => {
                              getAllCandiateWithShiftExist(data);
                            }}
                            paginationData={candidateData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ShiftExistList ">
                  <div>
                    <div>
                      <div className="">
                        <div className=""></div>

                        <div>
                          <div
                            className="btn btn-primary-blue float-right"
                            onClick={() => {
                              generatepayout();
                            }}
                          >
                            <span> Generate Payout</span>
                          </div>
                        </div>

                        {upload && (
                          <div className="uploadding">
                            uploading ..... &nbsp;{currentuser.index}/
                            {candidates.length}
                            <br />
                            Current user uplaod &nbsp;:&nbsp;{currentuser.user}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
const getcandidatelist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputValue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          for (let d = 0; d < data.candidate.data.rows.length; d++) {
            data.candidate.data.rows[d].cat_name =
              data.candidate.data.rows[d]['Category.name'];
          }
          resolve(
            data.candidate.data.rows.map(
              ({ _id, name, last_name, cat_name }) => ({
                value: _id,
                label:
                  name +
                  ' ' +
                  (last_name ? last_name : '') +
                  ' ' +
                  (cat_name ? '(' + cat_name + ')' : ''),
              }),
            ),
          );
        }),
      )
      .catch(reject);
  });
function mapStateToProps() {
  return {};
}

const connectedPayoutNew = connect(mapStateToProps)(PayoutNew);
export { connectedPayoutNew as PayoutNew };
