import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { allocatecandidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';

const Candidateemployerallocation = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [allocatecandidate, setAllocatecandidate] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  useEffect(() => {
    if (id !== undefined) {
      query._id = id;
      setQuery(query);
      getAllAllocatecandidate(1, query);
    }
  }, []);
  /**
   * Get all allocatecandidates
   * */
  const getAllAllocatecandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(allocatecandidateActions.allocatecandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setAllocatecandidate(res.data.rows[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Allocatecandidate">
      {(props.requestStatus === reducerConstants.ALLOCATECANDIDATE_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Allocated Candidate </h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <label> Candidate Name</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{allocatecandidate['Candidate.name']}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <label> Carehome</label>
              </div>
              <div className="">
                <label>:</label>
              </div>
              <div className="col">
                <p>{allocatecandidate['Employer.company_name']}</p>
              </div>
            </div>
            <div className="row">
              <Link
                className="link"
                to={'/allocatecandidateadd/' + allocatecandidate._id}
                style={{ marginLeft: '15px' }}
              >
                Edit
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, allocatecandidateData } =
    state.candidateemployerallocation;
  return {
    requestStatus,
    allocatecandidateData,
  };
}

const connectedCandidateemployerallocation = connect(mapStateToProps)(
  Candidateemployerallocation,
);
export { connectedCandidateemployerallocation as Candidateemployerallocation };
