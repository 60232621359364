import React, { useEffect, useState } from 'react';
// import { faCalendar } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AgencyCandidateProfile } from '../AgencyCandidate/AgencyCandidateProfile';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { Shifts } from '../Shifts';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { miscService } from '../_services';
import { OrganizationReport } from './OrganizationReport';

// eslint-disable-next-line no-unused-vars
let shiftsRows = [];
let employer_id = '';
let user_role = '';

const AllShiftReport = props => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [consolidate_data, setconsolidate_data] = useState({});
  const [view_shift_id, Setview_shift_id] = useState();
  const [selecteddate, setselecteddate] = useState({
    from_date: '',
    to_date: '',
  });
  const [Reporttoggle, setReporttoggle] = useState(true);
  const [organizationReporttoggle, setorganizationReporttoggle] =
    useState(false);
  const [isParentEmployer, setisParentEmployer] = useState(false);
  const [filter, setfilter] = useState({ employer_id: '', candidate_id: '' });
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isEmployer, setisEmployer] = useState(false);
  const [IsBunit, setIsBunit] = useState(false);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [showAgencyCandidateprofileModal, setshowAgencyCandidateprofileModal] =
    useState(false);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    is_verified: 1,
  });
  useEffect(() => {
    if (id) {
      if (id == 'unverified') {
        query.is_accepted = 0;
      }
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        employer_id = user.employer_id;
        if (user.is_parent) {
          setisParentEmployer(true);
        }
      }
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 4) {
        setisEmployer(true);
      }
      if (user.role == 7) {
        setIsBunit(true);
      }
    }
    getAllShifts(1);
  }, [selecteddate, query, isAdmin]);

  const selectedcandidate = selectedOption => {
    setfilter({ ...filter, candidate_id: selectedOption.value });
    query.candidate_id = selectedOption.value;
    let newquery = {
      ...query,
      candidate_id: selectedOption.value,
      agency_id: 0,
    };
    getAllShifts(1, newquery);
  };

  const selectedagency = (selectedOption, event) => {
    console.log(event);
    // let name = event.name;
    setfilter({ ...filter, agency_id: selectedOption.value });
    query.candidate_id = selectedOption.value;
    let newquery = {
      ...query,
      agency_id: selectedOption.value,
      candidate_id: 0,
    };
    getAllShifts(1, newquery);
  };
  const handleChangetoggles = e => {
    console.log(e.currentTarget.value, 'ferno', e.target.name);
    if (e.currentTarget.value && e.target.name == 'tabs1') {
      console.log('enter inside');
      setReporttoggle(true);
      setorganizationReporttoggle(false);
    } else {
      setReporttoggle(false);
      setorganizationReporttoggle(true);
    }
  };

  const setEmployer = (selectedOption, event) => {
    console.log(event);
    let name = event.name;
    setfilter({ ...filter, employer_id: selectedOption.value });
    query.employer_id = selectedOption.value;
    let newquery = { ...query, [name]: selectedOption.value };
    getAllShifts(1, newquery);
  };

  /**   * ----Get all shiftss-----   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (id == 'unverified') {
      queryNew.is_accepted = 0;
    }
    if (selecteddate.from_date) {
      queryNew.from_date = selecteddate.from_date;
    }
    if (selecteddate.to_date) {
      queryNew.to_date = selecteddate.to_date;
    }
    if (queryNew.organization_id == 0) {
      delete queryNew.organization_id;
    }

    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.Allshiftreport(queryNew)).then(
      res => {
        setconsolidate_data(res?.data?.consolidate_data[0]);
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  let shiftsList = [];
  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      shiftsList.push(
        <tr key={j} className="row-body2">
          {shiftsData.rows[j].candidate_id && (
            <td className="username">
              <div
                onClick={() => {
                  setSelectedCandidateId(shiftsData.rows[j].candidate_id),
                    setshowCandidateprofileModal(true);
                }}
                style={{ color: '#000', cursor: 'pointer' }}
              >
                {shiftsData.rows[j]['Candidate.profileimage'] ? (
                  <img
                    className=" round-emp-image"
                    src={
                      apiConstants.imgUrl +
                      shiftsData.rows[j]['Candidate.profileimage']
                    }
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
                {shiftsData.rows[j]['Candidate.name']
                  ? shiftsData.rows[j]['Candidate.name'] +
                    ' ' +
                    (shiftsData.rows[j]['Candidate.last_name']
                      ? shiftsData.rows[j]['Candidate.last_name']
                      : '')
                  : ''}
              </div>
            </td>
          )}
          {shiftsData.rows[j].agency_candidate_id && (
            <td className="username">
              <div
                style={{ color: '#000', cursor: 'pointer' }}
                onClick={() => {
                  setSelectedCandidateId(
                    shiftsData.rows[j].agency_candidate_id,
                  ),
                    setshowAgencyCandidateprofileModal(true);
                }}
              >
                {shiftsData.rows[j]['Agencycandidate.profileimage'] ? (
                  <img
                    className=" round-emp-image"
                    src={
                      apiConstants.imgUrl +
                      shiftsData.rows[j]['Agencycandidate.profileimage']
                    }
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
                {shiftsData.rows[j]['Agency.company_name']
                  ? shiftsData.rows[j]['Agencycandidate.name'] +
                    ' ' +
                    '(' +
                    shiftsData.rows[j]['Agency.company_name'] +
                    ')'
                  : ''}
              </div>
            </td>
          )}
          <td style={{ width: '80px' }}>
            {moment(shiftsData.rows[j].check_in_date_string).format(
              'YYYY-MMM-DD',
            )}
          </td>
          <td>
            <div>
              {shiftsData.rows[j].section_type == 'Morning'
                ? 'Early'
                : shiftsData.rows[j].section_type == 'Evening'
                ? 'Late'
                : shiftsData.rows[j].section_type}
            </div>
            <div style={{ fontSize: '10px' }}>
              {miscService.time24to12(shiftsData.rows[j].check_in_time_string)}{' '}
              -{' '}
              {miscService.time24to12(shiftsData.rows[j].check_out_time_string)}
            </div>
          </td>
          <td>
            {miscService.time24to12(shiftsData.rows[j].attendance_check_in)}
          </td>
          <td>
            {miscService.time24to12(shiftsData.rows[j].attendance_check_out)}
          </td>
          <td>{shiftsData.rows[j].break_time}</td>
          <td>{shiftsData.rows[j].total_hours}</td>
          <td>{shiftsData.rows[j].actual_hours_after_break_time.toFixed(2)}</td>
          {(user_role == 1 || user_role == 6) && (
            <td>{shiftsData.rows[j].price} </td>
          )}
          {(user_role == 1 || user_role == 6) && (
            <td> {shiftsData.rows[j].employer_to_pay}</td>
          )}
          <td className="">
            {' '}
            {shiftsData.rows[j].is_verified ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-times"></i>
            )}
          </td>
          <td>
            {shiftsData.rows[j].invoice_id &&
            shiftsData.rows[j].invoice_generated == 1 ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-times"></i>
            )}
          </td>
          <td>{shiftsData.rows[j]['Employer.company_name']}</td>
          <td>
            <div
              data-cy="allshift-viewdt"
              className="allshift-viewdt"
              onClick={() => {
                Setview_shift_id(shiftsData.rows[j]._id),
                  setviewdetailsmodal(true);
              }}
            >
              View
            </div>
          </td>
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };

  const handleChange = event => {
    const { name, value } = event.target;
    if (name == 'invoice_generated') {
      setQuery({ ...query, invoice_generated: value });
    } else if (name == 'paid_staus') {
      setQuery({ ...query, paid_status: value });
    } else if (name == 'signed_staus') {
      setQuery({ ...query, signed_staus: value });
    } else if (name == 'branch') {
      setQuery({ ...query, branch: value });
    } else {
      setselecteddate({
        ...selecteddate,
        [name]: value,
      });
    }
  };

  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };

  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '26px',
    }),
  };

  return (
    <div className="ShiftList">
      <div className="tabsContainer">
        {isEmployer && (
          <div className="tabs">
            {/* //-------------first tab---------- */}
            <input
              type="radio"
              style={{ display: 'none' }}
              id="radio-1"
              name="tabs1"
              onChange={handleChangetoggles}
              checked={Reporttoggle ? true : false}
            />
            <label className="tab" htmlFor="radio-1">
              Report
            </label>
            {/* //----------second tab----------- */}
            <input
              type="radio"
              style={{ display: 'none' }}
              id="radio-2"
              name="tabs2"
              data-cy="organisation_report"
              onChange={handleChangetoggles}
              checked={organizationReporttoggle ? true : false}
            />
            <label className="tab" htmlFor="radio-2">
              Organisation Report
            </label>
            <span className="glider"></span>
          </div>
        )}
      </div>

      <div className="col-md-12">
        {Reporttoggle && (
          <div className="card regular-table-with-color m-0">
            <div
              className="card-body  table-responsive main-card-body p-0"
              style={{ paddingTop: 0 }}
            >
              <div className="  pt-4">
                <div className="filter-row ">
                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="">
                        {' '}
                        <label className="" htmlFor="type">
                          From
                        </label>
                      </div>
                      <div>
                        <input
                          type="date"
                          className="form-control "
                          name="from_date"
                          placeholder=""
                          value={selecteddate.from_date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          To
                        </label>
                      </div>
                      <div>
                        <input
                          type="date"
                          className="form-control "
                          name="to_date"
                          placeholder=""
                          value={selecteddate.to_date}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  {!isEmployer && !IsBunit && (
                    <div className="filter-item3">
                      <div className="" style={{}}>
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            Employer
                          </label>
                        </div>
                        <div>
                          <AsyncSelect
                            styles={customStyles}
                            name="employer_id"
                            onChange={setEmployer}
                            placeholder={'select employer'}
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerlist}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {!isEmployer && !IsBunit && (
                    <div className="filter-item3">
                      <div className="" style={{}}>
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            candidate
                          </label>
                        </div>
                        <div>
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedcandidate}
                            placeholder={'select candidate '}
                            cacheOptions
                            defaultOptions
                            // isMulti
                            loadOptions={getcandidatelist}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {isEmployer && isParentEmployer && (
                    <div className="filter-item3">
                      <div className="" style={{}}>
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            organisations
                          </label>
                        </div>
                        <div>
                          <AsyncSelect
                            styles={customStyles}
                            name="organization_id"
                            onChange={setEmployer}
                            placeholder={'select employer'}
                            cacheOptions
                            defaultOptions
                            className="organizationselect"
                            loadOptions={getEmployerlist}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Agency
                        </label>
                      </div>
                      <div>
                        <AsyncSelect
                          styles={customStyles}
                          name="agency_id"
                          onChange={selectedagency}
                          placeholder={'select agency'}
                          cacheOptions
                          defaultOptions
                          className="agencyselect"
                          // isMulti
                          loadOptions={getagencylist}
                        />
                      </div>
                    </div>
                  </div>
                  {(isEmployer || IsBunit) && (
                    <div className="filter-item3">
                      <div className="">
                        <div className="" style={{}}>
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            candidate dsfsdf
                          </label>
                        </div>
                        <div>
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedcandidate}
                            placeholder={'select candidate '}
                            className="candidateselect"
                            cacheOptions
                            defaultOptions
                            loadOptions={getshiftscandidatelist}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="">
                        <label className="ml-10 " htmlFor="type">
                          Invoice
                        </label>
                      </div>
                      <div className="select_bg_div">
                        <select
                          className="select col-md-12 selectdesign-report"
                          name="invoice_generated"
                          onChange={handleChange}
                        >
                          <option value="">All</option>
                          <option value={1}>yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Paid Status
                        </label>
                      </div>
                      <div className="select_bg_div">
                        <select
                          className="select col-md-12 selectdesign-report"
                          name="paid_staus"
                          onChange={handleChange}
                        >
                          <option value="">All</option>
                          <option value={1}>yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="filter-item3">
                    <div className="" style={{}}>
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Signed Status
                        </label>
                      </div>
                      <div className="select_bg_div">
                        <select
                          className="select col-md-12 selectdesign-report"
                          name="signed_staus"
                          onChange={handleChange}
                        >
                          <option value="">All</option>
                          <option value={1}>Signed</option>
                          <option value={0}>Unsigned</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {!IsBunit && !isEmployer && (
                    <div className="filter-item3">
                      <div className="" style={{}}>
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            Branch(businessunit)
                          </label>
                        </div>
                        <div>
                          <select
                            className="select col-md-12 selectdesign"
                            name="branch"
                            value={query.branch}
                            onChange={handleChange}
                          >
                            <option value="" hidden={true}>
                              {' '}
                              select
                            </option>
                            <option value=""> All</option>
                            <option value={'Basildon'}>Basildon</option>
                            <option value={'Wales'}>Wales</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {Reporttoggle && (
                <div className="mb-2">
                  <div className=" d-flex  flex-wrap gap-10 mt-2">
                    <div className="box-report">
                      Total shifts :{' '}
                      <label>&nbsp;{consolidate_data.no_of_shifts}</label>{' '}
                    </div>
                    <div className="box-report">
                      Total verified shifts :{' '}
                      <label>
                        &nbsp;{consolidate_data.no_of_shifts_verified}
                      </label>{' '}
                    </div>
                    <div className="box-report">
                      Total hours :{' '}
                      <label>
                        &nbsp;
                        {consolidate_data.total_hours
                          ? consolidate_data.total_hours
                          : 0}
                      </label>
                    </div>
                    <div className="box-report">
                      Total payable hours :{' '}
                      <label>
                        &nbsp;{consolidate_data.actual_hours_after_break_time}
                      </label>
                    </div>
                    <div className="box-report">
                      Total employer pay :{' '}
                      <label>
                        {' '}
                        &nbsp;{consolidate_data.total_employer_amount}
                      </label>{' '}
                    </div>
                    {!isEmployer && (
                      <div className="box-report">
                        Total candidate to pay :{' '}
                        <label>
                          {' '}
                          &nbsp;
                          {consolidate_data.total_candidate_amount
                            ? consolidate_data.total_candidate_amount
                            : 0}
                        </label>{' '}
                      </div>
                    )}
                    {!isEmployer && (
                      <div className="box-report">
                        Total paid amount:{' '}
                        <label>
                          {' '}
                          &nbsp;{consolidate_data.total_paid_amount}
                        </label>{' '}
                      </div>
                    )}
                    {!isEmployer && (
                      <div className="box-report">
                        Total invoiced amount :{' '}
                        <label>
                          {' '}
                          &nbsp;{consolidate_data.total_invoiced_amount}
                        </label>{' '}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {!isLoading && Reporttoggle && (
                <table className="table table-hover table-overflow flx-custom-table">
                  <thead>
                    <tr>
                      <th
                        style={{ width: '80px' }}
                        onClick={() => handleSort('candidate_name')}
                        className="sortHead border-top1"
                      >
                        Name
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'candidate_name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'candidate_name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th
                        onClick={() => handleSort('check_in_date_string')}
                        className="sortHead"
                      >
                        Date
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'check_in_date_string' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'check_in_date_string' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th>Shift</th>
                      <th>Check in</th>
                      <th>Check out</th>
                      <th>Break time</th>
                      <th>Total hours</th>
                      <th>Payable hours</th>
                      {(user_role == 1 || user_role == 6) && <th>Price</th>}
                      {(user_role == 1 || user_role == 6) && (
                        <th>Employer pay</th>
                      )}
                      <th>Timesheet</th>
                      <th>Invoice generated</th>
                      <th>Employer</th>
                      <th className="border-top2"></th>
                      {user_role != 1 && <th></th>}
                    </tr>
                  </thead>
                  <tbody>{shiftsRows}</tbody>
                </table>
              )}
              {isLoading && <LoadingImg />}
              {shiftsRows &&
                shiftsRows &&
                shiftsRows.length === 0 &&
                !isLoading && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
            </div>

            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllShifts(data);
                }}
                paginationData={shiftsData}
              />
            </div>
          </div>
        )}
        {organizationReporttoggle && isEmployer && (
          <OrganizationReport></OrganizationReport>
        )}
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setviewdetailsmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body m-3">
                <Shifts id={view_shift_id} />
              </div>
            </div>
          </div>
        </div>
      )}
      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAgencyCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowAgencyCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <AgencyCandidateProfile
                  agency_candidate_id={SelectedCandidateId}
                ></AgencyCandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    let parent_id = '';
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id && user.role == 4) {
        parent_id = user.employer_id;
      }
    }
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
        parent_id: parent_id,
        select: ['_id', 'company_name'],
        // is_organization_load_options: true,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        isverified: 1,
        searchKey: inputvalue,
        select: ['_id', 'name', 'last_name'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name, last_name }) => ({
              value: _id,
              label: name + ' ' + (last_name ? last_name : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });

const getagencylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        isverified: 1,
        searchKey: inputvalue,
        select: ['_id', 'company_name'],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

const getshiftscandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getshiftscandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        employer_id: employer_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(i => ({
              value: i['_id'],
              label:
                i['name'] +
                (i['last_name'] ? i['last_name'] : '') +
                (i['Category'] || i['city'] ? '(' : '') +
                (i['Category'] ? i['Category']['name'] : '') +
                (i['Category'] && i['city'] ? '-' : '') +
                (i['city'] ? i['city'] : '') +
                (i['Category'] || i['city'] ? ')' : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedAllShiftReport = connect(mapStateToProps)(AllShiftReport);
export { connectedAllShiftReport as AllShiftReport };
