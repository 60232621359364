// const crypto = require('crypto-browserify');

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Education } from '../Education/Education';
import { Experience } from '../Experience';
import { QualificationAndTraining } from '../QualificationAndTraining';
import { AllShiftsList } from '../Shifts';
import { bookingActions, candidateActions, miscActions } from '../_actions';
import { LoadingModal } from '../_components';
import { DocumentView } from '../_components/DocumentView';
import { apiConstants } from '../_constants';
import { CandidateAdd } from './CandidateAdd';

const CandidateProfile = props => {
  const { id } = useParams();
  const [candidate, setcandidate] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
  });
  const [Item, setItem] = useState('details');
  // eslint-disable-next-line no-unused-vars
  const [link, setLink] = useState('');
  const [candidateeditmodal, setcandidateeditmodal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showimage, setshowimage] = useState({ modal: false, url: '' });
  const [showDocument, setshowDocument] = useState({ modal: false, url: '' });
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
    is_candidate_with_docs: true,
  });
  const [bookingData, setbookingData] = useState({});
  const [hideedit, sethideedit] = useState(false);
  const [candidatefiles, setcandidatefiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmLoading, setconfirmloading] = useState(false);
  const [rejectLoading, setrejectloading] = useState(false);
  // effect to check the current user and
  // get details of user and booking
  // generate shareable profile link if status is not equals 0
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1 || user.role == 6) {
        sethideedit(true);
      }
    }
    if (props.status !== 0) {
      generateLink();
    }
    if (props.candidate_id) {
      getAllCandidate(1, query);
    }
    if (id !== '0') {
      getAllCandidate(1, { ...query, status: 1, _id: id });
    }
    if (props.booking_id) {
      getAllBooking(props.bookingid);
    }
  }, []);
  // update booking for confirm or reject candidate -
  // booking (id recieved via props from booking list)
  const updtaebooking = (
    booking_status,
    booking_id,
    vacancy_id,
    candidate_id,
    agency_id,
    candidate_user_id,
    agency_user_id,
    agency_candidate_id,
  ) => {
    if (booking_status == 1) {
      setconfirmloading(true);
    } else {
      setrejectloading(true);
    }
    let querynew = {};
    if (booking_status) {
      querynew.booking_status = booking_status;
    }
    if (booking_id) {
      querynew.booking_id = booking_id;
    }
    if (vacancy_id) {
      querynew.vacancy_id = vacancy_id;
    }
    if (agency_id && agency_user_id) {
      querynew.agency_id = agency_id;
      querynew.agency_user_id = agency_user_id;
    }
    if (candidate_id && candidate_user_id) {
      querynew.candidate_id = candidate_id;
      querynew.candidate_user_id = candidate_user_id;
    }
    if (agency_candidate_id) {
      querynew.agency_candidate_id = agency_candidate_id;
    }
    const { dispatch } = props;
    ///-- update booking_status=0 to booking_status=1  (booking tbl)----------------
    dispatch(bookingActions.confirmBooking(querynew)).then(
      res => {
        console.log(res);
        setconfirmloading(false);
        setrejectloading(false);
        getAllBooking();
      },
      error => {
        console.log(error);
        setconfirmloading(false);
        setrejectloading(false);
      },
    );
  };
  // generate shareable profile link
  const generateLink = () => {
    const { dispatch } = props;
    dispatch(miscActions.sharabletoken({ _id: props.candidate_id })).then(
      res => {
        const url = `${apiConstants.sharableurl}candidateprofilepublic/${res.data}`;
        setLink(url);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // call for fetch all booking by booking id
  const getAllBooking = booking_id => {
    console.log(booking_id);
    const { dispatch } = props;
    dispatch(
      bookingActions.bookingList({ status: 1, _id: props.booking_id }),
    ).then(
      res => {
        setbookingData(res.data.rows[0]);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for list item click and state the value
  const listItemClick = item => {
    setItem(item);
  };
  // to hide edit modal
  const hideeditmodal = () => {
    setcandidateeditmodal(false);
    listItemClick('details');
    getAllCandidate();
  };
  // get candidates
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (props.candidate_id) {
      queryNew._id = props.candidate_id;
    }
    if (props.status == 0) {
      delete queryNew.status;
      queryNew.deletedcandidate = true;
    }
    dispatch(candidateActions.candidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data) {
          setcandidate(res.data.rows[0]);
          setcandidatefiles(res.data.rows[0]?.document);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // to view documents as in modal
  const getViewUrl = url => {
    if (url) {
      return (
        <div
          data-cy="viewdoc"
          style={{ color: '#5588dd', cursor: 'pointer' }}
          onClick={() => {
            setshowDocument({ modal: 'true', url: url });
          }}
        >
          View
        </div>
      );
    } else {
      return '';
    }
  };
  // to copy link
  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  // get age of the candidate using date of birth
  function getAge(dateString) {
    if (dateString) {
      console.log(dateString, 'checking the dob');
      // converting dateString in the format 'DD-MM-YYYY'
      // const dateMoment = moment(dateString, 'DD-MM-YYYY');
      // // Define the birthdate in a format like 'YYYY-MM-DD'
      // const birthdate = dateMoment.format('YYYY-MM-DD');
      // Calculate the current date
      const currentDate = moment();
      // Calculate the age
      const age = currentDate.diff(moment(dateString), 'years');
      return age;
    } else {
      return '--';
    }
  }
  return (
    <div className="Candidate" data-cy="candidtaeprofile">
      {isLoading && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content"></div>
          </div>
          <div className="card-body disp">
            <div className="row d-flex mb-10 ">
              <div className="col-md-12  dis-profile p-3">
                <div className="col-md-3  ">
                  <div
                    data-cy="Details"
                    onClick={() => {
                      listItemClick('details');
                    }}
                    className={
                      Item == 'details'
                        ? 'listBoxItem top pointer activeListItem'
                        : 'listBoxItem top pointer InactiveListItem'
                    }
                  >
                    <img
                      className="flx-menu-icons flx-header-employer-menu-icon"
                      src="/assets/img/employer-icon.svg"
                      style={{ marginRight: '8px' }}
                    ></img>
                    Details
                  </div>
                  <div
                    data-cy="Documents"
                    onClick={() => {
                      listItemClick('CV');
                    }}
                    className={
                      Item == 'CV'
                        ? 'listBoxItem pointer activeListItem'
                        : 'listBoxItem pointer InactiveListItem'
                    }
                  >
                    <img
                      className="flx-menu-icons flx-header-employer-menu-icon"
                      src="/assets/img/dashboard-icon.svg"
                      style={{ marginRight: '8px' }}
                    ></img>
                    Documents
                  </div>
                  <div
                    onClick={() => {
                      listItemClick('history');
                    }}
                    data-cy="Shift_History"
                    className={
                      Item == 'history'
                        ? 'listBoxItem pointer activeListItem'
                        : 'listBoxItem pointer InactiveListItem'
                    }
                  >
                    <img
                      className="flx-menu-icons flx-header-employer-menu-icon"
                      src="/assets/img/shift-icon.svg"
                      style={{ marginRight: '8px' }}
                    ></img>
                    Shift History
                  </div>
                  <div
                    data-cy="Educations"
                    onClick={() => {
                      listItemClick('educations');
                    }}
                    className={
                      Item == 'educations'
                        ? 'listBoxItem pointer activeListItem'
                        : 'listBoxItem pointer InactiveListItem'
                    }
                  >
                    <img
                      className="flx-menu-icons flx-header-employer-menu-icon"
                      src="/assets/img/report-icon.svg"
                      style={{ marginRight: '8px' }}
                    ></img>
                    Educations
                  </div>
                  <div
                    data-cy="Experiences"
                    onClick={() => {
                      listItemClick('experiences');
                    }}
                    className={
                      Item == 'experiences'
                        ? 'listBoxItem pointer activeListItem'
                        : 'listBoxItem pointer InactiveListItem'
                    }
                  >
                    <img
                      className="flx-menu-icons flx-header-employer-menu-icon"
                      src="/assets/img/vacancy-icon.svg"
                      style={{ marginRight: '8px' }}
                    ></img>
                    Experiences
                  </div>
                  <div
                    data-cy="Certifications"
                    onClick={() => {
                      listItemClick('qualifications');
                    }}
                    className={
                      Item == 'qualifications'
                        ? 'listBoxItem pointer activeListItem'
                        : 'listBoxItem pointer InactiveListItem'
                    }
                  >
                    <img
                      className="flx-menu-icons flx-header-employer-menu-icon"
                      src="/assets/img/allocate-icon.svg"
                      style={{ marginRight: '8px' }}
                    ></img>
                    Certifications
                  </div>
                  <div
                    data-cy="Trainings"
                    onClick={() => {
                      listItemClick('trainings');
                    }}
                    className={
                      Item == 'trainings'
                        ? 'listBoxItem pointer activeListItem'
                        : 'listBoxItem pointer InactiveListItem'
                    }
                  >
                    <img
                      className="flx-menu-icons flx-header-employer-menu-icon"
                      src="/assets/img/payment-icon.svg"
                      style={{ marginRight: '8px' }}
                    ></img>
                    Trainings
                  </div>
                  <div>
                    {link && (
                      <div className="mt-20">
                        <p>Shareable Link:</p>
                        <div
                          className="sharablelinkdiv"
                          style={{ alignItems: 'center' }}
                        >
                          <div
                            style={{
                              display: 'block',
                              overflowX: 'scroll',

                              padding: 5,
                            }}
                          >
                            <textarea
                              className="sharablelink"
                              type="text"
                              rows="4"
                              cols="50"
                              disabled
                              value={link}
                              style={{
                                color: '#757aee',

                                marginRight: '0px',
                              }}
                            ></textarea>
                            <div className="">
                              <button
                                className="btn linkcopybtn"
                                data-cy="sharable-link"
                                disabled={copied}
                                onClick={() => handleCopy(link)}
                              >
                                {copied ? 'Copied!' : 'Copy'}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flx-vertical-hr"></div>

                <div className="col-md-7 col-sm-12">
                  <div className="item_div border-1">
                    {Item == 'details' && (
                      <>
                        <div className="profile_image"></div>
                        {/* //-----new image section -------------- */}
                        <div className="row">
                          <div className="col d-flex align-items-center">
                            {candidate?.profileimage ? (
                              <img
                                className=" round-emp-image-single"
                                src={
                                  apiConstants.imgUrl + candidate.profileimage
                                }
                              />
                            ) : (
                              <img
                                className=" round-emp-image-single"
                                src="/assets/img/un-avatar.png"
                              />
                            )}
                            <h4 className="txt_up">
                              {' '}
                              {candidate?.name + ' '}
                              {candidate?.last_name ? candidate?.last_name : ''}
                            </h4>
                          </div>
                          <div className="col d-grid-end">
                            <div className="">
                              <i className="fas fa-phone"></i>{' '}
                              {candidate?.contact_number}
                            </div>
                            <div className="">
                              <i className="fas fa-envelope"></i>{' '}
                              {candidate?.email}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex">
                          <div className="tag1">
                            {candidate?.isverified == 1
                              ? 'Verified '
                              : 'Not Verified '}
                            {candidate?.isverified == 1 && (
                              <img
                                src="/assets/img/check22.png"
                                style={{
                                  height: '10px',
                                  width: '10px',
                                  marginLeft: 5,
                                }}
                              />
                            )}
                          </div>
                          {bookingData.shiftscount > 0 && (
                            <div className="d-flex">
                              <div className="workedcandidate">
                                {' '}
                                <p style={{ fontWeight: 'bold' }}>
                                  Already worked
                                </p>
                              </div>
                              <div>
                                {' '}
                                <img
                                  className="tick-mark"
                                  src="/assets/img/tick-mark2.png"
                                />
                              </div>
                            </div>
                          )}
                          {hideedit && candidate.status == 1 && (
                            <div
                              className="tagedit ml-10"
                              onClick={() => {
                                setcandidateeditmodal(true);
                              }}
                            >
                              Edit
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Ni number :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.ni_number}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Membership No. :</label>
                          </div>
                          <div className="col-6">
                            <p>
                              {candidate?.membership_no
                                ? candidate.membership_no
                                : '--'}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Age :</label>
                          </div>
                          <div className="col-6">
                            <p>{getAge(candidate?.dob)}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Nationality :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.nationality}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Branch :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.branch}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Jobrole :</label>
                          </div>
                          <div className="col-6">
                            {candidate && <p>{candidate['Category.name']}</p>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Other Categories</label>
                          </div>
                          <div className="col-6">
                            <p>
                              {candidate?.other_categories &&
                                candidate.other_categories.map(i => {
                                  return i.name + ',';
                                })}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Address :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.address}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>City :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.city}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Postcode :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.postcode}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Experience :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.experience}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className=" col-lg-4 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Vaccination status:</label>
                          </div>
                          <div className="col-6 ">
                            <p>{candidate?.vaccination_status}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Visa type :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.visatype}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Gender :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.gender}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Working limit :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.working_limit}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Ni hour :</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate?.ni_hour}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>premium :</label>
                          </div>
                          <div className="col">
                            <p>{candidate?.is_premium ? 'yes' : 'No'}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                            <label>Service Sector :</label>
                          </div>
                          <div className="col">
                            <p>{candidate?.service_type}</p>
                          </div>
                        </div>
                        {candidate?.about && (
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>About :</label>
                            </div>
                            <div className="col">
                              <p>{candidate?.about}</p>
                            </div>
                          </div>
                        )}
                        {candidate?.lat && candidate?.long && (
                          <div className="row">
                            <div className="col-lg-3 col-xl-2 col-md-6 col-sm-6 col-6">
                              <label>Location :</label>
                            </div>
                            <div className="col">
                              <a
                                href={
                                  'https://www.google.com/maps/search/?api=1&query=' +
                                  candidate?.lat +
                                  ',' +
                                  candidate?.long
                                }
                              >
                                Open location
                              </a>
                            </div>
                          </div>
                        )}
                        {bookingData.booking_status != undefined && (
                          <div className="col">
                            {bookingData.booking_status == 0 && (
                              <div className="row">
                                <div
                                  onClick={() => {
                                    updtaebooking(
                                      1,
                                      bookingData._id,
                                      bookingData.vacancy_id,
                                      bookingData.candidate_id,
                                      bookingData.agency_id,
                                      bookingData['Candidate.user_id'],
                                      bookingData['Agency.user_id'],
                                      bookingData.agency_candidate_id,
                                    );
                                  }}
                                  className="confirmm mr-2"
                                >
                                  Confirm{' '}
                                  {confirmLoading && (
                                    <div className="lds-ring ml-2">
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onClick={() => {
                                    updtaebooking(2, bookingData._id);
                                  }}
                                  className="reject"
                                >
                                  Reject
                                  {rejectLoading && (
                                    <div className="lds-ring ml-2">
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                      <div></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {bookingData.booking_status == 1 ? (
                              <div className="confirmed">Confirmed</div>
                            ) : (
                              bookingData.booking_status == 2 && (
                                <div className="rejected">Rejected</div>
                              )
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {/* //=====cv division ---------------- */}
                    {Item == 'CV' && (
                      <>
                        {candidatefiles?.map((item, i) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <div
                              className="col-md-7"
                              data-cy="viewdocumentsection"
                            >
                              <div className="file_div">
                                <div className="w20p">PDF</div>
                                <div className="w60p">
                                  {candidatefiles[i].document_mode}
                                </div>
                                <div className="w40p">
                                  {getViewUrl(candidatefiles[i].document_name)}
                                </div>
                                <div className="w20picon">
                                  <div className="">
                                    <a
                                      className="downloadlink ml-0"
                                      href={
                                        apiConstants.imgUrl +
                                        candidatefiles[i].document_name
                                      }
                                    >
                                      <img
                                        style={{ height: 20, width: 20 }}
                                        src="/assets/img/download.png"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {(candidatefiles?.length == 0 ||
                          candidate.status == 0) && (
                          <div className="text-center">No files found</div>
                        )}
                      </>
                    )}
                    {/* //=====cv division ---------------- */}
                    {Item == 'history' && candidate.status == 1 && (
                      <div>
                        <AllShiftsList
                          isHistory={true}
                          candidate_id={query._id}
                          is_verified="1"
                          status="1"
                        ></AllShiftsList>
                      </div>
                    )}
                    {Item == 'history' && candidate.status == 0 && (
                      <div className="text-center">No shifts found</div>
                    )}
                    {/* //=====cv division ---------------- */}
                    {/* //=====eduaction division ---------------- */}
                    {Item == 'educations' && candidate.status == 1 && (
                      <div>
                        <Education candidate_id={query._id}></Education>
                      </div>
                    )}
                    {Item == 'educations' && candidate.status == 0 && (
                      <div className="text-center">No data found</div>
                    )}
                    {/* //=====eduaction division ---------------- */}
                    {/* //=====experience division ---------------- */}
                    {Item == 'experiences' && candidate.status == 1 && (
                      <div>
                        <Experience candidate_id={query._id}> </Experience>
                      </div>
                    )}
                    {Item == 'experiences' && candidate.status == 0 && (
                      <div className="text-center">No data found</div>
                    )}

                    {/* //=====experience division ---------------- */}
                    {Item == 'qualifications' && candidate.status == 1 && (
                      <div>
                        <QualificationAndTraining
                          candidate_id={query._id}
                          mode={1}
                        ></QualificationAndTraining>
                      </div>
                    )}
                    {Item == 'qualifications' && candidate.status == 0 && (
                      <div className="text-center">No data found</div>
                    )}
                    {Item == 'trainings' && candidate.status == 1 && (
                      <div>
                        <QualificationAndTraining
                          candidate_id={query._id}
                          mode={2}
                        ></QualificationAndTraining>
                      </div>
                    )}
                    {Item == 'trainings' && candidate.status == 0 && (
                      <div className="text-center">No data found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {candidateeditmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a onClick={() => setcandidateeditmodal(false)}>
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <CandidateAdd
                    candidate_id={candidate._id}
                    hideeditmodal={hideeditmodal}
                  ></CandidateAdd>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDocument.modal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog " style={{ maxWidth: '90%' }}>
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div className="close" data-cy="close-view-docs-modal">
                      <a onClick={() => setshowDocument({ modal: false })}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <DocumentView
                    url={apiConstants.imgUrl + showDocument.url}
                    onClose={() => setshowDocument({ modal: false })}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {showimage.modal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content ">
                <div className="modal-header border-bot-0">
                  <div className="   ">
                    <div className="close">
                      <a onClick={() => setshowimage({ modal: false })}>
                        <i className=" fa fa-times-circle close-button-modal "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="modal-body m-3">
                  <img
                    className="viewimage"
                    src={apiConstants.imgUrl + showimage.url}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, userData } = state.user;
  return {
    requestStatus,
    userData,
  };
}

const connectedCandidateProfile = connect(mapStateToProps)(CandidateProfile);
export { connectedCandidateProfile as CandidateProfile };
