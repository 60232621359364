import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../_actions';
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmpassword: '',
      submitted: false,
      currentpassword: '',
      showmodal: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeText = this.changeText.bind(this);
  }
  // call for change password action
  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    const { confirmpassword, password, currentpassword } = this.state;
    const { dispatch } = this.props;
    if (password && password === confirmpassword && currentpassword) {
      dispatch(userActions.changePassword({ currentpassword, password })).then(
        res => {
          if (res) {
            console.log(
              'resssss...................password update reposnse boss',
              res,
            );
            this.setState({ showmodal: true });
          }
        },
        // err => {
        //   console.log(
        //     'resssss......fdddddddd.............password update reposnse boss',
        //     err,
        //   );
        //   window.location.href = '/';
        // },
      );
    }
  }
  //-----------handlechage------------
  changeText(e) {
    console.log(e.target);
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }
  render() {
    const { requestStatus } = this.props;
    const { submitted, confirmpassword, currentpassword, password, showmodal } =
      this.state;
    return (
      <div className="CandidateAdd d-flex justify-content-center">
        <div className="col-md-10 col-md-offset-2">
          <div className="card ">
            <div className=" page-header ">
              <div className="page-title-content">
                <p className="card-title">Change Password </p>
              </div>
            </div>
            <div className="card-body">
              <form name="form" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div
                      className={
                        'form-group' +
                        (submitted && !currentpassword ? ' has-error' : '')
                      }
                    >
                      <label htmlFor="username">Current Password </label>
                      <input
                        type="password"
                        className="form-control"
                        name="currentpassword"
                        value={currentpassword}
                        onChange={this.changeText}
                      />
                      {submitted && !currentpassword && (
                        <div className="help-block">
                          current password is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div
                      className={
                        'form-group' +
                        (submitted && !password ? ' has-error' : '')
                      }
                    >
                      <label htmlFor="new password">New password </label>
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        value={password}
                        onChange={this.changeText}
                      />
                      {submitted && !password && (
                        <div className="help-block">
                          new password is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div
                      className={
                        'form-group' +
                        (submitted &&
                        (!confirmpassword || confirmpassword !== password)
                          ? ' has-error'
                          : '')
                      }
                    >
                      <label htmlFor="username">Confirm password </label>
                      <input
                        type="password"
                        className="form-control"
                        name="confirmpassword"
                        value={confirmpassword}
                        onChange={this.changeText}
                      />
                      {submitted && !confirmpassword && (
                        <div className="help-block">
                          confirm password is required
                        </div>
                      )}
                      {submitted && confirmpassword !== password && (
                        <div className="help-block">
                          Password and confirmpassword not match
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary-blue float-right mt-1"
                    data-cy="confirmnow"
                  >
                    Confirm Now
                  </button>
                  {requestStatus == 1 && (
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {showmodal && (
          <div
            // data-cy="sucessfulpopup"
            className="modal sucessfulpopup"
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-sm ">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <div
                      className="hidemodal"
                      data-cy="hidemodal"
                      onClick={() => {
                        {
                          this.setState({ showmodal: false });
                        }
                        this.props.setshowmodal(false);
                        window.location.href = '/login';
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body ">
                  <div className="text-center">
                    <img
                      className=" border-rad-55"
                      src="/assets/img/image.png"
                    ></img>
                    <h3
                      className="font-weight-bold"
                      style={{ fontSize: '20px' }}
                    >
                      Password Changed
                    </h3>
                    <h6 style={{ fontSize: '11px' }}>
                      Your password has been changed successfully{' '}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { requestStatus } = state.misc;
  return {
    requestStatus,
  };
}

const connectedResetPassword = connect(mapStateToProps)(ResetPassword);
export { connectedResetPassword as ResetPassword };
