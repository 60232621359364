export const ShiftSummary = () => {
  return (
    <>
      <div className="container flx-job-post-main-container">
        <h2 className="text-center">Post Agency Shift</h2>
        <div className="col-lg-8 col-xl-8 col-md-10 col-sm-12 flx-job-post-section">
          <p className="text-center mb-22">Summary</p>
          <div className="container mt-4">
            <div className="flx-table-res-container flx-summary-table-responsive">
              <table className="table flx-shift-table-summary">
                <tbody>
                  <tr>
                    <td>
                      <div className="flx-summary-table-date">
                        {' '}
                        15-Dec-2023,
                      </div>
                      <div className="flx-summary-table-position">
                        Healthcare assistant
                        <span className="flx-summary-quantity-role">x2</span>
                      </div>
                    </td>
                    <td>
                      <div className="flx-shift-final-job-timings">
                        9.00-16.00
                      </div>
                      <div className="flx-shift-final-job-shiftname">
                        Morning Shift
                      </div>
                    </td>
                    <td>
                      <div className="flx-summary-table-date"> Hours</div>
                      <div className="flx-summary-table-position">
                        10
                        <span className="flx-summary-quantity-role">x2</span>
                      </div>
                    </td>
                    <td className="flx-summary-rate-per-hour">£18/h</td>
                    <td>
                      <div>
                        <img
                          className="flx-immediate-cover-icon"
                          src="/assets/img/flx-immediate-cover.svg"
                        />
                        <img
                          className="flx-paid-break-icon"
                          src="/assets/img/flx-paid-break.svg"
                        />
                      </div>
                    </td>
                    <td className="flx-sum-table-total-rate">
                      <div className="flx-summary-table-date"> Total</div>
                      <div className="flx-summary-table-position">£360.00</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="flx-summary-table-date">
                        {' '}
                        16-Dec-2023,
                      </div>
                      <div className="flx-summary-table-position">
                        RGN
                        <span className="flx-summary-quantity-role">x2</span>
                      </div>
                    </td>
                    <td>
                      <div className="flx-shift-final-job-timings">
                        16.00-23.00
                      </div>
                      <div className="flx-shift-final-job-shiftname">
                        Evening Shift
                      </div>
                    </td>
                    <td>
                      <div className="flx-summary-table-date"> Hours</div>
                      <div className="flx-summary-table-position">
                        10
                        <span className="flx-summary-quantity-role">x2</span>
                      </div>
                    </td>
                    <td className="flx-summary-rate-per-hour">£18/h</td>
                    <td>
                      <div>
                        <img
                          className="flx-immediate-cover-icon"
                          src="/assets/img/flx-immediate-cover.svg"
                        />
                        <img
                          className="flx-paid-break-icon"
                          src="/assets/img/flx-paid-break.svg"
                        />
                      </div>
                    </td>
                    <td className="flx-sum-table-total-rate">
                      <div className="flx-summary-table-date"> Total</div>
                      <div className="flx-summary-table-position">£360.00</div>
                    </td>
                  </tr>
                  <tr className="flx-subtotal-section">
                    <td colSpan="5" className="flx-shift-sub-total-text-end">
                      Sub total
                    </td>
                    <td className="flx-shift-total-rate">£640.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="flx-job-post-nxt-back-btn-section">
            <button className="btn btn-sm flx-job-post-back-btn">Back</button>
            <button className="btn btn-sm flx-recs-primary-btn flx-job-post-next-btn float-right">
              Publish Now <i className="fa fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
