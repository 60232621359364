import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { userActions } from '../_actions';
import { LoadingModal } from '../_components';
import {
  alertConstants,
  apiConstants,
  reducerConstants,
  roleContant,
} from '../_constants';

import { FileUpload } from '../fileupload/FileUpload';

const ProfileDetails = props => {
  let navigate = useNavigate();
  const [role, setRole] = useState(false);
  const { id } = useParams();
  const [profile, setprofile] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  const [error, setError] = useState({ common: '' });
  const [submitted, setSubmitted] = useState(false);
  // effect get all users
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllUser(1, query);
    }
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllUser(1, query);
    }
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      setRole(userData.role);
    }
  }, []);
  // handler for state profile
  const handleChange = event => {
    setError({ common: '' });
    const { name, value } = event.target;
    if (name == 'secondary_number' && value.length > 11) {
      return;
    }
    setprofile({
      ...profile,
      [name]: value,
    });
  };
  // phone number validation
  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    // eslint-disable-next-line no-useless-escape
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };
  // submit action to add user and validation checking
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    let contact_number = valid_contact_number(profile.phone);
    if (contact_number == false) {
      setError({ common: 'Phone number not valid' });
      return;
    }
    if (profile.email && profile.name && profile.phone) {
      setIsLoading(true);
      dispatch(userActions.userAdd(profile)).then(
        res => {
          console.log(res, 'ressssssssssssssss amdin');
          navigate('/');
          setIsLoading(false);
        },
        err => {
          console.log(err);
          setError({
            common:
              err && err.message ? err.message : alertConstants.SERVER_ERROR,
          });
          setIsLoading(false);
        },
      );
    } else {
      setError({
        common: 'Please complete the form',
      });
    }
  };
  // upload file response handler
  const getuploadresponse = response => {
    if (response && response.file_name) {
      setprofile(prevState => ({
        ...prevState,
        profileimage: response.file_name,
      }));
    }
    setshowmodalfileupload(false);
  };
  /**
   * Get user
   * */
  const getAllUser = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(userActions.userList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setprofile(res.data.rows[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // show file upload modal
  const showfileupload = () => {
    setshowmodalfileupload(true);
  };
  // hide file upload modal
  const hidefileupload = () => {
    setshowmodalfileupload(false);
  };
  return (
    <div className="userAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2 ">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className=" page-header ">
            <div className="page-title-content">
              <p className="card-title">Profile details </p>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 ">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !profile.name && !profile.company_name
                        ? ' has-error'
                        : '')
                    }
                  >
                    <div className={'form-group mb-4'}>
                      {profile.role == 4 && (
                        <label htmlFor="type">Carehome Name</label>
                      )}
                      {profile.role == 1 && <label htmlFor="type">Admin</label>}
                      <input
                        type="name  "
                        className="form-control"
                        name="name"
                        placeholder=" name"
                        value={
                          profile.name ? profile.name : profile.company_name
                        }
                        onChange={handleChange}
                      />
                      {submitted && !profile.name && !profile.company_name && (
                        <div className="help-block">Name is required</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div className={'form-group mb-4'}>
                    <label htmlFor="type">Upload Logo</label>
                    <div className="d-flex">
                      <div className="col-md-8 d-flex justify-content-between align-items-center filenames">
                        <div>
                          {profile && profile.profileimage
                            ? profile.profileimage
                            : 'no image uploaded'}
                        </div>
                        <div className="float-right">
                          {' '}
                          <div
                            className="changebutton m-2 p-3"
                            onClick={showfileupload}
                          >
                            Change
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: '10px',
                          marginTop: '10px',
                        }}
                      >
                        {profile && profile.profileimage ? (
                          <img
                            className=" round-emp-image"
                            src={apiConstants.imgUrl + profile.profileimage}
                          />
                        ) : (
                          <img
                            className=" round-emp-image"
                            src="/assets/img/un-avatar.png"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !profile.email ? ' has-error' : '')
                    }
                  >
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        disabled={role == roleContant.EMPLOYER}
                        placeholder="email"
                        value={profile.email}
                        onChange={handleChange}
                      />
                      {submitted && !profile.email && (
                        <div className="help-block">Email is required</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 ">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !profile.phone ? ' has-error' : '')
                    }
                  >
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">Phone Number</label>
                      <input
                        type="phone_number"
                        className="form-control"
                        name="phone"
                        disabled={role == roleContant.EMPLOYER}
                        placeholder="phone_number"
                        value={profile.phone}
                        onChange={handleChange}
                      />
                      {submitted && !profile.phone && (
                        <div className="help-block">
                          Phone number is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group mt-3'}>
                    <label htmlFor="type">About</label>
                    <textarea
                      name="about"
                      placeholder="about"
                      value={profile.about}
                      onChange={handleChange}
                      id="about"
                      className="form-control"
                      rows="4"
                      cols="50"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group d-flex justify-content-end">
                    <button
                      className="btn bluebutton btn-wd mt-2 "
                      data-cy="savechanges"
                    >
                      Save changes
                      {props.requestStatus ===
                        reducerConstants.USER_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {showmodalfileupload && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div onClick={hidefileupload}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <FileUpload
                    filename={'carehomelogo'}
                    _id={query._id}
                    showmodalfileupload={showmodalfileupload}
                    setshowmodalfileupload={setshowmodalfileupload}
                    getuploadresponse={getuploadresponse}
                  ></FileUpload>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, userData } = state.user;
  return {
    requestStatus,
    userData,
  };
}

const connectedProfileDetails = connect(mapStateToProps)(ProfileDetails);
export { connectedProfileDetails as ProfileDetails };
