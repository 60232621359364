import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { sectionActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, reducerConstants } from '../_constants';

const SectionAdd = props => {
  let { id } = useParams();
  let navigate = useNavigate();
  const [section, setSection] = useState({
    name: '',
    from_time: '',
    to_time: '',
    employer_id: '',
    section_type: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ common: '' });

  useEffect(() => {
    //----to get employer id from local storage---
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        setSection(prevstate => ({
          ...prevstate,
          employer_id: user.employer_id,
        }));
      }
    }
    /**
     * Edit data using url query
     */
    if (id !== 0 && id !== '0' && !props.isAdd) {
      query._id = id;
      setQuery(query);
      // getAllSection(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllSection(1, query);
    }
    if (
      props.employer_id &&
      props.employer_id !== 0 &&
      props.employer_id !== '0'
    ) {
      setSection({ ...section, employer_id: props.employer_id });
    }
  }, []);
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  /**
   * Get all sections
   * */
  const getAllSection = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(sectionActions.sectionList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setSection(res.data.rows[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // onchange event handler for radio input
  const onValueChange = event => {
    let { name } = event.target;
    setSection(prevstate => ({ ...prevstate, section_type: name }));
  };
  // handler for input texts
  const handleChange = event => {
    const { name, value } = event.target;
    if (value.length <= 30) {
    setSection({
      ...section,
      [name]: value,
    });
  }
  };
  // submit action for save section
  const handleSubmit = event => {
    console.log(section);
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    if (section.from_time && section.to_time) {
      var beginningTime = moment(section.from_time, 'h:mm');
      var endTime = moment(section.to_time, 'h:mm');
      if (
        beginningTime.isBefore(endTime) == false &&
        section.section_type !== 'Night'
      ) {
        setError({ common: 'Choose valid time' });
        return;
      }
      if (section.section_type == 'Night') {
        let start = '2023-03-13' + ' ' + section.from_time;
        let end = ' 2023-03-14' + ' ' + section.to_time;
        const startTime = moment(start, 'YYYY-MM-DD hh:mm');
        const endTime = moment(end, 'YYYY-MM-DD hh:mm');
        const duration = moment.duration(endTime.diff(startTime));
        let hours = duration.asHours();
        hours = hours.toFixed(2);
        if (hours > 22) {
          setError({
            common: 'Section duration cannot be greater than 22 hours',
          });
          return;
        }
      }
    }
    if (
      section.name &&
      section.from_time &&
      section.to_time &&
      section.section_type
    ) {
      setIsLoading(true);
      dispatch(sectionActions.sectionAdd(section)).then(
        res => {
          console.log(res);
          if (props.closeSectionAddModal) {
            props.closeSectionAddModal(false);
          } else {
            navigate('/sectionlist');
          }
          setIsLoading(false);
        },
        err => {
          setError({
            common:
              err && err.message ? err.message : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    }
  };
  return (
    <div className="SectionAdd d-flex justify-content-center">
      <div className="col-md-12 col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className=" page-header ">
            <div className="page-title-content">
              <p className="card-title">Add section </p>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              {/* -------section name row----- */}
              <div className="row">
                <div className="col-md-12 ">
                  <div className={'form-group mb-4'}>
                    <label htmlFor="type">Section Label</label>
                    <input
                      type="name  "
                      className="form-control"
                      name="name"
                      placeholder="section name"
                      value={section.name}
                      onChange={handleChange}
                    />
                    {submitted && !section.name && (
                      <div className="help-block">section name is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-md-6 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">from time</label>
                      <input
                        type="time"
                        className="form-control"
                        name="from_time"
                        placeholder="from time"
                        value={section.from_time}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pr-1">
                    <div className={'form-group mb-4'}>
                      <label htmlFor="type">To time</label>
                      <input
                        type="time"
                        className="form-control"
                        name="to_time"
                        placeholder="to time"
                        value={section.to_time}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'row'}>
                      {/* ///--------morning--------- */}
                      <div className="m-2">
                        <label style={{ marginRight: '20px' }}>
                          <input
                            name="Morning"
                            style={{ marginRight: '5px' }}
                            type="radio"
                            value={true}
                            checked={section.section_type == 'Morning'}
                            onChange={onValueChange}
                          />
                          Morning
                        </label>
                      </div>
                      {/* //------night--------- */}
                      <div className="m-2">
                        <label style={{ marginRight: '20px' }}>
                          <input
                            name="Night"
                            style={{ marginRight: '5px' }}
                            type="radio"
                            value={true}
                            checked={section.section_type == 'Night'}
                            onChange={onValueChange}
                          />
                          Night
                        </label>
                      </div>
                      {/* //----------day-------------- */}
                      <div className="m-2">
                        <label style={{ marginRight: '20px' }}>
                          <input
                            name="Day"
                            style={{ marginRight: '5px' }}
                            type="radio"
                            value={true}
                            checked={section.section_type == 'Day'}
                            onChange={onValueChange}
                          />
                          Day
                        </label>
                      </div>
                      {/* //----------evening--------- */}
                      <div className="m-2">
                        <label style={{ marginRight: '20px' }}>
                          <input
                            name="Evening"
                            style={{ marginRight: '5px' }}
                            type="radio"
                            value={true}
                            checked={section.section_type == 'Evening'}
                            onChange={onValueChange}
                          />
                          Evening
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {submitted && !section.section_type && (
                  <div className="help-block">section type is required</div>
                )}
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-wd"
                      data-cy="addsection"
                    >
                      Save
                      {props.requestStatus ===
                        reducerConstants.SECTION_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, sectionData } = state.section;
  return {
    requestStatus,
    sectionData,
  };
}

const connectedSectionAdd = connect(mapStateToProps)(SectionAdd);
export { connectedSectionAdd as SectionAdd };
