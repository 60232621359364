import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { logActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

const LogList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [LogData, setLogData] = useState([]);
  const [LogRows, setLogRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [adminloglist, setadminloglist] = useState(false);
  // effect to load logs
  useEffect(() => {
    if (props.shift_id && props.shift_id !== 0 && props.shift_id !== '0') {
      query.shift_id = props.shift_id;
      setQuery(query);
      getAllLog(1, query);
    }
    if (window.location.pathname == '/loglist') {
      setadminloglist(true);
    }
    getAllLog(1);
  }, [query, adminloglist]);
  // event handler filters
  const handleChange = event => {
    const { name, value } = event.target;
    setQuery({ ...query, [name]: value });
  };
  //----------handlesort function ------------
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  //------------get all log data--------
  const getAllLog = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (queryNew.employer_id == 0) {
      delete queryNew.employer_id;
    }
    if (queryNew.candidate_id == 0) {
      delete queryNew.candidate_id;
    }
    if (queryNew.date_string == '') {
      delete queryNew.date_string;
    }
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(logActions.logList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // list and paginate logs
  const setListAndPagination = LogData => {
    let LogList = [];
    for (
      let j = 0;
      LogData && LogData.rows && j < LogData.rows.length;
      j = j + 1
    ) {
      LogList.push(
        <tr key={j}>
          <td>
            {LogData.rows[j].date_string
              ? LogData.rows[j].date_string
              : moment(parseInt(LogData.rows[j].createdtime_mills)).format(
                  'YYYY-MM-DD',
                )}{' '}
          </td>
          <td>
            <img
              className=" round-emp-image"
              src="/assets/img/recsy-fav-icon.png"
            ></img>
            {LogData.rows[j]['User.name']}{' '}
          </td>
          <td>{LogData.rows[j].action} </td>
          <td>{LogData.rows[j].time_string} </td>
          {query.log_type == 'candidate' && (
            <td>{LogData.rows[j].candidate_id}</td>
          )}
          {query.log_type == 'employer' && (
            <td>{LogData.rows[j].employer_id}</td>
          )}
          {query.log_type == 'vacancy' && <td>{LogData.rows[j].vacancy_id}</td>}
          {query.log_type == 'invoice' && <td>{LogData.rows[j].invoice_id}</td>}
          {query.log_type == 'payout' && <td>{LogData.rows[j].payout_id}</td>}
          {query.log_type == 'category' && (
            <td>{LogData.rows[j].category_id}</td>
          )}
          {query.log_type == 'timesheet' && <td>{LogData.rows[j].shift_id}</td>}
          {query.log_type == 'shift' && <td>{LogData.rows[j].shift_id}</td>}
          <td>{LogData.rows[j].description} </td>
        </tr>,
      );
    }
    setLogRows(LogList);
    setLogData(LogData);
  };
  // custom styles for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };
  // handler for choose employer
  const setEmployer = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  // handler for choose candidate
  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  return (
    <div className="StaffList">
      <div className="col-md-12">
        <div className="card regular-table-with-color col-md-12">
          <div className="card-header page-header ">
            {adminloglist && (
              <div className="row mt-20">
                <div className="col-md-12">
                  <div className="page-title"> Log List </div>
                </div>
              </div>
            )}
            {adminloglist && (
              <div className="row align-items-center">
                <div className="col-md-3 ">
                  <p>
                    <div className="input-outer-div">
                      <input
                        className="search-input-div"
                        value={query.searchKey}
                        onChange={handleChange}
                        type="text"
                        name="searchKey"
                        aria-label="Search"
                        placeholder="Search here..."
                      />
                      <a className="search_bar">
                        <i
                          className="fa fa-search in_bar"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </p>
                </div>
                <div className="col-md-2">
                  <AsyncSelect
                    styles={customStyles}
                    name="employer_id"
                    className="employerselect"
                    onChange={setEmployer}
                    placeholder={'Select Employer'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getEmployerlist}
                  />
                </div>
                <div className="col-md-2">
                  <AsyncSelect
                    styles={customStyles}
                    className="selectcandidate"
                    name="candidate_id"
                    onChange={selectedcandidate}
                    placeholder={'Select Candidate'}
                    cacheOptions
                    defaultOptions
                    loadOptions={getcandidatelist}
                  />
                </div>
                <div className="col-md-2">
                  <input
                    className="form-control"
                    value={query.date_string}
                    onChange={handleChange}
                    type="date"
                    name="date_string"
                  />
                </div>
                <div className="col-md-2">
                  <select
                    className="select selectdesign  "
                    name="log_type"
                    value={query.log_type}
                    onChange={handleChange}
                  >
                    <option value={''}> All</option>
                    <option value="shift">Shift</option>
                    <option value="candidate">Candidate </option>
                    <option value="vacancy">Vacancy</option>
                    <option value="category">Jobrole</option>
                    <option value="employer">Employer</option>
                    <option value="invoice">Invoice</option>
                    <option value="payout">Payout</option>
                    <option value="timesheet">Timesheet</option>
                  </select>
                </div>
                {/* FROM DATE PICKER======== */}
                <div className="col-md-6">
                  <div className="row d-flex justify-content-end align-items-center">
                    <div className="col-md-3"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="card-body table-responsive main-card-body col-md-12 ">
            {!isLoading && (
              <table className="table table-hover col-md-12">
                <thead>
                  <tr>
                    <th
                      onClick={() => handleSort('date_string')}
                      style={{ width: '150px' }}
                      className="border-top1 sortHead"
                    >
                      Date
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'date_string' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'date_string' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Name</th>
                    <th>Action</th>
                    <th>Time</th>
                    {query.log_type == 'candidate' && <th>Candidate Id</th>}
                    {query.log_type == 'employer' && <th>Employer Id</th>}
                    {query.log_type == 'vacancy' && <th>Vacancy Id</th>}
                    {query.log_type == 'invoice' && <th>Invoice Id</th>}
                    {query.log_type == 'payout' && <th>Payout Id</th>}
                    {query.log_type == 'category' && <th>Jobrole Id</th>}
                    {(query.log_type == 'timesheet' ||
                      query.log_type == 'shift') && <th>shift Id</th>}
                    <th className="border-top2">Desc</th>
                  </tr>
                </thead>
                <tbody>{LogRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {LogData &&
              LogData.rows &&
              LogData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllLog(data);
              }}
              paginationData={LogData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
// loader for employers in select options
const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for candidate in select box
const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          for (let d = 0; d < data.rows.length; d++) {
            data.rows[d].cat_name = data.rows[d]['Category.name'];
          }
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name, last_name, cat_name }) => ({
              value: _id,
              label:
                name +
                ' ' +
                (last_name ? last_name : '') +
                ' ' +
                (cat_name ? '(' + cat_name + ')' : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });
function mapStateToProps(state) {
  const { requestStatus, LogData } = state.staff;
  return {
    requestStatus,
    LogData,
  };
}

const connectedLogList = connect(mapStateToProps)(LogList);
export { connectedLogList as LogList };
