import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const userService = {
  userAdd,
  userList,
  userDelete,
  login,
  logout,
  register,
  registerCandidate,
  changePassword,
  sendOtp,
  sendResetUserCredentials,
  verifyOtp,
  GoogleLoginRegistration,
  updatePhoneNumber,
  sendOtpRegister,
  verifyOtpRegister,
  registrationWithToken,
  microsoftlogin,
  sendOtpLogin,
  verifyLogin,
  resendOtpRegister,
};

function handleResponse(response) {
  if (response.status === 401) {
    logout();
    window.location.reload(true);
  }

  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (data.message === 'Token authentication failed.') {
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    if (data.error_code && data.error_code !== 0) {
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(apiConstants.apiUrl + '/login', requestOptions)
    .then(handleResponse)
    .then(user => {
      console.log(user.data);

      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
      }

      return user;
    });
}

function logout() {
  localStorage.removeItem('user');
  localStorage.clear();
}
function userAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/adduser', requestOptions).then(
    handleResponse,
  );
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/employerregister', requestOptions).then(
    handleResponse,
  );
}

//-----register candidate thru referal-------------
function registerCandidate(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(
    apiConstants.apiUrl + '/candidateregisterreferal',
    requestOptions,
  ).then(handleResponse);
}
function sendOtp(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/setotpmail', requestOptions).then(
    handleResponse,
  );
}

function verifyOtp(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/verifyOtpweb', requestOptions).then(
    handleResponse,
  );
}

function userList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getuserList', requestOptions).then(
    handleResponse,
  );
}

function userDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteuser', requestOptions).then(
    handleResponse,
  );
}

function changePassword(user) {
  console.log('userrr', user);

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };

  return fetch(apiConstants.apiUrl + '/changepassword', requestOptions).then(
    handleResponse,
  );
}

function sendResetUserCredentials(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/sendResetUserCredentials',
    requestOptions,
  ).then(handleResponse);
}

function microsoftlogin(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      access_token: data.accessToken,
      email: data.account.username,
      isLogin: data.isLogin,
      id: data.id,
      is_saved_microsoft_id: !!data.is_saved_microsoft_id,
    }),
  };
  return fetch(apiConstants.apiUrl + '/microsoft-auth', requestOptions).then(
    handleResponse,
  );
}

function GoogleLoginRegistration(user) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  };
  return fetch(
    apiConstants.apiUrl + '/verify-google-token',
    requestOptions,
  ).then(handleResponse);
}

function updatePhoneNumber(user) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/updateprofile', requestOptions).then(
    handleResponse,
  );
}

function sendOtpRegister(user) {
  console.log(user, 'wwwwwwwwwwwwwwwwwwwww');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/sentOtpRegister', requestOptions).then(
    handleResponse,
  );
}

function resendOtpRegister(user) {
  console.log(user, 'wwwwwwwwwwwwwwwwwwwww');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/resendotpRegister', requestOptions).then(
    handleResponse,
  );
}

function sendOtpLogin(user) {
  console.log(user, 'wwwwwwwwwwwwwwwwwwwww');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/sendLoginOtp', requestOptions).then(
    handleResponse,
  );
}

function verifyOtpRegister(user) {
  console.log(user, '11111111111111111111111111');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/verifyRegister', requestOptions).then(
    handleResponse,
  );
}

function registrationWithToken(user) {
  console.log(user, '11111111111111111111111111');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(
    apiConstants.apiUrl + '/employerRegistration',
    requestOptions,
  ).then(handleResponse);
}

function verifyLogin(user) {
  console.log(user, '11111111111111111111111111');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(apiConstants.apiUrl + '/verifyLogin', requestOptions)
    .then(handleResponse)
    .then(user => {
      console.log(user.data);
      // login successful if there's a jwt token in the response
      if (user.data.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user.data));
      }
      return user;
    });
}
