import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { SectionAdd } from '../Section';
import { PriceActions, employerActions, shiftsActions } from '../_actions';
import { apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';

import { sectionActions } from '../_actions';
import { LoadingImg } from '../_components';
// eslint-disable-next-line no-unused-vars
let employer_id = '';
const ShiftEdit = props => {
  const [shifts, setShifts] = useState({
    shift_start_from: '',
    shift_start_to: '',
    check_in_time: '',
    check_out_time: '',
    shift_status: '',
    employer_id: '', //---carehome id--------
    business_unit: '',
    // check_in_mills: '',
    check_out_mills: '',
    isShift_multiple: false,
    from_time_string: '',
    to_time_string: '',
    from_date_string: '',
    to_date_string: '',
    break_time: 0,
    section_type: '',
    employer_to_pay: '',
    dates: [],
    user: [], //----this is candidates to ehich shifts are allocated-----
  });
  const [selectedSectionType, setselectedSectionType] = useState('');

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [sectionrows, setsectionrows] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const [isEditEmployer, setisEditEmployer] = useState(false);
  const [isEditcategory, setisEditcategory] = useState(false);
  const [isEditcandidate, setisEditcandidate] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [sectionData, setsectionData] = useState([]);
  const [shiftsoptionsData, setshiftsoptionsData] = useState([]);
  const [isPastday, setisPastday] = useState(false);
  const [IsEmployer, setIsEmployer] = useState(false);
  const [SectionAddModal, setSectionAddModal] = useState(false);
  // const [breaktime, setbreaktime] = useState(0);
  const [isDisabled, setisDisabled] = useState(false);
  const [error, setError] = useState({ common: '' });

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.role && user.role == 4) {
        setIsEmployer(true);
      }
      if (user && user.employer_id) {
        employer_id = user.employer_id;
        getemployer(user.employer_id);
        getbusinessunit(user.employer_id);
      }
    }

    if (props._id) {
      console.log(props._id, '->>>>>>>>.shift id as propskool>>>>>>>>>>');
      getAllShifts(1, { _id: props._id });
    }

    if (
      props &&
      props.listViewShiftAddDate &&
      props.listViewShiftAddDate.shiftdata &&
      props.listViewShiftAddDate.shiftdata._id
    ) {
      console.log(
        props.listViewShiftAddDate.shiftdata,
        '----------proooops hsift data',
      );
      setShifts(prevState => ({
        ...prevState,
        user: [
          {
            value: props.listViewShiftAddDate.shiftdata.name,
            _id: props.listViewShiftAddDate.shiftdata._id,
            candidate_id: props.listViewShiftAddDate.shiftdata._id,
            user_id: props.listViewShiftAddDate.shiftdata.user_id,
          },
        ],
        from_date_string: props.listViewShiftAddDate.date,
        shift_start_from: props.listViewShiftAddDate.date,
        //check_in_mills: new Date(props.listViewShiftAddDate.date).getTime(),
      }));
    }

    if (props.daymonthyear) {
      // setdaymonthyear(props.daymonthyear);

      getcheck_in();
      console.log(props.daymonthyear, '568jk-------');
      checkPastDay();
    }

    //-------if shift is being added by carehome set carehome id / employer_id from localstorage else select from asyn
    if (IsEmployer) {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        // console.log(user, "this is employeeeeeeer id in as localstorage---");
        setShifts(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
        }));
      }
    }

    // getAllSection();

    //------------------get business unit---------------------
    getbusinessunit(shifts.employer_id);
  }, []);

  useEffect(() => {
    getAllSection();
  }, [shifts.employer_id]);

  const getemployer = employer_id => {
    console.log('employeerrrr', employer_id);
    const { dispatch } = props;
    dispatch(employerActions.employerList({ _id: employer_id })).then(
      res => {
        setIsLoading(false);
        // setbreaktime(res.data.rows[0].break_time);

        setShifts(prevState => ({
          ...prevState,
          break_time: res.data.rows[0].break_time,
        }));
        // setEmployer(res.data.rows[0]);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //------------------get candiate---------------------
  const getcandidatelist = inputvalue =>
    new Promise((resolve, reject) => {
      console.log(inputvalue, 'this is search key--');

      fetch(apiConstants.apiUrl + '/getcandidatelist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({ status: 1, searchKey: inputvalue }),
      })
        .then(response =>
          response
            .json()

            .then(({ data }) => {
              resolve(
                data.rows.map(item => ({
                  value: item['_id'],
                  label: item['name'],
                  user_id: item['user_id'],
                })),
              );
            }),
        )
        .catch(reject);
    });

  //------------------get business unit---------------------
  const getbusinessunit = employer_id => {
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          employer_id: employer_id,
          select: ['_id', 'name'],
        }),
      }).then(response =>
        response
          .json()

          .then(({ data }) => {
            if (data && data.rows) {
              // setbusinessunitData(data.rows);

              let shift_option_rows = [];
              for (let s = 0; s < data.rows.length; s++) {
                shift_option_rows.push(
                  <option
                    selected={s == 0 ? true : false}
                    value={data.rows[s]._id}
                  >
                    {data.rows[s].name}
                  </option>,
                );
              }
              setshiftsoptionsData(shift_option_rows);
              // setShifts(prevState => ({
              //   ...prevState,
              //   business_unit: data.rows[0]?._id,
              // }));
            }
          }),
      );
    }
  };

  //--------get date in string format--------
  const timeparse = datestr => {
    let timesplitted = datestr.split('T');
    return timesplitted[1];
  };

  //---------get date in string format =----------
  const dateparse = datestr => {
    let datesplitted = datestr.split('T');
    // console.log(datesplitted[0], "---this is time----");
    return datesplitted[0];
  };

  /**
   * Get all shiftss
   * */
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    // queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          // let dates_array = new Date(res.data.rows[0].check_in_date_string)
          res.data.rows[0].dates = [res.data.rows[0].check_in_date_string];
          res.data.rows[0].section_type =
            res.data.rows[0]['Section.section_type'];

          let shiftdata = res.data.rows[0];
          setShifts(res.data.rows[0]);

          if (shiftdata.employer_id) {
            getbusinessunit(shiftdata.employer_id);
          }

          if (shiftdata.employer_id) {
            getAllSection(shiftdata.employer_id);
          }
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  console.log(sectionData, '>>>>section-----');
  const handleWheel = event => {
    event.target.blur();
  };
  //---get all section when carehome is selected--------
  const getAllSection = () => {
    setsectionrows([]);
    // console.log('get section998877-------------------', employer_id);

    setIsLoading(true);
    const { dispatch } = props;
    if (shifts && shifts.employer_id && shifts.employer_id != 0) {
      dispatch(
        sectionActions.sectionList({ employer_id: shifts.employer_id }),
      ).then(
        res => {
          let sectionlist = [];
          setIsLoading(false);
          // setListAndPagination(res.data);
          // let sectionData = res.data.rows;
          console.log(res.data.rows, '>>>section rows');
          setsectionData(res.data.rows);
          for (let i = 0; i < res.data.rows.length; i++) {
            sectionlist.push(
              <option
                selected={i == 0 ? true : false}
                key={res.data.rows[i].section_type}
                value={res.data.rows[i]._id}
              >
                {res.data.rows[i].name}{' '}
                {'(' +
                  res.data.rows[i].from_time +
                  '-' +
                  res.data.rows[i].to_time +
                  ')'}
              </option>,
            );
          }

          // setShifts(prevState => ({
          //   ...prevState,
          //   section_type: res.data.rows[0].section_type,
          //   section_id: res.data.rows[0]._id,
          //   to_time_string: res.data.rows[0].to_time,
          //   from_time_string: res.data.rows[0].from_time,
          //   check_in_time: res.data.rows[0].from_time,
          //   check_out_time: res.data.rows[0].to_time,
          // }));

          setsectionrows(sectionlist);
        },
        () => {
          setIsLoading(false);
        },
      );
    } else {
      console.log('enter no call data--- no empoooo');
    }
  };

  const handleChange = event => {
    let { name, value } = event.target;

    if (name == 'shift_start_from') {
      console.log('entedate');

      let d = new Date(value);

      let newvalue = d.getTime();
      console.log('pppppppppppp', name, value, 'lllll', newvalue);
      //---to get time seperated from date string-----
      let time_string = timeparse(value);
      // let date_string = dateparse(value);
      console.log(time_string, '-----------');

      //---check if there is a checkout mills and validate the check in mills----
      setShifts({
        ...shifts,
        // from_time_string: time_string,
        // from_date_string: date_string,
        //  check_in_mills: newvalue,
        // check_in_time: time_string,
        // check_out_time: res.data.rows[0].to_time,
        // shift_start_fromtime: d,
        [name]: value,
      });
    } else if (name == 'shift_start_to') {
      let d = new Date(value);
      let newvalue = d.getTime();

      //---------check if the checkindate is less than checkout date in mills--
      if (newvalue < new Date(shifts.check_in_time).getTime()) {
        setError({ common: 'choose a valid date' });
      } else {
        //---to get time seperated from date string-----
        let time_string = timeparse(value);
        let date_string = dateparse(value);

        setShifts({
          ...shifts,
          to_time_string: time_string,
          to_date_string: date_string,
          check_out_mills: newvalue,

          [name]: value,
        });
      }
    } else if (name == 'price') {
      setShifts({
        ...shifts,

        [name]: value,
      });
    } else if (name == 'section_type') {
      let selected_section_type = '';
      let selected_section = '';

      for (let s = 0; sectionData.length && s < sectionData.length; s++) {
        if (value == sectionData[s]._id) {
          selected_section = sectionData[s];
          selected_section_type = sectionData[s].section_type;
          setselectedSectionType(sectionData[s].section_type);
        }
      }

      setShifts({
        ...shifts,
        // price: price,
        section_id: value,
        to_time_string: selected_section.to_time,
        from_time_string: selected_section.from_time,
        check_in_time: selected_section.from_time,
        check_out_time: selected_section.to_time,
        // [name]: value,
        section_type: selected_section_type,
      });
    } else if (name == 'business_unit') {
      console.log('bunit value--->>>', value);
      // var index = event.nativeEvent.target.selectedIndex;

      // console.log("entedate", event.nativeEvent.target[index].text)

      const select = event.target;
      const bunitname = select.options[select.selectedIndex].text;
      console.log('entedate-----', bunitname);

      setShifts(prevState => ({
        ...prevState,
        [name]: value,
        bunit_name: bunitname,
      }));

      // let selected_section_type = '';
      // let selected_section = '';

      // for (let s = 0; sectionData.length && s < sectionData.length; s++) {
      //   if (value == sectionData[s]._id) {
      //     selected_section = sectionData[s]
      //     selected_section_type = sectionData[s].section_type
      //     setselectedSectionType(sectionData[s].section_type)
      //   }
      // }

      // setShifts({
      //   ...shifts,
      //   // price: price,
      //   section_id: value,
      //   to_time_string: selected_section.to_time,
      //   from_time_string: selected_section.from_time,
      //   check_in_time: selected_section.from_time,
      //   check_out_time: selected_section.to_time,
      //   business_unit:value,
      //   // [name]: value,
      //   section_type: selected_section_type
      // });
    } else {
      setShifts(prevState => ({ ...prevState, [name]: value }));
    }
  };

  console.log(shifts, '--state shifts--');
  const breaktimeChange = event => {
    let { name, value } = event.target;

    console.log(name, value, '---select brak tim');
    // setbreaktime(value);
    setShifts(prevState => ({ ...prevState, break_time: value }));
  };

  //---async select multiple 0user selecet----
  const selecteduser = selectedOption => {
    let SelectedValues = [];
    console.log(selectedOption, '----selectedOption----');

    for (let i = 0; i < selectedOption.length; i++) {
      let obj = {
        value: selectedOption[i].label,
        _id: selectedOption[i].value,
        candidate_id: selectedOption[i].value,
        user_id: selectedOption[i].user_id,
        // description: selectedOption[i].description,
      };
      SelectedValues.push(obj);
      setShifts(prevState => ({
        ...prevState,
        user: SelectedValues,
      }));
    }
  };

  const handleSubmit = event => {
    //------------TO AVOUD MULTIPLE BUTTON CLICK ------------
    console.log(submitted, 'valooo', isDisabled);
    // if (submitted) {
    //   return false;
    // }

    console.log(shifts, '>>>> handle submitted');
    setSubmitted(true);
    event.preventDefault();
    const { dispatch } = props;

    if (
      shifts.check_in_date_string &&
      shifts.check_in_time &&
      shifts.check_out_time &&
      shifts.section_id &&
      shifts.category
    ) {
      setIsLoading(true);
      if (!shifts.break_time) {
        shifts.break_time = 0;
      }
      if (shifts.price && shifts.price < 10.42) {
        setError({
          common: 'Price should be greater than minimum price of 10.42',
        });
        return;
      }
      if (shifts.employer_to_pay && shifts.employer_to_pay < 10.42) {
        setError({
          common: 'Employer pay should be greater than minimum price of 10.42',
        });
        return;
      }
      setisDisabled(true);
      dispatch(shiftsActions.shiftsEdit(shifts)).then(
        () => {
          //----close shift modal after success response -----
          //------ function showAddshiftmodal is passed from  candidate component-----
          props.showEditshiftmodal(false);
          setSubmitted(false);
          setIsLoading(false);
          setisDisabled(true);
        },
        () => {
          setSubmitted(false);
          setIsLoading(false);
          setisDisabled(false);
        },
      );
    } else {
      console.log(shifts, '---shifts incomp');
      setError({ common: 'please complete the form' });
    }
  };

  const selectedemployer = (selectedOption, event) => {
    (shifts.check_in_time = ''), (shifts.check_out_time = '');
    let name = event.name;

    setShifts(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    getbusinessunit(selectedOption.value);
    getemployer(selectedOption.value);

    // getAllSection();
  };

  //@author --fahisa
  //to get check in date from props to date fromat (yyyy-mm-dd)-----
  const getcheck_in = () => {
    let fromdate =
      props.daymonthyear.year +
      '-' +
      (props.daymonthyear.month.toString().length == 1
        ? '0' + (props.daymonthyear.month + 1)
        : props.daymonthyear.month + 1) +
      '-' +
      (props.daymonthyear.day.toString().length == 1
        ? '0' + props.daymonthyear.day
        : props.daymonthyear.day);

    // let d = new Date(fromdate);
    // let newvalue = d.getTime();

    //---to get time seperated from date string-----
    let time_string = timeparse(fromdate);

    //---check if there is a checkout mills and validate the check in mills----
    setShifts({
      ...shifts,
      from_time_string: time_string,
      from_date_string: props.daymonthyear.index,
      //  check_in_mills: newvalue,
      // shift_start_fromtime: d,
      shift_start_from: props.daymonthyear.index,
    });
  };

  //get price chart -----
  const getAllpriceChart = category_id => {
    const { dispatch } = props;
    dispatch(PriceActions.PriceList({ category: category_id })).then(
      res => {
        if (res.data && res.data.rows && res.data.rows[0]) {
          let price_data = res.data.rows[0];
          let new_price = '';
          if (selectedSectionType == 'Evening') {
            new_price = price_data.evening_price;
          } else if (selectedSectionType == 'Morning') {
            new_price = price_data.morning_price ? price_data.morning_price : 0;
          } else if (selectedSectionType == 'Weekend') {
            new_price = price_data.weekend_price ? price_data.weekend_price : 0;
          } else if (selectedSectionType == 'Night') {
            new_price = price_data.night_price ? price_data.night_price : 0;
          } else {
            new_price = price_data.day_price ? price_data.day_price : 0;
          }
          if (new_price != 0) {
            setShifts({
              ...shifts,
              price: new_price,
            });
          }
        }
      },
      () => {},
    );
  };

  ///----category select change -----------
  const selectedcategory = (selectedOption, event) => {
    console.log('selectedoption jang', selectedOption.label);
    // console.log("selectedoption", event)
    let name = event.name;
    getAllpriceChart(selectedOption.value);
    if (selectedSectionType) {
      let new_price = '';
      if (selectedSectionType == 'Evening') {
        new_price = selectedOption.evening_price;
      } else if (selectedSectionType == 'Morning') {
        new_price = selectedOption.morning_price
          ? selectedOption.morning_price
          : 0;
      } else if (selectedSectionType == 'Weekend') {
        new_price = selectedOption.weekend_price
          ? selectedOption.weekend_price
          : 0;
      } else if (selectedSectionType == 'Night') {
        new_price = selectedOption.night_price ? selectedOption.night_price : 0;
      } else {
        new_price = selectedOption.day_price ? selectedOption.day_price : 0;
      }

      if (new_price != 0) {
        setShifts({
          ...shifts,
          price: new_price,
        });
      }
    }

    setShifts(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      category_name: selectedOption.label,
    }));
  };

  const closeSectionAddModal = value => {
    setSectionAddModal(value);
    getAllSection();
  };

  const checkPastDay = () => {
    console.log('enter same');

    let today =
      new Date().getFullYear() +
      '-' +
      ((new Date().getMonth() + 1).toString().length == 1
        ? '0' + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1) +
      '-' +
      (new Date().getDate().toString().length == 1
        ? '0' + new Date().getDate()
        : new Date().getDate());

    if (props.daymonthyear && props.daymonthyear.index < today) {
      console.log('past day ');
      setisPastday(true);
      return 'Selected a previous date';
    }
  };

  const dateshandleChange = value => {
    let temp_dates = [];
    for (let d = 0; d < value.length; d++) {
      temp_dates.push(value[d].format('YYYY-MM-DD'));
    }
    setShifts({
      ...shifts,
      dates: temp_dates,
    });
  };

  console.log(
    shifts['Employer.company_name'],
    '->>>>>...state9999999999999999999',
  );

  return (
    <div className="ShiftEdit">
      <div className="col-md-12 col-sm-6">
        <div className="card">
          <div className=" page-header ">
            <div className="page-title-content">
              <div className="row d-flex justify-content-center">
                {isLoading && <LoadingImg></LoadingImg>}
              </div>
              <div className="row d-flex align-items-center">
                <div className="col-md-3">
                  <h4 className="card-title mt-0">Edit Shift</h4>
                </div>

                {props.daymonthyear &&
                  props.daymonthyear.day &&
                  props.allocateshift && (
                    <div className="col d-flex align-items-center">
                      {' '}
                      <div className="date-div">
                        {props.daymonthyear.day.toString().length == 1
                          ? '0' + props.daymonthyear.day
                          : props.daymonthyear.day}
                        -
                        {props.daymonthyear.month.toString().length == 1
                          ? '0' + props.daymonthyear.month
                          : props.daymonthyear.month}
                        -{props.daymonthyear.year}
                      </div>
                    </div>
                  )}

                {isPastday && (
                  <div className="text-secondary">
                    Selected date is past day
                  </div>
                )}

                {props.listViewShiftAddDate &&
                  props.listViewShiftAddDate.date && (
                    <div className="col d-flex align-items-center">
                      {' '}
                      <div className="date-div">
                        {props.listViewShiftAddDate.date}
                        {/* hello */}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          {/* </div> */}

          <div className="card-body">
            <form name="form">
              {/* //------------select category ------------>>>>>>>.. */}
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="row"
                    style={{ marginBottom: '10px', marginTop: '15px' }}
                  >
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">Date</label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !shifts.shift_start_from
                            ? ' has-error'
                            : '')
                        }
                      >
                        <DatePicker
                          multiple
                          style={{
                            // backgroundColor: "aliceblue",
                            height: '44px',
                            borderRadius: '4px',
                            fontSize: '14px',
                            padding: '4px 10px',
                          }}
                          value={shifts.dates}
                          format="YYYY-MM-DD"
                          onChange={dateshandleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Jobrole</label>
                      {isEditcategory && (
                        <AsyncSelect
                          name="category"
                          onChange={selectedcategory}
                          cacheOptions
                          defaultOptions
                          loadOptions={getcategorylist}
                        />
                      )}
                      {!isEditcategory && (
                        <div className="col">
                          <div className="row">
                            <div className=" col-md-10 select_box1">
                              {' '}
                              {shifts['Category.name']}
                            </div>
                            <div
                              className="edit_text"
                              onClick={() => {
                                setisEditcategory(true);
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        </div>
                      )}
                      {submitted && !shifts.category && (
                        <div className="help-block"> category is required</div>
                      )}
                    </div>
                  </div>
                </div>
              }

              {/* //--------select user ---------- */}
              {
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Shift allocated to</label>
                      {isEditcandidate && (
                        <AsyncSelect
                          name="candidate_id"
                          onChange={selecteduser}
                          placeholder={'select candidate'}
                          cacheOptions
                          defaultInputValue={shifts && shifts['Candidate.name']}
                          defaultOptions
                          loadOptions={getcandidatelist}
                        />
                      )}
                      {!isEditcandidate && (
                        <div className="col">
                          <div className="row">
                            <div className=" col-md-10 select_box1">
                              {' '}
                              {shifts['Candidate.name']
                                ? shifts['Candidate.name']
                                : shifts['Agencycandidate.name']}
                            </div>
                            <div
                              className="edit_text"
                              onClick={() => {
                                setisEditcandidate(true);
                              }}
                            >
                              Edit
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              }
              {!IsEmployer && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Select employer</label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !shifts.employer_id ? ' has-error' : '')
                        }
                      >
                        {isEditEmployer && (
                          <AsyncSelect
                            name="employer_id"
                            onChange={selectedemployer}
                            defaultInputValue={
                              shifts &&
                              shifts['Employer.company_name'] &&
                              shifts['Employer.company_name']
                            }
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerList}
                          />
                        )}
                        {!isEditEmployer && (
                          <div className="col">
                            <div className="row">
                              <div className=" col-md-10 select_box1">
                                {' '}
                                {shifts['Employer.company_name']}
                              </div>
                              <div
                                className="edit_text"
                                onClick={() => {
                                  setisEditEmployer(true);
                                }}
                              >
                                Edit
                              </div>
                            </div>
                          </div>
                        )}

                        {submitted && !shifts.employer_id && (
                          <div className="help-block">Employer is required</div>
                        )}
                        {/*Required*/}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Select business unit</label>
                    <div className="form-group">
                      <select
                        className="select col-md-12 select-business-unit"
                        name="business_unit"
                        onChange={handleChange}
                        value={shifts.business_unit}
                      >
                        <option value="" hidden="true">
                          {' '}
                          select
                        </option>
                        {shiftsoptionsData}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-5 pr-1">
                  <label htmlFor="type">Shift Time</label>
                  <div className={'form-group '}>
                    <select
                      className="select col-md-12 selectdesign"
                      name="section_type"
                      value={shifts.section_id}
                      onChange={handleChange}
                    >
                      <option value=""> select shift Time</option>
                      {/* {sectionData.map((i) => {
                        return (
                          <option value={i._id} >{i.name} ({i.from_time}-{i.to_time})/{i.section_type}</option>
                        );
                      })} */}
                      {sectionrows}
                    </select>
                    {submitted && !shifts.section_type && (
                      <div className="help-block"> section is required</div>
                    )}
                  </div>
                </div>
              </div>

              {/* //--------------select category before user=--------user(rgn)----------- */}
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Candidate Pay</label>
                    <div className="form-group">
                      <input
                        type="number"
                        step="any"
                        className="form-control form-group-50px"
                        name="price"
                        disabled={IsEmployer}
                        placeholder="Price"
                        value={shifts.price}
                        onChange={handleChange}
                        onWheel={handleWheel}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <label htmlFor="type">Employer price</label>
                    <div className="form-group">
                      <input
                        onWheel={handleWheel}
                        type="number"
                        step="any"
                        className="form-control form-group-50px"
                        name="employer_to_pay"
                        placeholder="Employer price "
                        disabled={IsEmployer}
                        value={shifts.employer_to_pay}
                        onChange={handleChange}
                      />
                      <small>
                        *include Employer NI,Holiday pay, Admin charge{' '}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 pr-1">
                  <div className={'form-group mb-4'}>
                    <label htmlFor="type" className="mr-2">
                      Break paid ?
                    </label>
                    <input
                      type="radio"
                      className="mr-2 ml-4"
                      name="is_break_time_pay"
                      placeholder="is_break_time_pay"
                      value={1}
                      checked={shifts.is_break_time_pay == 1}
                      onChange={handleChange}
                    />
                    <label className="mr-2 ">Yes</label>
                    <input
                      type="radio"
                      className="mr-2"
                      name="is_break_time_pay"
                      placeholder="is_break_time_pay"
                      value={0}
                      checked={shifts.is_break_time_pay == 0}
                      onChange={handleChange}
                    />
                    <label className="mr-2">No</label>
                  </div>
                </div>
              </div>

              {false && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">Break Time</label>

                      <div className="form-group">
                        <select
                          className="select col-md-12 selectdesign"
                          name="break_time"
                          value={shifts.break_time}
                          onChange={breaktimeChange}
                        >
                          <option selected={shifts.break_time == 0} value={0}>
                            {' '}
                            0 mins
                          </option>
                          <option selected={shifts.break_time == 15} value={15}>
                            {' '}
                            15 mins
                          </option>
                          <option selected={shifts.break_time == 20} value={20}>
                            20 mins
                          </option>
                          <option selected={shifts.break_time == 25} value={25}>
                            25 mins
                          </option>
                          <option selected={shifts.break_time == 30} value={30}>
                            30 mins
                          </option>
                          <option selected={shifts.break_time == 35} value={35}>
                            35 mins
                          </option>
                          <option selected={shifts.break_time == 40} value={40}>
                            40 mins
                          </option>
                          <option selected={shifts.break_time == 45} value={45}>
                            45 mins
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Break Time (mins) : <span>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          onWheel={handleWheel}
                          type="number"
                          step="any"
                          className="form-control form-group-50px"
                          name="break_time"
                          placeholder="Break Time "
                          value={shifts.break_time}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-md-12 pr-1">
                  <small>
                    * If it is already checked in, you should go and edit
                    timesheet{' '}
                  </small>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <div className="d-flex">
                      <div
                        className=" btn-primary-blue-border "
                        onClick={handleSubmit}
                      >
                        Save
                        {props.requestStatus ===
                          reducerConstants.SHIFTS_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="card-footer" />
        </div>
      </div>

      {SectionAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setSectionAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <SectionAdd closeSectionAddModal={closeSectionAddModal} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getEmployerList = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      // body: JSON.stringify({ searchKey: inputValue,category:['Water purifier','Water treatment plan']}),
      body: JSON.stringify({ searchKey: inputValue, status: 1 }),
    })
      .then(response =>
        response
          .json()

          .then(({ data }) => {
            resolve(
              data.rows.map(({ _id, company_name }) => ({
                value: _id,
                label: company_name,
              })),
            );
          }),
      )
      .catch(reject);
  });

const getcategorylist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputValue }),
    })
      .then(response =>
        response
          .json()

          .then(({ data }) => {
            resolve(
              data.rows.map(
                ({
                  _id,
                  name,
                  morning_price,
                  night_price,
                  evening_price,
                  day_price,
                  weekend_price,
                  holiday_price,
                }) => ({
                  value: _id,
                  label: name,
                  morning_price: morning_price,
                  night_price: night_price,
                  evening_price: evening_price,
                  day_price: day_price,
                  weekend_price: weekend_price,
                  holiday_price: holiday_price,
                }),
              ),
            );
          }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return {
    requestStatus,
    shiftsData,
  };
}

const connectedShiftEdit = connect(mapStateToProps)(ShiftEdit);
export { connectedShiftEdit as ShiftEdit };
