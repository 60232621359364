import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { BusinessunitAdd } from '../BusinessUnit/BusinessunitAdd';
import { SectionAdd } from '../Section';
import {
  PriceActions,
  categoryActions,
  employerActions,
  sectionActions,
  shiftsActions,
} from '../_actions';
import { apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';

let employer_id = '';
// eslint-disable-next-line no-unused-vars
const breaktime = '';

const ShiftsAdd = props => {
  const [shifts, setShifts] = useState({
    shift_start_from: '',
    dates: [],
    shift_start_to: '',
    check_in_time: '',
    check_out_time: '',
    shift_status: '',
    employer_id: '', //---carehome id--------
    business_unit: '',
    // check_in_mills: '',
    check_out_mills: '',
    isShift_multiple: false,
    from_time_string: '',
    to_time_string: '',
    from_date_string: '',
    to_date_string: '',
    break_time: 0,
    section_type: '',
    employer_to_pay: '',
    is_break_time_pay: 0,
    user: [], //----this is candidates to is_break_paidehich shifts are allocated-----
  });
  // eslint-disable-next-line no-unused-vars
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [sectionrows, setsectionrows] = useState([]);
  const [candidatepaydetails, setcandidatepaydetails] = useState([]);
  // const [employerpaydetails, setemployerpaydetails] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [sectionData, setsectionData] = useState([]);
  // const [isPastday, setisPastday] = useState(false);
  const [IsEmployer, setIsEmployer] = useState(false);
  const [IsBunit, setIsBunit] = useState(false);
  const [SectionAddModal, setSectionAddModal] = useState(false);
  const [BusinessunitAddModal, setBusinessunitAddModal] = useState(false);
  const [businessunitoptionsData, setbusinessunitoptionsData] = useState();
  const [error, setError] = useState({ common: '' });
  const [isDisabled, setisDisabled] = useState(false);
  const [IsParentEmployer, setIsParentEmployer] = useState(false);

  //---get al category for listing in table -------------
  const getAllCategory = category_id => {
    props
      .dispatch(
        categoryActions.categoryList({
          _id: category_id,
          select: ['_id', 'name', 'minimum_employer_price', 'minimum_price'],
        }),
      )
      .then(
        res => {
          if (res && res.data.rows && res.data.rows.length > 0) {
            setCategory(res.data.rows[0]);
          }
        },
        () => {
          setIsLoading(false);
          setError({ common: 'Error ' });
        },
      );
  };

  useEffect(() => {
    getAllCategory(shifts.category);
  }, [shifts.category]);

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.role && user.role == 4) {
        setIsEmployer(true);
        if (user.is_parent == 1) {
          setIsParentEmployer(true);
          console.log(IsParentEmployer);
        }
      }
      if (user.role == 7) {
        setIsBunit(true);
      }
      if (user && user.employer_id) {
        employer_id = user.employer_id;
        getemployer(user.employer_id);
        getbusinessunit(user.employer_id);
        setsectionrows([]);
        getAllSection(user.employer_id);
      }
    }
    if (
      props &&
      props.listViewShiftAddDate &&
      props.listViewShiftAddDate.shiftdata &&
      props.listViewShiftAddDate.shiftdata._id
    ) {
      setShifts(prevState => ({
        ...prevState,
        user: [
          {
            value: props.listViewShiftAddDate.shiftdata.name,
            _id: props.listViewShiftAddDate.shiftdata._id,
            candidate_id: props.listViewShiftAddDate.shiftdata._id,
            user_id: props.listViewShiftAddDate.shiftdata.user_id,
          },
        ],
        from_date_string: props.listViewShiftAddDate.date,
        dates: [props.listViewShiftAddDate.date],
        shift_start_from: props.listViewShiftAddDate.date,
        // check_in_mills: new Date(props.listViewShiftAddDate.date).getTime(),
      }));
    }

    //-----------FOR SHIFT ADD THROUGH 30 DAYS CALENDAR VIEW------@harshad---date is send via "props.daymonthyear"
    if (props.daymonthyear && props.daymonthyear.day) {
      setShifts({
        ...shifts,
        shift_start_from: props.daymonthyear.index,
        dates: [props.daymonthyear.index],
      });
    }
    //-------if shift is being added by carehome set carehome id / employer_id from localstorage else select from asyn
    if (IsEmployer) {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        setShifts(prevState => ({
          ...prevState,
          employer_id: user.employer_id,
          employer_name: user.name,
        }));
      }
    }
    if (props && props.id) {
      setShifts(prevState => ({
        ...prevState,
        user: [
          {
            _id: props.id,
            user_id: props.user_id,
            candidate_id: props.candidate_id,
          },
        ], //if admin add shifts---
      }));
    }
    //------------------get business unit---------------------
  }, [query]);

  useEffect(() => {
    setsectionrows([]);
    getAllSection();
  }, [shifts.employer_id]);

  const getemployer = employer_id => {
    const { dispatch } = props;
    dispatch(employerActions.employerList({ _id: employer_id })).then(
      res => {
        setIsLoading(false);
        console.log(res);
        // setbreaktime(res.data.rows[0].break_time);
        setShifts(prevState => ({
          ...prevState,
          break_time: res?.data?.rows[0]?.break_time,
          is_break_time_pay: res?.data?.rows[0]?.pay_break == 1 ? 1 : 0,
          employer_id: res?.data?.rows[0]?._id,
        }));
        // setEmployer(res.data.rows[0]);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  //------------------get candiate---------------------
  const getcandidatelist = inputvalue =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getcandidatelist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          searchKey: inputvalue,
          active_status: 1,
          isverified: 1,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            resolve(
              data.rows.map(item => ({
                value: item['_id'],
                label:
                  item['name'] +
                  ' ' +
                  (item['last_name'] ? item['last_name'] : ' ') +
                  ' ' +
                  '(' +
                  (item['Category.name'] ? item['Category.name'] : ' ') +
                  ' ' +
                  (item['city'] ? '-' + item['city'] : ' ') +
                  ')',
                user_id: item['user_id'],
                candidate_mail: item['email'],
                candidate_name: item['name'],
                category: item['Category._id'],
                weekend_day_price: item['weekend_day_price'],
                weekend_night_price: item['weekend_night_price'],
                day_price: item['day_price'],
                night_price: item['night_price'],
                evening_price: item['evening_price'],
              })),
            );
          }),
        )
        .catch(reject);
    });

  //------------------get business unit---------------------
  const getbusinessunit = employer_id => {
    console.log('business unit -----', employer_id, shifts.employer_id);
    if (employer_id) {
      fetch(apiConstants.apiUrl + '/getbusinessunitlist', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          status: 1,
          employer_id: employer_id,
          select: ['_id', 'name'],
        }),
      }).then(response =>
        response.json().then(({ data }) => {
          if (data && data.rows) {
            // setbusinessunitData(data.rows);
            let businessunit_rows = [];
            for (let s = 0; s < data.rows.length; s++) {
              businessunit_rows.push(
                <option
                  selected={s == 0 ? true : false}
                  value={data.rows[s]._id}
                >
                  {data.rows[s].name}
                </option>,
              );
            }
            setbusinessunitoptionsData(businessunit_rows);
            setShifts(prevState => ({
              ...prevState,
              business_unit: data?.rows[0]?._id,
              bunit_name: data?.rows[0]?.name,
              bunit_address: data?.rows[0]?.address,
            }));
          }
        }),
      );
    }
  };

  //--------get date in string format--------
  const timeparse = datestr => {
    let timesplitted = datestr.split('T');
    return timesplitted[1];
  };

  //---------get date in string format =----------
  const dateparse = datestr => {
    let datesplitted = datestr.split('T');
    return datesplitted[0];
  };

  //---get all section when carehome is selected--------
  const getAllSection = () => {
    setIsLoading(true);
    const { dispatch } = props;
    if (shifts && shifts.employer_id && shifts.employer_id != 0) {
      if (localStorage.getItem('user')) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user.employer_id) {
          shifts.employer_id = user.employer_id;
        }
      }
      dispatch(
        sectionActions.sectionList({ employer_id: shifts.employer_id }),
      ).then(
        res => {
          let sectionlist = [];
          setIsLoading(false);
          // let sectionData = res.data.rows;
          setsectionData(res.data.rows);
          for (let i = 0; i < res.data.rows.length; i++) {
            sectionlist.push(
              <option
                selected={i == 0 ? true : false}
                key={res.data.rows[i].section_type}
                value={res.data.rows[i]._id}
              >
                {res.data.rows[i].name}{' '}
                {'(' +
                  res.data.rows[i].from_time +
                  '-' +
                  res.data.rows[i].to_time +
                  ')'}
              </option>,
            );
          }
          setShifts(prevState => ({
            ...prevState,
            section_type: res.data.rows[0].section_type,
            section_id: res.data.rows[0]._id,
            to_time_string: res.data.rows[0].to_time,
            from_time_string: res.data.rows[0].from_time,
            check_in_time: res.data.rows[0].from_time,
            check_out_time: res.data.rows[0].to_time,
          }));
          setsectionrows(sectionlist);
          getemployerpay(res.data.rows[0].section_type);
        },
        () => {
          setIsLoading(false);
        },
      );
    }
  };
  const getemployerpay = sectiontype => {
    const { dispatch } = props;
    if (
      shifts.employer_id &&
      (candidatepaydetails.category || shifts.category) &&
      sectiontype
    ) {
      let details = {};
      details.employer_id = shifts.employer_id;
      (details.category = shifts.category
        ? shifts.category
        : candidatepaydetails.category
        ? candidatepaydetails.category
        : ''),
        // details.section_type=sectiontype
        dispatch(PriceActions.PriceList(details)).then(
          res => {
            setIsLoading(false);
            let employerpay = 0;
            let candiadtepay = 0;
            if (res?.data?.rows.length !== 0) {
              if (sectiontype == 'Night') {
                employerpay = res.data.rows[0].night_price
                  ? res?.data?.rows[0].night_price
                  : res.data.rows[0].night_price;
                candiadtepay = candidatepaydetails.night_price
                  ? candidatepaydetails.night_price
                  : candidatepaydetails.night_price;
              } else if (sectiontype == 'Morning') {
                employerpay = res.data.rows[0].morning_price
                  ? res.data.rows[0].morning_price
                  : res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.morning_price
                  ? candidatepaydetails.morning_price
                  : candidatepaydetails.day_price;
              } else if (sectiontype == 'Day') {
                employerpay = res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.day_price;
              } else if (sectiontype == 'Evening') {
                employerpay = res.data.rows[0].evening_price
                  ? res.data.rows[0].evening_price
                  : res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.evening_price
                  ? candidatepaydetails.evening_price
                  : candidatepaydetails.day_price;
              } else {
                employerpay = res.data.rows[0].day_price;
                candiadtepay = candidatepaydetails.day_price;
              }

              setShifts(prevState => ({
                ...prevState,
                employer_to_pay: employerpay,
                price: candiadtepay,
              }));
            }
          },
          () => {
            setIsLoading(false);
          },
        );
    }
  };

  const handleChange = event => {
    let { name, value } = event.target;
    if (name == 'shift_start_from') {
      // let d = new Date(value);
      // let newvalue = d.getTime();
      // --to get time seperated from date string-----
      // let time_string = timeparse(value);
      // let date_string = dateparse(value);
      setShifts({
        ...shifts,
        // check_in_mills: newvalue,
        [name]: value,
      });
    } else if (name == 'shift_start_to') {
      let d = new Date(value);
      let newvalue = d.getTime();
      //---------check if the checkindate is less than checkout date in mills--
      if (newvalue < new Date(shifts.check_in_time).getTime()) {
        setError({ common: 'choose a valid date' });
      } else {
        //---to get time seperated from date string-----
        let time_string = timeparse(value);
        let date_string = dateparse(value);
        setShifts({
          ...shifts,
          to_time_string: time_string,
          to_date_string: date_string,
          check_out_mills: newvalue,
          [name]: value,
        });
      }
    } else if (name == 'price' || name == 'employer_to_pay') {
      const regex = /^(?!\.)^(?!.*[+e])[\d\s]{0,3}(\.\d{0,2})?$/;
      console.log('value:' + value);
      const sanitizedValue = regex.test(value) ? value : '';
      if (sanitizedValue) {
        setShifts(shifts => ({
          ...shifts,
          [name]: sanitizedValue,
        }));
        console.log(sanitizedValue, 'sanitised input', value);
      } else if (value === '') {
        setShifts(shifts => ({
          ...shifts,
          [name]: value,
        }));
      } else {
        // If sanitizedValue is empty or null, replace letters in value before setting
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setShifts(shifts => ({
            ...shifts,
            [name]: '',
          }));
        }
      }
    } else if (name == 'break_time') {
      console.log('break_time inside');
      const regex = /^(?!.*[+e])[\d\s]{0,3}$/;
      console.log('value:' + value);
      const sanitizedValue = regex.test(value) ? value : '';
      if (sanitizedValue) {
        setShifts(shifts => ({
          ...shifts,
          [name]: sanitizedValue,
        }));
        console.log('sanitized value..');
      } else if (value === '') {
        setShifts(shifts => ({
          ...shifts,
          [name]: value,
        }));
      } else {
        let digitCheck = /\d/.test(value);
        if (!digitCheck) {
          setShifts(shifts => ({
            ...shifts,
            [name]: '',
          }));
        }
        console.log('replaced letters with empty string');
      }
    } else if (name == 'section_type') {
      let selected_section_type = '';
      let selected_section = '';
      for (let s = 0; sectionData.length && s < sectionData.length; s++) {
        if (value == sectionData[s]._id) {
          selected_section = sectionData[s];
          selected_section_type = sectionData[s].section_type;
          // setselectedSectionType(sectionData[s].section_type);
        }
      }
      setShifts({
        ...shifts,
        section_id: value,
        to_time_string: selected_section.to_time,
        from_time_string: selected_section.from_time,
        check_in_time: selected_section.from_time,
        check_out_time: selected_section.to_time,
        section_type: selected_section_type,
      });
      getemployerpay(selected_section_type);
    } else if (name == 'business_unit') {
      const select = event.target;
      console.log(select, 'foliop');
      const bunitname = select.options[select.selectedIndex].text;
      setShifts(prevState => ({
        ...prevState,
        [name]: value,
        bunit_name: bunitname,
      }));
    } else {
      setShifts(prevState => ({ ...prevState, [name]: value }));
    }
  };
  console.log(shifts, '>>>vamos');

  //--------multipel date picker handle change ---------------
  const dateshandleChange = value => {
    let temp_dates = [];
    for (let d = 0; d < value.length; d++) {
      temp_dates.push(value[d].format('YYYY-MM-DD'));
    }
    setShifts({
      ...shifts,
      dates: temp_dates,
    });
  };

  const selecteduser = selectedOption => {
    console.log('kkkdaskda', selectedOption);
    // let name = event.name;
    let SelectedValues = [];
    for (let i = 0; i < selectedOption.length; i++) {
      let obj = {
        value: selectedOption[i].label,
        _id: selectedOption[i].value,
        candidate_id: selectedOption[i].value,
        user_id: selectedOption[i].user_id,
        candidate_mail: selectedOption[i].candidate_mail,
        candidate_name: selectedOption[i].candidate_name,
      };
      setcandidatepaydetails({
        category: selectedOption[i].category,
        _id: selectedOption[i].value,
        weekend_day_price: selectedOption[i].weekend_day_price,
        weekend_night_price: selectedOption[i].weekend_night_price,
        day_price: selectedOption[i].day_price,
        night_price: selectedOption[i].night_price,
        evening_price: selectedOption[i].evening_price,
      });
      SelectedValues.push(obj);
      setShifts(prevState => ({
        ...prevState,
        user: SelectedValues,
      }));
    }
  };
  const handleWheel = event => {
    event.target.blur();
  };
  const handleSubmit = event => {
    console.log('enter handlesub 556');
    //------------TO AVOUD MULTIPLE BUTTON CLICK ------------
    event.preventDefault();
    if (submitted) {
      return false;
    }
    const { dispatch } = props;
    if (
      (shifts.shift_start_from || shifts.dates.length) &&
      shifts.check_in_time &&
      shifts.check_out_time &&
      shifts.section_id &&
      shifts.category &&
      shifts.business_unit &&
      shifts.user.length > 0
    ) {
      if (shifts.shift_start_from && shifts.shift_start_to) {
        if (shifts.shift_start_from >= shifts.shift_start_to) {
          setError({ common: 'Choose a valid date' });
          return;
        }
      }
      if (shifts.price && shifts.price < 10.42) {
        setError({
          common: 'Price should be greater than minimum price of 10.42',
        });
        return;
      }
      if (shifts.price && shifts.price < category.minimum_price) {
        setError({
          common:
            'Price should be greater than minimum price of category' +
            ' ' +
            category.minimum_price,
        });
        return;
      }

      if (shifts.break_time && shifts.break_time >= 180) {
        setError({
          common: 'Break time should be less than 180 minutes ',
        });
        return;
      }
      if (shifts.employer_to_pay && shifts.employer_to_pay < 10.42) {
        setError({
          common: 'Employer pay should be greater than minimum price of 10.42',
        });
        return;
      }
      if (
        shifts.employer_to_pay &&
        shifts.employer_to_pay < category.minimum_employer_price
      ) {
        setError({
          common:
            'Employer pay should be greater than minimum price of category ' +
            ' ' +
            category.minimum_employer_price,
        });
        return;
      }

      setIsLoading(true);
      if (!shifts.break_time) {
        shifts.break_time = 0;
      }
      setSubmitted(true);
      setisDisabled(true);
      dispatch(shiftsActions.shiftsAdd(shifts)).then(
        () => {
          props.showAddshiftmodal(false);
          setSubmitted(false);
          setIsLoading(false);
          if (!props.listViewShiftsAdd) {
            window.location.reload();
          }
          setisDisabled(false);
        },
        err => {
          console.log('errorrrrrr', err);
          setError({ common: err });
          setSubmitted(false);
          setisDisabled(false);
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });
    }
  };

  const selectedemployer = (selectedOption, event) => {
    (shifts.check_in_time = ''), (shifts.check_out_time = '');
    let name = event.name;
    setShifts(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
    // setbreaktime(selectedOption.break_time);
    setShifts(prevState => ({
      ...prevState,
      employer_name: selectedOption.company_name + ' ' + selectedOption.city,
      break_time: selectedOption.break_time,
      is_break_time_pay: selectedOption.pay_break == 1 ? 1 : 0,
    }));
    // setEmployer(selectedOption);
    getbusinessunit(selectedOption.value);
    getemployer(selectedOption.value);
  };

  ///----category select change -----------
  const selectedcategory = selectedOption => {
    // let name = event.name;
    setShifts(prevState => ({
      ...prevState,
      category: selectedOption.value,
      category_name: selectedOption.label,
    }));
    if (shifts.section_type) {
      getemployerpay(shifts.section_type);
    }
  };

  const closeSectionAddModal = value => {
    setSectionAddModal(value);
    setsectionrows([]);
    getAllSection();
  };

  const closeBusinessunitAddModal = value => {
    setBusinessunitAddModal(value);
    getbusinessunit(shifts.employer_id);
  };

  return (
    <div className="ShiftsAdd">
      <div className="col-md-12 col-sm-6">
        <div className="card">
          <div className=" page-header ">
            <div className="page-title-content">
              <div className="row d-flex align-items-center">
                <div className="col-md-3">
                  <h4 className="card-title mt-0">
                    {!props.allocateshift
                      ? 'Assign New Shift'
                      : 'Allocate Shift'}{' '}
                  </h4>
                </div>
                {props.daymonthyear &&
                  props.daymonthyear.day &&
                  props.allocateshift && (
                    <div className="col d-flex align-items-center">
                      <div className="date-div">
                        {moment(props.daymonthyear.index, 'YYYY-MM-DD').format(
                          'DD MMM YYYY',
                        )}
                      </div>
                    </div>
                  )}
                {/* {isPastday && (
                  <div className="text-secondary">
                    Selected date is past day{' '}
                  </div>
                )} */}
              </div>
            </div>
          </div>
          <div className="card-body">
            <form name="form">
              {props.listViewShiftAddDate &&
                props.listViewShiftAddDate.date && (
                  <div className="row">
                    <div className="col d-flex align-items-center">
                      {' '}
                      <div className="date-div">
                        {moment(
                          props.listViewShiftAddDate.date,
                          'YYYY-MM-DD',
                        ).format('DD MMM YYYY')}
                      </div>
                    </div>
                  </div>
                )}
              {props.listViewShiftAddDate &&
                props.listViewShiftAddDate.shiftdata &&
                props.listViewShiftAddDate.shiftdata.name && (
                  <div className="col ">
                    <div className="row d-flex align-items-center mt-2  ">
                      <div className="">
                        <img
                          src="/assets/img/recsy-fav-icon.png"
                          className=" round-emp-image"
                        ></img>
                      </div>
                      <div style={{ fontWeight: '700' }}>
                        {props.listViewShiftAddDate.shiftdata.name}
                      </div>
                    </div>
                  </div>
                )}

              {/* //-----------dates for multiple date picker range ----------------- */}
              {!props.allocateshift && !props.listViewShiftAddDate && (
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="row"
                      style={{ marginBottom: '10px', marginTop: '15px' }}
                    >
                      <div className="col-md-6 pr-1">
                        <label htmlFor="type">
                          Date: <span>*</span>
                        </label>
                        <div
                          className={
                            'form-group ' +
                            (submitted && !shifts.shift_start_from
                              ? ' has-error'
                              : '')
                          }
                        >
                          <DatePicker
                            multiple
                            data-cy="shift-date"
                            style={{
                              // backgroundColor: "aliceblue",
                              height: '44px',
                              borderRadius: '4px',
                              fontSize: '14px',
                              padding: '4px 10px',
                            }}
                            // value={Dates}
                            format="YYYY-MM-DD"
                            minDate={new Date()}
                            onChange={dateshandleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* //------------select category ------------>>>>>>>.. */}
              {
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Jobrole: <span>*</span>
                      </label>
                      <AsyncSelect
                        name="category"
                        onChange={selectedcategory}
                        cacheOptions
                        defaultOptions
                        className="categoryselect"
                        loadOptions={getcategorylist}
                      />
                      {submitted && !shifts.category && (
                        <div className="help-block"> category is required</div>
                      )}
                    </div>
                  </div>
                </div>
              }

              {/* //--------select user ---------- */}
              {!IsEmployer &&
                !IsBunit &&
                !props.isHideuser &&
                props.isSelectUsershow && (
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Shift allocated to : <span>*</span>
                        </label>
                        <AsyncSelect
                          name="candidate_id"
                          onChange={selecteduser}
                          placeholder={'select candidate'}
                          cacheOptions
                          defaultOptions
                          className="Shift_allocated_to"
                          isMulti
                          loadOptions={getcandidatelist}
                        />
                      </div>
                    </div>
                  </div>
                )}

              {/* //---user multiple---- */}
              {(IsEmployer || IsBunit) &&
                (props.isSelectUsershow ||
                  !props.listViewShiftAddDate ||
                  props.isMultipleDays) &&
                !props.isHideuser && (
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Shift allocated to : <span>*</span>
                        </label>
                        <div className={'form-group '}>
                          <AsyncSelect
                            name="user"
                            onChange={selecteduser}
                            cacheOptions
                            defaultOptions
                            className="Shift_allocatedd_to"
                            isMulti
                            loadOptions={getBlockBookedCandidatelist}
                          />
                          {submitted && !shifts.user && (
                            <div className="help-block">User is required</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {!IsBunit && !IsEmployer && (
                // !IsEmployer && // it hide for employer add shift to child organization
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Select{' '}
                        {IsParentEmployer
                          ? 'organization (Select if shift is for organization)'
                          : 'employer'}{' '}
                        : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !shifts.employer_id ? ' has-error' : '')
                        }
                      >
                        <AsyncSelect
                          name="employer_id"
                          onChange={selectedemployer}
                          cacheOptions
                          defaultOptions
                          className="employerselect"
                          loadOptions={getEmployerList}
                        />
                        {submitted && !shifts.employer_id && (
                          <div className="help-block">Employer is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* --!!!!!!!!!!!!it comment for temporary after to do  */}
              {/* {!IsEmployer ||
                (IsParentEmployer && (
                  // !IsEmployer && // it hide for employer add shift to child organization
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        {IsParentEmployer && (
                          <label htmlFor="type">
                            Select employer :(select for organization)
                          </label>
                        )}
                        {!IsEmployer && (
                          <label htmlFor="type">
                            Select employer :(select for organization)
                          </label>
                        )}
                        <div
                          className={
                            'form-group ' +
                            (submitted && !shifts.employer_id
                              ? ' has-error'
                              : '')
                          }
                        >
                          <AsyncSelect
                            name="employer_id"
                            onChange={selectedemployer}
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerList}
                          />
                          {submitted && !shifts.employer_id && (
                            <div className="help-block">
                              Employer is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))} */}

              {!IsBunit && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Select business unit : <span>*</span>
                      </label>
                      {shifts.employer_id && (
                        <i
                          onClick={() => {
                            setBusinessunitAddModal(true);
                          }}
                          className="fa fa-plus-circle"
                          style={{
                            fontSize: '20px',
                            color: ' #3388EB',
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                        ></i>
                      )}
                      <div className="form-group">
                        <select
                          className="select col-md-12 select-business-unit"
                          name="business_unit"
                          onChange={handleChange}
                        >
                          <option value="" hidden="true">
                            {' '}
                            select
                          </option>
                          {businessunitoptionsData}
                        </select>
                        {submitted && !shifts.business_unit && (
                          <div className="help-block">
                            {' '}
                            business unit is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-5 pr-1">
                  <label htmlFor="type">
                    Shift Time : <span>*</span>
                  </label>
                  {shifts.employer_id && (
                    <i
                      onClick={() => {
                        setSectionAddModal(true);
                      }}
                      className="fa fa-plus-circle"
                      style={{
                        fontSize: '20px',
                        color: ' #3388EB',
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    ></i>
                  )}
                  <div className={'form-group '}>
                    <select
                      className="select col-md-12 selectdesign"
                      name="section_type"
                      value={shifts.section_id}
                      onChange={handleChange}
                    >
                      <option value=""> select shift Time</option>
                      {sectionrows}
                    </select>
                    {submitted && !shifts.section_type && (
                      <div className="help-block"> section is required</div>
                    )}
                  </div>
                </div>
              </div>
              {!IsEmployer && !IsBunit && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Candidate Pay : <span>*</span>{' '}
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="price"
                          min={category.minimum_price}
                          disabled={IsEmployer}
                          placeholder="Price"
                          value={shifts.price}
                          onChange={handleChange}
                          onWheel={handleWheel}
                        />
                        <small>
                          * Keep price blank or zero,It will add candidate
                          default price{' '}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!IsEmployer && !IsBunit && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Employer price : <span>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="employer_to_pay"
                          placeholder="Employer price "
                          min={category.minimum_employer_price}
                          value={shifts.employer_to_pay}
                          onChange={handleChange}
                          onWheel={handleWheel}
                        />
                        <small>
                          *include Employer NI,Holiday pay, Admin charge{' '}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-md-6 pr-1">
                  <div className={'form-group mb-4'}>
                    <label htmlFor="type" className="mr-2">
                      Break paid ?
                    </label>
                    <input
                      type="radio"
                      className="mr-2 ml-4"
                      name="is_break_time_pay"
                      placeholder="is_break_time_pay"
                      value={1}
                      checked={shifts.is_break_time_pay == 1}
                      onChange={handleChange}
                    />
                    <label className="mr-2 ">Yes</label>
                    <input
                      type="radio"
                      className="mr-2"
                      name="is_break_time_pay"
                      placeholder="is_break_time_pay"
                      value={0}
                      checked={shifts.is_break_time_pay == 0}
                      onChange={handleChange}
                    />
                    <label className="mr-2">No</label>
                  </div>
                </div>
              </div>
              {/* //----------show only break timeis pay true------- */}
              {
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">
                        Break Time (mins) : <span>*</span>
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          step="any"
                          className="form-control form-group-50px"
                          name="break_time"
                          placeholder="Break Time "
                          value={shifts.break_time}
                          onChange={handleChange}
                          onWheel={handleWheel}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <div className="d-flex">
                      <div
                        className=" btn-primary-blue-border "
                        onClick={isDisabled ? '' : handleSubmit}
                      >
                        Add now
                        {props.requestStatus ===
                          reducerConstants.SHIFTS_REQUEST && (
                          <div className="lds-ring ml-2">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />{' '}
        </div>
      </div>

      {SectionAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setSectionAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <SectionAdd
                        isAdd={true}
                        employer_id={shifts.employer_id}
                        closeSectionAddModal={closeSectionAddModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {BusinessunitAddModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setBusinessunitAddModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-12">
                      <BusinessunitAdd
                        employer_id={shifts.employer_id}
                        closeBusinessunitAddModal={closeBusinessunitAddModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getBlockBookedCandidatelist = inputValue =>
  new Promise((resolve, reject) => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    fetch(apiConstants.apiUrl + '/getcandidateemployerallocationlist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        employer_id: employer_id,
        searchKey: inputValue,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(i => ({
              value: i['Candidate._id'],
              label:
                i['Candidate.name'] +
                ' ' +
                (i['Candidate.last_name'] ? i['Candidate.last_name'] : ' ') +
                ' ' +
                '(' +
                (i['Category.name'] ? +i['Category.name'] : ' ') +
                (i['Candidate.city'] && i['Category.name'] ? '-' : '') +
                (i['Candidate.city'] ? ' ' + i['Candidate.city'] : ' ') +
                ')',
              user_id: i['Candidate.user_id'],
              candidate_mail: i['Candidate.email'],
              candidate_name: i['Candidate.name'],
            })),
          );
        }),
      )
      .catch(reject);
  });

const getEmployerList = inputValue =>
  new Promise((resolve, reject) => {
    let parent_id = '';
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id && user.role == 4) {
        parent_id = user.employer_id;
      }
    }
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        searchKey: inputValue,
        status: 1,
        active_status: 1,
        parent_id: parent_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(employer => ({
              value: employer._id,
              label: employer.company_name,
              ...employer,
            })),
          );
        }),
      )
      .catch(reject);
  });

const getcategorylist = inputValue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputValue,
        select: [
          '_id',
          'name',
          'minimum_employer_price',
          'minimum_price',
          'morning_price',
          'night_price',
          'evening_price',
          'day_price',

          'holiday_price',
        ],
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows.map(
              ({
                _id,
                name,
                morning_price,
                night_price,
                evening_price,
                day_price,

                holiday_price,
              }) => ({
                value: _id,
                label: name,
                morning_price: morning_price,
                night_price: night_price,
                evening_price: evening_price,
                day_price: day_price,

                holiday_price: holiday_price,
              }),
            ),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedShiftsAdd = connect(mapStateToProps)(ShiftsAdd);
export { connectedShiftsAdd as ShiftsAdd };
