import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const blockcandidateService = {
  blockcandidateAdd,
  blockcandidateList,
  blockcandidateDelete,
  unblockCandidate,
};
function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function blockcandidateAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/candidateblockadd', requestOptions).then(
    handleResponse,
  );
}
function unblockCandidate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/unblockcandidate', requestOptions).then(
    handleResponse,
  );
}
function blockcandidateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getblockcandidatelist',
    requestOptions,
  ).then(handleResponse);
}

function blockcandidateDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/blockcandidatedelete',
    requestOptions,
  ).then(handleResponse);
}
