import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';

// eslint-disable-next-line no-unused-vars
let shiftsRows = [];

const ShiftsList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    check_in_date: new Date().toISOString().slice(0, 10),
  });
  // effect loader for get all shift call
  useEffect(() => {
    getAllShifts(1);
  }, []);
  // get all shift call
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    queryNew.shifttype = props.shifttype;
    queryNew.candidate_id = props.candidate_id;
    if (props.agency_candidate_id) {
      queryNew.agency_candidate_id = props.agency_candidate_id;
      queryNew.agency_id = props.agency_id;
    }
    setQuery(query);
    setIsLoading(true);
    if (props && props.candidate_id) {
      queryNew.candidate_id = props.candidate_id;
    }
    const { dispatch } = props;
    dispatch(shiftsActions.upcomingandcompleteshiftsList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  /**
   *
   *  set pagination and list rows
   */
  let shiftsList = [];
  const setListAndPagination = shiftsData => {
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      shiftsList.push(
        <tr key={j}>
          <td style={{ color: '#3D4A54', width: '200px' }}>
            {shiftsData.rows[j].check_in_date_string}
          </td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j].section_type}
          </td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j].check_in_time_string}
          </td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j].check_out_time_string}
          </td>
          <td style={{ color: '#02365C' }}>{shiftsData.rows[j].break_time}</td>
          <td style={{ color: '#02365C' }}>{shiftsData.rows[j].price}</td>
          <td style={{ color: '#02365C' }}>{shiftsData.rows[j].total_hours}</td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j].employer_to_pay}
          </td>
          <td style={{ color: '#02365C' }}>
            {shiftsData.rows[j]['Employer.company_name']}
          </td>
          <div className="d-flex justify-content-end"></div>
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };
  return (
    <div className="ShiftsList">
      <div className="row">
        <div className="col-md-12">
          <div className="card regular-table-with-color ml-0 mr-0">
            <div className="card-body  table-responsive main-card-body pl-0 pr-0">
              {!isLoading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Section</th>
                      <th>Check in</th>
                      <th>Check out</th>
                      <th>Break time</th>
                      <th>price</th>
                      <th>hours</th>
                      <th>employer pay</th>
                      <th>Employer</th>
                    </tr>
                  </thead>
                  <tbody>{shiftsRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {shiftsRows && shiftsRows.length == 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllShifts(data);
                }}
                shiftsData={shiftsData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return {
    requestStatus,
    shiftsData,
  };
}

const connectedShiftsList = connect(mapStateToProps)(ShiftsList);
export { connectedShiftsList as ShiftsList };
