import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoadingImg } from '../_components';
import { userService } from '../_services';
// import OtpInput from '../_components/OtpInput';
import { roleContant } from '../_constants';
import { todoConstant } from '../_constants/messageConstant';

const OTP_TYPE = 'email';
const emailRegex = /^[a-zA-Z]+[a-zA-Z0-9.]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/;

const LoginWithEmail = () => {
  const [user, setUser] = useState({
    email: '',
    phone: '',
    role: roleContant.EMPLOYER,
    otp: '',
  });
  const [error, setError] = useState({ common: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [otpSendSuccessfully, setOtpSendSuccessfully] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [resendOTPFlag, setResendOTPFlag] = useState(false);
  const [timerLeft, setTimeLeft] = useState(60);
  const [emailValidation, setEmailValidation] = useState(false);

  // Masked the Email Id
  const maskEmail = email => {
    const [emailPrefix, emailDomain] = email.split('@');
    const emailMask = emailPrefix
      .split('')
      .map((c, i, arr) => (i < 2 || i > arr.length - 3 ? c : '*'))
      .join('');
    return `${emailMask}@${emailDomain}`;
  };

  // handle change to set email and password
  const handleChange = e => {
    let { name, value } = e.target;
    //checking the OTP length
    if (name == 'otp' && value.length > 4) {
      return;
    }
    if (emailRegex.test(user.email)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
    user.email = user.email.toLowerCase();
    setUser({
      ...user,
      [name]: value,
    });
    setError({
      ...error,
      otp_error: '',
    });
  };

  // start the count Down
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        localStorage.clear();
      }
    }

    const timerId = setInterval(() => {
      if (timerLeft > 0) {
        setTimeLeft(timerLeft - 1);
      }

      if (timerLeft === 0) {
        setTimerOn(false);
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timerLeft]);

  const resendOTP = async otp_type => {
    setResendOTPFlag(true);
    setTimeLeft(60);
    setTimerOn(true);
    setError({
      ...error,
      otp_error: '',
    });

    try {
      if (otp_type == OTP_TYPE) {
        const userData = {
          email: user.email,
          otp_type: OTP_TYPE,
        };

        const res = await userService.sendOtpLogin(userData);
        console.log(res, `response from sendOTP`);
        // setTimeLeft(60);
        // setTimerOn(true);
      }
    } catch (error) {
      console.log(error);
      setError({
        ...error,
        phone: typeof error == 'string' ? error : error.message,
      });
      // console.error('Falied to verify the OTP');
    }
  };

  // Replace all characters except the first two and last two with asterisks

  const loginsendOTP = async otp_type => {
    setOtpSendSuccessfully(false);

    setError({
      ...error,
      otp_error: '',
    });

    if (otp_type === OTP_TYPE) {
      if (!user.email || !emailRegex.test(user.email)) {
        setError({ ...error, email: todoConstant.INVALID_EMAIL });
        return;
      }
      if (!user.email) {
        setError({
          ...error,
          email: todoConstant.EMAIL_REQUIRED,
        });
        return;
      }
      try {
        setIsLoading(true);
        const userData = {
          email: user.email,
          otp_type: OTP_TYPE,
        };

        const res = await userService.sendOtpLogin(userData);

        console.log(res, `response from sendOTP`);

        if (!res.data) {
          error.email = todoConstant.USER_NOT_FOUND;
          error.isError = true;
          setIsLoading(false);
          return;
        }
        if (res.data.role != roleContant.EMPLOYER) {
          error.email = todoConstant.NOT_AUTHRIZED_USER;
          error.isError = true;
          setIsLoading(false);
          return;
        }

        if (res.error_code == 0 && res.data.email) {
          setIsLoading(false);
          setOtpSendSuccessfully(true);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        setOtpSendSuccessfully(false);
        setError({
          ...error,
          common: error,
        });
      }
    }
  };

  const handleVerifyOtp = async (otp, otp_type) => {
    if (isLoadingVerify) {
      return;
    }
    setError({
      ...error,
      common: '',
    });
    setError({
      ...error,
      otp_error: '',
    });
    setIsLoadingVerify(true);

    try {
      const userData = {
        email: user.email,
        role: user.role,
        otp_type: otp_type,
        otp: otp, // Replace with the actual OTP value
      };
      console.log(userData, `UserData`);
      const res = await userService.verifyLogin(userData);
      console.log(res, 'Response Data for verify OTP');
      console.log(res.data, res.error_code, 'Response Data for verify OTP');
      if (res.data && res.error_code == 0) {
        console.log(res.data.token, '<<<<---');
        if (res.data.role == 4) {
          localStorage.setItem('user', JSON.stringify(res.data));
          window.location.href = '/dashboardemployer';
        } else {
          window.location.href = '/';
        }
      }
      setIsLoadingVerify(false);
    } catch (error) {
      console.log(error, 'error');
      setIsLoadingVerify(false);
      //   setotpSendSuccessfullyEmail(true);
      setError({
        ...error,
        otp_error: todoConstant.OTP_EXP_OR_INVALID,
      });
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-12">
        <div className="  logo-box-auth">
          <div className=" logo-box logo-box2">
            <img src="/assets/img/flexirecs-logo-emp.png" />
          </div>
        </div>
        <div className=" row justify-content-center">
          <div className="col-md-8 col-lg-6  login-box">
            <div className="row d-flex justfy-content-center">
              {/* first div ---  */}
              <div className="col-md-6 mx-auto">
                {!otpSendSuccessfully ? (
                  <div>
                    <h3
                      className="mb-4"
                      style={{
                        color: '#000',
                        fontWeight: '700',
                        fontSize: '24px',
                      }}
                    >
                      Sign In With OTP
                    </h3>
                    <span className="color-red">{error.common}</span>

                    <div name="form">
                      <div>
                        <p className="label-login mb-1" htmlFor="email">
                          Email
                        </p>
                        <input
                          type="email"
                          className="flx-common-input-fields form-control"
                          name="email"
                          value={user.email}
                          onChange={handleChange}
                        />
                        {error.email && (
                          <div className="text-danger mt-1">{error.email}</div>
                        )}
                      </div>
                      <div className="form-group mt-2">
                        <button
                          onClick={() => loginsendOTP(OTP_TYPE)}
                          style={{
                            fontWeight: '700',
                            fontSize: '12px',
                          }}
                          className={
                            'send-btn  ' +
                            (emailValidation ? 'active-signup-btn' : 'disabled')
                          }
                          disabled={isLoading}
                        >
                          {!isLoading ? 'Get OTP' : 'Loading...'}
                        </button>
                        {isLoading && false && <LoadingImg />}
                      </div>
                    </div>
                    {/* <Link
                      to="/loginwithphone"
                      className="btn btn-link text-center"
                      style={{ textDecoration: 'none' }}
                    >
                      <span className="flex-sign-up-text">
                        Send OTP to Phone?
                      </span>
                    </Link> */}
                    <div className="d-flex justify-content-center align-items-center mt-3">
                      <span className="flx-general-text">
                        Don&lsquo;t have an account?
                      </span>
                      <Link
                        to="/register"
                        className="btn btn-link text-right"
                        style={{ textDecoration: 'none' }}
                      >
                        <span className="flex-sign-up-text">Sign Up</span>
                      </Link>
                    </div>
                  </div>
                ) : (
                  <section className="">
                    <div className="row justify-content-center">
                      <div className="col-10 col-md-12">
                        <div className="card border-0">
                          <div className="text-center">
                            <h3
                              className="mb-4"
                              style={{
                                color: '#000',
                                fontWeight: '700',
                                fontSize: '34px',
                              }}
                            >
                              Enter OTP
                            </h3>
                            <p
                              className=" p-2 mb-4"
                              style={{
                                width: '100%',
                                fontSize: '14px',
                                fontFamily: 'Manrope',
                                fontWeight: '500',
                              }}
                            >
                              We have send an OTP
                              <br />
                              to {maskEmail(user.email)}
                            </p>
                            <div
                              className={
                                'row justify-content-center ' +
                                (error.otp_error ? 'otp_error' : '')
                              }
                            >
                              <div className="col-9 col-md-6 mb-4">
                                <input
                                  type="number"
                                  className="flx-common-input-fields form-control text-center"
                                  name="otp"
                                  maxLength={4}
                                  value={user.otp}
                                  onChange={handleChange}
                                />
                                {error.otp && (
                                  <div className="text-danger mt-1">
                                    {error.otp}
                                  </div>
                                )}
                              </div>
                            </div>
                            {!!error.otp_error && (
                              <div
                                className="alert alert-danger mt-2"
                                role="alert"
                              >
                                {error.otp_error}
                              </div>
                            )}
                            <div className="d-flex justify-content-center align-items-center mt-3 mb-4">
                              {!timerOn ? (
                                <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                                  <span className="flx-general-text">
                                    Don&lsquo;t received code?
                                  </span>
                                  <Link
                                    onClick={() => resendOTP(OTP_TYPE)}
                                    className="btn btn-link text-right"
                                    style={{ textDecoration: 'underline' }}
                                  >
                                    <span className="flex-sign-up-text">
                                      Resend OTP
                                    </span>
                                  </Link>
                                </div>
                              ) : (
                                <div>
                                  <p className="flx-general-text">
                                    We will resend code in{' '}
                                    {timerLeft < 10
                                      ? `0${timerLeft}`
                                      : timerLeft}{' '}
                                    sec
                                  </p>
                                  <div className="d-flex justify-content-center align-items-center mt-3 mb-2">
                                    <span className="flx-general-text">
                                      Don&lsquo;t received code?
                                    </span>
                                    <span
                                      className="inactive-link px-2"
                                      style={{
                                        color: 'gray',
                                        textDecoration: 'none',
                                      }}
                                    >
                                      Resend OTP
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="form-group mt-2">
                              <button
                                onClick={() =>
                                  handleVerifyOtp(user.otp, OTP_TYPE)
                                }
                                style={{
                                  fontWeight: '700',
                                  fontSize: '12px',
                                }}
                                className={
                                  'send-btn  ' +
                                  (user.otp.length == 4
                                    ? 'active-signup-btn'
                                    : 'disabled')
                                }
                                disabled={isLoadingVerify}
                              >
                                {!isLoadingVerify ? 'Verify' : 'Verifing...'}
                              </button>
                              {isLoading && false && <LoadingImg />}
                            </div>

                            {/* <div className="d-flex justify-content-center align-items-center mt-4">
                              <Link
                                to="/loginwithphone"
                                className="btn btn-link text-right"
                                style={{ textDecoration: 'underline' }}
                              >
                                <span className="flex-sign-up-text">
                                  I Don&lsquo;t have access to this Email?
                                </span>
                              </Link>
                            </div> */}
                            {resendOTPFlag && (
                              <div className="d-flex justify-content-center align-items-center mt-4">
                                <Link
                                  to="/login"
                                  className="btn btn-link text-right"
                                  style={{ textDecoration: 'underline' }}
                                >
                                  <span className="flex-sign-up-text">
                                    I Don&lsquo;t have access to this Email &
                                    Phone?
                                  </span>
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps() {
  // const { LoginWithPhone } = state.authentication;
  return {
    // LoginWithPhone,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginWithEmail);
export { connectedLoginPage as LoginWithEmail };
// export default LoginWithPhone;
