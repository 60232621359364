import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { candidateActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';

//TODO:Split the component into smaller components

const CandidateList = props => {
  let navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [TrashChecked, setTrashChecked] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [candidateRows, setCandidateRows] = useState([]);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [candidateid, setcandidateid] = useState(0);
  const [status, setstatus] = useState();
  const [AllSelected, setAllSelected] = useState(false);
  //---csv states -----
  const [shiftidsCount, setshiftidsCount] = useState(0);
  const [selected_shiftIds, setselected_shiftIds] = useState([]);
  const [selected_payout_data, setselected_payout_data] = useState([]);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: parseInt(new URLSearchParams(location.search).get('page')) || 1,
      noOfItems: 8,
    },
    searchKey: '',
    // isverified: '',
    city_postcode: '',
    status: 1,
  });
  //--- debounce feature start ---
  const debouncedSearch = useDebounce(query.searchKey, 500);
  const debouncedSearchCityPostcode = useDebounce(query?.city_postcode, 500);
  const debouncedSearchIsverified = useDebounce(query?.isverified, 500);
  const debouncedSearchBranch = useDebounce(query?.branch, 500);
  const debouncedSearchService_type = useDebounce(query?.service_type, 500);
  const debouncedSearchCategory = useDebounce(query?.category, 500);
  useEffect(() => {
    if (
      debouncedSearch ||
      debouncedSearchCityPostcode ||
      debouncedSearchIsverified ||
      debouncedSearchBranch ||
      debouncedSearchService_type ||
      debouncedSearchCategory
    ) {
      getAllCandidate(1);
    }
  }, [query]);
  //--- debounce feature end ---
  // effect for load get candidates while change in selected shiftids
  useEffect(() => {
    getAllCandidate(query.pageVo.pageNo);
  }, [selected_shiftIds.length]);
  // filter for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // check box selected
  const check_ifSelected = _id => {
    let found = false;
    for (let s = 0; s < selected_shiftIds.length; s++) {
      if (selected_shiftIds[s] == _id) {
        found = true;
      }
    }
    return found;
  };
  // all candiate select check box handle funciton
  const allcheckedhandle = value => {
    let new_selectedShiftIds = [];
    let new_selected_payout_data = [];
    if (value) {
      let newCandi = candidateData.rows;
      for (let c = 0; c < newCandi.length; c++) {
        new_selectedShiftIds.push(newCandi[c]._id);
        let csv_file_data = {
          Name: newCandi[c].name,
          Surname: newCandi[c].last_name ? newCandi[c].last_name : '',
          'Employee NINO': newCandi[c].ni_number,
          Gender: newCandi[c].gender,
          'Date of Birth': newCandi[c].dob,
          'Address Line 1': newCandi[c].address,
          'Address Line 2': newCandi[c].address_line2,
          Postcode: newCandi[c].postcode,
          'Email Address': newCandi[c].email,
          'Phone Number': newCandi[c].contact_number,
          'Hourly Pay Rate': (newCandi[c].total_paid
            ? newCandi[c].total_paid / newCandi[c].total_hours
            : newCandi[c].day_price
          ).toFixed(2),
        };
        new_selected_payout_data.push(csv_file_data);
      }
    }
    setshiftidsCount(new_selectedShiftIds.length);
    setselected_shiftIds(new_selectedShiftIds);
    setselected_payout_data(new_selected_payout_data);
    setAllSelected(value);
  };
  // handle select while selcting candidate from the list view
  const handleSelect = (_name, shift_id, payoutdata) => {
    let new_selectedShiftIds = selected_shiftIds;
    let new_selected_payout_data = selected_payout_data;
    let found = false;
    for (let p = 0; p < new_selectedShiftIds.length; p++) {
      if (new_selectedShiftIds[p] == shift_id) {
        new_selectedShiftIds.splice(p, 1);
        found = true;
      }
    }
    let csv_file_data = {
      id: payoutdata._id,
      Name: payoutdata.name,
      Surname: payoutdata.last_name ? payoutdata.last_name : '',
      'Employee NINO': payoutdata.ni_number,
      Gender: payoutdata.gender,
      'Date of Birth': payoutdata.dob,
      'Address Line 1': payoutdata.address,
      'Address Line 2': payoutdata.address_line2,
      Postcode: payoutdata.postcode,
      'Email Address': payoutdata.email,
      'Phone Number': payoutdata.contact_number,
      'Hourly Pay Rate': (payoutdata.total_paid
        ? payoutdata.total_paid / payoutdata.total_hours
        : payoutdata.day_price
      ).toFixed(2),
    };
    let data_found = false;
    for (let s = 0; s < new_selected_payout_data.length; s++) {
      if (new_selected_payout_data[s].id == payoutdata._id) {
        new_selected_payout_data.splice(s, 1);
        data_found = true;
      }
    }
    if (!found) {
      new_selectedShiftIds.push(shift_id);
    }
    if (!data_found) {
      new_selected_payout_data.push(csv_file_data);
    }
    setshiftidsCount(new_selectedShiftIds.length);
    setselected_shiftIds(new_selectedShiftIds);
    setselected_payout_data(new_selected_payout_data);
  };
  // delete candidate confirmation modal view
  const deletecandidatemodal = _id => {
    setdeleteconfirmationmodal(true);
    setcandidateid(_id);
  };
  // delete candidate function
  const deletecandidate = () => {
    const { dispatch } = props;
    if (isDeleteLoading) {
      return;
    }
    setIsDeleteLoading(true);
    dispatch(
      candidateActions.candidateDelete({ _id: candidateid, status: 0 }),
    ).then(
      response => {
        console.log(response);
        setdeleteconfirmationmodal(false);
        setIsDeleteLoading(false);
        getAllCandidate(query.pageVo.pageNo);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };

  const changePage = newPage => {
    const newUrl = new URL(window.location);
    newUrl.searchParams.set('page', newPage);

    navigate(newUrl.pathname + newUrl.search, { replace: true });
  };
  // get all candidates list
  const getAllCandidate = (pageNo, queryTemp) => {
    if (isLoading) return;
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    changePage(pageNo);
    setIsLoading(true);
    const { dispatch } = props;
    if (props.isHomepage) {
      queryNew.isverified = 0;
    }
    if (queryNew.branch == '') {
      delete queryNew.branch;
    }
    if (queryNew?.city_postcode == '') {
      delete queryNew.city_postcode;
    }
    dispatch(candidateActions.candidateListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data.candidate.data);
        setCandidateData(res.data.candidate.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // onclick trash button
  const trash_checked = value => {
    setTrashChecked(value);
    if (value) {
      setQuery({ ...query, status: 0 });
    } else {
      setQuery({ ...query, status: 1 });
    }
  };
  // handler for change in filter searchKey,city_postcode,
  // isverified,branch,service_type
  const handleChange = event => {
    let { name, value } = event.target;
    // if (name == 'dob') {
    //   value = moment(value).format('YYYY-MM-DD');
    // }
    setQuery(prev => ({
      ...prev,
      [name]: value,
    }));
  };
  // handle change while category select
  const category_change = selectedOption => {
    setQuery(prevState => ({ ...prevState, category: selectedOption.value }));
    if (debouncedSearchCategory) {
      getAllCandidate(1, { ...query, category: selectedOption.value });
    }
  };
  // list and pagination data for candidate data
  const setListAndPagination = candidateData => {
    let candidateList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      candidateList.push(
        <tr key={j}>
          {!props.isHomepage && (
            <td>
              <div>
                <div
                  className="pointer"
                  onClick={() =>
                    handleSelect(
                      'shift_checked',
                      candidateData.rows[j]._id,
                      candidateData.rows[j],
                    )
                  }
                >
                  {check_ifSelected(candidateData.rows[j]._id) ? (
                    <img className="check_style" src="/assets/img/check1.png" />
                  ) : (
                    <img
                      className="check_style"
                      src="/assets/img/un_check1.png"
                    />
                  )}
                </div>
              </div>
            </td>
          )}
          <td>
            {2000 +
              (candidateData &&
                candidateData.rows &&
                candidateData.rows[j]._id &&
                candidateData.rows[j]._id)}{' '}
          </td>
          <td className="username">
            <div className="d-flex align-items-center">
              {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].profileimage ? (
                <img
                  className=" round-emp-image"
                  src={apiConstants.imgUrl + candidateData.rows[j].profileimage}
                />
              ) : (
                <img
                  className=" round-emp-image"
                  src="/assets/img/un-avatar.png"
                />
              )}
              <p
                data-cy="viewprofile"
                style={{ cursor: 'pointer', color: '#404144' }}
                onClick={() => {
                  setSelectedCandidateId(candidateData.rows[j]._id),
                    setstatus(candidateData.rows[j].status);
                  setshowCandidateprofileModal(true);
                }}
              >
                {' '}
                {candidateData.rows[j].name + ' '}{' '}
                {candidateData.rows[j].last_name
                  ? candidateData.rows[j].last_name
                  : ''}{' '}
              </p>
            </div>
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j]['Category.name'] &&
              candidateData.rows[j]['Category.name']}
          </td>
          <td className="text-capitalize">
            <a
              href={
                'https://www.google.com/maps/search/?api=1&query=' +
                candidateData.rows[j].lat +
                ',' +
                candidateData.rows[j].long
              }
            >
              {candidateData &&
                candidateData.rows &&
                candidateData.rows[j].city &&
                candidateData.rows[j].city}
            </a>
            <br />
            <p className="text-uppercase font-11">
              {candidateData.rows[j].postcode
                ? candidateData.rows[j].postcode
                : ''}
            </p>
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].service_type &&
              candidateData.rows[j].service_type}
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].createdAt &&
              moment(candidateData.rows[j].createdAt).format('YYYY-MMM-DD')}
          </td>
          {!props.isHomepage && (
            <td className="hours_text">
              {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].total_hours
                ? candidateData.rows[j].total_hours + ' ' + 'hrs'
                : 0 + ' ' + 'hrs'}
            </td>
          )}
          {!props.isHomepage && (
            <td className="price_text">
              {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].total_paid
                ? '£' + ' ' + candidateData.rows[j].total_paid
                : '£' + ' ' + 0}
            </td>
          )}
          {!TrashChecked && (
            <td className="eventCell  eventView">
              <Link
                data-cy="viewdetails"
                to={'/candidate/' + candidateData.rows[j]._id}
              >
                view
              </Link>
            </td>
          )}
          {!TrashChecked && !props.isHomepage && (
            <td
              style={
                candidateData.rows[j].isverified == 1
                  ? { color: '#008907' }
                  : candidateData.rows[j].isverified == 0
                  ? { color: 'rgb(205 27 50)' }
                  : candidateData.rows[j].isverified == 2
                  ? { color: 'rgb(255 160 2)' }
                  : { color: '#b02739' }
              }
            >
              {candidateData.rows[j].isverified == 1
                ? 'Verified'
                : candidateData.rows[j].isverified == 0
                ? 'Not verified'
                : candidateData.rows[j].isverified == 2
                ? 'Ready for Compliance review'
                : 'Rejected'}
            </td>
          )}
          {/* {!TrashChecked && (
            <td
              style={
                candidateData.rows[j].active_status == 1
                  ? { color: '#a4a4a4' }
                  : { color: '#b02739' }
              }
            >
              {candidateData.rows[j].active_status == 1 ? 'Active' : 'Inactive'}
            </td>
          )} */}
          {!TrashChecked && (
            <td
              data-cy="deletevacancy"
              className="eventCell event Edit"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                deletecandidatemodal(candidateData.rows[j]._id);
              }}
            >
              <img src="/assets/img/delete.png"></img>
            </td>
          )}
        </tr>,
      );
    }
    setCandidateRows(candidateList);
  };
  // style for select box
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      width: '200px',
      lineHeight: '25px',
    }),
  };

  return (
    <div className=" ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            {!props.isHomepage && (
              <div className="card-header page-header ">
                <div className="row">
                  <div className="col-md-8">
                    <div className="page-title">Candidate</div>
                  </div>
                </div>
                <div className="row d-flex align-items-center">
                  <div className="col-md-2 p-0 ">
                    <p>
                      <div className="input-outer-div mt-1">
                        <input
                          className="search-input-div"
                          value={query.searchKey}
                          onChange={handleChange}
                          type="text"
                          name="searchKey"
                          aria-label="Search"
                          placeholder="Search here..."
                        />
                        <a className="search_bar">
                          <i
                            className="fa fa-search in_bar"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                  <div className="col-md-2 p-0 ">
                    <p>
                      <div className="input-outer-div mt-1">
                        <input
                          className="search-input-div"
                          value={query?.city_postcode}
                          onChange={handleChange}
                          type="text"
                          name="city_postcode"
                          aria-label="Search"
                          placeholder="Search Postcode or City"
                        />
                        <a className="search_bar">
                          <i
                            className="fa fa-search in_bar"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                  {/* //------------filter section ---------------- */}
                  <div className="col-md-1 p-0">
                    <select
                      className="select col-md-12 selectdesing2 mt-1"
                      name="isverified"
                      value={query.isverified}
                      onChange={handleChange}
                    >
                      <option value=""> ALL</option>
                      <option value={1}> Verified</option>
                      <option value={0}> Not Verified</option>
                      <option value={2}> Ready for compliance review</option>
                      <option value={-1}> Rejected</option>
                    </select>
                  </div>
                  <div className="col-md-2 ">
                    <select
                      className="select col-md-12 selectdesing2 mt-1"
                      name="branch"
                      value={query.branch}
                      onChange={handleChange}
                    >
                      <option value="77" hidden={true}>
                        {' '}
                        Select Branch
                      </option>
                      <option value=""> All</option>
                      <option value={'Basildon'}>Basildon</option>
                      <option value={'Wales'}>Wales</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <select
                      className="select col-md-12 selectdesing2 mt-1"
                      name="service_type"
                      value={query.service_type}
                      onChange={handleChange}
                      placeholder={'select service sector'}
                    >
                      <option hidden={true}> select service sector</option>
                      <option value=""> ALL</option>
                      <option value="Hospitality"> Hospitality</option>
                      <option value="Health Care"> Health Care</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <div className="mt-1" style={{ marginRight: '20px' }}>
                      <AsyncSelect
                        styles={customSelectStyles}
                        name="category"
                        onChange={category_change}
                        placeholder="select category"
                        cacheOptions
                        defaultOptions
                        loadOptions={getCategorylist}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="row m-0" style={{ justifyContent: 'end' }}>
                      <div
                        className=" btn-primary-blue-nowidth"
                        data-cy="addcandidate"
                        onClick={() => {
                          navigate('/candidateadd/0');
                        }}
                      >
                        Add Candidate
                      </div>
                    </div>
                  </div>

                  {/* //==============filter end ---------------- */}
                </div>
                <div className="row mt-2">
                  {/* <div className=" p-0 ">
                    <p>
                      <div className="form-group">
                        <input
                          className="form-control"
                          value={query.dob}
                          onChange={handleChange}
                          type="date"
                          name="dob"
                          aria-label="Search"
                          placeholder="Search here..."
                        />
                      </div>
                    </p>
                  </div> */}
                  <div className="col-md-2 p-0">
                    <select
                      className="select p-0 col-md-12 selectdesign"
                      name="visatype"
                      value={query.visatype}
                      onChange={handleChange}
                    >
                      <option value=""> All</option>
                      <option value={'Student'}>Student</option>
                      <option value={'Student Dependent'}>
                        Student Dependent
                      </option>
                      <option value={'Citizen'}>Citizen</option>
                      <option value={'Citizen Dependent'}>
                        Citizen Dependent
                      </option>
                      <option value={' Graduate Visa'}> Graduate Visa</option>
                      <option value={'Graduate Dependent'}>
                        {' '}
                        Graduate Dependent Visa
                      </option>
                      Graduate Dependent
                      <option value={'ILR'}>Indefinite Leave to Remain</option>
                      <option value={'No Valid Work Permit'}>
                        No Valid Work Permit
                      </option>
                      <option value={'PR'}>PR</option>
                      <option value={'Skilled Worker'}>Skilled Worker</option>
                      <option value={'Dependent'}>
                        Skilled Worker Dependent
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            )}
            {!props.isHomepage && (
              <div className="  ml-5 mr-5 ">
                <div className="row d-flex justify-content-between">
                  <div> {shiftidsCount} items selected</div>
                  <div>
                    <div className="d-flex ">
                      {selected_shiftIds.length > 0 && (
                        <div className="row">
                          <CsvDownload
                            style={{
                              border: 'none',
                              padding: '4px',
                              backgroundColor: '#f7f9fb',
                            }}
                            data={selected_payout_data}
                          >
                            Download CSV
                          </CsvDownload>
                          <div>
                            <img
                              style={{ height: 15, width: 15 }}
                              src="/assets/img/csv1.png"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-header page-header   ">
                    <div
                      data-cy="trash"
                      className={
                        TrashChecked
                          ? 'trashButtonActive'
                          : 'trashButtonInActive'
                      }
                      onClick={() => {
                        trash_checked(!TrashChecked);
                      }}
                    >
                      Trash
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="card-body  table-responsive main-card-body p-0">
              {!isLoading && (
                <table className="table table-hover flx-custom-table">
                  <thead>
                    <tr>
                      {!props.isHomepage && (
                        <th className="border-top1 pointer">
                          <div
                            onClick={() => {
                              allcheckedhandle(!AllSelected);
                            }}
                          >
                            {AllSelected ? (
                              <img
                                className="check_style"
                                src="/assets/img/check1.png"
                              />
                            ) : (
                              <img
                                className="check_style"
                                src="/assets/img/un_check1.png"
                              />
                            )}
                          </div>
                        </th>
                      )}
                      <th className={props.isHomepage ? 'border-top1' : ''}>
                        Id
                      </th>
                      <th
                        style={{ width: '200px' }}
                        onClick={() => handleSort('name')}
                        className="sortHead"
                      >
                        Name{' '}
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th>Jobrole</th>
                      <th>Location</th>
                      <th>Service Sector</th>
                      <th
                        onClick={() => handleSort('createdAt')}
                        className="sortHead"
                      >
                        Created
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      {!props.isHomepage && <th>Total Working hrs</th>}
                      {!props.isHomepage && <th>Total Paid</th>}
                      {!TrashChecked && <th></th>}
                      {!TrashChecked && !props.isHomepage && <th></th>}
                      {!TrashChecked && (
                        <th className="eventCell  eventView " />
                      )}
                      {!TrashChecked && (
                        <th className="eventCell  eventView border-top2" />
                      )}
                    </tr>
                  </thead>
                  <tbody>{candidateRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {candidateRows && candidateRows.length == 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllCandidate(data);
                }}
                paginationData={candidateData}
              />
            </div>
          </div>
        </div>
        \{/* //---------delete candidate confirmation modal view----------- */}
        {deleteconfirmationmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a
                      onClick={() => {
                        setdeleteconfirmationmodal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="bag">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <p className="delete_string" style={{ color: 'black' }}>
                          Are you sure ?{' '}
                        </p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="button_delete width_100"
                            onClick={() => {
                              setdeleteconfirmationmodal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            data-cy="deletebutton"
                            className="button_delete_close width_100"
                            onClick={() => deletecandidate()}
                          >
                            {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showCandidateprofileModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setshowCandidateprofileModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <CandidateProfile
                    candidate_id={SelectedCandidateId}
                    status={status}
                  ></CandidateProfile>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const getCategorylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // add an option in select
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({ value: _id, label: name })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return { requestStatus, candidateData };
}

const connectedCandidateList = connect(mapStateToProps)(CandidateList);
export { connectedCandidateList as CandidateList };
