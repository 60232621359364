// import 'jspdf-autotable';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { shiftsActions } from '../_actions';
import { apiConstants } from '../_constants';

// eslint-disable-next-line no-unused-vars
let shiftsRows = [];

const ShiftPrint = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [shifts, setShift] = useState([]);
  const { invoiceprint, data, employerid, fromdate, todate } = useParams();
  const dataArray = data != 0 && data.split(',').map(Number);
  let consolidated_total_shifts = 0;
  let consolidated_total_hours = 0;
  let consolidated_total_amount = 0;
  useEffect(() => {
    getAllShifts(1);
  }, []);
  /**   * ----Get all shiftss-----   **/
  const getAllShifts = () => {
    setIsLoading(true);
    const { dispatch } = props;
    let selected_shiftIds = [];
    let querytemp = {};
    if (props?.shifts_Ids) {
      selected_shiftIds = props?.shifts_Ids;
    }
    if (dataArray.length) {
      querytemp.Ids = dataArray;
    } else {
      querytemp.employerreportprint = true;
    }
    if (invoiceprint == 1) {
      querytemp.invoiceprint = true;
    }
    if (employerid != 0) {
      querytemp.employer_id = employerid;
    }
    if (fromdate != 0) {
      querytemp.from_date = fromdate;
    }
    if (fromdate != 0) {
      querytemp.to_date = todate;
    }
    console.log('shift_ids', selected_shiftIds);
    dispatch(shiftsActions.printShift(querytemp)).then(
      res => {
        console.log(isLoading);
        if (res && res.data && res.data) {
          setShift(res.data);
        }
        setTimeout(() => {
          setIsLoading(false);
          window.print();
        }, 3000); // 10000 milliseconds = 10 seconds
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // to rener table header
  const renderTbHeader = () => (
    <thead>
      <tr>
        <th>Id</th>
        <th>Auth Person</th>
        <th>Day</th>
        <th>Date</th>
        <th>Time In</th>
        <th>Time Out</th>
        <th>Paid Hours</th>
        <th>Candidate Signature</th>
        <th>Employer Signature</th>
      </tr>
    </thead>
  );
  return (
    <div className="StaffList" style={{ fontFamily: 'helvica-bold' }}>
      <div className="col-md-12">
        <div className="card regular-table-with-color mt-20">
          <div className="mt40px">
            {!!shifts.length && (
              <div className="d-flex justify-content-between mb-30">
                <div className=" print_shift_flexilogo ">
                  <img
                    src="/assets/img/flexi_logo_bg.jpg"
                    className="printlogo"
                  />
                </div>
                <div className="lh-sm">
                  FLEXIRECS LTD.
                  <br />
                  FIRST FLOOR 10-12 <br />
                  SOUTHERNHAY , BASILDON
                  <br />
                  ESSEX, ENGLAND, SS14 1EL UNITED KINGDOM
                </div>
              </div>
            )}
          </div>

          <React.Fragment>
            <table className="table-bordered mt-20 ">
              {!!shifts.length && (
                <thead>
                  <tr>
                    <th>Candidate Name</th>
                    <th>Employer</th>
                    <th>No of Shifts</th>
                    <th>Total Hours</th>
                    {(!dataArray.length || invoiceprint == 1) && (
                      <th>Total Amount</th>
                    )}
                  </tr>
                </thead>
              )}
              {shifts.map((shiftGroup, index) => {
                let total_hours = 0;
                let total_amount = 0;
                consolidated_total_shifts =
                  consolidated_total_shifts + shiftGroup.shifts.length;
                return (
                  <>
                    {index !== 0 && index % 20 === 0 && (
                      <>
                        <tr style={{ breakAfter: 'page' }}></tr>
                      </>
                    )}
                    {shiftGroup.shifts.map(shift => {
                      total_hours =
                        total_hours + shift.actual_hours_after_break_time;
                      total_amount =
                        total_amount +
                        shift.actual_hours_after_break_time *
                          shift.employer_to_pay;
                      consolidated_total_hours =
                        consolidated_total_hours +
                        shift.actual_hours_after_break_time;
                      consolidated_total_amount =
                        consolidated_total_amount +
                        shift.actual_hours_after_break_time *
                          shift.employer_to_pay;
                    })}
                    <tbody>
                      <tr>
                        <td>{shiftGroup.headerdetails.candidate_name}</td>
                        <td>{shiftGroup.headerdetails.employer_name}</td>
                        <td>{shiftGroup.shifts.length}</td>
                        <td>{total_hours.toFixed(2)}</td>
                        {(!dataArray.length || invoiceprint == 1) && (
                          <td>{total_amount.toFixed(2)}</td>
                        )}
                      </tr>
                      {index == shifts.length - 1 && (
                        <>
                          <tr>
                            <td
                              colSpan={2}
                              className="fontwghtbold"
                              style={{ textAlignLast: 'end' }}
                            >
                              TOTAL{' '}
                            </td>
                            <td className="fontwghtbold">
                              {consolidated_total_shifts}
                            </td>
                            <td className="fontwghtbold">
                              {consolidated_total_hours.toFixed(2)}
                            </td>
                            {(!dataArray.length || invoiceprint == 1) && (
                              <td className="fontwghtbold">
                                {consolidated_total_amount.toFixed(2)}
                              </td>
                            )}
                          </tr>
                        </>
                      )}
                    </tbody>
                  </>
                );
              })}
            </table>
          </React.Fragment>
          <div style={{ breakAfter: 'page' }}></div>
          {shifts.map(shiftGroup => {
            let total_hours = 0;
            let total_amount = 0;
            return (
              <>
                <div className="mt40px">
                  <div className="d-flex justify-content-between mb-30">
                    <div className=" print_shift_flexilogo ">
                      <img
                        src="/assets/img/flexi_logo_bg.jpg"
                        className="printlogo"
                      />
                    </div>
                    <div className="lh-sm">
                      FLEXIRECS LTD.
                      <br />
                      FIRST FLOOR 10-12 <br />
                      SOUTHERNHAY , BASILDON
                      <br />
                      ESSEX, ENGLAND, SS14 1EL UNITED KINGDOM
                    </div>
                  </div>
                  <div
                    className="d-flex mb-30"
                    style={{ textTransform: 'capitalize' }}
                  >
                    <div className="lh-20 font-bold">
                      {shiftGroup.headerdetails.candidate_name}
                      <br />
                      {shiftGroup.headerdetails.candidate_address}
                    </div>
                    <div className="lh-20 ml-100 font-bold">
                      {shiftGroup.headerdetails.employer_name}
                      <br />
                      {shiftGroup.headerdetails.employer_address}
                    </div>
                  </div>
                </div>
                <React.Fragment key={shiftGroup.headerdetails.candidate_id}>
                  <table
                    className="table-bordered mt-20 mb-30 "
                    style={{ breakAfter: 'page' }}
                  >
                    {renderTbHeader()}
                    {shiftGroup.shifts.map((shift, index) => {
                      total_hours =
                        total_hours + shift.actual_hours_after_break_time;
                      total_amount =
                        total_amount +
                        shift.actual_hours_after_break_time *
                          shift.employer_to_pay;
                      return (
                        <>
                          {index !== 0 && index % 20 === 0 && (
                            <>
                              <tr style={{ breakAfter: 'page' }}></tr>
                            </>
                          )}
                          <tbody>
                            <tr>
                              <td>{'000' + shift._id}</td>
                              <td>{shift.signature_employer_name}</td>
                              <td>{shift.day}</td>
                              <td>{shift.check_in_date_string}</td>
                              <td>{shift.check_in_time_string}</td>
                              <td>{shift.check_out_time_string}</td>
                              <td>
                                {shift.actual_hours_after_break_time.toFixed(2)}
                              </td>
                              <td>
                                {shift.candidate_signature_url != null ? (
                                  <img
                                    className="sign-image"
                                    src={
                                      apiConstants.imgUrl +
                                      shift.candidate_signature_url
                                    }
                                  />
                                ) : (
                                  'Nil'
                                )}
                              </td>
                              <td>
                                {shift.employer_signature_url != null ? (
                                  <img
                                    className="sign-image"
                                    src={
                                      apiConstants.imgUrl +
                                      shift.employer_signature_url
                                    }
                                  />
                                ) : (
                                  'Nil'
                                )}
                              </td>
                            </tr>
                            {index == shiftGroup.shifts.length - 1 && (
                              <>
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="fontwghtbold"
                                    style={{ textAlignLast: 'end' }}
                                  >
                                    TOTAL SHIFTS
                                  </td>
                                  <td className="fontwghtbold">
                                    {shiftGroup.shifts.length}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="fontwghtbold"
                                    style={{ textAlignLast: 'end' }}
                                  >
                                    TOTAL HOURS
                                  </td>
                                  <td className="fontwghtbold">
                                    {total_hours.toFixed(2)}
                                  </td>
                                </tr>
                                {(!dataArray.length || invoiceprint == 1) && (
                                  <tr>
                                    <td
                                      colSpan={8}
                                      className="fontwghtbold"
                                      style={{ textAlignLast: 'end' }}
                                    >
                                      TOTAL AMOUNT
                                    </td>
                                    <td className="fontwghtbold">
                                      {total_amount.toFixed(2)}
                                    </td>
                                  </tr>
                                )}
                              </>
                            )}
                          </tbody>
                        </>
                      );
                    })}
                  </table>
                </React.Fragment>
              </>
            );
          })}
          <footer>
            {/* You can use a library like react-paginate to paginate and display page numbers */}
          </footer>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedShiftPrint = connect(mapStateToProps)(ShiftPrint);
export { connectedShiftPrint as ShiftPrint };
