import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const notificationService = {
  notificationAdd,
  notificationList,
  notificationDelete,
};
function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function notificationAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addnotification', requestOptions).then(
    handleResponse,
  );
}
function notificationList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getnotificationList',
    requestOptions,
  ).then(handleResponse);
}
function notificationDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/deletenotification',
    requestOptions,
  ).then(handleResponse);
}
