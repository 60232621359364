import React from 'react';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { miscService } from '../_services';
const Fileview = () => {
  const { url } = useParams();
  const fullurl = miscService.documentUrl(url);
  console.log('fullurl', fullurl, url);
  return (
    <div>
      <object data={fullurl} type="application/pdf" width="100%" height="600px">
        PDF could not be loaded. Please check the URL or try a different
        browser.
      </object>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedFileview = connect(mapStateToProps)(Fileview);
export { connectedFileview as Fileview };
