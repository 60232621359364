import { useGoogleLogin } from '@react-oauth/google';
import React, { useState } from 'react';
import { userService } from '../_services';
import { LoadingImg } from '../_components';

const GoogleLogin = ({ isLogin, showError }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [googleLoginId, setGoogleLoginId] = useState(null);
  const [promptModel, setPromptModel] = useState(false);

  const login = useGoogleLogin({
    onSuccess: codeResponse => {
      setIsLoading(true);
      GoogleLoginService(codeResponse);
      setIsLoading(false);
      // console.log('codeResponses........', codeResponse);
    },
    onError: error => {
      setIsLoading(false);
      console.log('Login Failed:', error);
      showError('Google Login Failed');
    },
  });

  const GoogleLoginService = async user => {
    console.log('User data:', user);
    setGoogleLoginId(user);
    user.isLogin = isLogin;
    try {
      const res = await userService.GoogleLoginRegistration(user);
      console.log('Data from GoogleLoginService:', res);

      if (res.error_code == 0) {
        // Store data in local storage
        if (res.data.shouldStoreModel) {
          setPromptModel(true);
          setIsLoading(false);
          return;
        }
        localStorage.setItem('user', JSON.stringify(res.data));
        console.log('Local Storage Data from GoogleLoginService');

        // Navigate
        if (res.data.role == 4) {
          if (res.data.phone) {
            window.location.href = '/dashboardemployer';
          } else {
            window.location.href = '/phonenumber';
          }
        } else {
          window.location.href = '/';
        }
      } else {
        console.log('1111111111111111', res.message);
        showError(res.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error in GoogleLoginService:', error);
      showError(typeof error === 'string' ? error : 'Google Login Failed');
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setGoogleLoginId(null);
    setPromptModel(false);
  };

  return (
    <>
      <>
        {promptModel && (
          <div id="myModal" className="modal" style={{ display: 'block' }}>
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                <img src="/assets/img/close-button.png" className="m-auto" />
              </span>
              <p>Would you like to merge with our system.</p>
              <button
                onClick={() =>
                  GoogleLoginService({
                    ...googleLoginId,
                    is_save_google_id: 'true',
                  })
                }
              >
                Yes
              </button>
              <button onClick={closeModal}>No</button>
            </div>
          </div>
        )}
      </>

      <button
        className="google-auth-btn"
        onClick={() => {
          login();
          setIsLoading(true);
        }}
      >
        <img src="/assets/img/google.png" className="m-auto" />
        <span className="px-2 m-auto flx-sign-up-option-text"> Google</span>
      </button>
      {isLoading && false && <LoadingImg />}
    </>
  );
};

export default GoogleLogin;
