import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { loginRequest, userService } from '../_services';

const MicrosoftAuth = ({ isLogin, showError, showLoading }) => {
  const { accounts, inProgress, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [microsoftLoginId, setMicrosoftLoginId] = useState(null);
  const [promptModel, setpromptModel] = useState(false);

  const handleLogin = async () => {
    await instance.loginRedirect(loginRequest);
  };

  const updateLoadingStatus = status => {
    showLoading(status);
  };

  const closeModal = () => {
    setMicrosoftLoginId(null);
    setpromptModel(false);
  };

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      updateLoadingStatus(true);
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: loginRequest.scopes,
        })
        .then(response => {
          microsoftLoginService(response);
        });
    }
  }, [inProgress, isAuthenticated, accounts, instance]);

  const microsoftLoginService = async data => {
    setMicrosoftLoginId(data);
    data.isLogin = isLogin;
    try {
      const res = await userService.microsoftlogin(data);
      if (res.error_code == 0) {
        if (res.data.show_microsoftIntegrationModel) {
          setpromptModel(true);
          return;
        }
        // Store data in local storage
        localStorage.setItem('user', JSON.stringify(res.data));
        // Navigate
        if (res.data.role == 4) {
          if (res.data.phone) {
            window.location.href = '/dashboardemployer';
          } else {
            window.location.href = '/phonenumber';
          }
        } else {
          window.location.href = '/';
        }
      }
    } catch (error) {
      // updateLoadingStatus(false);
      showError(typeof error === 'string' ? error : 'Microsoft Login Failed');
      window.localStorage.clear();
    }
    // updateLoadingStatus(false);
  };

  return (
    <div>
      <div>
        {promptModel && (
          <div
            id="myModal"
            className="modal Main "
            style={{ display: 'block' }}
          >
            <div className="modal-content popupMain">
              <div className="cancel-btn ">
                <span className="close " onClick={closeModal}>
                  <img
                    src="/assets/img/close-button.png"
                    className="close-btn-forID"
                  />
                </span>
              </div>
              <p className="text">Would you like to merge with our system ?</p>
              <span className="pt-4">
                <span>
                  <button
                    onClick={() =>
                      microsoftLoginService({
                        ...microsoftLoginId,
                        is_saved_microsoft_id: true,
                      })
                    }
                    className="btn-yes "
                  >
                    Yes
                  </button>{' '}
                </span>
                <span className="pl-3">
                  <button className="btn-btn2 " onClick={closeModal}>
                    No
                  </button>
                </span>
              </span>
            </div>
          </div>
        )}
      </div>

      <button className="microsoft-auth-btn" onClick={handleLogin}>
        <img src="/assets/img/microsoft-login-icon.png" className="m-auto" />
        <span className="px-2 m-auto flx-sign-up-option-text"> Microsoft</span>
      </button>
    </div>
  );
};

export default MicrosoftAuth;
