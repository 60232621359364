import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { sampleActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';

// eslint-disable-next-line no-unused-vars
let sampleRows = [];

const SampleList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [sampleData, setSampleData] = useState({ rows: [] });
  const [sampleRows, setSampleRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  });

  useEffect(() => {
    getAllSample(1);
  }, []);

  /**
   * Get all samples
   **/
  const getAllSample = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(sampleActions.sampleList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = sampleData => {
    console.log(sampleData);

    let sampleList = [];
    for (
      let j = 0;
      sampleData && sampleData.rows && j < sampleData.rows.length;
      j = j + 1
    ) {
      sampleList.push(
        <tr key={j}>
          <td>{(sampleData.page - 1) * sampleData.limit + j + 1}</td>
          <td>{sampleData.rows[j].name} </td>
          <td className="eventCell  eventView">
            <a href={'/sample/' + sampleData.rows[j]._id}>view</a>
          </td>
          <td className="eventCell  event Edit">
            <a href={'/sampleadd/' + sampleData.rows[j]._id}>Edit</a>
          </td>
        </tr>,
      );
    }
    setSampleRows(sampleList);
    setSampleData(sampleData);
  };
  return (
    <div className="SampleList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row">
              <div className="col-md-8">
                <h4> Sample List</h4>
              </div>
              <div className="col-md-4">
                <a className="btn btn-primary float-right" href="/sampleadd/0">
                  <i className="fa fa-envelope" />
                  <span> Add Sample</span>
                </a>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th className="eventCell  eventView" />
                    <th className="eventCell  eventEdit" />
                  </tr>
                </thead>

                <tbody>{sampleRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingImg />}
            {sampleData && sampleData.rows && sampleData.rows.length === 0 && (
              <p className="margin-top-20 text-center"> No result found </p>
            )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllSample(data);
              }}
              sampleData={sampleData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, sampleData } = state.sample;
  return {
    requestStatus,
    sampleData,
  };
}

const connectedSampleList = connect(mapStateToProps)(SampleList);
export { connectedSampleList as SampleList };
