import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { employerActions, staffActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { FileUpload } from '../fileupload/FileUpload';

const EmployerAdd = props => {
  const { id } = useParams();
  let navigate = useNavigate();
  const [employer, setEmployer] = useState({
    name: '',
    pay_break: 0,
    description: '',
    key_contact: [{}],
    postcode: '',
    is_vat: 0,
    break_time: 30,
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  const [stage, setstage] = useState(1);
  const [permenentemployer, setpermenentemployer] = useState(false);
  const [previous_parent_id, setprevious_parent_id] = useState();
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clownCounter, setClownCounter] = useState(0);
  const [error, setError] = useState({ common: '' });
  const [IsValid, setIsValid] = useState(false);
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  const [showsyncmodal, setshowsyncmodal] = useState(false);
  const [staffrows, setstaffrows] = useState();
  const [parentEmployerRows, setparentEmployerRows] = useState([]);
  const [IsParentEmployer, setIsParentEmployer] = useState(false);
  // effect to load employer when id present
  // and load staffs
  useEffect(() => {
    if (id !== '0') {
      setstage(2);
      query._id = id;
      setQuery(query);
      getAllEmployer(1, query);
      getAllstaff();
    }
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllEmployer(1, query);
    }
    getAllstaff();
  }, []);
  // effect to load address by postcode
  // to load employer list having dependencies -
  // employer id and permanent employer boolean
  useEffect(() => {
    loadaddress();
    getAllParentEmployer();
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 4 && user.is_parent == 1) {
        setEmployer({ ...employer, parent_id: user.employer_id });
        setIsParentEmployer(true);
      }
    }
  }, [employer._id, permenentemployer]);
  // load address using postal code
  const loadaddress = async () => {
    const res = employer.postcode.replace(/ /g, '');
    if (valid_postcode(res)) {
      let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let addresses_rows = [];
        let regex = /\s+,/g;
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, ' ')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setEmployer({
          ...employer,
          lat: response.latitude,
          long: response.longitude,
          postcode: employer.postcode,
        });
      }
    }
  };
  // call for get all parent employer  --
  const getAllParentEmployer = () => {
    const { dispatch } = props;
    let newquery = { status: 1, is_parent: 1 };
    if (employer._id) {
      newquery.employer_id = employer._id;
      newquery.ExceptThisEmployer = true;
    }
    dispatch(employerActions.employerListv2(newquery)).then(
      res => {
        if (res) {
          console.log(res);
          let parentArray = [];
          for (let s = 0; s < res.data.rows.length; s++) {
            parentArray.push(
              <option
                value={res.data.rows[s]._id}
                selected={res.data.rows[s]._id == employer.parent_id}
              >
                {res.data.rows[s].company_name}
              </option>,
            );
          }
          setparentEmployerRows(parentArray);
        }
      },
      err => {
        console.log(err);
      },
    );
  };
  // call for checking if email already exist
  const checkemailexist = () => {
    const { dispatch } = props;
    if (employer.email) {
      dispatch(
        employerActions.checkemailExist({
          email: employer.email,
        }),
      ).then(
        res => {
          setIsLoading(false);
          if (
            res &&
            res?.data?.data?.rows &&
            res?.data?.data?.rows?.length > 0
          ) {
            delete res.data.data.rows[0]._id;
            res.data.data.rows[0].is_parent = 0;
            setEmployer(res.data.data.rows[0]);
            setpermenentemployer(true);
            setshowsyncmodal(true);
            // setstage(2);
          } else if (res && res?.message == 'already registered email') {
            setError({ common: res.message });
          } else {
            setstage(2);
          }
        },
        () => {
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please enter email' });
    }
  };
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // to show file upload modal
  const showfileupload = () => {
    setshowmodalfileupload(true);
  };
  // to close file upload modal
  const hidefileupload = () => {
    setshowmodalfileupload(false);
  };
  // ----to remove keycontact array element ----
  // @params = i (array element index)
  const removeKeyElement = i => {
    let newkeycontact = employer.key_contact ? employer.key_contact : [];
    if (newkeycontact[0] && newkeycontact[0].name) {
      newkeycontact.splice(i, 1);
      setEmployer(prevState => ({ ...prevState, key_contact: newkeycontact }));
    }
    setClownCounter(clownCounter - 1);
  };
  // call for get all staffs
  const getAllstaff = () => {
    const { dispatch } = props;
    dispatch(staffActions.staffList()).then(res => {
      let staffData = res.data.rows;
      let stafflist = [];
      for (let i = 0; i < staffData.length; i++) {
        stafflist.push(
          <option value={staffData[i]._id}>{staffData[i].name}</option>,
        );
      }
      setstaffrows(stafflist);
    });
  };
  /**
   * Get all employers
   * */
  const getAllEmployer = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(employerActions.employerListv2(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setEmployer(res.data.rows[0]);
          setEmployer(prevState => ({
            ...prevState,
            key_contact: res.data.rows[0].key_contact,
          }));
          if (res.data.rows[0].key_contact) {
            setClownCounter(res.data.rows[0].key_contact.length);
          }
          if (res.data.rows[0].parent_id) {
            setprevious_parent_id(res.data.rows[0].parent_id);
          }
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  ///---------handle change array------
  const handleChange = event => {
    setError({ common: '' });
    const { name, value, checked } = event.target;
    if (name == 'contact_number' && value.length > 11) {
      return;
    }
    if (name == 'email') {
      const regex = /\S+@\S+\.\S+/;
      let isValidEmail = regex.test(event.target.value);
      if (!isValidEmail) {
        setIsValid(false);
        setEmployer({
          ...employer,
          [name]: value,
        });
        setError({ common: 'Enter a valid email' });
        return false;
      } else {
        setIsValid(true);
      }
    }
    if (
      name == 'key_name' ||
      name == 'key_position' ||
      name == 'key_email' ||
      name == 'key_phone'
    ) {
      //--------set to key_contact object -------
      setEmployer({
        ...employer,
        key_contact: { ...employer.key_contact, [name]: value },
      });
    } else if (name == 'contact_number' || name == 'secondary_number') {
      if (value.length >= 12) {
        return;
      } else {
        setEmployer({
          ...employer,
          [name]: value,
        });
      }
    } else if (name == 'is_vat') {
      if (checked) {
        setEmployer({
          ...employer,
          [name]: 1,
        });
      } else {
        setEmployer({
          ...employer,
          [name]: 0,
        });
      }
    } else if (name == 'is_parent') {
      if (checked) {
        setEmployer({
          ...employer,
          [name]: 1,
        });
      } else {
        setEmployer({
          ...employer,
          [name]: 0,
        });
      }
    } else {
      //--------set to employer state variable-----------
      setEmployer({
        ...employer,
        [name]: value,
      });
    }
  };
  // key contact change  ---
  const handleChangekeycontact = (event, i) => {
    const { name, value } = event.target;
    let newkeycontact = employer.key_contact ? employer.key_contact : [];
    if (newkeycontact && newkeycontact[i]) {
      newkeycontact[i][name] = value;
    } else {
      newkeycontact[i] = {};
      newkeycontact[i][name] = value;
    }
    setEmployer({
      ...employer,
      key_contact: newkeycontact,
    });
  };

  // check if valid postcode
  const valid_postcode = str_postcode => {
    let postcode = str_postcode.toUpperCase();
    postcode = postcode.toString().replace(/\s/g, '');
    var regex =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
    return regex.test(postcode);
  };
  // check if valid contact number
  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    // eslint-disable-next-line no-useless-escape
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };

  // get file upload response  -
  const getuploadresponse = response => {
    if (response && response.file_name) {
      setEmployer(prevState => ({
        ...prevState,
        profileimage: response.file_name,
      }));
    }
    setshowmodalfileupload(false);
  };
  // handler for postcode change
  const handleChangePostcode = async event => {
    event.preventDefault();
    let { name, value } = event.target;
    value = value.replace(/[^\w\s]/gi, '');
    let post_code = value.replace(/ /g, ''); //remove space
    setEmployer({
      ...employer,
      [name]: post_code,
    });
    setPostcodeObject({
      isValied: false,
      addresses: [],
    });
    if (valid_postcode(value)) {
      let posstcodeurl = `https://api.getaddress.io/find/${value}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let newarray = response.addresses[0].split(',');
        const regex = /\s+,/g;
        let addresses_rows = [];
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, '')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setEmployer({
          ...employer,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: post_code,
        });
      }
    } else if (employer._id) {
      setEmployer({
        ...employer,
        [name]: value,
      });
    }
  };
  // submit action to save employer and validation checking
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    if (employer.company_phone && !employer.contact_number) {
      employer.contact_number = employer.company_phone;
    }
    if (!employer.email && employer.company_email) {
      employer.email = employer.company_email;
    }
    if (!employer.cdm_staff && employer.staff_id) {
      employer.cdm_staff = employer.staff_id;
    }
    if (employer.pay_break == null) {
      setError({
        common: 'please choose Break paid ',
      });
      return;
    }
    if (employer.break_time && employer.break_time >= 180) {
      setError({
        common: 'Break time should be less than 180 minutes ',
      });
      return;
    }
    employer.previous_parent_id = previous_parent_id;
    if (employer.is_parent) {
      employer.parent_id = null;
    }
    if (
      employer.company_name &&
      employer.email &&
      employer.contact_number &&
      employer.address &&
      employer.postcode
    ) {
      if (!employer._id && !permenentemployer) {
        if (!employer.password && !employer.confirm_password) {
          setError({ common: 'Enter a password' });
          return;
        }
      }
      if (employer.is_vat && !employer.vat_percentage) {
        setError({ common: 'please enter vat percentage' });
        return;
      }
      if (!employer.is_parent && !employer.parent_id) {
        setError({ common: ' select parent company' });
        return;
      }
      setError({ common: '' });
      let postcode = valid_postcode(employer.postcode);
      if (postcode == false) {
        setError({ common: 'Enter a valid postcode' });
        return;
      }
      if (
        employer.password &&
        employer.confirm_password &&
        employer.password != employer.confirm_password &&
        !permenentemployer
      ) {
        setError({ common: 'Password does not match' });
        return;
      }
      let contact_number_validate = valid_contact_number(
        employer.contact_number,
      );
      if (employer.secondary_number) {
        let secondary_number = valid_contact_number(employer.secondary_number);
        if (secondary_number == false) {
          setError({ common: 'Secondary number not valid' });
          return;
        }
      }
      if (contact_number_validate == false) {
        setError({ common: 'Enter a valid phone number' });
        return;
      }
      if (
        (employer.is_vat == 1 && employer.vat_percentage <= 0) ||
        employer.vat_percentage > 100
      ) {
        setError({ common: ' Vat percentage not valid' });
        return;
      }
      if (
        employer.vacancy_percentage <= 0 ||
        employer.vacancy_percentage > 80
      ) {
        setError({ common: ' Vacancy percentage not valid' });
        return;
      }
      setIsLoading(true);
      let newEmployer = employer;
      console.log(newEmployer, 'newEmpppppppppp');
      // -----register employer from login page--------no default businessunit,------add businessunit----------
      if (employer.active_status == 0) {
        employer.addbusinessunit = 1;
      }
      employer.active_status = 1;

      if (permenentemployer) {
        employer.permenentemployer = true;
      }
      dispatch(employerActions.employerAdd(employer)).then(
        () => {
          navigate('/employerlist');
          setIsLoading(false);
        },
        err => {
          console.log(err);
          setError({
            common: err ? err : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please complete the form' });
    }
  };
  return (
    <div className="EmployerAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2 ">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className="  page-header ">
            <div className="page-title-content">
              {stage == 2 && !employer._id && (
                <img
                  src="/assets/img/Vector.png"
                  style={{ height: '25px' }}
                  onClick={() => {
                    setstage(1);
                    setError({ common: '' });
                    setSubmitted(false);
                  }}
                ></img>
              )}
              {stage == 1 && (
                <p className="card-title">
                  {employer && employer._id ? 'Update' : 'Add'} employer{' '}
                </p>
              )}
            </div>
          </div>
          <div className="card-body add-employer">
            <form name="form" onSubmit={handleSubmit} autoComplete="off">
              {stage == 1 && (
                <div className="row">
                  <div className="col-md-6 mb-0">
                    <div className={'form-group'}>
                      <label htmlFor="type">
                        Email : <span>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        value={employer.email}
                        onChange={handleChange}
                      />
                      {submitted && !employer.email && (
                        <div className="help-block">email is required</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: '-11px' }}>
                    <span className="color-red">{error.common}</span>
                  </div>{' '}
                  <button
                    data-cy="nextbuttn"
                    onClick={() => checkemailexist()}
                    type="button"
                    disabled={IsValid ? false : true}
                    className="btn regbutton py-2 mb-0 ml-3"
                  >
                    Next
                    <img
                      src="/assets/img/next.png"
                      style={{ height: 13, width: 13, marginLeft: 5 }}
                    ></img>
                  </button>
                </div>
              )}
              {stage == 2 && (
                <div className="stage2">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type">
                          Company name : <span>*</span>
                        </label>
                        <input
                          type="company_name  "
                          className="form-control"
                          name="company_name"
                          placeholder="company name"
                          value={employer.company_name}
                          onChange={handleChange}
                        />
                        {submitted && !employer.company_name && (
                          <div className="help-block">
                            company name is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* //--------------is parent section ---------------------- */}
                  {/* //-----if is parent hide this section and set the user employer as parent ---------- */}
                  {!IsParentEmployer && (
                    <div className="row d-flex align-items-center">
                      <div className="col-md-6 pr-1">
                        <div className={'form-group  mb-0 '}>
                          <label htmlFor="type" className="mr-2">
                            Is parent employer
                          </label>
                          <input
                            type="checkbox"
                            className="mr-2 ml-4"
                            // className="form-control"
                            name="is_parent"
                            placeholder="is parent"
                            // value={employer.is_parent}
                            checked={employer.is_parent == 1}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* //-------------- choose parent list ------------ */}
                      <div className="col-md-6 pr-1">
                        {!employer.is_parent && (
                          <div className={'form-group mb-4'}>
                            <label htmlFor="type">
                              Select parent Employer : <span>*</span>
                            </label>
                            <select
                              className="select col-md-12 selectdesign"
                              name="parent_id"
                              value={employer.parent_id}
                              onChange={handleChange}
                            >
                              <option value=""> select</option>
                              {parentEmployerRows}
                            </select>
                            {submitted &&
                              !employer.is_parent &&
                              !employer.parent_id && (
                                <div className="help-block">
                                  select parent company
                                </div>
                              )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {/* //--------------is parent section ends here ---------------------- */}
                  <div className="row">
                    {!permenentemployer && (
                      <div className="col-md-6 ">
                        <div className={'form-group mb-4'}>
                          <label htmlFor="type">
                            Email : <span>*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="email"
                            disabled
                            value={
                              employer.email
                                ? employer.email
                                : employer.company_email
                            }
                            onChange={handleChange}
                          />
                          {submitted && !employer.email && (
                            <div className="help-block">email is required</div>
                          )}
                        </div>
                      </div>
                    )}
                    <div
                      className={!permenentemployer ? 'col-md-6 ' : 'col-md-12'}
                    >
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type">
                          contact number : <span>*</span>
                        </label>
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          name="contact_number"
                          placeholder="contact number"
                          value={
                            employer.contact_number
                              ? employer.contact_number
                              : employer.company_phone
                          }
                          onChange={handleChange}
                        />
                        {submitted && !employer.contact_number && (
                          <div className="help-block">
                            contact number is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6  ">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type">
                          Select Priority : <span>*</span>
                        </label>
                        <select
                          className="select col-md-12 selectdesign"
                          name="priority"
                          value={employer.priority}
                          onChange={handleChange}
                        >
                          <option value="" hidden="true">
                            {' '}
                            select
                          </option>
                          <option value={'1'}>Normal </option>
                          <option value={'2'}>Medium</option>
                          <option value={'2'}>High</option>
                        </select>
                        {submitted && !employer.priority && (
                          <div className="help-block">priority is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type">
                          Select CDM Staff : <span>*</span>
                        </label>
                        <select
                          className="select col-md-12 selectdesign"
                          name="cdm_staff"
                          value={
                            employer.cdm_staff
                              ? employer.cdm_staff
                              : employer.staff_id
                          }
                          onChange={handleChange}
                        >
                          <option value=""> select Staff</option>
                          {staffrows}
                        </select>
                        {submitted && !employer.cdm_staff && (
                          <div className="help-block">
                            cdm staff is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Branch : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !employer.branch ? ' has-error' : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="branch"
                          value={employer.branch}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          <option value={'Basildon'}>Basildon</option>
                          <option value={'Wales'}>Wales</option>
                        </select>

                        {submitted && !employer.branch && (
                          <div className="help-block">Branch is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Scope : <span>*</span>
                        </label>
                        <textarea
                          name="scope"
                          placeholder="scope"
                          value={employer.scope}
                          onChange={handleChange}
                          id="scope"
                          className="form-control"
                          rows="4"
                          cols="50"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Postcode : <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="postcode"
                          placeholder="postcode "
                          value={employer.postcode}
                          onChange={handleChangePostcode}
                        />
                        {submitted && !employer.postcode && (
                          <div className="help-block">postcode is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Address : <span>*</span>
                        </label>
                        <select
                          className="form-control"
                          name="address"
                          placeholder="address"
                          value={employer.address}
                          onChange={handleChange}
                        >
                          <option value={''}>select</option>
                          {PostcodeObject.addresses}
                        </select>
                        {submitted && !employer.address && (
                          <div className="help-block">address is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group mb-4'}>
                        <input
                          type="address2"
                          className="form-control"
                          name="address2"
                          placeholder="Address2 "
                          value={employer.address2}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group mb-4'}>
                        <input
                          type="number"
                          className="form-control"
                          name="secondary_number"
                          placeholder="secondary number"
                          value={employer.secondary_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group mt-3'}>
                        <label htmlFor="type">Website</label>
                        <input
                          type="Website"
                          className="form-control"
                          name="website"
                          placeholder="website"
                          value={employer.website}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div
                        className={
                          'form-group mt-3' +
                          (submitted && !employer.service_type
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Service Sector :<span>*</span>
                        </label>
                        <select
                          className="select col-md-12 selectdesign"
                          name="service_type"
                          value={employer.service_type}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          <option value={'Hospitality'}>Hospitality</option>
                          <option value={'Health Care'}>Health Care</option>
                        </select>
                        {submitted && !employer.service_type && (
                          <div className="help-block">
                            service_type is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group mt-3'}>
                        <label htmlFor="type">Other info</label>
                        <input
                          type="other_info"
                          className="form-control"
                          name="other_info"
                          placeholder="other info"
                          value={employer.other_info}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group mt-3'}>
                        <label htmlFor="type"> Vacancy Percentage</label>
                        <input
                          type="number"
                          className="form-control"
                          name="vacancy_percentage"
                          placeholder="vacancy percentage"
                          value={employer.vacancy_percentage}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type" className="mr-2">
                          Vat Applicable
                        </label>
                        <input
                          type="checkbox"
                          className="mr-2 ml-4"
                          name="is_vat"
                          placeholder="is_vat"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type" className="mr-2">
                          Break Paid
                        </label>
                        <input
                          type="radio"
                          className="mr-2 ml-4"
                          name="pay_break"
                          placeholder="pay_break"
                          value={1}
                          checked={employer.pay_break == 1}
                          onChange={handleChange}
                        />
                        <label className="mr-2 ">Yes</label>
                        <input
                          type="radio"
                          className="mr-2"
                          name="pay_break"
                          placeholder="pay_break"
                          value={0}
                          checked={employer.pay_break == 0}
                          onChange={handleChange}
                        />
                        <label className="mr-2">No</label>
                      </div>
                    </div>
                  </div>
                  {employer.is_vat == 1 && (
                    <div className="row">
                      <div className="col-md-12 pr-1">
                        <div className={'form-group '}>
                          <label htmlFor="type">
                            Vat Percentage : <span>*</span>
                          </label>
                          <input
                            type="number"
                            step="any"
                            className="form-control form-group-50px"
                            name="vat_percentage"
                            placeholder="Vat Percentage "
                            value={employer.vat_percentage}
                            onChange={handleChange}
                          />
                          {submitted && !employer.vat_percentage && (
                            <div className="help-block">
                              Vat Percentage is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {employer.pay_break == 1 && (
                    <div className="row">
                      <div className="col-md-12 pr-1">
                        <div className={'form-group '}>
                          <label htmlFor="type">
                            Break Time (mins) : <span>*</span>
                          </label>
                          <input
                            type="number"
                            step="any"
                            className="form-control form-group-50px"
                            name="break_time"
                            placeholder="Break Time "
                            value={employer.break_time}
                            onChange={handleChange}
                          />
                          {submitted && !employer.break_time && (
                            <div className="help-block">
                              Break_time is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* //------------key contact section -------------- */}
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div className={'form-group mt-4'}>
                        <label htmlFor="type" className="mr-2">
                          Key contact
                        </label>
                        <i
                          onClick={() => {
                            setClownCounter(clownCounter + 1);
                          }}
                          className="fa fa-plus-circle"
                          style={{
                            fontSize: '26px',
                            color: ' #3388EB',
                            cursor: 'pointer',
                          }}
                        ></i>
                      </div>
                      {Array.from(
                        { length: clownCounter },
                        (_unused, index) => index + 1,
                      ).map((e, i) => (
                        <div key={i}>
                          <div className="col-md-12">
                            <div className="row ">
                              <div className="col-md-6">
                                <label>Key contact {i + 1}</label>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex justify-content-end">
                                  <i
                                    onClick={() => removeKeyElement(i)}
                                    className="fa fa-trash"
                                    style={{
                                      fontSize: '22px',
                                      color: ' #787878',
                                      cursor: 'pointer',
                                    }}
                                  ></i>{' '}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 pr-1">
                                <div className={'form-group mb-4'}>
                                  <label htmlFor="type">Name</label>
                                  <input
                                    type="name"
                                    className="form-control"
                                    name="key_name"
                                    placeholder="name"
                                    value={
                                      employer.key_contact &&
                                      employer.key_contact[i] &&
                                      employer.key_contact[i].key_name
                                    }
                                    onChange={e => handleChangekeycontact(e, i)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 pr-1">
                                <div className={'form-group mb-4'}>
                                  <label htmlFor="type">email</label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    name="key_email"
                                    placeholder="email"
                                    value={
                                      employer.key_contact &&
                                      employer.key_contact[i] &&
                                      employer.key_contact[i].key_email
                                    }
                                    onChange={e => handleChangekeycontact(e, i)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-6 pr-1">
                                <div className={'form-group mb-4'}>
                                  <label htmlFor="type">phone</label>
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    name="key_phone"
                                    placeholder="phone"
                                    value={
                                      employer.key_contact &&
                                      employer.key_contact[i] &&
                                      employer.key_contact[i].key_phone
                                    }
                                    onChange={e => handleChangekeycontact(e, i)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6 pr-1">
                                <div className={'form-group mb-4'}>
                                  <label htmlFor="type">position</label>
                                  <input
                                    type="position"
                                    className="form-control"
                                    name="key_position"
                                    placeholder="position"
                                    value={
                                      employer.key_contact &&
                                      employer.key_contact[i] &&
                                      employer.key_contact[i].key_position
                                    }
                                    onChange={e => handleChangekeycontact(e, i)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className={'form-group mb-4'}>
                        <label htmlFor="type">Upload Profile image</label>
                        <div className="d-flex">
                          <div
                            className="col-md-8 d-flex justify-content-between align-items-center filenames"
                            style={{ maxWidth: 'none' }}
                          >
                            <div>
                              {employer && employer.profileimage
                                ? employer.profileimage
                                : 'no image uploaded'}
                            </div>
                            <div className="float-right">
                              {' '}
                              <div
                                className="changebutton m-2 p-3"
                                onClick={showfileupload}
                              >
                                Change
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: '10px',
                              marginTop: '10px',
                            }}
                          >
                            {employer && employer.profileimage ? (
                              <img
                                className=" round-emp-image"
                                src={
                                  apiConstants.imgUrl + employer.profileimage
                                }
                              />
                            ) : (
                              <img
                                className=" round-emp-image"
                                src="/assets/img/recsy-fav-icon.png"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* //---------------password section add start ------------------ */}
                  {!permenentemployer && (
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className={'form-group mb-4'}>
                          <label htmlFor="type">
                            password : <span>*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            placeholder="password"
                            autoComplete="new-password"
                            value={employer.password}
                            onChange={handleChange}
                          />
                          {submitted &&
                            !employer._id &&
                            !employer.password &&
                            !permenentemployer && (
                              <div className="help-block">
                                password is required
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <div className={'form-group mb-4'}>
                          <label htmlFor="type">
                            confirm password : <span>*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            autoComplete="off"
                            name="confirm_password"
                            placeholder="confirm password"
                            value={employer.confirm_password}
                            onChange={handleChange}
                          />
                          {submitted &&
                            !employer._id &&
                            !employer.confirm_password &&
                            !permenentemployer && (
                              <div className="help-block">
                                confirm password is required
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <span className="color-red">{error.common}</span>
                      <div className="form-group d-flex justify-content-end">
                        <button
                          className="btn bluebutton btn-wd mt-2"
                          data-cy="add_employer"
                        >
                          {!permenentemployer ? 'Save' : 'Sync'}
                          {props.requestStatus ===
                            reducerConstants.EMPLOYER_REQUEST && (
                            <div className="lds-ring ml-2">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {/* //-----------file uplaod modal view --------------- */}
      {showmodalfileupload && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div onClick={hidefileupload}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <FileUpload
                    filename={'Profile image'}
                    _id={query._id}
                    showmodalfileupload={showmodalfileupload}
                    setshowmodalfileupload={setshowmodalfileupload}
                    getuploadresponse={getuploadresponse}
                  ></FileUpload>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showsyncmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowsyncmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p
                        className="delete_string"
                        style={{ color: '#121f28', lineHeight: '25px' }}
                      >
                        This email is already registered in the permanent
                        database. Would you like to synchronize the information?
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            navigate('/employerlist');
                          }}
                        >
                          No
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => {
                            setstage(2);
                            setshowsyncmodal(false);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, employerData } = state.employer;
  return {
    requestStatus,
    employerData,
  };
}

const connectedEmployerAdd = connect(mapStateToProps)(EmployerAdd);
export { connectedEmployerAdd as EmployerAdd };
