import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { candidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';

const PublicProfile = props => {
  // let navigate = useNavigate();
  const { token } = useParams();

  const [candidate, setcandidate] = useState({
    name: '',
    email: '',
    phone: '',
    about: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    // console.log('propsssssss', props);
    console.log('ddd 233', isExpired);
    if (!isExpired) {
      getAllCandidate(1, query);
    }
  }, []);

  /**
   * Get user
   * */
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    console.log('ddd');
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    query.token = token;
    if (!isExpired) {
      dispatch(candidateActions.candidateProfilePublic(queryNew)).then(
        res => {
          setIsLoading(false);
          if (res && res.data.rows && res.data.rows.length > 0) {
            setcandidate(res.data.rows[0]);
          }
        },
        () => {
          setIsExpired(true);
          setIsLoading(false);
        },
      );
    }
  };

  function getAge(dateString) {
    if (dateString) {
      // converting dateString in the format 'DD-MM-YYYY'
      const dateMoment = moment(dateString, 'DD-MM-YYYY');
      // Define the birthdate in a format like 'YYYY-MM-DD'
      const birthdate = dateMoment.format('YYYY-MM-DD');
      // Calculate the current date
      const currentDate = moment();
      // Calculate the age
      const age = currentDate.diff(moment(birthdate), 'years');
      return age;
    } else {
      return '--';
    }
  }

  return (
    <div className="Candidate">
      {(props.requestStatus === reducerConstants.CANDIDATE_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <div className="d-flex mt-20">
                <div style={{ marginTop: '-5px' }}></div>
              </div>
            </div>
          </div>
          {!isExpired && (
            <div className="card-body disp">
              <div className="row d-flex mb-10">
                <div className="col-md-7 d-flex table_box_shadow">
                  <div className="col-md-9 pd15">
                    <div className="d-flex align-items-center">
                      {candidate.profileimage ? (
                        <img
                          className=" round-emp-image-single"
                          src={apiConstants.imgUrl + candidate.profileimage}
                        />
                      ) : (
                        <img
                          className=" round-emp-image-single"
                          src="/assets/img/un-avatar.png"
                        />
                      )}
                      <h5
                        style={{ color: 'black', textTransform: 'capitalize' }}
                      >
                        {candidate.name} {candidate.surname}
                      </h5>
                    </div>
                    <i className="fas fa-phone"></i> {candidate.contact_number}
                    <br />
                    <i className="fas fa-envelope"></i> {candidate.email}
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Age </label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>

                      <div className="col">
                        <p>
                          {/* {candidate.age} */}
                          {getAge(candidate.dob)}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Nationality</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>

                      <div className="col">
                        <p>{candidate.nationality}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Jobrole</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>

                      <div className="col">
                        <p>{candidate['Category.name']}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Address</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>

                      <div className="col">
                        <p>{candidate.address}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>City</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>

                      <div className="col">
                        <p>{candidate.city}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Postcode</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>

                      <div className="col">
                        <p>{candidate.postcode}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <label>Experience</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>

                      <div className="col">
                        <p>{candidate.experience}</p>
                      </div>
                    </div>
                    {candidate.lat && candidate.long && (
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <label>Location</label>
                        </div>
                        <div className="">
                          <label>:</label>
                        </div>

                        <div className="col">
                          <a
                            href={
                              'https://www.google.com/maps/search/?api=1&query=' +
                              candidate.lat +
                              ',' +
                              candidate.long
                            }
                          >
                            Open location
                          </a>
                        </div>
                      </div>
                    )}
                    {candidate &&
                      candidate.document &&
                      candidate.document.map(i => {
                        return (
                          <>
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <label>{i.document_mode}</label>
                              </div>

                              <div className="col-md-8 filenames">
                                {i.orginalname}
                                <img
                                  className=" float-right"
                                  style={{
                                    marginTop: '8px',
                                  }}
                                  src="/assets/img/Subtract.png"
                                />
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <>
                                  <div className="downloadlink">
                                    {/* <a>Download</a> */}
                                    <a
                                      className="downloadlink ml-0"
                                      href={
                                        apiConstants.imgUrl + i.document_name
                                      }
                                    >
                                      <img
                                        style={{ height: 20, width: 20 }}
                                        src="/assets/img/download.png"
                                      />
                                    </a>
                                  </div>
                                </>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    {/* <div className='col-md-4 d-flex justify-content-center align-items-center'> <div className="col-md-12">
                <p className="float-right shiftstyle">Assign Shift</p>
                <img
                  className="float-right"
                  src="/assets/img/Plus.png"
                  style={{ height: "25px", cursor: 'pointer' }}
                  onClick={() => setshowmodal(true)}
                ></img>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
          {isExpired && (
            <div style={{ alignSelf: 'center' }}>
              {' '}
              <img className="errorimage" src="/assets/img/errorpage.png" />
              <p className="expiredtext">
                Link expired, please contact flexirecs
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, userData } = state.user;
  return {
    requestStatus,
    userData,
  };
}

const connectedPublicProfile = connect(mapStateToProps)(PublicProfile);
export { connectedPublicProfile as PublicProfile };
