import { alertActions } from '.';
import { reducerConstants } from '../_constants';
import { staffService } from '../_services';
export const staffActions = {
  staffAdd,
  staffList,
  staffDelete,
};

function staffAdd(staffObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({ type: reducerConstants.STAFF_REQUEST, staffObj });

      staffService.staffAdd(staffObj).then(
        data => {
          dispatch({
            type: reducerConstants.STAFF_SUCCESS,
            data,
          });

          if (data.error_code === 0) {
            resolve(data.data);
            dispatch(alertActions.success(data.message.toString()));
          } else {
            dispatch(alertActions.error(data.message.toString()));
            console.log('alertaction', data);
            reject(data.data);
          }
        },
        error => {
          dispatch({
            type: reducerConstants.STAFF_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function staffList(staffObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.STAFF_REQUEST,
        staffObj,
      });

      staffService.staffList(staffObj).then(
        data => {
          dispatch({
            type: reducerConstants.STAFF_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.STAFF_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}

function staffDelete(staffObj) {
  return dispatch =>
    new Promise((resolve, reject) => {
      dispatch({
        type: reducerConstants.STAFF_REQUEST,
        staffObj,
      });

      staffService.staffDelete(staffObj).then(
        data => {
          dispatch({
            type: reducerConstants.STAFF_SUCCESS,
            data,
          });
          resolve(data);
        },
        error => {
          dispatch({
            type: reducerConstants.STAFF_FAILURE,
            error,
          });
          reject(error);
          dispatch(alertActions.error(error?.toString()));
        },
      );
    });
}
