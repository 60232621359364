import React from 'react';
import { connect } from 'react-redux';
import { miscActions } from '../_actions';

class OtpverficationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      query: {
        phone: this.props.phone,
        number_id: this.props._id,
        code: '',
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // handler to state the otp code
  handleChange(event) {
    const { name, value } = event.target;
    const { query } = this.state;
    this.setState({
      query: {
        ...query,
        [name]: value,
      },
    });
  }
  // call for verify otp
  handleSubmit() {
    this.setState({ submitted: true });
    const { query } = this.state;
    const { dispatch } = this.props;
    console.log(query);
    if (query.code) {
      let objCopy = Object.assign({}, query);
      dispatch(miscActions.otpverification(objCopy)).then(
        function () {},
        function () {},
      );
    } else {
      alert('Enter valied otp');
    }
  }
  // to close modal
  clickHandler(data) {
    console.log(data);
    if (typeof this.props.callbackModal === 'function') {
      this.props.callbackModal(data);
    }
  }
  render() {
    return (
      <div
        className="modal "
        style={{ display: 'block', background: ' rgba(133, 187, 255, 0.5)' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={this.clickHandler.bind(this)}
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
              <h4 className="modal-title">otp verfication</h4>
            </div>
            <div className="modal-body">
              <p>
                <div className="row">
                  <div className="col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      name="code"
                      onChange={this.handleChange}
                      value={this.state.query.code}
                    />
                  </div>
                </div>
              </p>
            </div>
            <div className="modal-footer">
              <br />
              <button
                onClick={() => this.handleSubmit()}
                type="button"
                className="btn "
                data-dismiss="modal"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

const connectedOtpverficationModal =
  connect(mapStateToProps)(OtpverficationModal);
export { connectedOtpverficationModal as OtpverficationModal };
