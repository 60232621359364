import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AgencyInvoiceList, InvoiceList } from '../Invoice';
import { NewPayoutList } from '../Payout';
import { PayoutList } from '../Payout/PayoutList';
import { shiftsActions } from '../_actions';
let isAdmin = null;
let isEmployer = false;
let isBunit = false;
let isStaff = null;
let employer_id = '';
let bunit_id = '';

const Payments = props => {
  const [Agencyinvoicetoggle, setAgencyinvoicetoggle] = useState(false);
  const [Invoicetoggle, setInvoicetoggle] = useState(true);
  const [Payouttoggle, setPayouttoggle] = useState(false);
  const [Payoutnewtoggle, setPayoutnewtoggle] = useState(false);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    searchKey: '',
  });
  const [error, setError] = useState({ common: '' });
  // effect to load toggle
  // load all shifts
  useEffect(() => {
    setAgencyinvoicetoggle(false);
    setInvoicetoggle(true);
    setPayouttoggle(false);
    setPayoutnewtoggle(false);
    getAllShifts(1);
  }, [
    query.employer_id,
    query.from_date,
    query.to_date,
    query.candidate_id,
    query,
  ]);
  // call for fetch all shifts
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(queryNew);
    if (isAdmin == true || isStaff == true) {
      queryNew.adminreport = true;
    }
    if (isEmployer == true) {
      queryNew.carehomereport = true;
      if (employer_id) {
        queryNew.employer_id = employer_id;
      }
    }
    if (isBunit == true) {
      queryNew.carehomereport = true;
      if (employer_id) {
        queryNew.employer_id = employer_id;
        queryNew.business_unit = bunit_id;
      }
    }
    if (queryNew.from_date > queryNew.to_date) {
      setError({ common: 'Choose a valid date' });
      return;
    }
    const { dispatch } = props;
    dispatch(shiftsActions.reportShiftListv2(queryNew)).then(
      res => {
        setListAndPagination(res.data);
      },
      () => {
        // setIsLoading(false);
      },
    );
  };
  // list and paginate shifts
  const setListAndPagination = reportData => {
    let reportList = [];
    for (
      let j = 0;
      reportData && reportData.rows && j < reportData.rows.length;
      j = j + 1
    ) {
      reportList.push(
        <tr key={j} className="username">
          {isEmployer && (
            <td>
              {reportData.rows[j].name}{' '}
              <div
                className="categoryview"
                style={{ color: '#20B9B7', marginTop: '5px' }}
              >
                {reportData &&
                  reportData.rows[j] &&
                  reportData.rows[j].Category &&
                  reportData.rows[j].Category.name &&
                  reportData.rows[j].Category.name}
              </div>{' '}
            </td>
          )}
          {(isAdmin || isStaff) && <td>{reportData.rows[j].company_name}</td>}
          {(isAdmin || isStaff) && (
            <td>
              {reportData.rows[j].total_people
                ? reportData.rows[j].total_people
                : 0}
            </td>
          )}
          <td>
            {reportData.rows[j].total_shifts
              ? reportData.rows[j].total_shifts
              : 0}
          </td>
          <td>
            {reportData.rows[j].total_hours
              ? reportData.rows[j].total_hours + ' ' + 'hrs'
              : 0 + ' ' + 'hrs'}
          </td>
          <td>
            {reportData.rows[j].total_amount
              ? '£' + ' ' + reportData.rows[j].total_amount
              : '£' + ' ' + 0}
          </td>
          <td>
            Night &nbsp; - &nbsp;{' '}
            {reportData.rows[j].total_night && reportData.rows[j].total_night
              ? reportData.rows[j].total_night
              : 0}
            &nbsp; hrs
            <p>
              Morning&nbsp; - &nbsp;
              {reportData.rows[j].total_morning &&
              reportData.rows[j].total_morning
                ? reportData.rows[j].total_morning
                : 0}
              &nbsp; hrs
            </p>
            Custome&nbsp; - &nbsp;
            {reportData.rows[j].total_custome &&
            reportData.rows[j].total_custome
              ? reportData.rows[j].total_custome
              : 0}
            &nbsp; hrs
          </td>
          <td>
            <div className="d-flex justify-content-center">
              <div
                className="  allshift-viewdt"
                onClick={() => {
                  // SetviewshiftsEmployer_id(reportData.rows[j]._id),
                  // setviewdetailsmodal(true);
                }}
              >
                &nbsp; &nbsp; view &nbsp;&nbsp;
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    // setReportRows(reportList);
    // setCandidateData(reportData);
  };

  return (
    <div className="CandidateList ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            <div className="card-header page-header ">
              <div className="row">
                <div className="col-md-8"></div>
              </div>
            </div>
            <div className="card-header page-header p-0">
              <div className="d-flex justify-content-center ">
                <div className="ReportToggleMain">
                  <div
                    data-cy="invoicetoggle"
                    className={
                      Invoicetoggle
                        ? 'report_toggle_item_active'
                        : 'report_toggle_item_inactive'
                    }
                    onClick={() => {
                      setInvoicetoggle(true),
                        setAgencyinvoicetoggle(false),
                        setPayouttoggle(false);
                      setPayoutnewtoggle(false);
                    }}
                  >
                    Invoice{' '}
                  </div>
                  <div
                    data-cy="agencyinvoicetoggle"
                    className={
                      Agencyinvoicetoggle
                        ? 'report_toggle_item_active'
                        : 'report_toggle_item_inactive'
                    }
                    onClick={() => {
                      setInvoicetoggle(false),
                        setAgencyinvoicetoggle(true),
                        setPayouttoggle(false);
                      setPayoutnewtoggle(false);
                    }}
                  >
                    Agency Invoice
                  </div>
                  <div
                    data-cy="payouttoggle"
                    className={
                      Payouttoggle
                        ? 'report_toggle_item_active'
                        : 'report_toggle_item_inactive'
                    }
                    onClick={() => {
                      setInvoicetoggle(false),
                        setAgencyinvoicetoggle(false),
                        setPayouttoggle(true);
                      setPayoutnewtoggle(false);
                    }}
                  >
                    Payout
                  </div>
                  <div
                    className={
                      Payoutnewtoggle
                        ? 'report_toggle_item_active'
                        : 'report_toggle_item_inactive'
                    }
                    onClick={() => {
                      setInvoicetoggle(false),
                        setAgencyinvoicetoggle(false),
                        setPayouttoggle(false);
                      setPayoutnewtoggle(true);
                    }}
                  >
                    Payout new
                  </div>
                </div>
              </div>
            </div>
            {Invoicetoggle && <InvoiceList></InvoiceList>}
            {Agencyinvoicetoggle && (
              <AgencyInvoiceList
                is_agency_invoice_generated={0}
              ></AgencyInvoiceList>
            )}
            {Payouttoggle && <PayoutList></PayoutList>}
            {Payoutnewtoggle && <NewPayoutList></NewPayoutList>}
            <div className="row">
              <div className="col-md-12 pr-1" style={{ marginLeft: '10px' }}>
                <span className="color-red">{error.common}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return { requestStatus, candidateData };
}

const connectedPayments = connect(mapStateToProps)(Payments);
export { connectedPayments as Payments };
