/* eslint-disable no-useless-escape */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { EducationAdd } from '../Education';
import { ExperienceAdd } from '../Experience';
import {
  candidateActions,
  categoryActions,
  educationActions,
  employerActions,
  experienceActions,
} from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { FileUpload } from '../fileupload/FileUpload';
let othercat = [];

const CandidateAdd = props => {
  let navigate = useNavigate();
  let { id } = useParams();
  const [stage, setstage] = useState(1);
  const [permenentcandidate, setpermenentcandidate] = useState(false);
  const [showsyncmodal, setshowsyncmodal] = useState(false);
  const [candidate, setCandidate] = useState({
    name: '',
    ni_number: '',
    address: '',
    address_line2: '',
    postcode: '',
    location: '',
    gender: '',
    working_limit: '',
    email: '',
    branch: '',
    contact_number: '',
    experience: '',
    about: '',
    // active_status: 1,
    vaccination_status: '',
    other_documents: '',
    category: '',
    night_price: '',
    evening_price: 0,
    weekend_night_price: '',
    weekend_day_price: '',
    visatype: '',
    role: 3,
    is_temp_candidate: 1,
    response_status: '',
    ni_hour: 20,
    reference1: {
      reference1Name: '',
      reference1Email: '',
      reference1Contact: '',
    },
    reference2: {
      reference2Name: '',
      reference2Email: '',
      reference2Contact: '',
    },
  });
  const [IsValid, setIsValid] = useState(false);
  const currentdate = new Date().toISOString().slice(0, 10);
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [Disabled, setDisabled] = useState(false);
  const [rating, setRating] = useState(0);
  const [other_categories, setother_categories] = useState([]);
  const [isEditcategory, setisEditcategory] = useState(false);
  const [addeducationmodal, setaddeducationmodal] = useState(false);
  const [educationData, setEducationData] = useState([]);
  const [educationDataRows, setEducationDataRows] = useState([]);
  const [experience, setExperience] = useState({
    currently_enrolled: 0,
  });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [experienceModal, setShowExperienceModal] = useState(false);
  const [experienceData, setExperienceData] = useState([]);
  const [experienceDataRows, setExperienceDataRows] = useState([]);
  const [education, setEducation] = useState({
    currently_enrolled: 0,
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [educationsubmitted, seteducationSubmitted] = useState(false);
  const [experiencesubmitted, setexperienceSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addratingmodal, setaddratingmodal] = useState(false);
  const [showediteducationmodal, setshowediteducationmodal] = useState({
    modal: false,
  });
  const [showeditexperiencemodal, setshoweditexperiencemodal] = useState({
    modal: false,
  });
  const [showdeleteeducationmodal, setshowdeleteeducationmodal] = useState({
    modal: false,
  });
  const [showdeleteexperiencemodal, setshowdeleteexperiencemodal] = useState({
    modal: false,
  });
  const [categoriesrows, setcategoriesrows] = useState();
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  const [countries, setCountries] = useState();
  const [countriesData, setCountriesData] = useState([]);
  const [error, setError] = useState({ common: '' });
  let startdate = moment().subtract(5, 'years').format('YYYY-MM-DD');
  let subcategories = [];
  // effect for load candidatate where id present
  // fetch country json
  useEffect(() => {
    if (props.candidate_id) {
      id = props.candidate_id;
      setstage(2);
    }
    getAllcategories(1);
    if (id !== '0') {
      query._id = id;
      setstage(2);
      setQuery(query);
      getEducation(1, query, true);
      getAllCandidate(1, query);
      getExperience(1, query, true);
    }
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllCandidate(1, query);
      getEducation(1, query, true);
      getExperience(1, query, true);
    }
    //--------set countries data -----
    fetch('/assets/countries.json')
      .then(response => response.json())
      .then(data => {
        setCountriesData(data);
      });
  }, []);
  // effect to load country data to options
  useEffect(() => {
    if (!candidate._id) {
      candidate.active_status = 1;
    }
    let countrylist = [];
    let data = countriesData;
    let isFound = false;
    for (let i = 0; i < data.length; i++) {
      if (candidate.nationality == data[i].name) {
        isFound = true;
      }
      countrylist.push(
        <option
          value={data[i].value}
          // eslint-disable-next-line react/no-unknown-property
          test={isFound ? 'true' : 'false'}
          selected={candidate.nationality == data[i].name}
        >
          {data[i].name}
        </option>,
      );
    }
    if (!isFound) {
      countrylist.unshift(
        <option selected={'true'}>Select Nationality</option>,
      );
    }
    setCountries(countrylist);
  }, [countriesData]);
  // effect to load address by postcode by using _id
  useEffect(() => {
    loadaddress();
    if (candidate.other_categories) {
      othercat = [];
      setother_categories(candidate.other_categories);
      candidate.other_categories.map(i => {
        othercat.push(i._id);
      });
      delete candidate.other_categories;
    }
    console.log('gggg 123', othercat);
  }, [candidate._id]);
  // load address via postcode (call goes if only the postcode is valid)
  const loadaddress = async () => {
    const res = candidate.postcode.replace(/ /g, '');
    if (valid_postcode(res)) {
      let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let addresses_rows = [];
        let regex = /\s+,/g;
        let newarray = response.addresses[0].split(',');
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, ' ')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setCandidate({
          ...candidate,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: candidate.postcode,
        });
      }
    }
  };
  // to add education information
  const handleSubmitEducation = () => {
    seteducationSubmitted(true);
    let from_date_string = moment(education.from_date).format('YYYY-MM-DD');
    education.from_date_string = from_date_string;
    if (education.to_date) {
      let to_date_string = moment(education.to_date).format('YYYY-MM-DD');
      education.to_date_string = to_date_string;
    }
    if (
      education.level_of_education &&
      education.field_of_study &&
      education.college_university &&
      education.from_date
    ) {
      let educationArray = [];
      educationArray.push(education);
      setEducationData([...educationData, education]);
      setEducationlist(educationArray, false);
    }
  };
  // to set education data on edit or add
  const setEducationlist = (educationdata, educationedit) => {
    setaddeducationmodal(false);
    setEducation({ currently_enrolled: 0 });
    let educationlist = [];
    for (let j = 0; j < educationdata.length; j = j + 1) {
      educationlist.push(
        <div className="card  mt-2" key={j}>
          <div className="card-body">
            <div className="row ">
              <div className="col-md-11">
                <p className="collegename">
                  {educationdata[j].college_university}, London
                </p>
                <p className="stream">
                  {educationdata[j].level_of_education},{' '}
                  {educationdata[j].field_of_study}
                </p>
                <p style={{ float: 'left' }}>
                  {moment(educationdata[j].from_date).format('YYYY')}-
                  {educationdata[j].to_date &&
                    moment(educationdata[j].to_date).format('YYYY')}
                </p>
              </div>
              {query._id && (
                <div className="d-flex col-md-1 ">
                  <div className="">
                    <img
                      src="/assets/img/edit.png"
                      onClick={() => {
                        setshowediteducationmodal({
                          modal: true,
                          _id: educationdata[j]._id,
                        });
                      }}
                      className=""
                      style={{ height: 15, width: 18 }}
                    />
                  </div>
                  <div>
                    <img
                      className="filterimage"
                      src="/assets/img/delete_copy.png"
                      onClick={() => {
                        setshowdeleteeducationmodal({
                          modal: true,
                          id: educationdata[j]._id,
                        });
                      }}
                    ></img>
                  </div>
                </div>
              )}
              {!query._id && (
                <div className="col-md-1">
                  <img
                    src="/assets/img/close.png"
                    alt=""
                    onClick={() => {
                      removeDataEducation(educationdata[j]._id);
                    }}
                    className="colsebtnimg"
                  />
                </div>
              )}
            </div>
          </div>
        </div>,
      );
    }
    if (educationedit) {
      setEducationDataRows([educationlist]);
    } else {
      setEducationDataRows([...educationDataRows, educationlist]);
    }
  };
  // remove experience data
  const removeData = index => {
    let expdata = experienceData;
    expdata.splice(index, 1);
    setExperienceData(expdata);
    setExperiencelist(experienceData, false);
  };
  // handler for change education data and state
  const handleChangeEducation = event => {
    const { name, value } = event.target;
    if (name == 'currently_enrolled') {
      if (event.target.checked) {
        setEducation({
          ...education,
          [name]: 1,
        });
      } else {
        setEducation({
          ...education,
          [name]: 0,
        });
      }
    } else {
      setEducation({
        ...education,
        [name]: value,
      });
    }
  };
  //handler for experience change
  const handleExperience = event => {
    const { name, value } = event.target;
    if (name == 'currently_enrolled') {
      if (event.target.checked) {
        setExperience({
          ...experience,
          [name]: 1,
        });
      } else {
        setExperience({
          ...experience,
          [name]: 0,
        });
      }
    } else {
      setExperience({
        ...experience,
        [name]: value,
      });
    }
  };
  // hide edit education modal
  const hideediteducationmodal = () => {
    setshowediteducationmodal({ modal: false });
    getEducation(1, query, true);
  };
  // hide edit experience modal
  const hideeditexperiencemodal = () => {
    setshoweditexperiencemodal({ modal: false });
    getExperience(1, query, true);
  };
  // handler for set experience data
  const handleSubmitExperience = () => {
    seteducationSubmitted(true);
    let from_date_string = moment(experience.from_date).format('YYYY-MM-DD');
    experience.from_date_string = from_date_string;
    if (experience.to_date) {
      let to_date_string = moment(experience.to_date).format('YYYY-MM-DD');
      experience.to_date_string = to_date_string;
    }
    if (
      experience.from_date &&
      experience.company_name &&
      experience.from_date
    ) {
      let experienceArray = [];
      experienceArray.push(experience);
      setExperienceData([...experienceData, experience]);
      setExperiencelist(experienceArray, false);
    }
  };
  // delete education data by id
  const deleteeducation = id => {
    const { dispatch } = props;
    dispatch(
      educationActions.educationDelete({
        _id: id,
        status: 0,
      }),
    ).then(
      response => {
        console.log(response);
        setshowdeleteeducationmodal({ modal: false });
        setIsDeleteLoading(false);
        getEducation(1, query, true);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  // delete experience data by id
  const deleteexperience = id => {
    const { dispatch } = props;
    dispatch(
      experienceActions.experienceDelete({
        _id: id,
        status: 0,
      }),
    ).then(
      response => {
        console.log(response);
        setshowdeleteexperiencemodal({ modal: false });
        setIsDeleteLoading(false);
        getExperience(1, query, true);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  // set experience data as a list
  const setExperiencelist = (experiencedata, experienceedit) => {
    setShowExperienceModal(false);
    setExperience({ currently_enrolled: 0 });
    let experiencelist = [];
    for (let k = 0; k < experiencedata.length; k = k + 1) {
      experiencelist.push(
        <div className="card">
          <div className="card-body">
            <div className="row mt-2">
              <div className="col-md-11 experiencedetails">
                <p
                  className="collegename mb-0"
                  style={{ textTransform: 'capitalize' }}
                >
                  {experiencedata[k].job_title}
                </p>
                <p className="mb-0" style={{ textTransform: 'capitalize' }}>
                  {experiencedata[k].description}
                </p>
                <span className="mb-2 font-12 " style={{ fontWeight: '500' }}>
                  {experiencedata[k].company_name}
                </span>
                <p className="stream">
                  {moment(experiencedata[k].from_date).format('MMM YYYY')} -{' '}
                  {experiencedata[k].to_date
                    ? moment(experiencedata[k].to_date).format('MMM YYYY')
                    : 'Present' +
                      '(' +
                      moment(experiencedata[k].from_date, 'YYYYMMDD').toNow() +
                      ')'}
                </p>
                <p className="stream">Oaklands Drive, Molly Millars Lane</p>
              </div>
              {query._id && (
                <div className="d-flex col-md-1 ">
                  <div className="">
                    <img
                      src="/assets/img/edit.png"
                      onClick={() => {
                        setshoweditexperiencemodal({
                          modal: true,
                          _id: experiencedata[k]._id,
                        });
                      }}
                      className=""
                      style={{ height: 15, width: 18 }}
                    />
                  </div>
                  <div>
                    <img
                      className="filterimage"
                      src="/assets/img/delete_copy.png"
                      onClick={() => {
                        setshowdeleteexperiencemodal({
                          modal: true,
                          id: experiencedata[k]._id,
                        });
                      }}
                    ></img>
                  </div>
                </div>
              )}
              {!query._id && (
                <div className="col-md-1">
                  <img
                    src="/assets/img/close.png"
                    alt=""
                    onClick={() => {
                      removeData(k);
                    }}
                    className="colsebtnimg"
                  />
                </div>
              )}
            </div>
          </div>
        </div>,
      );
    }
    if (experienceedit) {
      setExperienceDataRows([experiencelist]);
    } else {
      setExperienceDataRows([...experienceDataRows, experiencelist]);
    }
  };
  // remove edication data
  const removeDataEducation = index => {
    let edudata = educationData;
    edudata.splice(index, 1);
    setEducationData(edudata);
    setEducationlist(educationData, false);
  };
  // deactivate candidate profile
  // const deactivatereasonChange = event => {
  //   const { name, value } = event.target;
  //   setCandidate({
  //     ...candidate,
  //     [name]: value,
  //   });
  // };
  // hide error after 10 sec
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  // category select handler
  const selectedcategory = (selectedOption, event) => {
    let name = event.name;
    let SelectedValues = [];
    for (let i = 0; i < selectedOption.length; i++) {
      if (selectedOption[i].value != candidate.category) {
        let obj = selectedOption[i].value;
        SelectedValues.push(obj);
        setCandidate(prevState => ({
          ...prevState,
          [name]: SelectedValues,
        }));
      }
    }
  };
  // call for get candidate information
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    queryNew.is_candidate_with_docs = true;
    dispatch(candidateActions.candidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          if (!res.data.rows[0].active_status) {
            res.data.rows[0].active_status = 1;
          }
          res.data.rows[0].dob = moment(
            res.data.rows[0].dob,
            'DD-MM-YYYY',
          ).format('YYYY-MM-DD');
          setCandidate(res.data.rows[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // call for get education information
  const getEducation = (pageNo, queryTemp, educationedit) => {
    let candidate = {};
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    if (queryNew._id) {
      candidate.candidate_id = queryNew._id;
    }
    dispatch(educationActions.educationList(candidate)).then(
      res => {
        setIsLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          setEducationDataRows([]);
          setEducationlist(res.data.rows, educationedit);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // call for get experience information
  const getExperience = (pageNo, queryTemp, experienceedit) => {
    let candidate = {};
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    if (queryNew._id) {
      candidate.candidate_id = queryNew._id;
    }
    dispatch(experienceActions.experienceList(candidate)).then(
      res => {
        setIsLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          setExperiencelist(res.data.rows, experienceedit);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // Ni Number validation checking
  function validateNINumber(niNumber) {
    // Remove spaces and convert to uppercase
    niNumber = niNumber.replace(/\s/g, '').toUpperCase();
    // Regular expression to match NI number format
    const niRegex = /^[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z]\d{6}[A-D]$/;
    return niRegex.test(niNumber);
  }
  // fileupload response handler
  const getuploadresponse = response => {
    if (response && response.file_name) {
      setCandidate(prevState => ({
        ...prevState,
        profileimage: response.file_name,
      }));
    }
    setshowmodalfileupload(false);
  };
  // get all categories for select drop doen----
  const getAllcategories = () => {
    const { dispatch } = props;
    if (query.is_candidate_with_docs) {
      delete query.is_candidate_with_docs;
    }
    dispatch(categoryActions.categoryList(query)).then(res => {
      let categoryData = res.data.rows;
      let categorylist = [];
      for (let i = 0; i < categoryData.length; i++) {
        categorylist.push(
          <option value={categoryData[i]._id}>{categoryData[i].name}</option>,
        );
      }
      setcategoriesrows(categorylist);
    });
  };
  // handler for change in input value
  const handleChange = event => {
    setError({ common: '' });
    const { name, value } = event.target;
    if (name == 'contact_number' && value.length > 11) {
      return;
    }
    if (name == 'secondary_number' && value.length > 11) {
      return;
    }
    //---accept only valid price---
    if (
      name == 'morning_price' ||
      name == 'night_price' ||
      name == 'day_price' ||
      name == 'evening_price' ||
      name == 'weekend_day_price' ||
      name == 'weekend_night_price'
    ) {
      if (value == '-' || value < 0 || value == '0') {
        return;
      }
    }
    if (name == 'email') {
      const regex = /\S+@\S+\.\S+/;
      let isValidEmail = regex.test(event.target.value);
      if (!isValidEmail) {
        setIsValid(false);
        setCandidate({
          ...candidate,
          [name]: value,
        });
        setError({ common: 'Enter a valid email' });
        return false;
      } else {
        setIsValid(true);
      }
    }
    setCandidate({
      ...candidate,
      [name]: value,
    });
  };
  // change in references  -
  const handleChangeReference = e => {
    const { name, value } = e.target;
    if (
      name == 'reference1Name' ||
      name == 'reference1Email' ||
      name == 'reference1Contact'
    ) {
      if (name == 'reference1Contact' && value.length > 10) {
        return false;
      }
      setCandidate({
        ...candidate,
        reference1: { ...candidate.reference1, [name]: value },
      });
    } else {
      if (name == 'reference2Contact' && value.length > 10) {
        return false;
      }
      setCandidate({
        ...candidate,
        reference2: { ...candidate.reference2, [name]: value },
      });
    }
  };
  // handler change for postcode
  const handleChangepostcode = async event => {
    event.preventDefault();
    let { name, value } = event.target;
    let post_code = value.replace(/ /g, '');
    setCandidate({
      ...candidate,
      [name]: post_code,
    });
    setPostcodeObject({
      isValied: false,
      addresses: [],
    });
    if (valid_postcode(value)) {
      let posstcodeurl = `https://api.getaddress.io/find/${value}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let newarray = response.addresses[0].split(',');
        let regex = /\s+,/g;
        let addresses_rows = [];
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, '')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setCandidate({
          ...candidate,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: post_code,
        });
      }
    }
  };
  // check if the postcode is valid or not
  const valid_postcode = str_postcode => {
    let postcode = str_postcode.toUpperCase();
    postcode = postcode.toString().replace(/\s/g, '');
    var regex =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
    return regex.test(postcode);
  };
  // check if the contact number is valid or not -
  const valid_contact_number = phone_number => {
    phone_number = phone_number.toString().replace(/\s/g, '');
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone_number);
  };
  // submit handler and validate candidate data
  const handleSubmit = event => {
    if (candidate.email) {
      const regex = /\S+@\S+\.\S+/;
      let isValidEmail = regex.test(candidate.email);
      if (!isValidEmail) {
        setError({ common: 'enter a valid email' });
        return false;
      }
    }
    setSubmitted(true);
    setDisabled(true);
    event.preventDefault();
    const { dispatch } = props;
    if (
      candidate.name &&
      candidate.ni_number &&
      candidate.address &&
      candidate.experience &&
      candidate.contact_number &&
      candidate.email &&
      candidate.working_limit &&
      candidate.postcode &&
      candidate.category &&
      candidate.gender &&
      candidate.vaccination_status &&
      candidate.night_price &&
      candidate.day_price &&
      candidate.weekend_day_price &&
      candidate.weekend_night_price &&
      candidate.morning_price &&
      candidate.dob &&
      candidate.ethncity &&
      candidate.ni_hour &&
      candidate.payout_date
    ) {
      if (
        candidate.night_price <= 0 ||
        candidate.day_price <= 0 ||
        candidate.weekend_price <= 0
      ) {
        setError({ common: 'Enter a valid price' });
        setDisabled(false);
        return;
      }
      if (candidate.morning_price < 10.42) {
        setError({
          common: 'Morning price should be greater than minimum price of 10.42',
        });
        setDisabled(false);
        return;
      }
      if (candidate.day_price < 10.42) {
        setError({
          common: 'Day price should be greater than minimum price of 10.42',
        });
        setDisabled(false);
        return;
      }
      if (candidate.night_price < 10.42) {
        setError({
          common: 'Night price should be greater than minimum price of 10.42',
        });
        setDisabled(false);
        return;
      }
      if (candidate.weekend_day_price < 10.42) {
        setError({
          common:
            'Weekend day price should be greater than minimum price of 10.42',
        });
        setDisabled(false);
        return;
      }
      if (candidate.weekend_night_price < 10.42) {
        setError({
          common:
            'Weekend night price should be greater than minimum price of 10.42',
        });
        setDisabled(false);
        return;
      }
      if (candidate.ni_number) {
        let checkni_number = validateNINumber(candidate.ni_number);
        if (!checkni_number) {
          // setIsValid(false);
          setError({ common: 'Enter a valid NI Number' });
          setDisabled(false);
          return;
        }
      }
      if (candidate.reference1?.reference1Email) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(candidate.reference1?.reference1Email) === false) {
          setError({ common: 'Enter a valid reference mail' });
          setDisabled(false);
          return;
        }
      }
      if (candidate.reference2?.reference2Email) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (reg.test(candidate.reference2?.reference2Email) === false) {
          setError({ common: 'Enter a valid  reference mail' });
          setDisabled(false);
          return;
        }
      }
      //--------check if postcode is valid -------
      let postcode = valid_postcode(candidate.postcode);
      if (postcode == false) {
        setError({ common: 'postcode not valid' });
        setDisabled(false);
        return;
      }
      //---------contact_number validation check------
      let contact_number = valid_contact_number(candidate.contact_number);
      if (candidate.secondary_number) {
        let secondary_number = valid_contact_number(candidate.secondary_number);
        if (secondary_number == false) {
          setError({ common: 'Secondary number not valid' });
          setDisabled(false);
          return;
        }
      }
      if (contact_number == false) {
        setError({ common: 'Phone number not valid' });
        setDisabled(false);
        return;
      }
      if (candidate.ni_hour && candidate.ni_hour >= 100) {
        setError({ common: 'ni hour should be less than 100 hours' });
        setDisabled(false);
        return;
      }
      if (candidate.ni_hour && candidate.ni_hour <= 0) {
        setError({
          common: 'ni hour should be greater than 0 hours',
        });
        setDisabled(false);
        return;
      }
      //-----valid date of birth  check -------
      if (candidate.dob) {
        if (candidate.dob > currentdate) {
          setError({ common: 'Choose a valid date of birth' });
          setDisabled(false);
          return;
        }
      }
      if (rating) {
        candidate.staff_rating = rating;
      }
      if (!candidate.other_categories && othercat) {
        candidate.other_categories = othercat;
      }
      if (educationData.length > 0) {
        candidate.education = educationData;
      }
      if (experienceData.length > 0) {
        candidate.experiencedata = experienceData;
      }
      if (permenentcandidate) {
        candidate.permenentcandidate = true;
      }
      candidate.dob = moment(candidate.dob, 'YYYY-MM-DD').format('DD-MM-YYYY');
      dispatch(candidateActions.candidateAdd(candidate)).then(
        () => {
          navigate('/candidateslist');
          setIsLoading(false);
        },
        err => {
          console.log(err);
          setError({ common: err && err ? err : alertConstants.SERVER_ERROR });
          hideError();
          setIsLoading(false);
          setDisabled(false);
        },
      );
    } else {
      setError({ common: 'Please complete the form!' });
      setDisabled(false);
    }
  };
  // show file upload modal function  -----
  const showfileupload = () => {
    setshowmodalfileupload(true);
  };
  // hide file upload modal function  --
  const hidefileupload = () => {
    setshowmodalfileupload(false);
  };
  // call for check if the email already exist
  const checkemailexist = () => {
    const { dispatch } = props;
    if (candidate.email) {
      dispatch(
        employerActions.checkemailExist({
          email: candidate.email,
          iscandidate: true,
        }),
      ).then(
        res => {
          setIsLoading(false);
          if (
            res &&
            res?.data?.data?.rows &&
            res?.data?.data?.rows?.length > 0
          ) {
            delete res.data.data.rows[0]._id;
            res.data.data.rows[0].name = res.data.data.rows[0].first_name;
            res.data.data.rows[0].gender =
              res.data.data.rows[0].gender == 'male'
                ? 'Male'
                : res.data.data.rows[0].gender == 'female'
                ? 'Female'
                : 'Other';
            (res.data.data.rows[0].active_status = 1),
              (res.data.data.rows[0].is_temp_candidate = 1);
            res.data.data.rows[0].is_permanent_candidate = 0;
            setCandidate(res.data.data.rows[0]);
            setpermenentcandidate(true);
            setshowsyncmodal(true);
            // setstage(2);
          } else if (res && res?.message == 'already registered email') {
            setError({ common: res.message });
          } else {
            setstage(2);
          }
        },
        () => {
          setIsLoading(false);
        },
      );
    } else {
      setError({ common: 'please enter email' });
    }
  };
  return (
    <div className=" d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card ">
          <div className=" page-header ">
            <div className="page-title-content">
              {stage == 2 && !candidate._id && (
                <img
                  src="/assets/img/Vector.png"
                  style={{ height: '25px' }}
                  onClick={() => {
                    setstage(1);
                    setError({ common: '' });
                    setSubmitted(false);
                  }}
                ></img>
              )}
              {stage == 1 && (
                <p className="card-title">
                  {candidate && candidate._id ? 'Update' : 'Add'} Candidate{' '}
                </p>
              )}
            </div>
          </div>
          <div className="card-body add-candiadte">
            <form name="form">
              {stage == 1 && (
                <div className="row">
                  <div className="col-md-6 mb-0">
                    <div className={'form-group'}>
                      <label htmlFor="type">
                        Email : <span>*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        placeholder="email"
                        value={candidate.email}
                        onChange={handleChange}
                      />
                      {submitted && !candidate.email && (
                        <div className="help-block">email is required</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12" style={{ marginTop: '-11px' }}>
                    <span className="color-red">{error.common}</span>
                  </div>{' '}
                  <button
                    onClick={() => checkemailexist()}
                    type="button"
                    disabled={IsValid ? false : true}
                    data-cy="nextbuttn"
                    className="btn regbutton py-2 mb-0 ml-3"
                  >
                    Next
                    <img
                      src="/assets/img/next.png"
                      style={{ height: 13, width: 13, marginLeft: 5 }}
                    ></img>
                  </button>
                </div>
              )}
              {stage == 2 && (
                <div className="stage2">
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.ni_number
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          NI Number / Reference Number : <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="ni_number"
                          placeholder=""
                          value={candidate.ni_number}
                          onChange={handleChange}
                        />
                        {submitted && !candidate.ni_number && (
                          <div className="help-block">
                            NI Number is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div>
                        <label htmlFor="type">
                          Membership Number : <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="membership_no"
                          placeholder=""
                          value={candidate.membership_no}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.name ? ' has-error' : '')
                        }
                      >
                        <label htmlFor="type">
                          {' '}
                          First Name : <span>*</span>
                        </label>
                        <input
                          type="name"
                          className="form-control"
                          name="name"
                          placeholder=""
                          value={candidate.name}
                          onChange={handleChange}
                        />
                        {submitted && !candidate.name && (
                          <div className="help-block">
                            {' '}
                            First Name is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.last_name
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Last Name : <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="last_name"
                          placeholder=""
                          value={candidate.last_name}
                          onChange={handleChange}
                        />
                        {submitted && !candidate.last_name && (
                          <div className="help-block">
                            {' '}
                            Last Name is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">
                          Postcode : <span>*</span>
                        </label>
                        <input
                          type="postcode"
                          className="form-control"
                          name="postcode"
                          placeholder="postcode "
                          value={candidate.postcode}
                          onChange={handleChangepostcode}
                        />
                        {/*Required*/}
                        {submitted && !candidate.postcode && (
                          <div className="help-block">postcode is required</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Visa type : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.visatype ? ' has-error' : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="visatype"
                          value={candidate.visatype}
                          onChange={handleChange}
                        >
                          <option value="" hidden="true">
                            {' '}
                            select
                          </option>
                          <option value={'Student'}>Student</option>
                          <option value={'Student Dependent'}>
                            Student Dependent
                          </option>
                          <option value={'Citizen'}>Citizen</option>
                          <option value={'Citizen Dependent'}>
                            Citizen Dependent
                          </option>
                          <option value={' Graduate Visa'}>
                            {' '}
                            Graduate Visa
                          </option>
                          <option value={'Graduate Dependent'}>
                            {' '}
                            Graduate Dependent Visa
                          </option>
                          Graduate Dependent
                          <option value={'ILR'}>
                            Indefinite Leave to Remain
                          </option>
                          <option value={'No Valid Work Permit'}>
                            No Valid Work Permit
                          </option>
                          <option value={'PR'}>PR</option>
                          <option value={'Skilled Worker'}>
                            Skilled Worker
                          </option>
                          <option value={'Dependent'}>
                            Skilled Worker Dependent
                          </option>
                        </select>
                        {submitted && !candidate.visatype && (
                          <div className="help-block">
                            Visa type is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <label htmlFor="type">
                    Address : <span>*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.address ? ' has-error' : '')
                        }
                      >
                        <select
                          className="form-control"
                          name="address"
                          placeholder="select address"
                          value={candidate.address}
                          onChange={handleChange}
                        >
                          <option value={''}>select address</option>
                          {PostcodeObject.addresses}
                        </select>
                        {/*Required*/}
                        {submitted && !candidate.address && (
                          <div className="help-block">address is required</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div className={'form-group '}>
                        <input
                          className="form-control"
                          name="address_line2"
                          placeholder="Address line 2"
                          value={candidate.address_line2}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Gender : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.gender ? ' has-error' : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="gender"
                          value={candidate.gender}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          <option value={'Male'}>Male</option>
                          <option value={'Female'}>Female</option>
                          <option value={'I do not wish to specify'}>
                            I do not wish to specify
                          </option>
                          <option value={'Other'}>Other</option>
                        </select>
                        {submitted && !candidate.gender && (
                          <div className="help-block">Gender is required</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Work Restrictions : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.working_limit
                            ? ' has-error'
                            : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="working_limit"
                          value={candidate.working_limit}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          <option value={' None - Eligible for Full Time'}>
                            {' '}
                            None - Eligible for Full Time
                          </option>
                          <option>Student - Term Time</option>
                          <option value={'part time'}>Part time</option>
                        </select>
                        {submitted && !candidate.working_limit && (
                          <div className="help-block">
                            Work Restrictions is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.email ? ' has-error' : '')
                        }
                      >
                        <label htmlFor="type">
                          Email : <span>*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          disabled
                          name="email"
                          placeholder=""
                          value={candidate.email}
                          onChange={handleChange}
                        />
                        {submitted && !candidate.email && (
                          <div className="help-block">Email is required</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Branch : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.branch ? ' has-error' : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="branch"
                          value={candidate.branch}
                          onChange={handleChange}
                        >
                          <option value="" hidden="true">
                            {' '}
                            select
                          </option>
                          <option value={'Basildon'}>Basildon</option>
                          <option value={'Wales'}>Wales</option>
                        </select>

                        {submitted && !candidate.branch && (
                          <div className="help-block">Branch is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.contact_number
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Mobile : <span>*</span>
                        </label>
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          name="contact_number"
                          placeholder=""
                          value={candidate.contact_number}
                          onChange={handleChange}
                        />

                        {submitted && !candidate.contact_number && (
                          <div className="help-block">
                            Contact Number is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">Secondary Number</label>
                        <input
                          type="number"
                          className="form-control"
                          name="secondary_number"
                          placeholder=""
                          value={candidate.secondary_number}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Experience : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.experience
                            ? ' has-error'
                            : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="experience"
                          value={candidate.experience}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          <option value={'4 month'}>4 month</option>
                          <option value={'6 month'}>6 month</option>
                          <option value={'8 month'}>8 month</option>
                          <option value={'1 year'}>1 year</option>
                          <option value={'2 year'}>2 year</option>
                          <option value={'3 year'}>3 year</option>
                          <option value={'4 year'}>4 year</option>
                          <option value={'morethan 4 year'}>
                            Morethan 4 year
                          </option>
                        </select>
                        {submitted && !candidate.experience && (
                          <div className="help-block">
                            Experience is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Jobrole : <span>*</span>
                      </label>
                      <div
                        className={
                          '' +
                          (submitted && !candidate.category ? ' has-error' : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="category"
                          value={candidate.category}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          {categoriesrows}
                        </select>
                        {submitted && !candidate.category && (
                          <div className="help-block">Jobrole is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  {candidate.category && (
                    <div className="row">
                      <div className="col-md-6 pr-1">
                        <label htmlFor="type">Other Categories</label>
                        <div>
                          {candidate._id && isEditcategory && (
                            <AsyncSelect
                              name="other_categories"
                              onChange={selectedcategory}
                              cacheOptions
                              defaultOptions
                              className="other_categoryselect"
                              defaultInputValue={
                                candidate &&
                                candidate['Category.name'] &&
                                candidate['Category.name']
                              }
                              isMulti
                              loadOptions={getcategorylist}
                            />
                          )}
                          {!candidate._id && !isEditcategory && (
                            <AsyncSelect
                              name="other_categories"
                              className="other_categoryselect"
                              onChange={selectedcategory}
                              cacheOptions
                              defaultOptions
                              isMulti
                              defaultInputValue={
                                candidate &&
                                candidate['Category.name'] &&
                                candidate['Category.name']
                              }
                              loadOptions={getcategorylist}
                            />
                          )}
                          {candidate._id && !isEditcategory && (
                            <div className="col">
                              <div className="row">
                                <div className=" col-md-10 select_box1">
                                  {' '}
                                  {other_categories.map(i => {
                                    subcategories.push(i.name + ' ,' + ' ');
                                  })}
                                  <p>{subcategories}</p>
                                </div>
                                <div
                                  className="edit_text"
                                  onClick={() => {
                                    setisEditcategory(true);
                                  }}
                                >
                                  Edit
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* ---------age and nationality -------- */}
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.dob ? ' has-error' : '')
                        }
                      >
                        <label htmlFor="type">
                          DOB : <span>*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          data-cy="candidate_dob"
                          name="dob"
                          max={startdate}
                          onKeyDown={e =>
                            ['e', 'E', '+', '-'].includes(e.key) &&
                            e.preventDefault()
                          }
                          placeholder="dd-mm-yyyy"
                          min="01-01-1900"
                          value={candidate.dob}
                          onChange={handleChange}
                        />

                        {submitted && !candidate.dob && (
                          <div className="help-block">DOB is required</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.nationality
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Nationality : <span>*</span>
                        </label>
                        <select
                          className="select col-md-12 selectdesign"
                          name="nationality"
                          value={candidate.nationality}
                          onChange={handleChange}
                        >
                          {countries}
                        </select>
                        {submitted && !candidate.nationality && (
                          <div className="help-block">
                            Nationality is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Ethnicity : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.ethncity ? ' has-error' : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="ethncity"
                          value={candidate.ethncity}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          <option value="I do not wish to disclose">
                            I do not wish to disclose
                          </option>
                          <option value="Indian-Asian or Asian British">
                            Indian-Asian or Asian British
                          </option>
                          <option value="Pakistani-Asian or Asian British">
                            Pakistani - Asian or Asian British
                          </option>
                          <option value="Bangladeshi - Asian or Asian British">
                            Bangladeshi - Asian or Asian British
                          </option>
                          <option value="Chinese-Asian or Asian British">
                            Chinese - Asian or Asian British
                          </option>
                          <option value="Any other Asian background">
                            Any other Asian background
                          </option>
                          <option value="Caribbean-Black, Black British, Caribbean or African">
                            Caribbean - Black, Black British, Caribbean or
                            African
                          </option>
                          <option value="African-Black, Black British, Caribbean or African">
                            African - Black, Black British, Caribbean or African
                          </option>
                          <option value="Any other Black, Black British, or Caribbean background">
                            Any other Black, Black British, or Caribbean
                            background
                          </option>
                          <option value="White and Black Caribbean - Mixed or multiple ethnic groups">
                            White and Black Caribbean - Mixed or multiple ethnic
                            groups
                          </option>
                          <option value="White and Black African - Mixed or multiple ethnic groups">
                            White and Black African - Mixed or multiple ethnic
                            groups
                          </option>
                          <option value="White and Asian - Mixed or multiple ethnic groups">
                            White and Asian - Mixed or multiple ethnic groups
                          </option>
                          <option value="Any other Mixed or multiple ethnic background">
                            Any other Mixed or multiple ethnic background
                          </option>
                          <option value="English, Welsh, Scottish, Northern Irish or British - White">
                            English, Welsh, Scottish, Northern Irish or British
                            - White
                          </option>
                          <option value="Irish - White">Irish - White</option>
                          <option value="Gypsy or Irish Traveller - White">
                            Gypsy or Irish Traveller - White
                          </option>
                          <option value="Roma - White">Roma - White</option>
                          <option value="Any other White background">
                            Any other White background
                          </option>
                          <option value="Arab - Other ethnic group">
                            Arab - Other ethnic group
                          </option>
                          <option value="Any other ethnic group">
                            Any other ethnic group
                          </option>
                        </select>
                        {submitted && !candidate.ethncity && (
                          <div className="help-block">Ethncity is required</div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.ni_hour ? ' has-error' : '')
                        }
                      >
                        <label htmlFor="type">
                          NI Hours : <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          name="ni_hour"
                          placeholder=""
                          value={candidate.ni_hour}
                          onChange={handleChange}
                        />
                        {submitted && !candidate.ni_hour && (
                          <div className="help-block">NI Hours is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">
                        Pay Frequency : <span>*</span>
                      </label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.payout_date
                            ? ' has-error'
                            : '')
                        }
                      >
                        <select
                          className="select col-md-12 selectdesign"
                          name="payout_date"
                          value={candidate.payout_date}
                          onChange={handleChange}
                        >
                          <option value="" hidden={true}>
                            {' '}
                            select
                          </option>
                          <option value={1}>Weekly</option>
                          <option value={2}>Biweekly</option>
                          <option value={3}>Monthly</option>
                        </select>
                        {submitted && !candidate.payout_date && (
                          <div className="help-block">
                            Pay Frequency is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.vaccination_status
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Vaccination Status: <span>*</span>
                        </label>
                        <select
                          className="select col-md-12 selectdesign"
                          name="vaccination_status"
                          value={candidate.vaccination_status}
                          onChange={handleChange}
                        >
                          <option value="" hidden="true">
                            {' '}
                            select
                          </option>
                          <option value={'First Dose'}> First Dose </option>
                          <option value={'Second Dose'}> Second Dose </option>
                          <option value={'Booster Dose1'}>
                            {' '}
                            Booster Dose1{' '}
                          </option>
                          <option value={'Booster Dose2'}>
                            {' '}
                            Booster Dose2{' '}
                          </option>
                          <option value={'Not Vaccinated'}>
                            Not Vaccinated
                          </option>
                        </select>
                        {submitted && !candidate.vaccination_status && (
                          <div className="help-block">
                            vaccination status is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="d-flex"> */}
                  {false && (
                    <div className=" row col-md-6 pr-1 d-flex align-items-center">
                      <div>
                        <h6 className="boldtext">
                          Education : <span>*</span>
                        </h6>
                      </div>
                      <div>
                        <i
                          onClick={() => {
                            setaddeducationmodal(true);
                          }}
                          className="fa fa-plus-circle"
                          style={{
                            fontSize: '15px',
                            color: ' #3388EB',
                            cursor: 'pointer',
                            marginRight: '15px',
                            marginLeft: '10px',
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                  {false && (
                    <div className="row m-0">
                      <div className="col-md-8">{educationDataRows}</div>
                    </div>
                  )}
                  {false && (
                    <div className="  row col-md-6 pr-1 d-flex align-items-center">
                      <div>
                        <h6 className="boldtext">
                          Experience : <span>*</span>
                        </h6>
                      </div>
                      <div>
                        <i
                          onClick={() => {
                            setShowExperienceModal(true);
                          }}
                          className="fa fa-plus-circle"
                          style={{
                            fontSize: '15px',
                            color: ' #3388EB',
                            cursor: 'pointer',
                            marginRight: '15px',
                            marginLeft: '10px',
                          }}
                        ></i>
                      </div>
                    </div>
                  )}
                  {false && (
                    <div className="row m-0">
                      <div className="col-md-8">{experienceDataRows}</div>
                    </div>
                  )}
                  {/* </div> */}
                  <div>
                    <h5 className="boldtext">Hourly Wage</h5>
                  </div>
                  <div className="row">
                    <div className="col-md-2 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.morning_price
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Morning : <span>*</span>{' '}
                        </label>
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          onKeyDown={e =>
                            ['e', 'E', '+', '-'].includes(e.key) &&
                            e.preventDefault()
                          }
                          name="morning_price"
                          placeholder=""
                          value={candidate.morning_price}
                          onChange={handleChange}
                        />
                        {/*Required*/}
                        {submitted && !candidate.morning_price && (
                          <div className="help-block">
                            Moring price is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.day_price
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Day : <span>*</span>{' '}
                        </label>
                        <input
                          type="number"
                          step="any"
                          onKeyDown={e =>
                            ['e', 'E', '+', '-'].includes(e.key) &&
                            e.preventDefault()
                          }
                          className="form-control"
                          name="day_price"
                          placeholder=""
                          value={candidate.day_price}
                          onChange={handleChange}
                        />
                        {/*Required*/}
                        {submitted && !candidate.day_price && (
                          <div className="help-block">
                            Day price is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-2 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.night_price
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Night : <span>*</span>{' '}
                        </label>
                        <input
                          type="number"
                          step="any"
                          className="form-control"
                          onKeyDown={e =>
                            ['e', 'E', '+', '-'].includes(e.key) &&
                            e.preventDefault()
                          }
                          name="night_price"
                          placeholder=""
                          value={candidate.night_price}
                          onChange={handleChange}
                        />
                        {/*Required*/}
                        {submitted && !candidate.night_price && (
                          <div className="help-block">
                            Night_price is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.weekend_day_price
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Weekend day : <span>*</span>
                        </label>
                        <input
                          type="number"
                          step="any"
                          onKeyDown={e =>
                            ['e', 'E', '+', '-'].includes(e.key) &&
                            e.preventDefault()
                          }
                          className="form-control"
                          name="weekend_day_price"
                          placeholder=""
                          value={candidate.weekend_day_price}
                          onChange={handleChange}
                        />
                        {/*Required*/}
                        {submitted && !candidate.weekend_day_price && (
                          <div className="help-block">
                            Weekend day price is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-3 pr-1">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.weekend_night_price
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Weekend night : <span>*</span>{' '}
                        </label>
                        <input
                          type="number"
                          step="any"
                          onKeyDown={e =>
                            ['e', 'E', '+', '-'].includes(e.key) &&
                            e.preventDefault()
                          }
                          className="form-control"
                          name="weekend_night_price"
                          placeholder=""
                          value={candidate.weekend_night_price}
                          onChange={handleChange}
                        />
                        {submitted && !candidate.weekend_night_price && (
                          <div className="help-block">
                            Weekend price is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <div className={'form-group '}>
                        <label htmlFor="type">About</label>
                        <textarea
                          name="about"
                          className="form-control"
                          placeholder=""
                          value={candidate.about}
                          onChange={handleChange}
                          rows="4"
                          cols="50"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {/* {candidate.active_status == 0 && (
                    <div className="row">
                      <div className="col-md-12 pr-1">
                        <div className={'form-group '}>
                          <label htmlFor="type">
                            Select reason for deactivate : <span>*</span>
                          </label>
                          <select
                            className="select col-md-12 selectdesign"
                            name="deactive_reason"
                            value={candidate.deactive_reason}
                            onChange={deactivatereasonChange}
                          >
                            <option value={''}>Select</option>
                            <option value={'Accidentally added shift'}>
                              Temporary deactivate{' '}
                            </option>
                            <option value={'Emergency cancellation'}>
                              Emergency deactivate
                            </option>
                            <option value="Other"> Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )} */}
                  <div className="row">
                    {/* //----------ni hours start -------- */}
                  </div>
                  {/* //------response status --------- */}
                  <div className="row">
                    <div className="col-md-6 ">
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate.service_type
                            ? ' has-error'
                            : '')
                        }
                      >
                        <label htmlFor="type">
                          Service Sector :<span>*</span>
                        </label>
                        <select
                          className="select col-md-12 selectdesign"
                          name="service_type"
                          value={candidate.service_type}
                          onChange={handleChange}
                        >
                          <option value=""> select</option>
                          <option value={'Hospitality'}>Hospitality</option>
                          <option value={'Health Care'}>Health Care</option>
                        </select>
                        {submitted && !candidate.service_type && (
                          <div className="help-block">
                            Service Sector is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* //------references --------- */}
                  <div className="row">
                    {/* //----------ref 1 col starts---------- */}
                    <div className="col-md-6 pr-1">
                      <label style={{ fontSize: 14 }}>Reference 1</label>
                      <div className={'form-group '}>
                        <label htmlFor="type">Name</label>
                        <input
                          className="form-control"
                          name="reference1Name"
                          placeholder=""
                          value={candidate?.reference1?.reference1Name}
                          onChange={handleChangeReference}
                        />
                      </div>
                      <label htmlFor="type">Email</label>
                      <div className={'form-group '}>
                        <input
                          className="form-control"
                          name="reference1Email"
                          placeholder=""
                          value={candidate?.reference1?.reference1Email}
                          onChange={handleChangeReference}
                        />
                      </div>
                      <div className={'form-group '}>
                        <label htmlFor="type">Contact Number</label>
                        <input
                          className="form-control"
                          name="reference1Contact"
                          type="number"
                          maxLength={10}
                          placeholder=""
                          value={candidate?.reference1?.reference1Contact}
                          onChange={handleChangeReference}
                        />
                      </div>
                    </div>
                    {/* //----------ref 1 ends here------------- */}
                    {/* //----------ref2 starts here --------------- */}
                    <div className="col-md-6 pr-1">
                      <label htmlFor="type">Reference 2</label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate?.reference2?.reference2Name
                            ? ''
                            : '')
                        }
                      >
                        <label htmlFor="type">Name</label>
                        <input
                          className="form-control"
                          name="reference2Name"
                          placeholder=""
                          value={candidate?.reference2?.reference2Name}
                          onChange={handleChangeReference}
                        />
                      </div>
                      <label htmlFor="type">Email</label>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !candidate?.reference2?.reference2Email
                            ? ' '
                            : '')
                        }
                      >
                        <input
                          className="form-control"
                          name="reference2Email"
                          placeholder=""
                          value={candidate?.reference2?.reference2Email}
                          onChange={handleChangeReference}
                        />
                      </div>
                      <div
                        className={
                          'form-group ' +
                          (submitted &&
                          !candidate?.reference2?.reference2Contact
                            ? ' '
                            : '')
                        }
                      >
                        <label htmlFor="type">Contact number</label>
                        <input
                          className="form-control"
                          name="reference2Contact"
                          placeholder=""
                          value={candidate?.reference2?.reference2Contact}
                          onChange={handleChangeReference}
                        />
                      </div>
                    </div>
                    {/* //--------ref 2 ends here ------------ */}
                  </div>
                  <div className="row ">
                    <div className="col-md-12">
                      <label htmlFor="type">Upload Profile image</label>
                      <div className="row m-0">
                        <div
                          className="col-md-10  filenames  "
                          style={{ maxWidth: 'none' }}
                        >
                          <div className="row filerow">
                            <div className="col-md-8" style={{ lineHeight: 2 }}>
                              {' '}
                              {candidate && candidate.profileimage
                                ? candidate.profileimage
                                : 'no image uploaded'}{' '}
                            </div>
                            <div className="col-md-3 float-right">
                              {' '}
                              <div
                                className="changebutton m-2 p-3"
                                onClick={showfileupload}
                              >
                                {' '}
                                Change{' '}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col align-self-center">
                          <div style={{ marginLeft: '10px', marginBottom: 10 }}>
                            {candidate && candidate.profileimage ? (
                              <img
                                className=" round-emp-image"
                                src={
                                  apiConstants.imgUrl + candidate.profileimage
                                }
                              />
                            ) : (
                              <img
                                className=" round-emp-image"
                                src="/assets/img/recsy-fav-icon.png"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {candidate.staff_rating && (
                    <div className="rating_div rating_div ">
                      <div className="">
                        <div className="mini_div">
                          <div className="rating_number_div col">
                            {(() => {
                              let rows = [];
                              for (let i = 0; i < candidate.staff_rating; i++) {
                                rows.push(
                                  <img
                                    className="star-image2"
                                    src="/assets/img/star.png"
                                  />,
                                );
                              }
                              return rows;
                            })()}
                          </div>
                        </div>
                        <div className="rating_number_div col ml-2">
                          {candidate.staff_rating_note}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="">
                    <div className="d-flex">
                      <div>
                        <img
                          className="star-image"
                          src="/assets/img/star.png"
                        />
                      </div>
                      <div
                        className="font-weight-bold d-flex"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setaddratingmodal(true);
                        }}
                      >
                        <label htmlFor="type">rating</label>
                      </div>
                      {!candidate.staff_rating && (
                        <div
                          className="font-weight-bold d-flex"
                          style={{
                            cursor: 'pointer',
                            color: 'blue',
                            marginLeft: '20px',
                          }}
                          onClick={() => {
                            setaddratingmodal(true);
                          }}
                        >
                          <label htmlFor="type">Add</label>
                        </div>
                      )}
                      {candidate.staff_rating && (
                        <div
                          className="font-weight-bold d-flex"
                          style={{
                            cursor: 'pointer',
                            color: 'blue',
                            marginLeft: '20px',
                          }}
                          onClick={() => {
                            setaddratingmodal(true);
                          }}
                        >
                          <label htmlFor="type">Edit</label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 pr-1">
                      <span className="color-red">{error.common}</span>
                      <div className="form-group">
                        <button
                          data-cy="addcandidate"
                          disabled={Disabled}
                          onClick={handleSubmit}
                          className="btn bluebutton pl-2 pr-2"
                        >
                          {!permenentcandidate
                            ? candidate && candidate._id
                              ? 'Update  Candidate'
                              : 'Add  Candidate'
                            : 'Sync'}

                          {props.requestStatus ===
                            reducerConstants.CANDIDATE_REQUEST && (
                            <div className="lds-ring ml-2">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
          <div className="card-footer" />
        </div>
      </div>
      {showmodalfileupload && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div onClick={hidefileupload}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="">
                  <FileUpload
                    filename={'Profile image'}
                    _id={query._id}
                    showmodalfileupload={showmodalfileupload}
                    setshowmodalfileupload={setshowmodalfileupload}
                    getuploadresponse={getuploadresponse}
                  ></FileUpload>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addratingmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a onClick={() => setaddratingmodal(false)}>
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          <div>
                            <label htmlFor="type">Rating</label>
                            <div className="star-rating">
                              {[...Array(5)].map((star, index) => {
                                index += 1;
                                return (
                                  <button
                                    type="button"
                                    key={index}
                                    className={
                                      index <= candidate.staff_rating
                                        ? 'on'
                                        : 'off'
                                    }
                                    onClick={() => {
                                      setRating(index),
                                        setCandidate({
                                          ...candidate,
                                          staff_rating: index,
                                        });
                                    }}
                                  >
                                    <span className="star">&#9733;</span>
                                  </button>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <label htmlFor="type">Review</label>
                          <textarea
                            className="form-control col-md-12"
                            name="staff_rating_note"
                            placeholder="Review"
                            value={candidate.staff_rating_note}
                            onChange={handleChange}
                            rows="4"
                            cols="50"
                          ></textarea>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-end mt-20">
                        <div className="d-flex justify-content-center ">
                          <button
                            className="button_delete_close width_100"
                            onClick={() => setaddratingmodal(false)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {addeducationmodal && (
        <>
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="heading">Education</h4>
                  <div className="close">
                    <a
                      onClick={() => {
                        setaddeducationmodal(false),
                          seteducationSubmitted(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <form name="form">
                    <div className="row">
                      <div className="col-md-6 p-10">
                        <div className="form-group currentLocationCardText">
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            Level of Education
                          </label>
                          <select
                            aria-labelledby="label-select-54"
                            aria-invalid="false"
                            className="form-control"
                            onChange={handleChangeEducation}
                            id="select-54"
                            name="level_of_education"
                          >
                            <option value="">Select an option</option>
                            <option value="Under GCSE">Under GCSE</option>
                            <option value="Secondary(10th Standard)/ GCSE">
                              Secondary(10th Standard)/ GCSE
                            </option>
                            <option value="Higher Secondary/A-Levels">
                              Higher Secondary/A-Levels
                            </option>
                            <option value="T-Levels">T-Levels</option>
                            <option value="Diploma">Diploma</option>
                            <option value="Bachelor’s Degree">
                              Bachelor’s Degree
                            </option>
                            <option value="Post Graduation Diploma">
                              Post Graduation Diploma
                            </option>
                            <option value="Master’s degree">
                              Master’s degree
                            </option>
                            <option value="Doctorate">Doctorate</option>
                            <option value="OTHER_KEY">
                              Other (Box to specify)
                            </option>
                          </select>
                          {educationsubmitted &&
                            !education.level_of_education && (
                              <div className="help-block">
                                Level of education is required
                              </div>
                            )}
                        </div>
                      </div>
                      {education.level_of_education == 'OTHER_KEY' && (
                        <div className="col-md-6">
                          <div
                            className={
                              'form-group currentLocationCardText' +
                              (educationsubmitted &&
                              education.level_of_education == 'OTHER_KEY' &&
                              !education.education_type
                                ? ' has-error'
                                : '')
                            }
                          >
                            <label
                              htmlFor="usr"
                              className="currentLocationCardText"
                            >
                              Education type
                            </label>
                            <input
                              type="text"
                              placeholder=""
                              name="education_type"
                              onChange={handleChangeEducation}
                              value={
                                education.education_type
                                  ? education.education_type
                                  : ''
                              }
                              className="form-control"
                              id="usr"
                            />
                            {educationsubmitted &&
                              !education.education_type &&
                              education.level_of_education == 'OTHER_KEY' && (
                                <div className="help-block">
                                  Education type is required
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            'form-group currentLocationCardText' +
                            (educationsubmitted && !education.field_of_study
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            Field of study
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="field_of_study"
                            onChange={handleChangeEducation}
                            value={
                              education.field_of_study
                                ? education.field_of_study
                                : ''
                            }
                            className="form-control"
                            id="usr"
                          />
                          {educationsubmitted && !education.field_of_study && (
                            <div className="help-block">
                              Field of study is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className={
                            'form-group currentLocationCardText' +
                            (educationsubmitted && !education.college_university
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            College or University
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="college_university"
                            onChange={handleChangeEducation}
                            value={
                              education.college_university
                                ? education.college_university
                                : ''
                            }
                            className="form-control"
                            id="usr"
                          />
                          {educationsubmitted &&
                            !education.college_university && (
                              <div className="help-block">
                                College or University is required
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            'form-group currentLocationCardText' +
                            (educationsubmitted && !education.location
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            Location
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="location"
                            onChange={handleChangeEducation}
                            className="form-control"
                            id="usr"
                          />
                          {educationsubmitted && !education.location && (
                            <div className="help-block">
                              Location is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className={'form-group'}
                          style={{ marginTop: '2rem' }}
                        >
                          <label className="container typetext">
                            Time period
                            <input
                              type="checkbox"
                              name="currently_enrolled"
                              checked={
                                education.currently_enrolled == 1 ? true : false
                              }
                              onChange={handleChangeEducation}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className={
                            'form-group currentLocationCardText' +
                            (educationsubmitted && !education.from_date
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            Start Date
                          </label>
                          <input
                            type={'date'}
                            name="from_date"
                            onChange={handleChangeEducation}
                            value={
                              education.from_date ? education.from_date : ''
                            }
                            className="form-control"
                          />
                          {educationsubmitted && !education.from_date && (
                            <div className="help-block">
                              Start date is required
                            </div>
                          )}
                        </div>
                      </div>
                      {education.currently_enrolled !== 1 && (
                        <div className="col-md-4">
                          <div
                            className={
                              'form-group currentLocationCardText datefield' +
                              (educationsubmitted &&
                              education.currently_enrolled !== 1 &&
                              !education.from_date
                                ? ' has-error'
                                : '')
                            }
                          >
                            <label className="currentLocationCardText">
                              Completion Date
                            </label>
                            <input
                              type={'date'}
                              name="to_date"
                              onChange={handleChangeEducation}
                              value={education.to_date ? education.to_date : ''}
                              className="form-control"
                            />
                            {educationsubmitted &&
                              !education.from_date &&
                              education.currently_enrolled !== 1 && (
                                <div className="help-block">
                                  Completion Date is required
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col regbuttonalign">
                      <a
                        className="btn skipbutton"
                        onClick={() => {
                          setEducation({}),
                            setaddeducationmodal(false),
                            seteducationSubmitted(false);
                        }}
                        style={{ color: '#000', marginRight: 4 }}
                      >
                        cancel
                      </a>
                      <div
                        onClick={() => handleSubmitEducation()}
                        className="btn regbuttonn regsave "
                      >
                        Save
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {experienceModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="heading">Experience</h4>
                <div className="close">
                  <a
                    onClick={() => {
                      setShowExperienceModal(false),
                        setexperienceSubmitted(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <form name="form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group currentLocationCardText">
                        <div
                          className={
                            'form-group ' +
                            (experiencesubmitted && !experience.job_title
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            Job title - required
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="job_title"
                            onChange={handleExperience}
                            value={
                              experience.job_title ? experience.job_title : ''
                            }
                            className="form-control"
                            id="usr"
                          />
                          {experiencesubmitted && !experience.job_title && (
                            <div className="help-block">
                              Job title is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group currentLocationCardText">
                        <div
                          className={
                            'form-group ' +
                            (experiencesubmitted && !experience.company_name
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            Company
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="company_name"
                            onChange={handleExperience}
                            value={
                              experience.company_name
                                ? experience.company_name
                                : ''
                            }
                            className="form-control"
                            id="usr"
                          />
                          {experiencesubmitted && !experience.company_name && (
                            <div className="help-block">
                              Company name is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* } */}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group currentLocationCardText">
                        <div
                          className={
                            'form-group ' +
                            (experiencesubmitted && !experience.location
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            Location
                          </label>
                          <input
                            type="text"
                            placeholder=""
                            name="location"
                            onChange={handleExperience}
                            className="form-control"
                            id="usr"
                          />
                          {experiencesubmitted && !experience.location && (
                            <div className="help-block">
                              Location is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={'form-group'}>
                        <label className="container typetext">
                          Time period
                          <input
                            type="checkbox"
                            name="currently_enrolled"
                            checked={
                              experience.currently_enrolled == 1 ? true : false
                            }
                            onChange={handleExperience}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group currentLocationCardText datefield">
                        <div
                          className={
                            'form-group ' +
                            (experiencesubmitted && !experience.from_date
                              ? ' has-error'
                              : '')
                          }
                        >
                          <label
                            htmlFor="usr"
                            className="currentLocationCardText"
                          >
                            From Date
                          </label>
                          <input
                            type={'date'}
                            name="from_date"
                            onChange={handleExperience}
                            value={
                              experience.from_date
                                ? moment(experience.from_date).format(
                                    'YYYY-MM-DD',
                                  )
                                : ''
                            }
                            className="form-control"
                          />
                          {experiencesubmitted && !experience.from_date && (
                            <div className="help-block">
                              Location is required
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {experience.currently_enrolled !== 1 && (
                      <div className="col-md-6">
                        <div
                          className={
                            'form-group currentLocationCardText datefield'
                          }
                        >
                          <div
                            className={
                              'form-group ' +
                              (experiencesubmitted &&
                              experience.currently_enrolled !== 1 &&
                              !experience.to_date
                                ? ' has-error'
                                : '')
                            }
                          >
                            <label className="currentLocationCardText">
                              To Date
                            </label>
                            <input
                              type={'date'}
                              name="to_date"
                              onChange={handleExperience}
                              value={
                                experience.to_date
                                  ? moment(experience.to_date).format(
                                      'YYYY-MM-DD',
                                    )
                                  : ''
                              }
                              className="form-control"
                            />
                            {experiencesubmitted &&
                              !experience.to_date &&
                              experience.currently_enrolled !== 1 && (
                                <div className="help-block">
                                  From date is required
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className={'form-group'}>
                        <label className="currentLocationCardText datefield">
                          Description
                        </label>
                        <textarea
                          className="form-control"
                          name="description"
                          id=""
                          onChange={handleExperience}
                          cols="30"
                          rows="5"
                        >
                          {experience.description ? experience.description : ''}
                        </textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col regbuttonalign">
                    <a
                      className="btn skipbutton"
                      onClick={() => {
                        setExperience({}),
                          setShowExperienceModal(false),
                          setexperienceSubmitted(true);
                      }}
                      style={{ color: '#000', marginRight: 4 }}
                    >
                      cancel
                    </a>
                    <div
                      onClick={() => handleSubmitExperience()}
                      className="btn regbutton"
                    >
                      Save
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {showediteducationmodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a
                      onClick={() => {
                        setshowediteducationmodal({ modal: false }),
                          setError({ common: '' });
                      }}
                    >
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="row d-flex justify-content-end mt-20">
                        <div className="d-flex justify-content-center "></div>
                      </div>
                      <div>
                        {' '}
                        <span className="color-red">{error.common}</span>
                      </div>
                    </div>
                  </div>
                  <EducationAdd
                    education_id={showediteducationmodal._id}
                    hideediteducationmodal={hideediteducationmodal}
                  ></EducationAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showeditexperiencemodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a
                      onClick={() => {
                        setshoweditexperiencemodal({ modal: false }),
                          setError({ common: '' });
                      }}
                    >
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="row d-flex justify-content-end mt-20">
                        <div className="d-flex justify-content-center "></div>
                      </div>
                      <div>
                        {' '}
                        <span className="color-red">{error.common}</span>
                      </div>
                    </div>
                  </div>
                  <ExperienceAdd
                    experience_id={showeditexperiencemodal._id}
                    hideeditexperiencemodal={hideeditexperiencemodal}
                  ></ExperienceAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showsyncmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowsyncmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p
                        className="delete_string"
                        style={{ color: '#121f28', lineHeight: '25px' }}
                      >
                        This email is already registered in the permanent
                        database. Would you like to synchronize the information?
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            navigate('/candidateslist');
                          }}
                        >
                          No
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => {
                            setstage(2);
                            setshowsyncmodal(false);
                          }}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showdeleteeducationmodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowdeleteeducationmodal({ modal: false });
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>

              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowdeleteeducationmodal({ modal: false });
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            deleteeducation(showdeleteeducationmodal.id)
                          }
                        >
                          {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showdeleteexperiencemodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowdeleteexperiencemodal({ modal: false });
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>

              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowdeleteexperiencemodal({ modal: false });
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            deleteexperience(showdeleteexperiencemodal.id)
                          }
                        >
                          {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getcategorylist = category =>
  new Promise((resolve, reject) => {
    console.log('lllll', category);
    fetch(apiConstants.apiUrl + '/getcategoryList', {
      method: 'post',
      headers: authHeader(),
      // body: JSON.stringify({ searchKey: inputValue,category:['Water purifier','Water treatment plan']}),
      body: JSON.stringify({ status: 1, searchKey: category }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          resolve(
            data.rows
              .filter(id => id._id != category)
              .map(({ _id, name }) => ({
                value: _id,
                label: name,
              })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}

const connectedCandidateAdd = connect(mapStateToProps)(CandidateAdd);
export { connectedCandidateAdd as CandidateAdd };
