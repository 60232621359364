import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const candidateService = {
  candidateAdd,
  candidateList,
  candidateDelete,
  candidateshifts,
  candidateListv2,
  candidateProfilePublic,
};
function handleResponse(response) {
  console.log(response, 'han78');
  return response.text().then(text => {
    console.log(text, '85txt');
    const data = text && JSON.parse(text);
    console.log('texttttttt', text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    console.log(data, '99hres');
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function candidateAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addcandidate', requestOptions).then(
    handleResponse,
  );
}

function candidateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getcandidateList', requestOptions).then(
    handleResponse,
  );
}

function candidateProfilePublic(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidateprofilepublic',
    requestOptions,
  ).then(handleResponse);
}

function candidateListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidateListv2',
    requestOptions,
  ).then(handleResponse);
}

// function unverifiedcandidateList(data) {
//   const requestOptions = {
//     method: 'POST',
//     headers: authHeader(),
//     body: JSON.stringify(data),
//   };
//   return fetch(
//     apiConstants.apiUrl + '/getcandidateListv2',
//     requestOptions,
//   ).then(handleResponse);
// }

function candidateshifts(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getcandidateshifts',
    requestOptions,
  ).then(handleResponse);
}
function candidateDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletecandidate', requestOptions).then(
    handleResponse,
  );
}
