import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { invoiceActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { InvoiceAdd } from './InvoiceAdd';

// eslint-disable-next-line no-unused-vars
let invoiceRows = [];

const InvoiceList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState({ rows: [] });
  const [invoiceRows, setInvoiceRows] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [invoiceid, setinvoiceid] = useState();
  const [selected_id, setselected_id] = useState('');
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [query, setQuery] = useState({
    searchKey: '',
    from: '',
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  let isadmin = false;
  //--- for initialization---
  useEffect(() => {
    getAllInvoice(1);
  }, [query]);
  //--- debouncing start ---
  let isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query.searchKey, 500);
  const debouncedSearchFrom = useDebounce(query.from, 500);
  const debouncedSearchTo = useDebounce(query?.to, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch || debouncedSearchFrom || debouncedSearchTo) {
        getAllInvoice(1);
      }
    }
  }, [debouncedSearch, debouncedSearchFrom, debouncedSearchTo]);
  //--- debouncing end ---
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        setisAdmin(true);
        isadmin = true;
      } else if (user.role == 6) {
        setisStaff(true);
      } else {
        if (user && user.employer_id) {
          setQuery(prevState => ({
            ...prevState,
            employer_id: user.employer_id,
          }));
        }
        if (user.role == 4) {
          setisAdmin(false);
        }
      }
    }
  }, [isAdmin, isadmin]);
  // handler for event change (filter)
  const handleChange = event => {
    const { name, value } = event.target;
    isfirsttime.current = false;

    // Convert date string to "YYYY-MM-DD" format
    // const formattedDate =
    //   name === 'from' || name === 'to'
    //     ? value.split('/').reverse().join('-')
    //     : value;
    setQuery(prevState => ({ ...prevState, [name]: value }));
  };
  //-----to show edit invoice modal ----------
  const setEditInvoiceModal = (value, _id) => {
    setselected_id(_id);
    showInvoiceModal(value);
  };
  // call for get all invoice
  const getAllInvoice = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);

    // Convert the date strings to Date objects
    const fromDate = queryNew.from ? new Date(queryNew.from) : null;
    const toDate = queryNew.to ? new Date(queryNew.to) : null;

    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 4 && user.employer_id) {
        queryNew.employer_id = user.employer_id;
      }
      if (user.role == 1) {
        isadmin = true;
      }
    }
    const { dispatch } = props;
    if (queryNew.from == '') {
      delete queryNew.from;
    }
    dispatch(invoiceActions.invoiceList(queryNew)).then(
      res => {
        setIsLoading(false);

        // Filter the invoices based on the date range
        const filteredInvoices = res.data.rows.filter(invoice => {
          const invoiceDate = new Date(invoice.updatedAt);
          // Check if the invoice date is within the specified range
          return (
            (!fromDate || invoiceDate >= fromDate) &&
            (!toDate || invoiceDate <= toDate)
          );
        });
        // Update the pagination count based on the filtered data
        const paginationData = { ...res.data, rows: filteredInvoices };
        setListAndPagination(paginationData);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // to show or close invoice modal
  const showInvoiceModal = value => {
    if (value == false) {
      window.location.reload();
    }
    setshowmodal(value);
    getAllInvoice(query.pageVo.pageNo);
  };
  // to sort invoice list
  const handleSort = key => {
    setQuery(query => ({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order:
          query.pageVo.sort === key && query.pageVo.sort_order == 'asc'
            ? 'desc'
            : 'asc',
        sort: key,
      },
    }));
    // Log for debugging
    console.log('Sorting Parameters:', query.pageVo);
  };
  //---update paid status -----
  const paidStatusChange = () => {
    const { dispatch } = props;
    dispatch(
      invoiceActions.invoiceUpdate({
        _id: invoiceid._id,
        payment_status: 'paid',
      }),
    ).then(res => {
      console.log(res, '---response----');
      setdeleteconfirmationmodal(false);
      getAllInvoice(query.pageVo.pageNo);
    });
  };
  /**
   *
   *  set pagination and list rows
   */
  const setListAndPagination = invoiceData => {
    let invoiceList = [];
    for (
      let j = 0;
      invoiceData && invoiceData.rows && j < invoiceData.rows.length;
      j = j + 1
    ) {
      invoiceList.push(
        <tr key={j}>
          <td>INV0{invoiceData.rows[j]._id}</td>
          <td>
            <Link to={'/employer/' + invoiceData.rows[j]['Employer._id']}>
              <div className="row d-flex align-items-center ml-2">
                {invoiceData &&
                invoiceData.rows[j] &&
                invoiceData.rows[j].profileimage ? (
                  <img
                    className=" round-emp-image"
                    src={apiConstants.imgUrl + invoiceData.rows[j].profileimage}
                  />
                ) : (
                  <img
                    className=" round-emp-image"
                    src="/assets/img/recsy-fav-icon.png"
                  />
                )}
                <div className="username">
                  {invoiceData.rows[j]['Employer.company_name']}{' '}
                </div>
              </div>
            </Link>
          </td>
          <td>
            {invoiceData.rows[j].updatedAt.split('T')[0]} &nbsp;-{' '}
            {invoiceData.rows[j].updatedAt.split('T')[1].substr(0, 5)}
          </td>
          <td>
            {invoiceData.rows[j].from_date_string} &nbsp; to &nbsp;{' '}
            {invoiceData.rows[j].to_date_string}{' '}
          </td>
          <td>
            {parseFloat(invoiceData.rows[j].total_hours).toFixed(2)}&nbsp;hrs{' '}
          </td>
          <td>
            {'£' +
              ' ' +
              parseFloat(invoiceData.rows[j].total_amount).toFixed(2)}{' '}
          </td>
          <td>
            <div className="d-flex ">
              {isadmin && (
                <div
                  className={
                    invoiceData.rows[j].payment_status == 'paid'
                      ? 'paid_div cursorPoint'
                      : 'notpaid_div cursorPoint'
                  }
                  onClick={() => {
                    if (invoiceData.rows[j].payment_status !== 'paid') {
                      setdeleteconfirmationmodal(true),
                        setinvoiceid({ _id: invoiceData.rows[j]._id });
                    }
                  }}
                >
                  {invoiceData.rows[j].payment_status.charAt(0).toUpperCase() +
                    invoiceData.rows[j].payment_status.slice(1)}
                </div>
              )}
              {!isadmin && (
                <div
                  className={
                    invoiceData.rows[j].payment_status == 'paid'
                      ? 'paid_div cursorPoint'
                      : 'notpaid_div cursorPoint'
                  }
                >
                  {invoiceData.rows[j].payment_status}
                </div>
              )}
            </div>
          </td>
          <td className="eventCell  eventView" data-cy="viewinvoice">
            <a href={'/invoice/' + invoiceData.rows[j]._id}>View</a>
          </td>
          {isadmin && (
            <td
              className="eventCell event Edit"
              style={{ cursor: 'pointer' }}
              data-cy="editinvoice"
              onClick={() => {
                setEditInvoiceModal(true, invoiceData.rows[j]._id);
              }}
            >
              {/* <div className="editButton">Edit</div> */}
              <img src="/assets/img/Edit.png" alt="" className="editButton" />
            </td>
          )}
        </tr>,
      );
    }
    setInvoiceRows(invoiceList);
    setInvoiceData(invoiceData);
  };
  return (
    <div className="InvoiceList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row d-flex align-items-center">
              <div className="col-md-8">
                <div className="page-title">Invoice List</div>
              </div>
              {(isAdmin || isStaff) && (
                <div className="col-md-4">
                  <div
                    data-cy="invoiceadd"
                    className="btn btn-primary-blue float-right"
                    onClick={() => {
                      setselected_id(''), setshowmodal(true);
                    }}
                  >
                    <span> Generate Invoice</span>
                  </div>
                </div>
              )}
            </div>
            <div className="filter-row">
              <div className="search-item ">
                <div className="input-outer-div">
                  <input
                    className="search-input-div"
                    value={query.searchKey}
                    onChange={handleChange}
                    type="text"
                    name="searchKey"
                    aria-label="Search"
                    placeholder="Search here..."
                  />
                  <a className="search_bar">
                    <i className="fa fa-search in_bar" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
              {/* ======FROM DATE PICKER======== */}
              <div className="filter-item">
                <div className={''}>
                  <input
                    type="date"
                    className="form-control2"
                    name="from"
                    placeholder="from"
                    value={query.from}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* TO DATE PICKER=== */}
              <div className="filter-item">
                <div className={''}>
                  <input
                    type="date"
                    className="form-control2"
                    name="to"
                    placeholder="to"
                    value={query.to}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover flx-custom-table">
                <thead>
                  <tr>
                    <th className="border-top1">Invoice No</th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('name')}
                      className="sortHead"
                    >
                      Name{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('updatedAt')}
                      className="sortHead eventCell  eventView"
                    >
                      Created time{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'updatedAt' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'updatedAt' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th className="eventCell  eventEdit ">From - to </th>
                    <th className="eventCell  eventEdit ">Working hours </th>
                    <th className="eventCell  eventEdit ">Total amount </th>
                    <th className="eventCell  eventEdit ">Payment status </th>
                    <th className="eventCell  eventEdit "> </th>
                    {isAdmin && (
                      <th className="eventCell  eventEdit border-top2"> </th>
                    )}
                  </tr>
                </thead>
                <tbody>{invoiceRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingImg />}
            {invoiceRows && invoiceRows.length === 0 && !isLoading && (
              <p className="margin-top-20 text-center"> No result found </p>
            )}
            {showmodal && (
              <div
                className="modal "
                style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
              >
                <div className="modal-dialog modal-dialog-large ">
                  <div className="modal-content ">
                    <div className="modal-body">
                      <div className="bag">
                        <InvoiceAdd
                          showInvoiceModal={showInvoiceModal}
                          editId={selected_id}
                        ></InvoiceAdd>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllInvoice(data);
              }}
              paginationData={invoiceData}
            />
          </div>
        </div>
      </div>
      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close"></div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row modal-content-center">
                    <div className="col-md-12">
                      <p className="delete_string" style={{ color: 'black' }}>
                        {' '}
                        Update status to paid ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setdeleteconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => paidStatusChange()}
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, invoiceData } = state.invoice;
  return {
    requestStatus,
    invoiceData,
  };
}

const connectedInvoiceList = connect(mapStateToProps)(InvoiceList);
export { connectedInvoiceList as InvoiceList };
