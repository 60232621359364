import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../_actions';
import { LoadingImg } from '../_components';
import { miscService } from '../_services';

const FileUpload = props => {
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  const [newfilename, setnewfilename] = useState('');
  const [isLoading, setisLoading] = useState(false);
  // effect to set props._id in query
  useEffect(() => {
    console.log('ffffffff', props.showmodalfileupload);
    setQuery({ ...query, _id: props._id });
  }, []);
  // handler for state file in newfilename
  const handleChange = e => {
    const { name, value } = e.target;
    setnewfilename({
      [name]: value,
    });
  };
  // file upload new
  const onFileChangeDirectS3 = event => {
    setisLoading(true);
    const { dispatch } = props;
    let ext = '';
    if (!event.target.files || !event.target.files[0]) {
      return;
    }
    let file_new = event.target.files[0];
    ext = event.target.files[0].name.split('.').pop();
    //-------get signed url for put data
    // dispatch(
    miscService
      .s3frontEndUploadUrl({
        type: 'document',
        mime_type: file_new.type,
        ext,
        // }),
      })
      .then(
        async res => {
          let signedurl = res.data.signedUrl;
          let file_name = res.data.filename;
          file_new.other_doc_name = newfilename.newfile;
          fetch(signedurl, {
            method: 'PUT',
            headers: {
              'Content-Type': file_new.type,
              'Content-Disposition': 'inline',
            },
            body: file_new,
          })
            .then(response => response.text())
            .catch(err => {
              console.log(err);
              setisLoading(false);
              dispatch(alertActions.error('File Upload Failed'));
            })
            .then(res => {
              console.log(res, file_name, file_new);
              if (res != undefined) {
                props.getuploadresponse({
                  file_name: file_name,
                  file: file_new,
                });
                setisLoading(false);
              }
            });
        },
        err => {
          console.log(err);
          setisLoading(false);
          dispatch(alertActions.error('File Upload Failed'));
        },
      );
  };
  return (
    <div className="fileupload d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2">
        <div className="card ">
          <div className="card-body">
            <form name="form">
              <h4 className="card-title">Upload file </h4>

              {props.filename !== 'other_document' && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <label htmlFor="type">{props.filename}</label>
                      <input
                        type="file"
                        data-cy="uploadfile"
                        className="form-control"
                        name={props.filename}
                        placeholder=""
                        onChange={onFileChangeDirectS3}
                      />
                    </div>
                  </div>
                </div>
              )}
              {props.filename == 'other_document' && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <input
                        data-cy="enterfilename"
                        type="otherdocuments"
                        style={{ marginBottom: '25px' }}
                        className="form-control"
                        name="newfile"
                        placeholder="Please enter document name"
                        value={newfilename.newfile}
                        onChange={handleChange}
                      />
                      {newfilename.newfile && (
                        <input
                          type="file"
                          data-cy="uploadfile"
                          className="form-control"
                          name={newfilename.newfile}
                          placeholder=""
                          onChange={onFileChangeDirectS3}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
          {isLoading && (
            <div className="d-flex justify-content-center">
              <LoadingImg />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedFileUpload = connect(mapStateToProps)(FileUpload);
export { connectedFileUpload as FileUpload };
