import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const bookingService = {
  bookingAdd,
  bookingList,
  bookingDelete,
  confirmBooking,
};
function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function bookingAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addbooking', requestOptions).then(
    handleResponse,
  );
}

function confirmBooking(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/confirmbooking', requestOptions).then(
    handleResponse,
  );
}
function bookingList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/bookinglist', requestOptions).then(
    handleResponse,
  );
}
function bookingDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deletebooking', requestOptions).then(
    handleResponse,
  );
}
