import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EducationAdd } from '../Education';
import { ExperienceAdd } from '../Experience';
import { QualificationAndTrainingAdd } from '../QualificationAndTraining';
import { ShiftsAdd, ShiftsList } from '../Shifts';
import {
  candidateActions,
  documentsActions,
  educationActions,
  experienceActions,
  qualificationActions,
  userActions,
} from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';
import { FileUpload } from '../fileupload/FileUpload';

const Candidate = props => {
  const { id } = useParams();
  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [experienceDataRows, setExperienceDataRows] = useState([]);
  const [showmodalfileupload, setshowmodalfileupload] = useState(false);
  const [showmodaladdeducation, setshowaddeducationmodal] = useState(false);
  const [showmodaladdexperience, setshowaddexperiencemodal] = useState(false);
  const [showverifyconfirmation, setshowverifyconfirmation] = useState(false);
  const [filename, setfilename] = useState('');
  const [fileverificationmodal, setfileverificationmodal] = useState(false);
  const [iscomponent, setiscomponent] = useState(1);
  const [candidate, setCandidate] = useState({});
  const [user, setuser] = useState({});
  const [educationDataRows, setEducationDataRows] = useState([]);
  const [experienceid, setexperienceid] = useState();
  const [qualificationid, setqualificationid] = useState();
  const [qualificationDataRows, setQualificationDataRows] = useState([]);
  const [trainingDataRows, setTrainingDataRows] = useState([]);
  const [showmodaladdqualification, setshowaddqualificationmodal] =
    useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  let readyForComplaince = 0;
  const [educationid, seteducationid] = useState();
  // eslint-disable-next-line no-unused-vars
  const [documents, setdocuments] = useState({});
  const [showdeleteeducationmodal, setshowdeleteeducationmodal] = useState({
    modal: false,
  });
  const [showdeleteexperiencemodal, setshowdeleteexperiencemodal] = useState({
    modal: false,
  });
  const [showdeletequalificationmodal, setshowdeletequalificationmodal] =
    useState({
      modal: false,
    });
  const [verifyexperiencemodal, setshowverifyexperiencemodal] = useState({
    modal: false,
  });
  const [other_categories, setother_categories] = useState([]);
  const [ismultipleuser, setismultipleuser] = useState(false);
  const [showdeleteconfirmationmodal, setdeleteconfirmationmodal] = useState({
    data: '',
    index: '',
    value: false,
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
    is_candidate_with_docs: true,
  });
  const [candidatefiles, setcandidatefiles] = useState({
    cv: {
      name: '',
      id: '  ',
      isverified: '',
    },
    brp: {
      name: '',
      id: '  ',
      isverified: '',
    },
    bank_statement: {
      name: '',
      id: '  ',
      isverified: '',
    },
    training_certificate: {
      name: '',
      id: '  ',
      isverified: '',
    },
    p45_p60: {
      name: '',
      id: '  ',
      isverified: '',
    },
    vaccinated_certificate: {
      name: '',
      id: '  ',
      isverified: '',
    },
    profile_sheet: {
      name: '',
      id: '  ',
      isverified: '',
    },
  });
  const [clownCounter, setClownCounter] = useState(0);
  const [other_docsArray, setother_docsArray] = useState([]);
  const [error, setError] = useState({ common: '' });
  let other_documentarray = [];
  let subcategories = [];
  // effect for get candidate details and have dependency isverified
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllCandidate(1, query);
    }
    setClownCounter(other_documentarray);
    if (candidate.other_categories) {
      setother_categories(candidate.other_categories);
    }
  }, [user.isverified]);
  // effect for get education ,qualification,experience info of candidate
  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getEducation(1, query);
      getQualification(1, query);
      getExperience(1, query);
    }
  }, []);
  // this function is send as props to fileupload to get upload response-----
  const getuploadresponse = response => {
    //---ADD DOCUMENTS DECTIO START---
    const { dispatch } = props;
    let document_add_query = {
      document_name: response.file_name, //--file-125555646156.pdf
      document_mode: filename, //---CV
      orginalname: response.file.name, //-----harshad_cv.pdf
      user_id: candidate.user_id,
    };
    if (filename == 'other_document') {
      document_add_query.document_mode = response.file.other_doc_name;
    }
    dispatch(documentsActions.documentsAdd(document_add_query)).then(
      res => {
        console.log(res, '--response doc upload table ');
        getAllCandidate(1);
        getEducation(1, query);
        setshowmodalfileupload(false);
      },
      err => {
        console.log(err);
      },
    );
  };
  // call fetch education information
  const getEducation = (pageNo, queryTemp) => {
    let candidate = {};
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    if (queryNew._id) {
      candidate.candidate_id = queryNew._id;
    }
    dispatch(educationActions.educationList(candidate)).then(
      res => {
        setIsLoading(false);
        if (res && res.data && res.data.rows) {
          setEducationlist(res.data.rows);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // list education data
  const setEducationlist = educationdata => {
    let educationlist = [];
    for (let j = 0; j < educationdata.length; j = j + 1) {
      educationlist.push(
        <div className="educard" key={j}>
          <div className="card-body">
            <div className="d-flex ">
              <div className="flex-4">
                <p className="collegename">
                  {educationdata[j].college_university}, London
                </p>
                <p className="stream">
                  {educationdata[j].level_of_education},{' '}
                  {educationdata[j].field_of_study}
                </p>
                <p className="stream">{educationdata[j].location}, </p>
                <p style={{ float: 'left' }}>
                  {moment(educationdata[j].from_date).format('YYYY')}-
                  {educationdata[j].to_date &&
                    moment(educationdata[j].to_date).format('YYYY')}
                </p>
              </div>
              <div className="right_div">
                {query._id && (
                  <div className="d-flex ">
                    <div className="">
                      <img
                        src="/assets/img/edit.png"
                        onClick={() => {
                          setshowaddeducationmodal(true),
                            seteducationid(educationdata[j]._id);
                        }}
                        className="pointer"
                        style={{ height: 15, width: 18 }}
                      />
                    </div>
                    <div>
                      <img
                        className="filterimage pointer"
                        src="/assets/img/delete_copy.png"
                        onClick={() => {
                          setshowdeleteeducationmodal({
                            modal: true,
                            id: educationdata[j]._id,
                          });
                        }}
                      ></img>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>,
      );
    }
    setEducationDataRows([educationlist]);
  };
  // call for fetch experience
  const getExperience = (pageNo, queryTemp) => {
    let candidate = {};
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    if (queryNew._id) {
      candidate.candidate_id = queryNew._id;
    }
    dispatch(experienceActions.experienceList(candidate)).then(
      res => {
        setIsLoading(false);
        if (res && res.data && res.data.rows) {
          setExperiencelist(res.data.rows);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // list experience data
  const setExperiencelist = experiencedata => {
    let experiencelist = [];
    for (let k = 0; k < experiencedata.length; k = k + 1) {
      experiencelist.push(
        <div className="educard">
          <div className="card-body">
            <div className="d-flex flex-1">
              <div className="flex-4 experiencedetails">
                <div className="d-flex">
                  <div className="mr-10">
                    <p
                      className="collegename mb-0"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {experiencedata[k].job_title}
                    </p>
                  </div>
                  <div style={{ alignSelf: 'center' }}>
                    {experiencedata[k].is_verified == 1 && (
                      <img
                        className=" float-right"
                        style={{ height: '15px', width: '15px' }}
                        src="/assets/img/Subtract.png"
                      />
                    )}
                  </div>
                </div>
                <p className="mb-0 " style={{ fontWeight: '500' }}>
                  {experiencedata[k].company_name}
                </p>
                <p className="mb-0" style={{ textTransform: 'capitalize' }}>
                  {experiencedata[k].description}
                </p>
                <p className="stream">
                  {moment(experiencedata[k].from_date).format('MMM YYYY')} -{' '}
                  {experiencedata[k].to_date
                    ? moment(experiencedata[k].to_date).format('MMM YYYY')
                    : 'Present' +
                      '(' +
                      moment(experiencedata[k].from_date, 'YYYYMMDD').toNow() +
                      ')'}
                </p>
                <p className="stream">{experiencedata[k].location}</p>
                <p className="stream">
                  {experiencedata[k].reference_description}
                </p>
              </div>
              <div className="right_div">
                {query._id && (
                  <div className="d-flex  ">
                    <div className="mr-10">
                      <button
                        className="verifybutton "
                        onClick={() => {
                          setshowverifyexperiencemodal({
                            modal: true,
                            id: experiencedata[k]._id,
                            status: experiencedata[k].is_verified == 1 ? 0 : 1,
                          });
                        }}
                      >
                        {' '}
                        {experiencedata[k].is_verified == 1
                          ? 'Unverify'
                          : 'Verify'}
                      </button>
                    </div>
                    <div className="">
                      <img
                        className="pointer"
                        src="/assets/img/edit.png"
                        onClick={() => {
                          setshowaddexperiencemodal(true),
                            setexperienceid(experiencedata[k]._id);
                        }}
                        style={{ height: 15, width: 18 }}
                      />
                    </div>
                    <div>
                      <img
                        className="filterimage pointer"
                        src="/assets/img/delete_copy.png"
                        onClick={() => {
                          setshowdeleteexperiencemodal({
                            modal: true,
                            id: experiencedata[k]._id,
                          });
                        }}
                      ></img>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>,
      );
    }
    setExperienceDataRows([experiencelist]);
  };
  // call for fetch qualification
  const getQualification = (pageNo, queryTemp) => {
    let candidate = {};
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    if (queryNew._id) {
      candidate.candidate_id = queryNew._id;
    }
    dispatch(qualificationActions.qualificationList(candidate)).then(
      res => {
        setIsLoading(false);
        if (res && res.data && res.data.rows) {
          setQualificationlist(res.data.rows);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // list qualification data
  const setQualificationlist = qualificationdata => {
    let qualificationlist = [];
    let traininglist = [];
    for (let k = 0; k < qualificationdata.length; k = k + 1) {
      if (qualificationdata[k].mode == 1) {
        qualificationlist.push(
          <div className="educard">
            <div className="card-body">
              <div className="d-flex flex-1">
                <div className="flex-4 experiencedetails">
                  <p className="mb-0 " style={{ fontWeight: '500' }}>
                    {qualificationdata[k].level}
                  </p>
                  <p className="stream">
                    completed date :{' '}
                    {qualificationdata[k].completed_date_string}
                  </p>
                  <p className="mb-0" style={{ textTransform: 'capitalize' }}>
                    {qualificationdata[k].description}
                  </p>
                  {qualificationdata[k].proof_document && (
                    <div className="col-md-1 proof_document d-flex align-items-center">
                      {qualificationdata[k] &&
                        qualificationdata[k].proof_document &&
                        qualificationdata[k].proof_document}
                      <div className="w20picon">
                        <div className="downloadlink">
                          <a
                            className="downloadlink ml-0"
                            href={
                              apiConstants.imgUrl +
                              qualificationdata[k].proof_document
                            }
                          >
                            <img
                              style={{ height: 20, width: 20 }}
                              src="/assets/img/download.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="right_div">
                  {query._id && (
                    <div className="d-flex  ">
                      <div className="">
                        <img
                          className="pointer"
                          src="/assets/img/edit.png"
                          onClick={() => {
                            setshowaddqualificationmodal(true),
                              setqualificationid(qualificationdata[k]._id);
                          }}
                          style={{ height: 15, width: 18 }}
                        />
                      </div>
                      <div>
                        <img
                          className="filterimage pointer"
                          src="/assets/img/delete_copy.png"
                          onClick={() => {
                            setshowdeletequalificationmodal({
                              modal: true,
                              id: qualificationdata[k]._id,
                            });
                          }}
                        ></img>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>,
        );
      } else {
        traininglist.push(
          <div className="educard">
            <div className="card-body">
              <div className="d-flex flex-1">
                <div className="flex-4 experiencedetails">
                  <p className="mb-0 " style={{ fontWeight: '500' }}>
                    {qualificationdata[k].level}
                  </p>
                  <p className="stream">
                    completed date :{' '}
                    {qualificationdata[k].completed_date_string}
                  </p>
                  <p className="mb-0" style={{ textTransform: 'capitalize' }}>
                    {qualificationdata[k].description}
                  </p>
                  {qualificationdata[k].proof_document && (
                    <div className="col-md-1 proof_document d-flex align-items-center">
                      {qualificationdata[k] &&
                        qualificationdata[k].proof_document &&
                        qualificationdata[k].proof_document}
                      <div className="w20picon">
                        <div className="downloadlink">
                          <a
                            className="downloadlink ml-0"
                            href={
                              apiConstants.imgUrl +
                              qualificationdata[k].proof_document
                            }
                          >
                            <img
                              style={{ height: 20, width: 20 }}
                              src="/assets/img/download.png"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="right_div">
                  {query._id && (
                    <div className="d-flex  ">
                      <div className="">
                        <img
                          className="pointer"
                          src="/assets/img/edit.png"
                          onClick={() => {
                            setshowaddqualificationmodal(true),
                              setqualificationid(qualificationdata[k]._id);
                          }}
                          style={{ height: 15, width: 18 }}
                        />
                      </div>
                      <div>
                        <img
                          className="filterimage pointer"
                          src="/assets/img/delete_copy.png"
                          onClick={() => {
                            setshowdeletequalificationmodal({
                              modal: true,
                              id: qualificationdata[k]._id,
                            });
                          }}
                        ></img>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>,
        );
      }
    }
    if (qualificationlist.length > 0) {
      setQualificationDataRows([qualificationlist]);
    }
    if (traininglist.length > 0) {
      setTrainingDataRows([traininglist]);
    }
  };
  // candidate verify   dropdown onchange function
  const handleChange = e => {
    setshowverifyconfirmation(true);
    setCandidate({
      ...candidate,
      [e.target.name]: e.target.value,
    });
    setQuery({ ...query, user_id: candidate.user_id });
  };
  // show file verification modal
  const fileverify = id => {
    setfileverificationmodal({ id: id, modal: true });
  };
  // uploaded file verification (change isverified(documents table))
  const confirmfileverification = () => {
    const { dispatch } = props;
    dispatch(
      documentsActions.documentsAdd({
        _id: fileverificationmodal.id,
        isverified: 1,
        user_id: candidate.user_id,
      }),
    ).then(function (res) {
      console.log(res);
      setfileverificationmodal({ id: '', modal: false });
      window.location.reload();
    });
  };
  // candidate verification (change isverified(user table))
  const verifyconfirmation = (data, isreadyForComplaince) => {
    const { dispatch } = props;
    if (
      data == 1 &&
      (!candidate.category ||
        !candidate.morning_price ||
        candidate.morning_price == 0 ||
        !candidate.day_price ||
        candidate.day_price == 0 ||
        !candidate.night_price)
    ) {
      setError({
        verifyerror:
          'Please add the category and price details of the candidate.',
      });
      setTimeout(() => {
        setError({ verifyerror: '' });
      }, 3000);
      return;
    }
    if (data == 1 && isreadyForComplaince != 2) {
      setError({
        verifyerror:
          'Please Ready for Compliance Review after Verify the Candidate',
      });
      setTimeout(() => {
        setError({ verifyerror: '' });
      }, 3000);
      return;
    }
    if (query._id) {
      dispatch(
        userActions.userAdd({ isverified: data, _id: candidate.user_id }),
      ).then(res => {
        console.log(res, 'reeeeees', data);
        setuser({ ...user, isverified: data });
      });
    }
    setshowverifyconfirmation(false);
  };
  // to delete document confirmation
  const documentdelete = () => {
    let data = showdeleteconfirmationmodal.data;
    let index = showdeleteconfirmationmodal.index;
    removeDocsElement(data, index);
    setdeleteconfirmationmodal(false);
    getAllCandidate(1, query);
    getEducation(1, query);
  };
  // call to delete education data based on id
  const deleteeducation = id => {
    const { dispatch } = props;
    dispatch(
      educationActions.educationDelete({
        _id: id,
        status: 0,
      }),
    ).then(
      response => {
        console.log(response);
        setshowdeleteeducationmodal({ modal: false });
        setIsDeleteLoading(false);
        getEducation(1, query, true);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  // call to delete experience data based on id
  const deleteexperience = id => {
    const { dispatch } = props;
    dispatch(
      experienceActions.experienceDelete({
        _id: id,
        status: 0,
      }),
    ).then(
      response => {
        console.log(response);
        setshowdeleteexperiencemodal({ modal: false });
        setIsDeleteLoading(false);
        getExperience(1, query, true);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  // call for experience verification
  const experienceverification = (id, status) => {
    const { dispatch } = props;
    dispatch(
      experienceActions.verifyexperience({
        _id: id,
        is_verified: status,
      }),
    ).then(
      response => {
        console.log(response);
        setshowverifyexperiencemodal({ modal: false });
        setIsDeleteLoading(false);
        getExperience(1, query, true);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  // call for delete qualification based on id
  const deletequalification = id => {
    const { dispatch } = props;
    dispatch(
      qualificationActions.qualificationAdd({
        _id: id,
        status: 0,
      }),
    ).then(
      response => {
        console.log(response);
        setshowdeletequalificationmodal({ modal: false });
        setIsDeleteLoading(false);
        getQualification(1, query, true);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  // call for documents delete
  const removeDocsElement = (data, i) => {
    const { dispatch } = props;
    if (data && data._id) {
      dispatch(documentsActions.documentsDelete(data)).then(function (res) {
        if (res.error_code == 0) {
          let newdocsarray = other_docsArray;
          newdocsarray.splice(i, 1);
          setother_docsArray(newdocsarray);
          other_documentarray.splice(i, 1);
          setClownCounter(newdocsarray.length);
        }
      });
    } else {
      let newdocsarray = clownCounter;
      newdocsarray.splice(i, 1);
      setClownCounter(newdocsarray.length);
    }
  };
  // to reset user credentials via mail
  const sendResetUserCredentials = () => {
    const { dispatch } = props;
    let temp = confirm(
      'Are you sure you want to send reset password  to this user?',
    );
    if (!temp) {
      return;
    }
    if (candidate && candidate.user_id) {
      dispatch(
        userActions.sendResetUserCredentials({ _id: candidate.user_id }),
      ).then(function (res) {
        console.log(res);
        alert('success');
      });
    }
  };
  /**
   * Get all candidates
   * */
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(candidateActions.candidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data) {
          setCandidate(res.data.rows[0]);
          console.log('readyforcompliance', readyForComplaince);
          if (res.data.rows[0].isverified == 2) {
            readyForComplaince = 1;
          }
          setuser(res.data.rows[0].user);
          setdocuments(res.data.rows[0].document);
          getAlldocasArray(res.data.rows[0].document);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  // set all uploaded files name and id  to the state
  const getAlldocasArray = documents => {
    setcandidatefiles(documents);
    for (let i = 0; i < documents.length; i++) {
      if (documents[i].document_mode == 'CV') {
        setcandidatefiles(prevState => ({
          ...prevState,
          cv: {
            ...prevState.cv,
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else if (documents[i].document_mode == 'BRP') {
        setcandidatefiles(prevState => ({
          ...prevState,
          brp: {
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else if (documents[i].document_mode == 'Bank Statement') {
        setcandidatefiles(prevState => ({
          ...prevState,
          bank_statement: {
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else if (documents[i].document_mode == 'P45/P60') {
        setcandidatefiles(prevState => ({
          ...prevState,
          p45_p60: {
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else if (documents[i].document_mode == 'Training Certificate') {
        setcandidatefiles(prevState => ({
          ...prevState,
          training_certificate: {
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else if (documents[i].document_mode == 'Profile_sheet') {
        setcandidatefiles(prevState => ({
          ...prevState,
          profile_sheet: {
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else if (documents[i].document_mode == 'vaccinated certificate') {
        setcandidatefiles(prevState => ({
          ...prevState,
          vaccinated_certificate: {
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else if (documents[i].document_mode == 'right_to_work') {
        setcandidatefiles(prevState => ({
          ...prevState,
          right_to_work: {
            name: documents[i].orginalname,
            id: documents[i]._id,
            isverified: documents[i].isverified,
            document_name: documents[i].document_name,
          },
        }));
      } else {
        other_documentarray.push(documents[i]);
      }
    }
    setClownCounter(other_documentarray.length);
    setother_docsArray(other_documentarray);
  };
  // add shift modal open
  const addshift = () => {
    if (user.isverified == 0) {
      setError({ common: ' please verify the candidate' });
    } else if (user.isverified == -1) {
      setError({ common: ' Rejected candidate, please verify the candidate ' });
    } else {
      setshowmodal(true);
    }
  };
  // to hide add experience modal
  const hideaddexperiencemodal = () => {
    setshowaddexperiencemodal(false);
    getExperience();
  };
  // to hide add education modal
  const hideaddeducationmodal = () => {
    setshowaddeducationmodal(false);
    getEducation();
  };
  // to hide add qualification modal
  const hideaddqualificationmodal = () => {
    setshowaddqualificationmodal(false);
    getQualification();
  };
  // to open or close add shift modal
  const showAddshiftmodal = value => {
    setshowmodal(value);
  };
  return (
    <div className="Candidate">
      {props.requestStatus === reducerConstants.CANDIDATE_REQUEST && (
        <LoadingModal />
      )}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <i
                data-cy="backicon"
                className="fa fa-arrow-left"
                style={{
                  cursor: 'pointer',
                  fontSize: '16px',
                  marginRight: '10px',
                }}
                onClick={() => {
                  navigate(-1);
                }}
              ></i>
              <span className="go-back-text">Go Back</span>
              <div className="row">
                <div className="col-md-12">
                  <p className="float-right shiftstyle">Assign Shift</p>
                  <i
                    className="fa fa-plus-circle add_document pointer float-right"
                    data-cy="assign_shift"
                    style={{ cursor: 'pointer' }}
                    onClick={() => addshift()}
                  ></i>
                  <br></br>
                  <div className="pr-1 float-right">
                    <span className="color-red">{error.common}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body disp">
            <div className="row " style={{}}>
              <button
                data-cy="profile"
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 1
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(1)}
              >
                {' '}
                Profile
              </button>
              <button
                data-cy="Timesheet"
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 2
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(2)}
              >
                {' '}
                Time Sheet
              </button>
              <button
                data-cy="Upcomingshift"
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 3
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(3)}
              >
                {' '}
                Upcoming Shift
              </button>
              <button
                data-cy="Completeshift"
                className={
                  'col-md-3 col-sm-6 ' &&
                  (iscomponent === 4
                    ? 'activestate col-md-3 col-sm-6'
                    : 'col-md-3 col-sm-6 nonactive ')
                }
                onClick={() => setiscomponent(4)}
              >
                {' '}
                Complete Shift
              </button>
            </div>
            {iscomponent === 1 && (
              <div className="col-md-12">
                <div className="row d-flex align-items-center mt-4 mb-4">
                  <div className="col-md-6">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-2 ">
                        <div className="d-flex justify-content-center">
                          {candidate && candidate.profileimage ? (
                            <img
                              className=" round-emp-image-candidate"
                              src={apiConstants.imgUrl + candidate.profileimage}
                            />
                          ) : (
                            <img
                              className=" round-emp-image-candidate"
                              src="/assets/img/un-avatar.png"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-8 ">
                        <h3>
                          {candidate.name + ' '}
                          {candidate.last_name ? candidate.last_name : ''}
                        </h3>
                        <div className=" col-md-12 d-flex align-items-center pl-0">
                          {candidate['Category.name'] && (
                            <div className="tag1">
                              <p className="">{candidate['Category.name']} </p>
                            </div>
                          )}
                          <div className="select_bg_div">
                            <select
                              className="select col-md-12 selectdesign"
                              name="verify"
                              value={user.isverified}
                              onChange={handleChange}
                            >
                              <option value={0}>
                                {' '}
                                Registered(not verified){' '}
                              </option>
                              <option value={2}>
                                {' '}
                                Ready for Compliance Review
                              </option>
                              <option value={1}>Verified</option>
                              <option value={-1}>Rejected</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 d-flex justify-content-center">
                    {candidate?.contact_number && (
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-phone green_border"></i>
                        <p className="emp-phone">{candidate.contact_number}</p>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 d-flex justify-content-center">
                    {candidate?.email && (
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-envelope red_border"></i>
                        <p className="emp-phone">{candidate.email}</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className=" col text_align">
                  <Link
                    data-cy="editcandiadte"
                    className="link"
                    to={'/candidateadd/' + candidate._id}
                  >
                    <i className="fas fa-pen pointer f18"></i>
                  </Link>
                </div>
                <div className="dummy-view"></div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="tab-content profile-tab" id="myTabContent">
                      <div id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Name</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>

                          <div className="col">
                            <p>
                              {candidate.name + ' '}
                              {candidate.last_name ? candidate.last_name : ''}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Jobrole</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col">
                            <p>{candidate['Category.name']}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Other Categories</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col">
                            {' '}
                            {other_categories.map((i, index) => {
                              subcategories.push(i.name);
                              if (index !== other_categories.length - 1) {
                                subcategories.push(', ');
                              }
                              return null; // Returning null because we're handling the rendering below
                            })}
                            <p>{subcategories.join('')}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>gender</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col">
                            <p>{candidate.gender}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Visa Type</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col">
                            <p>{candidate?.visatype}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Address</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col">
                            <p>{candidate.address}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Working limit</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col">
                            <p>{candidate.working_limit}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>NI Number</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col-6">
                            <p>{candidate.ni_number} </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Membership Number</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col-6">
                            <p>
                              {candidate.membership_no
                                ? candidate.membership_no
                                : '--'}{' '}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Experience</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col">
                            <p>{candidate.experience}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Ni hour</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col about-div">
                            <p>{candidate?.ni_hour} </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Payout date</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col about-div">
                            <p>
                              {candidate?.payout_date == 1
                                ? 'Weekly'
                                : candidate?.payout_date == 2
                                ? 'Biweekly'
                                : 'Monthly'}{' '}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Branch</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col about-div">
                            <p>{candidate?.branch} </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>About</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col about-div">
                            <p>{candidate.about} </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-3 col-sm-6">
                            <label>Branch</label>
                          </div>
                          <div className="">
                            <label>:</label>
                          </div>
                          <div className="col about-div">
                            <p>{candidate?.branch} </p>
                          </div>
                        </div>
                        {candidate.user && candidate.user['parent.name'] && (
                          <div className="row">
                            <div className="col-md-3 col-sm-6">
                              <label>Refered by </label>
                            </div>
                            <div className="">
                              <label>:</label>
                            </div>
                            <div className="col about-div">
                              <p>
                                {candidate.user &&
                                  candidate.user['parent.name']}{' '}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="d-flex">
                      <div>
                        <h6 className="boldtext ">Education</h6>
                      </div>
                      <div>
                        <i
                          data-cy="addeducation"
                          className="fa fa-plus-circle add_document pointer"
                          onClick={() => {
                            seteducationid(0);
                            setshowaddeducationmodal(true);
                          }}
                        ></i>
                      </div>
                    </div>
                    {educationDataRows.length > 0 && (
                      <div className="row m-0">
                        <div className="col-md-8">{educationDataRows}</div>
                      </div>
                    )}
                    <div className="d-flex">
                      <div>
                        <h6 className="boldtext ">Experience</h6>
                      </div>
                      <div>
                        <i
                          data-cy="addexperience"
                          className="fa fa-plus-circle add_document pointer"
                          onClick={() => {
                            setexperienceid(0);
                            setshowaddexperiencemodal(true);
                          }}
                        ></i>
                      </div>
                    </div>
                    {experienceDataRows.length > 0 && (
                      <div className="row m-0" style={{ marginBottom: '20px' }}>
                        <div className="col-md-8">{experienceDataRows}</div>
                      </div>
                    )}
                    <div className="d-flex">
                      <div>
                        <h6 className="boldtext ">
                          Training and Certifications
                        </h6>
                      </div>
                      <div>
                        <i
                          data-cy="Training_Certifications"
                          className="fa fa-plus-circle add_document pointer"
                          onClick={() => {
                            setqualificationid(0);
                            setshowaddqualificationmodal(true);
                          }}
                        ></i>
                      </div>
                    </div>
                    {qualificationDataRows.length > 0 && (
                      <>
                        <div className="row m-0">
                          <label>Qualifications</label>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-8">
                            {qualificationDataRows}
                          </div>
                        </div>
                      </>
                    )}
                    {trainingDataRows.length > 0 && (
                      <>
                        <div className="row m-0">
                          <label>Trainings</label>
                        </div>
                        <div className="row m-0">
                          <div className="col-md-8">{trainingDataRows}</div>
                        </div>
                      </>
                    )}
                    <div className="row" style={{ marginTop: '30px' }}>
                      <div className="col-md-3 col-sm-6">
                        <label>CV</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.cv &&
                      candidatefiles.cv.name &&
                      candidatefiles.cv.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.cv &&
                            candidatefiles.cv.name &&
                            candidatefiles.cv.name &&
                            candidatefiles.cv.name}
                          <img
                            className=" float-right"
                            style={{ marginTop: '8px' }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          No files uploaded
                          <img
                            className=" float-right icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            data-cy="uploadcv"
                            className="downloadlink ml-0"
                            onClick={() => {
                              setshowmodalfileupload(true), setfilename('CV');
                            }}
                          >
                            Add file
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.cv &&
                          candidatefiles.cv.name &&
                          candidatefiles.cv.document_name &&
                          candidatefiles.cv.name != '' && (
                            <>
                              <div className="downloadlink">
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.cv.document_name
                                  }
                                >
                                  Download
                                </a>
                              </div>
                              <div className="ml-2">
                                {candidatefiles &&
                                candidatefiles.cv &&
                                candidatefiles.cv.isverified &&
                                candidatefiles.cv.isverified == 1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton "
                                    onClick={() =>
                                      fileverify(candidatefiles.cv.id)
                                    }
                                  >
                                    {' '}
                                    verify
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        {/* //---verify end --- */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>BRP</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.brp &&
                      candidatefiles.brp.name &&
                      candidatefiles.brp.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.brp &&
                            candidatefiles.brp.name &&
                            candidatefiles.brp.name}
                          <img
                            className=" float-right"
                            style={{ marginTop: '8px' }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          No files uploaded
                          <img
                            className=" float-right icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            onClick={() => {
                              setshowmodalfileupload(true), setfilename('BRP');
                            }}
                          >
                            Add file
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.brp &&
                          candidatefiles.brp.name &&
                          candidatefiles.brp.name != '' && (
                            <>
                              <div className="downloadlink">
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.brp.document_name
                                  }
                                >
                                  {' '}
                                  Download{' '}
                                </a>
                              </div>
                              <div>
                                {candidatefiles &&
                                candidatefiles.brp &&
                                candidatefiles.brp.isverified &&
                                candidatefiles.brp.isverified == 1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton"
                                    onClick={() =>
                                      fileverify(candidatefiles.brp.id)
                                    }
                                  >
                                    {' '}
                                    verify
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        {/* //---verfiy end */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Bank Statement</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.bank_statement &&
                      candidatefiles.bank_statement.name &&
                      candidatefiles.bank_statement.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.bank_statement &&
                            candidatefiles.bank_statement.name &&
                            candidatefiles.bank_statement.name}
                          <img
                            className=" float-right"
                            style={{ marginTop: '8px' }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          No files uploaded
                          <img
                            className=" float-right icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            onClick={() => {
                              setshowmodalfileupload(true),
                                setfilename('Bank Statement');
                            }}
                          >
                            {' '}
                            Add file{' '}
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.bank_statement &&
                          candidatefiles.bank_statement.name &&
                          candidatefiles.bank_statement.document_name &&
                          candidatefiles.bank_statement.name != '' && (
                            <>
                              <div className="downloadlink">
                                {/* <a>Download</a> */}
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.bank_statement.document_name
                                  }
                                >
                                  {' '}
                                  Download{' '}
                                </a>
                              </div>
                              <div>
                                {candidatefiles &&
                                candidatefiles.bank_statement &&
                                candidatefiles.bank_statement.isverified &&
                                candidatefiles.bank_statement.isverified ==
                                  1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton"
                                    onClick={() =>
                                      fileverify(
                                        candidatefiles.bank_statement.id,
                                      )
                                    }
                                  >
                                    {' '}
                                    verify
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        {/* //---verify end--- */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>P45/P60</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.p45_p60 &&
                      candidatefiles.p45_p60.name &&
                      candidatefiles.p45_p60.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.p45_p60 &&
                            candidatefiles.p45_p60.name &&
                            candidatefiles.p45_p60.name}
                          <img
                            className=" float-right"
                            style={{
                              marginTop: '8px',
                            }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          No files uploaded
                          <img
                            className=" float-right icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            onClick={() => {
                              setshowmodalfileupload(true),
                                setfilename('P45/P60');
                            }}
                          >
                            Add file
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.p45_p60 &&
                          candidatefiles.p45_p60.name &&
                          candidatefiles.p45_p60.document_name &&
                          candidatefiles.p45_p60.name != '' && (
                            <>
                              <div className="downloadlink">
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.p45_p60.document_name
                                  }
                                >
                                  {' '}
                                  Download{' '}
                                </a>
                              </div>
                              <div>
                                {candidatefiles &&
                                candidatefiles.p45_p60 &&
                                candidatefiles.p45_p60.name &&
                                candidatefiles.p45_p60.name != '' &&
                                candidatefiles.p45_p60.isverified &&
                                candidatefiles.p45_p60.isverified == 1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton"
                                    onClick={() =>
                                      fileverify(candidatefiles.p45_p60.id)
                                    }
                                  >
                                    {' '}
                                    verify{' '}
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        {/* //---verify end ----- */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Training Certificate</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.training_certificate &&
                      candidatefiles.training_certificate.name &&
                      candidatefiles.training_certificate.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.training_certificate &&
                            candidatefiles.training_certificate.name &&
                            candidatefiles.training_certificate.name}
                          <img
                            className=" float-right"
                            style={{ marginTop: '8px' }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          {' '}
                          No files uploaded
                          <img
                            className=" float-right icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            onClick={() => {
                              setshowmodalfileupload(true),
                                setfilename('Training Certificate');
                            }}
                          >
                            Add file
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.training_certificate &&
                          candidatefiles.training_certificate.name &&
                          candidatefiles.training_certificate.document_name &&
                          candidatefiles.training_certificate.name != '' && (
                            <>
                              <div className="downloadlink">
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.training_certificate
                                      .document_name
                                  }
                                >
                                  {' '}
                                  Download{' '}
                                </a>
                              </div>
                              <div>
                                {candidatefiles &&
                                candidatefiles.training_certificate &&
                                candidatefiles.training_certificate
                                  .isverified &&
                                candidatefiles.training_certificate
                                  .isverified == 1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton"
                                    onClick={() =>
                                      fileverify(
                                        candidatefiles.training_certificate.id,
                                      )
                                    }
                                  >
                                    {' '}
                                    verify{' '}
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Profile Sheet</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.profile_sheet &&
                      candidatefiles.profile_sheet.name &&
                      candidatefiles.profile_sheet.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.profile_sheet &&
                            candidatefiles.profile_sheet.name &&
                            candidatefiles.profile_sheet.name}
                          <img
                            className=" float-right"
                            style={{ marginTop: '8px' }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          No files uploaded
                          <img
                            className=" float-right icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            onClick={() => {
                              setshowmodalfileupload(true),
                                setfilename('Profile_sheet');
                            }}
                          >
                            Add file
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.profile_sheet &&
                          candidatefiles.profile_sheet.name &&
                          candidatefiles.profile_sheet.document_name &&
                          candidatefiles.profile_sheet.name != '' && (
                            <>
                              <div className="downloadlink">
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.profile_sheet.document_name
                                  }
                                >
                                  {' '}
                                  Download{' '}
                                </a>
                              </div>
                              <div>
                                {candidatefiles &&
                                candidatefiles.profile_sheet &&
                                candidatefiles.profile_sheet.isverified &&
                                candidatefiles.profile_sheet.isverified == 1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton"
                                    onClick={() =>
                                      fileverify(
                                        candidatefiles.profile_sheet.id,
                                      )
                                    }
                                  >
                                    {' '}
                                    verify{' '}
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    {/* //-----------right to work starts------------ */}
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Right to Work</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.right_to_work &&
                      candidatefiles.right_to_work.name &&
                      candidatefiles.right_to_work.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.right_to_work &&
                            candidatefiles.right_to_work.name &&
                            candidatefiles.right_to_work.name}
                          <img
                            className=" float-right"
                            style={{ marginTop: '8px' }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          No files uploaded
                          <img
                            className=" float-right icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            onClick={() => {
                              setshowmodalfileupload(true),
                                setfilename('right_to_work');
                            }}
                          >
                            Add file
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.right_to_work &&
                          candidatefiles.right_to_work.name &&
                          candidatefiles.right_to_work.document_name &&
                          candidatefiles.right_to_work.name != '' && (
                            <>
                              <div className="downloadlink">
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.right_to_work.document_name
                                  }
                                >
                                  {' '}
                                  Download{' '}
                                </a>
                              </div>
                              <div>
                                {candidatefiles &&
                                candidatefiles.right_to_work &&
                                candidatefiles.right_to_work.isverified &&
                                candidatefiles.right_to_work.isverified == 1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton"
                                    onClick={() =>
                                      fileverify(
                                        candidatefiles.right_to_work.id,
                                      )
                                    }
                                  >
                                    {' '}
                                    verify{' '}
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    {/* //----------right to work ends here ------------ */}
                    {/* //------new by me ----------- */}
                    <div className="row">
                      {' '}
                      <div className="col-md-3">
                        <label>Review & Ratings</label>{' '}
                      </div>
                      {!candidate.staff_rating && <div>no reviews found</div>}
                    </div>
                    {candidate.staff_rating &&
                      candidate.staff_rating.length && (
                        <div className="rating_div rating_div ">
                          <div className="">
                            <div className="mini_div">
                              <div className="rating_number_div col">
                                {(() => {
                                  let rows = [];
                                  for (
                                    let i = 0;
                                    i < candidate.staff_rating;
                                    i++
                                  ) {
                                    rows.push(
                                      <img
                                        className="star-image2"
                                        src="/assets/img/star.png"
                                      />,
                                    );
                                  }
                                  return rows;
                                })()}
                              </div>
                            </div>
                            <div className="rating_number_div col ml-2">
                              {candidate.staff_rating_note}
                            </div>
                          </div>
                        </div>
                      )}
                    <div>
                      <label>References</label>
                      <div>
                        {candidate.reference1 &&
                          candidate.reference1?.reference1Name && (
                            <div className="filenames p-2 ml-2">
                              <label htmlFor="">Reference 1</label>
                              <p className="ref_item_name">
                                {candidate.reference1?.reference1Name}
                              </p>
                              <p className="ref_item">
                                <img
                                  className="star-image2 mr-2"
                                  src="/assets/img/mail_outline.png"
                                />
                                {candidate.reference1?.reference1Email
                                  ? candidate.reference1?.reference1Email
                                  : '--'}
                              </p>
                              <p className="ref_item">
                                <img
                                  className="star-image2 mr-2"
                                  src="/assets/img/phone_outline.png"
                                />
                                {candidate.reference1?.reference1Contact
                                  ? candidate.reference1?.reference1Contact
                                  : '--'}
                              </p>
                            </div>
                          )}
                        {candidate.reference2 &&
                          candidate.reference2?.reference2Name && (
                            <div className="filenames p-2 ml-2">
                              <label htmlFor="">Reference 2</label>
                              <p className="ref_item_name">
                                {candidate.reference2?.reference2Name}
                              </p>
                              <p className="ref_item">
                                <img
                                  className="star-image2 mr-2"
                                  src="/assets/img/mail_outline.png"
                                />
                                {candidate.reference2?.reference2Email
                                  ? candidate.reference2?.reference2Email
                                  : '--'}
                              </p>
                              <p className="ref_item">
                                <img
                                  className="star-image2 mr-2"
                                  src="/assets/img/phone_outline.png"
                                />
                                {candidate.reference2?.reference2Contact
                                  ? candidate.reference2?.reference2Contact
                                  : '--'}
                              </p>
                            </div>
                          )}
                        {!candidate.reference2 &&
                          !candidate.reference2?.reference2Name &&
                          !candidate.reference2 &&
                          !candidate.reference2?.reference2Name && (
                            <div className="filenames ml-2 p-2">
                              No references found
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="d-flex">
                      <div>
                        <h5 className="boldtext "> Other Documents</h5>
                      </div>
                      <div>
                        <i
                          data-cy="add_other_document"
                          className="fa fa-plus-circle add_document pointer"
                          onClick={() => {
                            setshowmodalfileupload(true),
                              setfilename('other_document');
                          }}
                        ></i>
                      </div>
                    </div>
                    {/* //---------other documents section---------- */}
                    {Array.from(
                      { length: clownCounter },
                      (_unused, index) => index + 1,
                    ).map((e, i) => (
                      // eslint-disable-next-line react/jsx-key
                      <div>
                        <div className="row ">
                          <div className="col-md-4 filenames">
                            {other_docsArray &&
                            other_docsArray[i] &&
                            other_docsArray[i].document_mode
                              ? other_docsArray[i].document_mode
                              : 'No files uploaded'}
                            <img
                              className=" float-right  icon-size"
                              src="/assets/img/Close.png"
                            />
                          </div>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div className="downloadlink">
                              {other_docsArray[i] &&
                                other_docsArray[i].document_name && (
                                  <a
                                    className="downloadlink ml-0"
                                    href={
                                      apiConstants.imgUrl +
                                      other_docsArray[i].document_name
                                    }
                                  >
                                    Download
                                  </a>
                                )}
                            </div>
                            <div className="ml-2">
                              {other_docsArray &&
                              other_docsArray[i] &&
                              other_docsArray[i].isverified &&
                              other_docsArray[i].isverified == 1 ? (
                                <div className="verifiedtag">verified</div>
                              ) : (
                                <button
                                  className="verifybutton"
                                  onClick={() =>
                                    fileverify(other_docsArray[i]._id)
                                  }
                                >
                                  {' '}
                                  verify
                                </button>
                              )}
                            </div>
                            <div>
                              <i
                                className="fa fa-trash"
                                style={{
                                  marginLeft: '20px',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  setdeleteconfirmationmodal({
                                    data: other_docsArray[i],
                                    index: i,
                                    value: true,
                                  })
                                }
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="row d-flex align-items-center">
                      <div className="col-md-3 col-sm-6">
                        <label>Status</label>
                      </div>
                      {candidate.active_status == 1 && (
                        <div className="col pl-0">
                          <div className="d-flex align-items-start">
                            <p className="activestyle">Active</p>
                          </div>
                        </div>
                      )}
                      {candidate.active_status == 0 && (
                        <div className="col  pl-0">
                          <div className="d-flex align-items-start">
                            <p className="activestyle"> Inactive</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row d-flex align-items-center mb-3">
                      <div className="col-md-3 col-sm-6">
                        <label> Vaccination Status:</label>
                      </div>
                      <div className="col pl-0">
                        <div className="d-flex align-items-start">
                          <p className="activestyle">
                            {candidate && candidate.vaccination_status
                              ? candidate.vaccination_status
                              : ''}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Vaccinated Certificate</label>
                      </div>
                      {candidatefiles &&
                      candidatefiles.vaccinated_certificate &&
                      candidatefiles.vaccinated_certificate.name &&
                      candidatefiles.vaccinated_certificate.name ? (
                        <div className="col-md-4 filenames">
                          {candidatefiles &&
                            candidatefiles.vaccinated_certificate &&
                            candidatefiles.vaccinated_certificate.name &&
                            candidatefiles.vaccinated_certificate.name}
                          <img
                            className=" float-right"
                            style={{
                              marginTop: '8px',
                            }}
                            src="/assets/img/Subtract.png"
                          />
                        </div>
                      ) : (
                        <div className="col-md-4 filenames">
                          No files uploaded
                          <img
                            className=" float-right  icon-size"
                            src="/assets/img/Close.png"
                          />
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className="viewlink">
                          <a
                            onClick={() => {
                              setshowmodalfileupload(true),
                                setfilename('vaccinated certificate');
                            }}
                          >
                            Add file
                          </a>
                        </div>
                        {candidatefiles &&
                          candidatefiles.vaccinated_certificate &&
                          candidatefiles.vaccinated_certificate.name &&
                          candidatefiles.vaccinated_certificate
                            .document_name && (
                            <>
                              <div className="downloadlink">
                                <a
                                  className="downloadlink ml-0"
                                  href={
                                    apiConstants.imgUrl +
                                    candidatefiles.vaccinated_certificate
                                      .document_name
                                  }
                                >
                                  Download
                                </a>
                              </div>
                              <div>
                                {candidatefiles &&
                                candidatefiles.vaccinated_certificate &&
                                candidatefiles.vaccinated_certificate
                                  .isverified &&
                                candidatefiles.vaccinated_certificate
                                  .isverified == 1 ? (
                                  <div className="verifiedtag">verified</div>
                                ) : (
                                  <button
                                    className="verifybutton"
                                    onClick={() =>
                                      fileverify(
                                        candidatefiles.vaccinated_certificate
                                          .id,
                                      )
                                    }
                                  >
                                    {' '}
                                    verify
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <hr />
                    <div className="d-flex">
                      <div
                        onClick={sendResetUserCredentials}
                        className=" btn-primary-blue-nowidth"
                      >
                        Reset login cridentials and send to mail
                      </div>
                    </div>
                    <hr />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            )}
            {/* //--------add shift modal view -------- */}
            {showmodal && user.isverified == 1 && (
              <div
                className="modal "
                style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="close">
                        <a onClick={() => setshowmodal(false)}>
                          <i className=" fa fa-times-circle red fs-18"></i>
                        </a>
                      </div>
                    </div>
                    <div className="modal-body">
                      <ShiftsAdd
                        showAddshiftmodal={showAddshiftmodal}
                        id={query._id}
                        user_id={candidate.user_id}
                        candidate_id={candidate._id}
                        ismultipleuser={ismultipleuser}
                        setismultipleuser={setismultipleuser}
                        isHideuser={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* //------------documents upload modal view ----------- */}
            {showmodalfileupload && (
              <div
                className="modal "
                style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
              >
                <div className="modal-dialog ">
                  <div className="modal-content ">
                    <div className="modal-header">
                      <div className="close">
                        <a onClick={() => setshowmodalfileupload(false)}>
                          <i className=" fa fa-times-circle red fs-18"></i>
                        </a>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="bag">
                        <FileUpload
                          filename={filename}
                          isDocumentUpload={true}
                          _id={query._id}
                          showmodalfileupload={showmodalfileupload}
                          setshowmodalfileupload={setshowmodalfileupload}
                          getuploadresponse={getuploadresponse}
                        ></FileUpload>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* //----------verify candidate modal view ---------------- */}
            {showverifyconfirmation && (
              <div
                className="modal "
                style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
              >
                <div className="modal-dialog modal-dialog-xsm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="close">
                        <a
                          onClick={() => {
                            setshowverifyconfirmation(false);
                          }}
                        >
                          <i className=" fa fa-times-circle red fs-18"></i>
                        </a>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="bag">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-8">
                            <p
                              className="delete_string"
                              style={{ color: 'black' }}
                            >
                              {candidate.verify == 1 &&
                                'Are you sure you want to move the candidate status to Verified?'}
                              {candidate.verify == 0 &&
                                'Are you sure you want to move the candidate status to Registered?'}
                              {candidate.verify == 2 &&
                                'Are you sure you want to move the candidate status to Ready for Compliance Review?'}
                              {candidate.verify == -1 &&
                                'Are you sure you want to reject the candidate?'}
                              {''}
                            </p>
                            <div className="d-flex justify-content-center">
                              <button
                                className="button_delete width_100"
                                onClick={() => {
                                  setshowverifyconfirmation(false);
                                }}
                              >
                                No
                              </button>
                              {candidate.verify == 1 && (
                                <button
                                  data-cy="verifystatus"
                                  className="button_delete_close width_100"
                                  onClick={() =>
                                    verifyconfirmation(1, user.isverified)
                                  }
                                >
                                  Yes
                                </button>
                              )}
                              {candidate.verify == 0 && (
                                <button
                                  className="button_delete_close width_100"
                                  onClick={() => verifyconfirmation(0)}
                                >
                                  {' '}
                                  Yes
                                </button>
                              )}
                              {candidate.verify == 2 && (
                                <button
                                  data-cy="readycompliancestatus"
                                  className="button_delete_close  width_100 "
                                  onClick={() => verifyconfirmation(2)}
                                >
                                  {' '}
                                  Yes
                                </button>
                              )}
                              {candidate.verify == -1 && (
                                <button
                                  className="button_delete_close width_100"
                                  onClick={() => verifyconfirmation(-1)}
                                >
                                  {' '}
                                  Yes
                                </button>
                              )}{' '}
                            </div>
                          </div>
                        </div>
                        {error && error.verifyerror && (
                          <div className="row ml-3">
                            <i className="fa fa-warning red fs-18"></i>
                            <div className="error text">
                              {error.verifyerror}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* //------------candidate documents verify modal------------ */}
            {fileverificationmodal.modal && (
              <div
                className="modal "
                style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
              >
                <div className=" modal-dialog modal-dialog-xsm">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="close">
                        <a
                          onClick={() => {
                            setfileverificationmodal(false);
                          }}
                        >
                          <i className=" fa fa-times-circle red fs-18"></i>
                        </a>
                      </div>
                    </div>
                    <div className="modal-body">
                      <div className="bag">
                        <div className="row d-flex justify-content-center">
                          <div className="col-md-8">
                            <p
                              className="delete_string"
                              style={{ color: 'black' }}
                            >
                              Confirm Verification ?{' '}
                            </p>
                            <div className="d-flex justify-content-center">
                              <button
                                className="button_delete width_100"
                                onClick={() => {
                                  setfileverificationmodal(false);
                                }}
                              >
                                Close
                              </button>
                              <button
                                className="button_delete_close width_100"
                                onClick={() => confirmfileverification()}
                              >
                                {' '}
                                Verify
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {iscomponent === 2 && (
              <div>
                <ShiftsList shifttype={'timesheet'} candidate_id={query._id} />
              </div>
            )}
            {iscomponent === 3 && (
              <div>
                <ShiftsList
                  shifttype={'upcomingshift'}
                  candidate_id={query._id}
                />
              </div>
            )}
            {iscomponent === 4 && (
              <div>
                <ShiftsList
                  shifttype={'completeshift'}
                  candidate_id={query._id}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showdeleteconfirmationmodal.value && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setdeleteconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setdeleteconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => documentdelete()}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showmodaladdeducation && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowaddeducationmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <EducationAdd
                    candidate_id={query._id}
                    education_id={educationid}
                    hideaddeducationmodal={hideaddeducationmodal}
                  ></EducationAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showmodaladdexperience && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowaddexperiencemodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <ExperienceAdd
                    candidate_id={query._id}
                    experience_id={experienceid}
                    hideaddexperiencemodal={hideaddexperiencemodal}
                  ></ExperienceAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showdeleteeducationmodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowdeleteeducationmodal({ modal: false });
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowdeleteeducationmodal({ modal: false });
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            deleteeducation(showdeleteeducationmodal.id)
                          }
                        >
                          {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showdeleteexperiencemodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowdeleteexperiencemodal({ modal: false });
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowdeleteexperiencemodal({ modal: false });
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            deleteexperience(showdeleteexperiencemodal.id)
                          }
                        >
                          {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {verifyexperiencemodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowverifyexperiencemodal({ modal: false });
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Confirm Verification ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowverifyexperiencemodal({ modal: false });
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            experienceverification(
                              verifyexperiencemodal.id,
                              verifyexperiencemodal.status,
                            )
                          }
                        >
                          {!isDeleteLoading && verifyexperiencemodal.status == 1
                            ? 'Verify'
                            : isDeleteLoading &&
                              verifyexperiencemodal.status == 1
                            ? 'Verifing...'
                            : !isDeleteLoading &&
                              verifyexperiencemodal.status == 0
                            ? 'Unverify'
                            : isDeleteLoading &&
                              verifyexperiencemodal.status == 0
                            ? 'Unerifiing...'
                            : ''}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showdeletequalificationmodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshowdeletequalificationmodal({ modal: false });
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshowdeletequalificationmodal({ modal: false });
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() =>
                            deletequalification(showdeletequalificationmodal.id)
                          }
                        >
                          {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showmodaladdqualification && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowaddqualificationmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <QualificationAndTrainingAdd
                    candidate_id={query._id}
                    qualification_id={qualificationid}
                    hideaddqualificationmodal={hideaddqualificationmodal}
                  ></QualificationAndTrainingAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return { requestStatus, candidateData };
}

const connectedCandidate = connect(mapStateToProps)(Candidate);
export { connectedCandidate as Candidate };
