import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const invoiceService = {
  invoiceAdd,
  agencyinvoiceAdd,
  invoiceUpdate,
  invoiceList,
  invoiceListAgency,
  invoiceDelete,
  generateInvoice,
  invoiceBulkGenerate,
};
function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}

function invoiceBulkGenerate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/invoicebulkgenerate',
    requestOptions,
  ).then(handleResponse);
}

function logout() {
  localStorage.removeItem('user');
}
function invoiceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addinvoice', requestOptions).then(
    handleResponse,
  );
}
function agencyinvoiceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/agencyaddinvoice', requestOptions).then(
    handleResponse,
  );
}
function invoiceUpdate(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/updateInvoice', requestOptions).then(
    handleResponse,
  );
}
function generateInvoice(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/generateinvoice ', requestOptions).then(
    handleResponse,
  );
}
function invoiceList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getinvoicelist', requestOptions).then(
    handleResponse,
  );
}
function invoiceListAgency(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getinvoicelistagency',
    requestOptions,
  ).then(handleResponse);
}
function invoiceDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteinvoice', requestOptions).then(
    handleResponse,
  );
}
