export const roleContant = {
  ADMIN: 1,
  USER: 2,
  COMPANY_OWNER: 3,
  BUSINESS_UNIT: 7,
  AGENCY: 5,
  CANDIDATE: 3,
  EMPLOYER: 4, //carehome
  STAFF: 6,
};
