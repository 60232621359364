import { MsalProvider } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import GoogleLogin from '../AuthPage/GoogleLogin';
import MicrosoftAuth from '../AuthPage/MicrosoftAuth';
import { roleContant } from '../_constants';
import { todoConstant } from '../_constants/messageConstant';
import { miscService, msalInstance, userService } from '../_services';

const RegisterPage = () => {
  const [user, setUser] = useState({
    company_name: '',
    phone: '',
    email: '',
    password: '',
    temp_token: '',
    role: roleContant.EMPLOYER,
  });
  const [error, setError] = useState({ common: '' });
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [isLoding, setLoading] = useState(false);
  const [otpSendSuccessfullyEmail, setotpSendSuccessfullyEmail] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLaodingOtpSentEmail, setIsLaodingOtpSentEmail] = useState(false);
  // const [verifiedEmail, setVerifiedEmail] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [timerOn, setTimerOn] = useState(false);
  const [timerLeft, setTimeLeft] = useState(60);

  const EMAIL = 'email';
  const PHONE = 'phone';

  const handleChange = event => {
    const { name, value } = event.target;
    setError({
      ...error,
      otp_error: '',
      phone: '',
      email: '',
      isError: false,
    });
    if (name === EMAIL && value.length > 320) {
      return;
    }
    // if (user.phone.length != 10) {
    //   error.phone = todoConstant.VALID_PHONE;
    //   error.isError = true;
    // }
    if (name === 'password' && value.length > 32) {
      return;
    }

    if (name === PHONE && !value) {
      if (value.length > 11) {
        error.phone = todoConstant.VALID_PHONE;
        error.isError = true;
      }
    }
    if (name == 'otp') {
      // alert(value);
      const otpValue = value.replace(/[^0-9]/g, '');
      if (name == 'otp' && otpValue.length > 4) {
        return;
      }
      setUser({
        ...user,
        otp: otpValue,
      });
      if (value.length === 4) {
        handleVerifyOtp(value, EMAIL);
      }
      return;
    } else if (name == PHONE) {
      const numericValue = value.replace(/[^0-9]/g, '');
      if (name == PHONE && numericValue.length > 11) {
        return;
      }
      setUser({
        ...user,
        [name]: numericValue,
      });
      return;
    } else {
      setUser({
        ...user,
        [name]: value,
      });
    }
    if (name === EMAIL && miscService.emailVerify(value)) {
      setEmailValidation(true);
    } else {
      setEmailValidation(false);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    error.isError = false;

    // if (!user.phone) {
    //   error.phone = todoConstant.PHONE_REQUIRED;
    //   error.isError = true;
    // }
    if (!user.email) {
      error.email = todoConstant.EMAIL_REQUIRED;
      error.isError = true;
    }
    if (!user.company_name) {
      error.company_name = todoConstant.COMPANY_REQUIRED;
      error.isError = true;
    }
    if (!user.password) {
      error.password = todoConstant.PASSWORD_REQUIRED;
      error.isError = true;
    }
    if (!user.temp_token) {
      error.common = todoConstant.VERIFY_EMAIL;
      error.isError = true;
    }
    if (error.isError) {
      setError({ ...error });
      return;
    }
    try {
      setLoading(true);
      const userData = {
        company_name: user.company_name,
        phone: user.phone,
        email: user.email,
        password: user.password,
        temp_token: user.temp_token,
      };
      const res = await userService.registrationWithToken(userData);
      console.log(res, `ressss`);
      if (res.error_code === 0) {
        // Store data in local storage
        localStorage.setItem('user', JSON.stringify(res.data.data));
        console.log('Local Storage Data from Response');
        if (parseInt(res.data.data.role) === 4) {
          window.location.href = '/dashboardemployer';
          console.log('Employer dashboard ...', res.data.role);
        } else {
          window.location.href = '/login';
          console.log('Employer dashboard ...', res.data.role);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error === todoConstant.PHONE_ALREADY_USED) {
        setError({
          ...error,
          validationPhone: todoConstant.PHONE_ALREADY_USED,
          isError: true,
        });
      } else {
        setError({
          ...error,
          common: todoConstant.PROCESS_ERROR,
          isError: true,
        });
      }
    }
    setLoading(false);
  };

  const handleSendOTP = async isRendOtp => {
    // Email validation
    if (!user.email || !miscService.emailVerify(user.email)) {
      setError({ ...error, email: todoConstant.INVALID_EMAIL });
      return;
    }
    if (!user.email) {
      setError({
        ...error,
        email: todoConstant.EMAIL_REQUIRED,
      });
      return;
    } else {
      setError({
        ...error,
        email: '',
        phone: '',
        otp_error: '',
      });
    }
    setIsLaodingOtpSentEmail(true);
    setTimerOn(true);
    setotpSendSuccessfullyEmail(true);
    setTimeLeft(60);

    try {
      const res = await userService.sendOtpRegister({
        email: user.email,
        role: user.role,
        isRendOtp: isRendOtp,
      });

      console.log(res, 'Response Data for send OTP', otpSendSuccessfullyEmail);
      setIsLaodingOtpSentEmail(false);
      setIsLoadingVerify(false);
    } catch (error) {
      console.log(error, 'error during OTP verification');
      setIsLoadingVerify(false);
      if (
        error == todoConstant.EMAIL_ALREADY_USED ||
        error == todoConstant.EMAIL_ALREADY_USED_FORGET_PASSWORD
      ) {
        setError({
          ...error,
          email: typeof error == 'string' ? error : error.message,
        });
      } else if (error == todoConstant.PHONE_ALREADY_USED) {
        setError({
          ...error,
          phone: typeof error == 'string' ? error : error.message,
        });
      } else {
        setError({
          ...error,
          common: typeof error == 'string' ? error : error.message,
        });
      }
      console.error('Failed to verify the OTP');
      setIsLaodingOtpSentEmail(false);
      setTimerOn(false);
      setotpSendSuccessfullyEmail(false);
    }
  };

  const handleVerifyOtp = async otp => {
    if (isLoadingVerify) {
      return;
    }
    setError({
      ...error,
      common: '',
      otp_error: '',
    });
    setIsLoadingVerify(true);

    try {
      const userData = {
        email: user.email,
        role: parseInt(user.role),
        phone: '44' + user.phone,
        otp: otp, // Replace with the actual OTP value
      };
      const res = await userService.verifyOtpRegister(userData);
      console.log(res, 'Response Data for verify OTP');
      console.log(res.data, res.error_code, 'Response Data for verify OTP');
      if (res.data && res.error_code == 0) {
        console.log(res.data.temp_token, '<<<<---');
        setUser({
          ...user,
          temp_token: res.data.temp_token,
        });
        setotpSendSuccessfullyEmail(false);
      }
      setIsLoadingVerify(false);
    } catch (error) {
      console.log(error, 'error');
      setIsLoadingVerify(false);
      setotpSendSuccessfullyEmail(true);
      setIsLaodingOtpSentEmail(false);
      setError({
        ...error,
        otp_error: todoConstant.OTP_EXP_OR_INVALID,
      });
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => {
      if (timerLeft > 0) {
        setTimeLeft(timerLeft - 1);
      }

      if (timerLeft === 0) {
        setTimerOn(false);
        clearInterval(timerId);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timerLeft]);
  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  // console.log(otpSendSuccessfully, 'otpSendSuccessfully');
  return (
    <div className="container-fluid">
      <div className="text-dark main-registration">
        <div className="registration-logo mt-10 ml-10 mb-4">
          <img src="/assets/img/recsy-temp-logo.svg" />
        </div>
        <div className="col-lg-3 col-md-5 m-auto">
          <div className="auth-box mt-4">
            <h3 className="mb-0 auth-title">Employer Sign Up</h3>
            <h6 className=" auth-subtitle">
              {"Let's hire your next great candidate"}
            </h6>
            {/* <form name="form" autoComplete="off"> */}
            <div
              className={
                'form-group' + (error.company_name ? ' has-error' : '')
              }
            >
              <label htmlFor="company_name" className="mt-4">
                Company Name <span className="help-block"> * </span>
              </label>
              <div className="d-flex bg-white flex-row border-0 input-group input-wrap-with-btn">
                <input
                  type="text"
                  className="form-control flx-common-input-fields pl-1 input-textarea input-wrap-with-btn"
                  name="company_name"
                  placeholder="Company name"
                  value={user.company_name}
                  onChange={handleChange}
                />
              </div>
              {error.company_name && (
                <div className="help-block">{error.company_name}</div>
              )}
            </div>
            <div className={'form-group' + (error.email ? ' has-error' : '')}>
              <label htmlFor="email">
                Email <span className="help-block"> * </span>
              </label>
              <div className="d-flex bg-white flex-row input-group px-2 input-wrap-with-btn">
                <input
                  type="email"
                  pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                  className="form-control border-0 m-auto input-textarea flx-sign-up-email"
                  name="email"
                  placeholder="Email"
                  value={user.email}
                  onChange={handleChange}
                />
                {!user.temp_token ? (
                  <button
                    onClick={() => {
                      otpSendSuccessfullyEmail
                        ? handleSendOTP(true)
                        : handleSendOTP();
                    }}
                    className={`btn border m-auto ${
                      emailValidation ? 'verify-btn' : 'bg-light text-dark'
                    }`}
                    disabled={
                      !emailValidation || isLaodingOtpSentEmail || timerOn
                    }
                  >
                    {otpSendSuccessfullyEmail && !timerOn
                      ? 'Resend OTP'
                      : timerOn
                      ? `${timerLeft} sec`
                      : 'Verify'}
                  </button>
                ) : (
                  <span className="text-success m-auto p-2">Verified</span>
                )}
              </div>
              {error.email && (
                <div className="text-danger mt-1">{error.email}</div>
              )}
              {/*After otp......*/}

              {otpSendSuccessfullyEmail && (
                <div className="">
                  <section className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-12">
                        <div className="card border-0">
                          <div className="text-center">
                            <p
                              className=" p-2"
                              style={{
                                width: '100%',
                                fontSize: '14px',
                                fontFamily: 'Manrope',
                                fontWeight: '500',
                              }}
                            >
                              We have send an OTP to this email
                            </p>
                            <div
                              className={
                                'row justify-content-center ' +
                                (error.otp_error ? 'otp_error' : '')
                              }
                            >
                              <div className="col-lg-6 col-md-10 ">
                                <input
                                  type="text"
                                  step="any"
                                  className="form-control border pl-1 fw-500 input-textarea"
                                  placeholder="OTP"
                                  name="otp"
                                  value={user.otp}
                                  onChange={handleChange}
                                />
                                {/* {timerOn && (
                                  <p
                                    className="flx-general-text mt-2"
                                    style={{
                                      width: '100%',
                                      fontSize: '14px',
                                      fontFamily: 'Manrope',
                                      fontWeight: '400',
                                    }}
                                  >
                                    Request resend OTP in{' '}
                                    {timerLeft < 10
                                      ? `0${timerLeft}`
                                      : timerLeft}{' '}
                                    sec
                                  </p>
                                )} */}
                              </div>
                            </div>
                            {!!error.otp_error && (
                              <div
                                className="alert alert-danger mt-2"
                                role="alert"
                              >
                                {error.otp_error}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )}
            </div>
            <div className={'form-group' + (error.phone ? ' has-error' : '')}>
              <label htmlFor="phone">Phone Number</label>
              <div className="d-flex flex-row bg-white input-group px-2 input-wrap-with-btn">
                <span
                  className="m-auto border-right px-2"
                  style={{ fontSize: '13px', fontWeight: '600' }}
                >
                  +44
                </span>
                <input
                  type="text"
                  step="any"
                  className="form-control border border-0 pl-1 fw-500 input-textarea flx-input-phone-number"
                  placeholder=""
                  name="phone"
                  value={user.phone}
                  onChange={handleChange}
                />
                {/* {!user.temp_token ? (
                  <button
                    onClick={() => handleSendOTP(OTP_TYPE_PHONE)}
                    className={
                      'btn  border  m-auto ' +
                      (user.phone ? 'verify-btn ' : 'bg-light color-primary')
                    }
                  >
                    {!isLaodingOtpSentPhone ? 'Verify' : 'Loading...'}
                  </button>
                ) : (
                  // collor green and text whit
                  <button
                    className={
                      verifiedPhone
                        ? 'text-success m-auto p-2'
                        : 'rounded p-1 border m-auto bg-light color-primary'
                    }
                  >
                    {verifiedPhone ? 'verified' : 'verify'}
                  </button>
                )} */}
              </div>
              {user.phone && user.phone.length < 11 && (
                <div className="help-block">{todoConstant.VALID_PHONE}</div>
              )}
              {error.phone && (
                <div className="text-danger mt-1">{error.phone}</div>
              )}
              {/*After otp......*/}
              {error.validationPhone && (
                <div className="help-block">{error.validationPhone}</div>
              )}
              {/* {otpSendSuccessfullyPhone && (
                <div className="">
                  <section className="">
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-12">
                        <div className="card border-0">
                          <div className="text-center">
                            <p
                              style={{
                                width: '100%',
                                fontSize: '14px',
                                fontFamily: 'Manrope',
                                fontWeight: '500',
                              }}
                            >
                              We have send an OTP to this Phone
                            </p>
                            <div
                              className={
                                'row justify-content-center ' +
                                (error.otp_error ? 'otp_error' : '')
                              }
                            >
                              <div className="col-12 col-md-5">
                                <OtpInput
                                  handleSubmit={otp =>
                                    handleVerifyOtp(otp, OTP_TYPE_PHONE)
                                  }
                                  handleChangeOtp={() => {
                                    setError({ ...error, otp_error: '' });
                                  }}
                                />
                              </div>
                            </div>
                            {!!error.otp_error && (
                              <div
                                className="alert alert-danger mt-2"
                                role="alert"
                              >
                                {error.otp_error}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              )} */}
            </div>

            <label htmlFor="password">
              Create Password<span className="help-block"> * </span>
            </label>
            <div className="d-flex flex-row bg-white input-group input-wrap-with-btn">
              <input
                type={showPassword ? 'text' : 'password'}
                step="any"
                className="form-control border border-0 pl-1 fw-500 input-textarea"
                placeholder="Password"
                name="password"
                value={user.password}
                onChange={handleChange}
              />
              <span onClick={handlePasswordToggle} className="m-auto px-2">
                {showPassword ? (
                  <img src="/assets/img/Vector.png" />
                ) : (
                  <img src="/assets/img/eye.png" />
                )}
              </span>
            </div>
            {user.password && user.password.length < 8 && (
              <div className="help-block">
                Password length should be min 8 characters
              </div>
            )}

            {user.password && (
              <div className="text-danger mt-1">{error.password}</div>
            )}
            <div className="form-group mt-4">
              <span className="color-red">{error.common}</span>
              <div>
                <button
                  onClick={handleSubmit}
                  className={
                    'send-btn  ' +
                    (user.password.length > 7 &&
                    user.company_name &&
                    user.email &&
                    user.temp_token
                      ? 'active-signup-btn'
                      : 'disabled')
                  }
                  disabled={isLoding}
                >
                  {!isLoding ? 'Sign Up' : 'Saving..'}
                </button>
              </div>
            </div>
            <div className="text-center">
              <div className="row flx-continue-sign-with-row">
                <div className="col">
                  <hr />
                </div>
                <div className="col-auto">
                  <span className="flx-continue-sign-with">
                    OR Continue with
                  </span>
                </div>
                <div className="col">
                  <hr />
                </div>
              </div>
            </div>

            <div className="flx-other-signup-option-container d-flex align-items-center">
              <div className="flx-google-sign-in">
                <GoogleLogin
                  showError={error =>
                    setError({
                      common: error,
                    })
                  }
                />
              </div>
              <div className="flx-microsoft-sign-in">
                <MsalProvider instance={msalInstance}>
                  <MicrosoftAuth
                    showLoading={status => {
                      setLoading(status);
                    }}
                  />
                </MsalProvider>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center p-3">
          <div
            className="d-flex m-auto text-dark link-black mb-2"
            style={{ fontSize: '14px' }}
          >
            Already have an account?
            <Link
              to="/login"
              className="m-auto px-2 flx-sign-in-text"
              style={{
                textDecoration: 'underline',
              }}
            >
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { registering } = state.registration;
  return { registering };
}
const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
