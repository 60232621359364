import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';

import { miscActions } from '../_actions';
// import 'chart.js/auto';
// import { Chart } from 'react-chartjs-2';
// import { Bar } from 'react-chartjs-2';

// import moment from 'moment';
// import { Doughnut } from 'react-chartjs-2';

import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
const Averagepricegraph = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [performanceData, setPerformanceData] = useState({});

  const [year, setyear] = useState(0);

  let employer_id = '';
  let agency_id;

  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        // setisAdmin(true);
      }
      if (user.role == 4 || user.role == 7) {
        // setisEmployer(true);
        console.log('employer_id', user.employer_id);
        employer_id = user.employer_id;
      }

      if (user.role == 6) {
        // setStaff(true);
      }
    }
    // console.log("gfdfghj")
    dashbordgraph();
  }, [year]);

  const previousyearhandlechange = () => {
    console.log('abcd p', year);

    setyear(year - 1);
  };

  const upcomingyearhandlechange = () => {
    console.log('abcd u', year);

    setyear(year + 1);
  };

  //--------get dashboard counts data-------------

  const dashbordgraph = () => {
    setIsLoading(true);
    const { dispatch } = props;
    console.log('subitdatesssstoreportt', year);

    var fromDate = new Date().getFullYear() + year + '-01-01';
    var toDate = new Date().getFullYear() + year + '-12-31';

    console.log('gotallvaluesss222 qqq', fromDate, toDate);

    let querynew = {
      fromDate: fromDate,
      toDate: toDate,
      averagepricegraph: 1,
    };

    if (employer_id) {
      querynew.employer_id = employer_id;
    }
    if (agency_id) {
      querynew.agency_id = agency_id;
    }
    // querynew.averagepricegraph=1
    console.log('insidefromandtoelseee', querynew);
    dispatch(miscActions.getgraphData(querynew)).then(res => {
      console.log(res.data, 'ressssspppp');
      setPerformanceData({
        flexi_price: res.data[0], //flexi price
        other_price: res.data[1], //other price
      });
      setIsLoading(false);
    });
  };

  console.log('performance', performanceData);

  const labels = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  console.log('adbse', performanceData);

  const data = {
    labels,

    datasets: [
      {
        label: 'Flexi price',
        data: performanceData && performanceData.flexi_price,
        width: '2px',
        height: '20px',
        fill: true,
        backgroundColor: '#20B9B7',
        //  borderColor: '#486edb',
        // borderWidth: 2,
        borderRadius: 10,
        cornerRadius: 10,
        borderSkipped: false,
        // scaleStepWidth: 1,
        // stepSize: 50,
      },
      {
        label: 'Other price',
        data: performanceData && performanceData.other_price,
        // width: "20px",
        // height: "20px",
        fill: false,
        // backgroundColor: '#bff2f1',
        // background: ' #bff2f1',
        cornerRadius: 10,
        borderColor: '#000',
        borderRadius: 10,
        borderSkipped: false,
        // borderRadius: {
        //   topLeft: 10,
        // },
        // borderWidth: 1,
      },
    ],
  };
  return (
    <div className="col-md-12 pl-0 pr-0">
      <div className=" table-responsive ">
        <div className="">
          <div className="card-body">
            {!isLoading && (
              <div className="chart-bar">
                <div className="col">
                  <label>Average Price {new Date().getFullYear() + year}</label>
                </div>
                <Bar
                  backgroundColor={'#fff'}
                  height={'100px'}
                  options={options}
                  data={data}
                />
                <div
                  className="left-right-arrow ml-2"
                  onClick={upcomingyearhandlechange}
                >
                  <i className="fas fa-arrow-right white"></i>
                </div>
                <div
                  className="left-right-arrow"
                  onClick={previousyearhandlechange}
                >
                  <i className="fas fa-arrow-left white"></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { users, authentication } = state;
  const { user } = authentication;
  const { isMenuOpen } = state.misc;

  return {
    user,
    users,
    isMenuOpen,
  };
}
const options = {
  responsive: true,
  barRoundness: 10,
  // borderRadius: 30,
  // cornerRadius: 20,
  legend: {
    cursor: 'pointer',
    display: true,
    position: 'right',
    labels: {
      usePointStyle: false,
      boxWidth: 10,
    },
  },

  plugins: {
    legend: {
      position: 'top',
    },

    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
  cornerRadius: 4,
  // scales: {
  //     x: {
  //       stacked: true
  //     }
  //   }
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
    // yAxes: [
    //   {
    //     ticks: {
    //       beginAtZero: true,
    //       margin: '10px',
    //       min: 5000,
    //       max: 500000,
    //     },
    //   },
    // ],
    xAxes: [
      {
        gridLines: {
          display: false,
          drawOnChartArea: false,
        },

        // ticks: {
        //   fontColor: '#898989',
        //   autoSkip: false,
        //   maxRotation: 0,
        //   minRotation: 0,
        // },
        ticks: {
          color: '#fff',
          beginAtZero: true,
          max: 100,
        },
        grid: {
          display: false, // Hide x grid
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          drawOnChartArea: false,
          // color: "rgba(0, 0, 0, 0)",
        },

        grid: {
          drawBorder: true,
          display: false,
        },
        ticks: {
          fontColor: '#898989',
        },
      },
    ],
  },

  // const options = {
  //     scales: {
  //       yAxes: [
  //         {
  //           ticks: {
  //             beginAtZero: true,
  //             min:0,
  //           max:10

  //           },
  //         },
  //       ],
  //     },
};
const connectedAveragepricegraph = connect(mapStateToProps)(Averagepricegraph);
export { connectedAveragepricegraph as Averagepricegraph };
