import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BlockBookinglist } from '../Block_booking';
import { AllShiftsList } from '../Shifts';

const Shiftmenu = props => {
  let routeKey = props.isrouteKey;
  const [Allshiftstoggle, setAllshiftstoggle] = useState(true);
  const [Assigntoggle, setAssigntoggle] = useState(false);
  // handler for change tab
  const handleChange = e => {
    if (e.currentTarget.value && e.target.name == 'tabs1') {
      setAllshiftstoggle(true), setAssigntoggle(false);
    } else {
      setAllshiftstoggle(false), setAssigntoggle(true);
    }
  };

  useEffect(() => {
    const { isrouteKey } = props;
    routeKey = isrouteKey;
  }, [props.isrouteKey]);

  return (
    <div className="CandidateList ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            <div className="card-header page-header ">
              <div className="row">
                <div className="col-md-8">
                  <div className="page-title"></div>
                </div>
              </div>
            </div>
            {false && (
              <div className="card-header page-header p-0">
                <div className="d-flex justify-content-center">
                  <div className="col-md-8 d-flex justify-content-center">
                    <button
                      className={
                        'col-md-3 col-sm-6 ' &&
                        (Allshiftstoggle
                          ? 'activestate col-md-3 col-sm-6'
                          : 'col-md-3 col-sm-6 nonactive ')
                      }
                      onClick={() => {
                        setAllshiftstoggle(true), setAssigntoggle(false);
                      }}
                    >
                      {' '}
                      All Shifts
                    </button>
                    <button
                      className={
                        'col-md-3 col-sm-6 ' &&
                        (Assigntoggle
                          ? 'activestate col-md-3 col-sm-6'
                          : 'col-md-3 col-sm-6 nonactive ')
                      }
                      onClick={() => {
                        setAllshiftstoggle(false), setAssigntoggle(true);
                      }}
                    >
                      {' '}
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className="tabsContainer">
              <div className="tabs">
                {/* //-------------first tab---------- */}
                <input
                  type="radio"
                  data-cy="list"
                  style={{ display: 'none' }}
                  id="radio-1"
                  name="tabs1"
                  onChange={handleChange}
                  checked={Allshiftstoggle ? true : false}
                />
                <label className="tab" htmlFor="radio-1">
                  All Shift
                </label>
                {/* //----------second tab----------- */}
                <input
                  data-cy="Assign"
                  type="radio"
                  style={{ display: 'none' }}
                  id="radio-2"
                  name="tabs2"
                  onChange={handleChange}
                  checked={Assigntoggle ? true : false}
                />
                <label className="tab" htmlFor="radio-2">
                  Assign
                </label>
                <span className="glider"></span>
              </div>
            </div>
            {Allshiftstoggle && <AllShiftsList key={routeKey}></AllShiftsList>}
            {Assigntoggle && (
              <BlockBookinglist key={routeKey}></BlockBookinglist>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  const { misc } = state;
  return {
    isrouteKey: misc.isrouteKey,
  };
}
const connectedShiftmenu = connect(mapStateToProps)(Shiftmenu);
export { connectedShiftmenu as Shiftmenu };
