import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';

export const experienceService = {
  experienceAdd,
  experienceRemove,
  experienceList,
  experienceDelete,
  verifyexperience,
};

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}

function logout() {
  localStorage.removeItem('user');
}

function experienceAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addexperience', requestOptions).then(
    handleResponse,
  );
}
function verifyexperience(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/verifyexperience', requestOptions).then(
    handleResponse,
  );
}
function experienceRemove(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/removeexperience', requestOptions).then(
    handleResponse,
  );
}
function experienceList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getexperienceList', requestOptions).then(
    handleResponse,
  );
}

function experienceDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteexperience', requestOptions).then(
    handleResponse,
  );
}
