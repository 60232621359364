export const todoConstant = {
    VALID_PHONE: 'Please enter complete phone number, leading with 0',
    PHONE_REQUIRED: 'Phone is required ',
    EMAIL_REQUIRED:'Email is required',
    INVALID_EMAIL:' Invalid email format. Please enter valid email',
    COMPANY_REQUIRED:'Company Name is required',
    PASSWORD_REQUIRED:'Password is required',
    VERIFY_EMAIL:'Please verify the email',
    PHONE_ALREADY_USED:'Phone number already registered for another user type. Please use an unused contact number',
    EMAIL_ALREADY_USED :'Email already registered as another user type. Use an unused email',
    OTP_EXP_OR_INVALID: 'Sorry. The OTP entered is invalid. Please enter a valid OTP',
    USER_NOT_FOUND:'User not found',
    NOT_AUTHRIZED_USER:'You are not authorised',
    EMAIL_ALREADY_USED_FORGET_PASSWORD:
    'Email already registered as an Employer. Use forget password',
  };