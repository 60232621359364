import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { agencycandidateActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { AgencyCandidateProfile } from './AgencyCandidateProfile';

const AgencyCandidateList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({ rows: [] });
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [candidateRows, setCandidateRows] = useState([]);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  //---csv states -----
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
    searchKey: '',
    status: 1,
  });
  // effect to load candidates
  useEffect(() => {
    getAllCandidate(query.pageVo.pageNo);
  }, [query]);
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // to delet candidate
  const deletecandidate = () => {
    const { dispatch } = props;
    if (isDeleteLoading) {
      return;
    }
    setIsDeleteLoading(true);
    dispatch(
      agencycandidateActions.agencycandidateDelete({
        status: 0,
      }),
    ).then(
      response => {
        console.log(response);
        setdeleteconfirmationmodal(false);
        setIsDeleteLoading(false);
        getAllCandidate(1);
      },
      () => {
        setIsDeleteLoading(false);
      },
    );
  };
  //---------get all candidates list -------------
  const getAllCandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    if (props.isHomepage) {
      queryNew.isverified = 0;
    }
    dispatch(agencycandidateActions.agencycandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
        setCandidateData(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for filters
  const handleChange = event => {
    const { name, value } = event.target;
    setQuery(prevstate => ({
      ...prevstate,
      [name]: value,
    }));
  };
  // handler category select
  const category_change = selectedOption => {
    setQuery(prevState => ({ ...prevState, category: selectedOption.value }));
  };
  //-----------list and pagination data for candidate data --------------
  const setListAndPagination = candidateData => {
    let candidateList = [];
    for (
      let j = 0;
      candidateData && candidateData.rows && j < candidateData.rows.length;
      j = j + 1
    ) {
      candidateList.push(
        <tr key={j}>
          <td>
            {2000 +
              (candidateData &&
                candidateData.rows &&
                candidateData.rows[j]._id &&
                candidateData.rows[j]._id)}{' '}
          </td>
          <td className="username">
            {' '}
            <div className="d-flex">
              {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].profileimage ? (
                <img
                  className=" round-emp-image"
                  src={apiConstants.imgUrl + candidateData.rows[j].profileimage}
                />
              ) : (
                <img
                  className=" round-emp-image"
                  src="/assets/img/recsy-fav-icon.png"
                />
              )}
              <div
                onClick={() => {
                  setSelectedCandidateId(candidateData.rows[j]._id),
                    setshowCandidateprofileModal(true);
                }}
                style={{ color: '#404144', cursor: 'pointer' }}
              >
                {' '}
                {candidateData.rows[j].name}{' '}
              </div>
            </div>
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].Category &&
              candidateData.rows[j].Category.name}
          </td>
          <td>
            <a
              href={
                'https://www.google.com/maps/search/?api=1&query=' +
                candidateData.rows[j].lat +
                ',' +
                candidateData.rows[j].long
              }
            >
              {candidateData &&
                candidateData.rows &&
                candidateData.rows[j].city &&
                candidateData.rows[j].city}
            </a>
            <br />
            {candidateData.rows[j].postcode
              ? candidateData.rows[j].postcode
              : ''}
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].Category &&
              candidateData.rows[j].service_type}
          </td>
          <td>
            {candidateData &&
              candidateData.rows &&
              candidateData.rows[j].createdAt &&
              moment(candidateData.rows[j].createdAt).format('YYYY-MMM-DD')}
          </td>
          <td className="eventCell  eventView">
            <a href={'/agencycandidate/' + candidateData.rows[j]._id}>view</a>
          </td>
        </tr>,
      );
    }
    setCandidateRows(candidateList);
  };
  // custom styles for select box
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      width: '200px',
      lineHeight: '25px',
    }),
  };
  return (
    <div className="CandidateList ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            {!props.isHomepage && (
              <div className="card-header page-header ">
                <div className="row">
                  <div className="col-md-8">
                    <div className="page-title">Agency Candidate</div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 ">
                    <p>
                      <div className="input-outer-div">
                        <input
                          className="search-input-div"
                          value={query.searchKey}
                          onChange={handleChange}
                          type="text"
                          name="searchKey"
                          aria-label="Search"
                          placeholder="Search here..."
                        />
                        <a className="search_bar">
                          <i
                            className="fa fa-search in_bar"
                            aria-hidden="true"
                          ></i>
                        </a>
                      </div>
                    </p>
                  </div>
                  <div className="col-md-2">
                    <select
                      className="select col-md-12 selectdesign39"
                      name="service_type"
                      value={query.service_type}
                      onChange={handleChange}
                      placeholder={'select service sector'}
                    >
                      <option value=""> ALL</option>
                      <option value="Hospitality"> Hospitality</option>
                      <option value="Health Care"> Health Care</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <div className="" style={{ marginRight: '20px' }}>
                      <AsyncSelect
                        styles={customSelectStyles}
                        name="category"
                        onChange={category_change}
                        placeholder="select category"
                        cacheOptions
                        defaultOptions
                        loadOptions={getCategorylist}
                      />
                    </div>
                  </div>
                  {/* //==============filter end ---------------- */}
                  <div className="col-md-4">
                    <a
                      className="btn btn-primary-blue float-right"
                      href="/agencycandidateadd/0"
                    >
                      <span> ADD</span>
                    </a>
                  </div>
                </div>
              </div>
            )}
            <div className="card-body  table-responsive main-card-body ">
              {!isLoading && (
                <table className="table table-hover flx-custom-table">
                  <thead>
                    <tr>
                      <th className="">Id</th>
                      <th
                        style={{ width: '200px' }}
                        onClick={() => handleSort('name')}
                        className="sortHead"
                      >
                        Name{' '}
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th>Jobrole</th>
                      <th>Location</th>
                      <th>Service Sector</th>
                      <th
                        onClick={() => handleSort('createdAt')}
                        className="sortHead"
                      >
                        Created
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'createdAt' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{candidateRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {candidateRows && candidateRows.length == 0 && !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
            </div>
            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllCandidate(data);
                }}
                paginationData={candidateData}
              />
            </div>
          </div>
        </div>
        {deleteconfirmationmodal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-xsm">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="close">
                    <a
                      onClick={() => {
                        setdeleteconfirmationmodal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </a>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="bag">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <p className="delete_string" style={{ color: 'black' }}>
                          Are you sure ?{' '}
                        </p>
                        <div className="d-flex justify-content-center">
                          <button
                            className="button_delete width_100"
                            onClick={() => {
                              setdeleteconfirmationmodal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_close width_100"
                            onClick={() => deletecandidate()}
                          >
                            {!isDeleteLoading ? 'Delete' : 'Deleting...'}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showCandidateprofileModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setshowCandidateprofileModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <AgencyCandidateProfile candidate_id={SelectedCandidateId} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
// loader for category list in select options
const getCategorylist = inputvalue =>
  new Promise((resolve, reject) => {
    console.log(inputvalue, 'this is search key--');
    fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, select: ['_id', 'name'] }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps() {
  return {};
}

const connectedAgencyCandidateList =
  connect(mapStateToProps)(AgencyCandidateList);
export { connectedAgencyCandidateList as AgencyCandidateList };
