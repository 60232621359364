import { combineReducers } from 'redux';

import { agency } from './agency.reducer';
import { alert } from './alert.reducer';
import { authentication } from './authentication.reducer';
import { businessunit } from './businessunit.reducer';
import { candidate } from './candidate.reducer';
import { candidateblock } from './candidateblock.reducer';
import { candidateemployerallocation } from './candidateemployerallocation.reducer';
import { category } from './category.reducer';
import { demo } from './demo.reducer';
import { documents } from './document.reducer';
import { employer } from './employer.reducer';
import { fileupload } from './fileupload.reducer';
import { invoice } from './invoice.reducer';
import { misc } from './misc.reducer';
import { modal } from './modal.reducer';
import { payout } from './payout.reducer';
import { price } from './price.reducer';
import { registration } from './registration.reducer';
import { sample } from './sample.reducer';
import { section } from './section.reducer';
import { shifts } from './shifts.reducer';
import { staff } from './staff.reducer';
import { user } from './users.reducer';
import { vacancyshift } from './vacancyshift.reducer';

let reducers = {
  authentication,
  registration,
  user,
  alert,
  misc,
  demo,
  modal,
  sample,
  fileupload,
  employer,
  candidate,
  documents,
  shifts,
  invoice,
  staff,
  category,
  vacancyshift,
  businessunit,
  section,
  candidateemployerallocation,
  payout,
  agency,
  price,
  candidateblock,
};

// function capitalize(string) {
//   string = string.toLowerCase();
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

const rootReducer = combineReducers(reducers);

export default rootReducer;
