import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { agencycandidateActions } from '../_actions';
import { LoadingModal } from '../_components';
import { apiConstants, reducerConstants } from '../_constants';

const AgencyCandidate = props => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [agencycandidate, setAgencycandidate] = useState({
    name: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });
  useEffect(() => {
    if (id && id !== 0 && id !== '0') {
      query._id = id;
      setQuery(query);
      getAllAgencycandidate(1, query);
    }
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      console.log(user);
    }
  }, []);
  /**
   * Get all agency candidates
   * */
  const getAllAgencycandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(agencycandidateActions.agencycandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setAgencycandidate(res.data.rows[0]);
          setAgencycandidate(prevState => ({
            ...prevState,
            // key_contact: JSON.parse(res.data.rows[0].key_contact),
          }));
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  return (
    <div className="Employer">
      {(props.requestStatus === reducerConstants.AGENCYCANDIDATE_REQUEST ||
        isLoading) && <LoadingModal />}
      <div className="col-md-12 employer_card_color ">
        <div className="card bgcolorth m-5">
          <div className="card-body">
            <div className="m-3">
              <div className="row d-flex justify-content-between ">
                <div className="col-md-6">
                  <div className="row d-flex align-items-center ">
                    <div className="col-md-2">
                      <div className="employer-image">
                        {agencycandidate && agencycandidate.profileimage ? (
                          <img
                            className=" round-emp-image-single"
                            src={
                              apiConstants.imgUrl + agencycandidate.profileimage
                            }
                          />
                        ) : (
                          <img
                            className="round-emp-image-single"
                            src="/assets/img/recsy-fav-icon.png"
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <p className="bold f18">{agencycandidate.name} </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row d-flex justify-content-end">
                    <div className="col-md-4  ">
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-phone green_border"></i>
                        <p className="emp-phone">
                          {agencycandidate &&
                            agencycandidate.contact_number &&
                            agencycandidate.contact_number}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row  d-flex align-items-center">
                        <i className="fas fa-envelope  red_border"></i>
                        <p className="emp-phone">
                          {agencycandidate &&
                            agencycandidate.email &&
                            agencycandidate.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {
              <div className=" col text_align">
                <Link
                  className="link"
                  to={'/agencycandidateadd/' + agencycandidate._id}
                >
                  <i className="fas fa-pen pointer f18"></i>
                </Link>
              </div>
            }
            <div className="col-md-12">
              <div className="row">
                {/* //-----------agencycandidate details section -------------------------- */}
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Name</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col-6">
                      <p>{agencycandidate.name} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Address</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>
                        {agencycandidate.address && agencycandidate.address}
                      </p>
                      <p style={{ lineHeight: '5px' }}>
                        {agencycandidate.location && agencycandidate.location}
                      </p>
                      <p>
                        &nbsp;
                        {agencycandidate.postcode && agencycandidate.postcode}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Jobrole</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>
                        {agencycandidate.Category &&
                          agencycandidate.Category.name &&
                          agencycandidate.Category.name}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Experience</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>
                        {agencycandidate.experience &&
                          agencycandidate.experience}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>City</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{agencycandidate.city} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Postcode</label>
                    </div>
                    <div className="">
                      <label>:</label>
                    </div>
                    <div className="col">
                      <p>{agencycandidate.postcode} </p>
                    </div>
                  </div>
                  {agencycandidate.lat && agencycandidate.long && (
                    <div className="row">
                      <div className="col-md-3 col-sm-6">
                        <label>Location</label>
                      </div>
                      <div className="">
                        <label>:</label>
                      </div>
                      <div className="col">
                        <a
                          href={
                            'https://www.google.com/maps/search/?api=1&query=' +
                            agencycandidate.lat +
                            ',' +
                            agencycandidate.long
                          }
                        >
                          Open location
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  return {};
}

const connectedAgencyCandidate = connect(mapStateToProps)(AgencyCandidate);
export { connectedAgencyCandidate as AgencyCandidate };
