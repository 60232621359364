import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { shiftsActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//   faCircleCheck,
//   faCalendar,
//   faBars,
// } from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line no-unused-vars
let employerRows = [];

const VerifyShift = props => {
  const [isLoading, setIsLoading] = useState(false);
  // const [AttendanceLoading, setAttendanceLoading] = useState(false);
  const [ShiftsData, setShiftsData] = useState({ rows: [] });
  const [employerRows, setEmployerRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
    status: 1,
  });

  useEffect(() => {
    if (props.VerifyShiftCallData) {
      console.log(
        props.VerifyShiftCallData,
        '->>>>>>>>>>>>>verfiyshjfy data----------',
      );
      getAllShifts(1, props.VerifyShiftCallData);
    }
    if (props.shiftId) {
      // setshiftId(props.shiftId);
      setQuery({ ...query, _id: props.shiftId });
    }
  }, []);

  /**
   * Get all shift
   **/
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo
      ? (queryNew.pageVo.pageNo = pageNo)
      : (queryNew.pageVo = { pageNo: 1, noOfItems: 10 }),
      setIsLoading(true);
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        queryNew.employer_id = user.employer_id;
      }
    }
    queryNew.is_verified = 0;
    if (props.VerifyShiftCallData) {
      queryNew.candidate_id = props.VerifyShiftCallData.candidate_id;
    }
    if (props.from_date) {
      queryNew.from_date = props.from_date;
      var nextMonthDate = moment(queryNew.from_date, 'YYYY-MM-DD')
        .add(1, 'months')
        .format('YYYY/MM/DD');
      queryNew.end_date = nextMonthDate;
    }
    queryNew.status = 1;
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        setIsLoading(false);
        for (let i = 0; i < res.data.rows.length; i++) {
          res.data.rows[i].is_verify_shift = true;
        }
        setShiftsData({ rows: res.data.rows });
        setListAndPagination(res.data);
      },
      err => {
        console.log(err);
        setIsLoading(false);
      },
    );
  };

  const handleChange = (event, j, shiftsdataa) => {
    const { name, value, checked } = event.target;
    if (name == 'check_in_time_string') {
      shiftsdataa.rows[j].check_in_time_string = value;
    }
    if (name == 'check_out_time_string') {
      shiftsdataa.rows[j].check_out_time_string = value;
    }
    if (name == 'is_verify_shift') {
      shiftsdataa.rows[j].is_verify_shift = !!checked;
    }
    setShiftsData(shiftsdataa);
    setListAndPagination(shiftsdataa);
  };

  const allshiftsverify = candidate_id => {
    console.log(candidate_id);
    // let from_date = '';
    let shift_Ids = [];
    for (let s = 0; s < ShiftsData.rows.length; s++) {
      if (ShiftsData.rows[s].is_verify_shift) {
        shift_Ids.push(ShiftsData.rows[s]._id);
      }
    }
    const { dispatch } = props;
    dispatch(
      shiftsActions.updateShift({
        candidate_id: props.VerifyShiftCallData.candidate_id,
        is_verified: 1,
        shift_Ids: shift_Ids,
        allshiftsverify: true,
      }),
    ).then(
      res => {
        console.log(res);
        props.closeVerifyShiftModal(false);
        setIsLoading(false);
        getAllShifts();
      },
      err => {
        console.log(err);
        setIsLoading(false);
      },
    );
  };

  const setListAndPagination = ShiftsData => {
    console.log(ShiftsData, 'res584');
    let employerList = [];
    for (
      let j = 0;
      ShiftsData && ShiftsData.rows && j < ShiftsData.rows.length;
      //    j < 10;
      j = j + 1
    ) {
      employerList.push(
        <tr key={j}>
          <td>{ShiftsData.rows[j].check_in_date_string}</td>
          <td>
            <img className="staff_img" src="/assets/img/candidate.jpg"></img>
            {ShiftsData.rows[j]['Candidate.name']}
          </td>
          <td>
            <div>{ShiftsData.rows[j].section_type}</div>
            <div style={{ fontSize: '10px' }}>
              {ShiftsData.rows[j].check_in_time_string} -{' '}
              {ShiftsData.rows[j].check_out_time_string}
            </div>
          </td>
          <td>
            <input
              type="time"
              className="form-control"
              name="check_in_time_string"
              placeholder="from time"
              style={{ height: '35px' }}
              value={ShiftsData.rows[j].check_in_time_string}
              onChange={e => handleChange(e, j, ShiftsData)}
              disabled={ShiftsData.rows[j].is_verified}
            />
            <div style={{ fontSize: '9px', color: '#009933', height: '9px' }}>
              {ShiftsData.rows[j].is_scanned_check_in == 1 && (
                <>Mobile scanned</>
              )}
            </div>
          </td>
          <td>
            <input
              type="time"
              className="form-control"
              style={{ height: '35px' }}
              name="check_out_time_string"
              placeholder="from time"
              value={ShiftsData.rows[j].check_out_time_string}
              onChange={e => handleChange(e, j, ShiftsData)}
              disabled={ShiftsData.rows[j].is_verified}
            />
            <div style={{ fontSize: '9px', color: '#009933', height: '9px' }}>
              {ShiftsData.rows[j].is_scanned_check_out == 1 && (
                <>Mobile scanned</>
              )}
            </div>
          </td>
          <td>
            {ShiftsData.rows[j].is_verified == 0 ? (
              <input
                type="checkbox"
                className="form-control"
                style={{ height: '15px' }}
                name="is_verify_shift"
                checked={ShiftsData.rows[j].is_verify_shift}
                onChange={e => handleChange(e, j, ShiftsData)}
              />
            ) : (
              // <FontAwesomeIcon
              //   className="pr-1"
              //   icon={faCircleCheck}
              //   color="#2DA463"
              // />
              <p>xx</p>
            )}
          </td>
        </tr>,
      );
    }
    setEmployerRows(employerList);
    setShiftsData(ShiftsData);
  };

  return (
    <div className="employerList ">
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="card regular-table-with-color">
            <div className="card-header page-header ">
              <div className="row">
                <div className="col-md-8">
                  <div className="page-title">
                    <div className="font-weight-bold">Verify Shifts </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body  table-responsive main-card-body ">
              {!isLoading && (
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-top1">Date</th>
                      <th>Name</th>
                      <th>Section</th>
                      <th>Check in time</th>
                      <th>Check out time</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>{employerRows}</tbody>
                </table>
              )}
              {!!isLoading && <LoadingImg />}
              {ShiftsData &&
                ShiftsData.rows &&
                ShiftsData.rows.length === 0 && (
                  <p className="margin-top-20 text-center"> No result found </p>
                )}
            </div>
            {ShiftsData && ShiftsData.rows && ShiftsData.rows.length > 0 && (
              <div className="row">
                <div className=" col-md-12 d-flex justify-content-end">
                  <button
                    className="btn btn-primary-blue "
                    onClick={allshiftsverify}
                  >
                    Verify
                  </button>
                </div>
              </div>
            )}
            <span style={{ color: '#808080', fontSize: '9px', height: '15px' }}>
              {' '}
              * max 10 items are displayed{' '}
            </span>
            <span style={{ color: '#808080', fontSize: '9px' }}>
              {' '}
              * Each page is verified seperately
            </span>

            <div className="card-footer">
              <Pagination
                pageOnClick={data => {
                  getAllShifts(data);
                }}
                paginationData={ShiftsData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps() {
  // const { requestSt } = state.employer;
  return {
    // requestStatus,
    // ShiftsData
  };
}

const connectedVerifyShift = connect(mapStateToProps)(VerifyShift);
export { connectedVerifyShift as VerifyShift };
