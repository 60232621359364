import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const agencyService = {
  agencyAdd,
  agencyList,
  agencyListv2,
  agencyDelete,
  dashbordagencyreport,
  subscribedagencylist,
};
function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function agencyAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/addagency', requestOptions).then(
    handleResponse,
  );
}
function agencyList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getagencyList', requestOptions).then(
    handleResponse,
  );
}

function dashbordagencyreport(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/dashbordagencyreport',
    requestOptions,
  ).then(handleResponse);
}

function agencyListv2(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getagencyListv2', requestOptions).then(
    handleResponse,
  );
}
function subscribedagencylist(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/subscribedagencylist',
    requestOptions,
  ).then(handleResponse);
}
function agencyDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/deleteagency', requestOptions).then(
    handleResponse,
  );
}
