import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ResetPassword } from '../AuthPage';
import { userActions } from '../_actions';
import { apiConstants } from '../_constants';
// export default createBrowserHistory();
// const historyNew = createHistory();
const MainMenu = props => {
  // let navigate = useNavigate();
  const [state, setState] = useState({
    isSidebarshow: true,
    height: window.innerHeight + 'px',
    isOpenProfile: false,
  });
  const [showmodal, setshowmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [isemployer, setisemployer] = useState(false);
  const [isAgency, setisAgency] = useState(false);
  const [isBusinessUnit, setisBusinessunit] = useState(false);
  const [profile, setprofile] = useState({
    // name: '',
    // email: '',
    // phone: '',
    // // secondary_number:'',
    // about: '',
  });
  // const openMenu = () => {
  //   const { dispatch, isMenuOpen } = props;
  //   dispatch({
  //     type: 'isMenuOpen',
  //     data: !isMenuOpen,
  //   });
  // };
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });

  console.log(props.isMenuOpen, 'pp584', state.isOpenProfile);
  // setState({ isOpenProfile: props.isMenuOpen });

  useEffect(() => {
    console.log(isemployer, 'isemployer'); //TODO remove ismployer
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user.role == 1) {
        setisAdmin(true);
      }
      if (user.role == 5) {
        setisAgency(true);
      }
      if (user.role == 4) {
        setisemployer(true);
      }
      if (user.role == 6) {
        setisStaff(true);
      }
      if (user.role == 7) {
        setisBusinessunit(true);
      }
      getAllUser(1);

      console.log('idddddd', user);
      query._id = user._id;
      query.employer_id = user.employer_id;
      query.role = user.role;
    }
    console.log('enter useffect main menu', props.isMenuOpen);
    setState({ isOpenProfile: true });
  }, [state.isMenuOpen]);

  const getAllUser = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;

    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      console.log(user, 'foopa');
      if (user.role != 3) {
        setprofile(user);
      }
    }

    setQuery(query);
    // setIsLoading(true);
    // const { dispatch } = props;
    // dispatch(userActions.userList(queryNew)).then(
    //   (res) => {
    //     setIsLoading(false);
    //     // console.log("responseee------", res.data[0][0])
    //     if (res && res.data.rows && res.data.rows.length > 0) {
    //       // if (res && res.data[0] && res.data[0][0]) {

    //       console.log("enter to sggf",res.data.rows)
    //       setprofile(res.data.rows[0]);
    //       // setprofile(res.data[0][0]);

    //     }
    //   },
    //   () => {
    //     setIsLoading(false);
    //   }
    // );
  };
  console.log('profileeee', profile);

  const closeMenu = () => {
    const { dispatch } = props;
    dispatch({
      type: 'isMenuOpen',
      data: !props.isMenuOpen,
    });
  };

  const logout = () => {
    console.log('enter logout fun');
    const { dispatch } = props;
    let res = dispatch(userActions.logout());
    console.log(res, 'logout respo');
    window.location.href = '/login';

    // navigate('/login');
  };

  console.log(props.isMenuOpen, 'sidebarvalu2');

  return (
    <div>
      {Object.keys(profile).length !== 0 && (
        <nav
          className="navbar navbar-light "
          style={{
            background: ' #ffffff',
            padding: '10px',
            position: 'static',
          }}
        >
          <div className="container-fluid">
            <a className="navbar-brand" href="#" style={{ color: 'white' }}>
              <img
                src="/assets/img/recsy-temp-logo.svg"
                className="d-inline-block align-top logo-img"
                alt=""
              ></img>
              {/* Flexi Recs */}
            </a>
            <form className="form-inline">
              <div className="form-inline">
                <div style={{ marginTop: '8px', marginRight: '10px' }}>
                  <div
                    className="btn-permanent"
                    onClick={() => {
                      window.open(apiConstants.permurl, '_blank');
                    }}
                  >
                    Go to Permanent
                  </div>
                </div>
                <div
                  style={{
                    marginTop: '8px',
                    marginRight: '5px',
                    textTransform: 'capitalize',
                    color: '#47546D',
                    fontFamily: 'Manrope',
                    fontWeight: '600',
                  }}
                >
                  {profile.name}
                </div>

                <a
                  href={
                    query.role == 4
                      ? '/employer/' + query.employer_id
                      : 'profiledetails/' + query._id
                  }
                >
                  {profile && profile.profileimage ? (
                    <img
                      className=" "
                      src={apiConstants.imgUrl + profile.profileimage}
                      style={{
                        height: '25px',
                        marginRight: '30px',
                        marginTop: '10px',
                      }}
                    ></img>
                  ) : (
                    <img
                      src="/assets/img/recsy-fav-icon.png"
                      style={{
                        height: '26px',
                        marginRight: '30px',
                        marginTop: '10px',
                        borderRadius: '15px',
                      }}
                    ></img>
                  )}
                </a>
              </div>

              <ul className=" navbar-styled navbar-nav flex-right">
                <li
                  className={
                    'nav-item dropdown  menu-ddown navbar-styled '
                    // (props.isMenuOpen ? 'show' : ' ')
                  }
                  data-cy="settingtab"
                  onClick={() => {
                    // setState({ isOpenProfile: !props.isMenuOpen });
                    closeMenu();
                  }}
                >
                  <div
                    className="nav-link dropdown-toggle card-body2 pointer"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="flx-menu-settings fa fa-cog"></i>
                  </div>
                  <div
                    className={
                      'dropdown-menu dropdown-menu-right menu-ddown-content item_align' +
                      (!props.isMenuOpen ? 'show' : ' hide')
                    }
                  >
                    <p
                      data-cy="changepassword"
                      className="text_black"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setshowmodal(true);
                      }}
                    >
                      Change Password
                    </p>
                    {/* {<Link to={"/forgotpassword"}>
                    <p className="text_black">Forgot Password</p>
                  </Link>} */}
                    {!isAdmin && !isStaff && !isBusinessUnit && !isAgency && (
                      <Link to={'/settings/'} data-cy="settings">
                        <p className="text_black">Settings</p>
                      </Link>
                    )}
                    {(isAdmin || isStaff) && (
                      <Link to={'/businessunit'} data-cy="bunitt">
                        <p className="text_black">Business unit</p>
                      </Link>
                    )}

                    {(isAdmin || isStaff) && (
                      <Link to={'/blockcandidate'} data-cy="rejected">
                        <p className="text_black">Rejected</p>
                      </Link>
                    )}

                    {(isAdmin || isStaff) && (
                      <Link to="/categorylist" data-cy="category">
                        <p className="text_black"> Jobrole</p>
                      </Link>
                    )}
                    <Link
                      data-cy="viewprofile"
                      to={'/profiledetails/' + query._id}
                    >
                      <p className="text_black">View Profile</p>
                    </Link>
                    {(isAdmin || isStaff) && (
                      <Link to="/loglist" data-cy="loglist">
                        <p className="text_black"> Log </p>
                      </Link>
                    )}
                    {(isAdmin || isStaff) && (
                      <Link to="/referallist" data-cy="referal">
                        <p className="text_black"> Referals </p>
                      </Link>
                    )}
                    <Link
                      to="#"
                      // className="dropdown-item text-danger"
                      onClick={() => {
                        logout();
                      }}
                    >
                      <p className="text_black">Logout</p>
                    </Link>
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </nav>
      )}

      {showmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setshowmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  {/* <h4 className="card-title">Add invoice </h4> */}
                  <ResetPassword
                    _id={query._id}
                    showmodal={showmodal}
                    setshowmodal={setshowmodal}
                  ></ResetPassword>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  const { alert, misc } = state;
  return {
    alert,
    isMenuOpen: misc.isMenuOpen,
  };
}

const connectedMainMenu = connect(mapStateToProps)(MainMenu);
export { connectedMainMenu as MainMenu };
