import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { demoActions } from '../_actions';
import { LoadingModal } from '../_components';
import { reducerConstants } from '../_constants';
const Demo = props => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [demo, setDemo] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 2,
    },
  });

  useEffect(() => {
    if (id !== '0') {
      query._id = id;
      setQuery(query);
      getAllDemo(1, query);
    }
  }, []);

  /**
   * Get all demos
   * */
  const getAllDemo = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(demoActions.demoList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setDemo(res.data.rows[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <div className="Demo">
      {(props.requestStatus === reducerConstants.DEMO_REQUEST || isLoading) && (
        <LoadingModal />
      )}
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="page-titile-content">
              <h4 className="card-title">Demo view</h4>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Sort Description </label>
              </div>
              <div className="col-md-6">
                <b>{demo.description}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label className="float-right">Demo </label>
              </div>
              <div className="col-md-6">
                <b>{demo.name}</b>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <Link className="link" to={'/demoadd/' + demo._id}>
              Edit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  // const { results } = state.test

  const { requestStatus, demoData } = state.demo;
  return {
    requestStatus,
    demoData,
  };
}

const connectedDemo = connect(mapStateToProps)(Demo);
export { connectedDemo as Demo };
