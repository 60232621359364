import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
export const agencycandidateService = {
  agencycandidateAdd,
  agencycandidateList,
  agencycandidateDelete,
};
function handleResponse(response) {
  console.log(response, 'han78');
  return response.text().then(text => {
    console.log(text, '85txt');
    const data = text && JSON.parse(text);
    console.log('texttttttt', text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    console.log(data, '99hres');
    return data;
  });
}
function logout() {
  localStorage.removeItem('user');
}
function agencycandidateAdd(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/addagencycandidate',
    requestOptions,
  ).then(handleResponse);
}

function agencycandidateList(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/getagencycandidateList',
    requestOptions,
  ).then(handleResponse);
}

function agencycandidateDelete(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/deleteagencycandidate',
    requestOptions,
  ).then(handleResponse);
}
