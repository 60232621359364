import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { blockcandidateActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import { CandidateblockAdd } from './CandidateblockAdd';

const CandidateblockList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [blockcandidateData, setBlockcandidateData] = useState({
    rows: [],
  });
  const [blockcandidateRows, setBlockcandidateRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [showmodal, setshowmodal] = useState(false);
  const [unblockconfirmationmodal, setunblockconfirmationmodal] =
    useState(false);
  const [blockcandidateid, setblockcandidateid] = useState([]);
  const showsavemodal = value => {
    setshowmodal(value);
    getAllBlockcandidate(query.pageVo.pageNo);
  };
  // effect to get call block candidate
  useEffect(() => {
    getAllBlockcandidate(query.pageVo.pageNo);
  }, [query]);
  // handler for search
  const handleChange = event => {
    const { name, value } = event.target;
    //-----------search query--------
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
      getAllBlockcandidate(query.pageVo.pageNo);
    }
  };
  // handler for select carehome
  const selectedcarehome = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({ ...prevState, [name]: selectedOption.value }));
  };
  /**
   * Get all allocatecandidates
   **/
  const getAllBlockcandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(blockcandidateActions.blockcandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for sort the list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // to unblocck candidate form blocked list
  const unblockblockcandidate = () => {
    const { dispatch } = props;
    dispatch(blockcandidateActions.unblockCandidate(blockcandidateid));
    setunblockconfirmationmodal(false);
    getAllBlockcandidate(query.pageVo.pageNo);
  };
  // to split date from UTC format
  const datesplitter = datetime => {
    let date_splitted = datetime.split('T');
    return date_splitted[0];
  };
  // list and pagination for block list
  const setListAndPagination = blockcandidateData => {
    console.log(blockcandidateData);
    let blockcandidateList = [];
    for (
      let j = 0;
      blockcandidateData &&
      blockcandidateData.rows &&
      j < blockcandidateData.rows.length;
      j = j + 1
    ) {
      blockcandidateList.push(
        <tr key={j}>
          <td>CNA0{blockcandidateData.rows[j]._id}</td>
          <td>
            <div className="d-flex align-items-center pointer">
              {blockcandidateData &&
              blockcandidateData.rows &&
              blockcandidateData.rows[j]['Candidate.profileimage'] ? (
                <img
                  className="staff_img"
                  src={
                    apiConstants.imgUrl +
                    blockcandidateData.rows[j]['Candidate.profileimage']
                  }
                />
              ) : (
                <img className="staff_img" src="/assets/img/un-avatar.png" />
              )}
              <div
                onClick={() => {
                  setSelectedCandidateId(
                    blockcandidateData.rows[j]['Candidate._id'],
                  ),
                    setshowCandidateprofileModal(true);
                }}
                style={{ color: '#000', textDecoration: 'none' }}
                className="username"
              >
                {blockcandidateData.rows[j]['Candidate.name'] +
                  ' ' +
                  blockcandidateData.rows[j]['Candidate.last_name']}
              </div>
            </div>
          </td>
          <td style={{ textTransform: 'capitalize' }}>
            {blockcandidateData.rows[j]['Candidate.Category.name'] &&
              blockcandidateData.rows[j]['Candidate.Category.name']}
          </td>
          <td>{datesplitter(blockcandidateData.rows[j].updatedtime)}</td>
          <td>{blockcandidateData.rows[j]['Employer.company_name']} </td>
          <td className="" style={{ width: '250px' }}>
            <div className="d-flex justify-content-between">
              <div className=" eventCell eventView">
                <a
                  data-cy="viewrejectedcandidate"
                  style={{ textDecoration: 'none' }}
                  href={'/candidate/' + blockcandidateData.rows[j].candidate_id}
                >
                  view
                </a>
              </div>
              <div>
                <a
                  data-cy="unblockcandidate"
                  className="notpaid_div cursorPoint"
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => {
                    unblockblockcandidatemodal(blockcandidateData.rows[j]._id);
                  }}
                >
                  Unblock
                </a>
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    setBlockcandidateRows(blockcandidateList);
    setBlockcandidateData(blockcandidateData);
  };
  // ublock modal open for confirmation
  const unblockblockcandidatemodal = id => {
    setunblockconfirmationmodal(true);
    setblockcandidateid({ _id: id });
  };
  // custom style for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      lineHeight: '25px',
    }),
  };
  return (
    <div className="AllocatecandidateList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row mt-20">
              <div className="col-md-11">
                <h4 className="font-weight-bold"> Employer Rejected</h4>
              </div>
              <div className="col-md-1">
                <div
                  data-cy="rejectmodal"
                  className="btn btn-primary-blue float-right"
                  onClick={() => setshowmodal(true)}
                >
                  <span>Reject</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row pd-20">
            <div className="col-md-7 d-flex  ">
              <div className="col-md-5">
                <div className="">
                  <div>
                    <AsyncSelect
                      styles={customStyles}
                      className="employerselect"
                      name="employer_id"
                      onChange={selectedcarehome}
                      placeholder={'select carehome'}
                      cacheOptions
                      defaultOptions
                      loadOptions={getcarehomelist}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-md-4 input-outer-div">
              <input
                className="search-input-div"
                value={query.searchKey}
                onChange={handleChange}
                type="text"
                name="searchKey"
                aria-label="Search"
                placeholder="Search here..."
              />
              <a className="search_bar">
                <i className="fa fa-search in_bar" aria-hidden="true"></i>
              </a>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('candidate_name')}
                      className="sortHead"
                    >
                      User{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Jobrole</th>
                    <th
                      onClick={() => handleSort('updatedtime')}
                      className="sortHead"
                    >
                      date{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'updatedtime' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'updatedtime' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Carehome</th>
                    <th className="eventCell  eventView">Actions</th>
                  </tr>
                </thead>
                <tbody>{blockcandidateRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingImg />}
            {blockcandidateData &&
              blockcandidateData.rows &&
              blockcandidateData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllBlockcandidate(data);
              }}
              paginationData={blockcandidateData}
            />
          </div>
        </div>
      </div>
      {showmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog  modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bottom-0">
                <div className="close">
                  <a onClick={() => setshowmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <CandidateblockAdd
                    showsavemodal={showsavemodal}
                  ></CandidateblockAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {unblockconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setunblockconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setunblockconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          data-cy="unblockthecandidate"
                          className="button_delete_close width_100"
                          onClick={() => unblockblockcandidate()}
                        >
                          Unblock
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// loader for get all carehome
const getcarehomelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus } = state.candidateblock;
  return {
    requestStatus,
  };
}

const connectedCandidateblockList =
  connect(mapStateToProps)(CandidateblockList);
export { connectedCandidateblockList as CandidateblockList };
