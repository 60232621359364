import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { businessunitActions } from '../_actions';
import { LoadingModal } from '../_components';
import { alertConstants, apiConstants, reducerConstants } from '../_constants';
import { authHeader } from '../_helpers';

const BusinessunitAdd = props => {
  let navigate = useNavigate();
  const { id } = useParams();
  const [businessunit, setBusinessunit] = useState({
    name: '',
    description: '',
  });
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [submitted, setSubmitted] = useState(false);
  const [isadmin, setisadmin] = useState(false);
  const [isEditemployer, setisEditemployer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [PostcodeObject, setPostcodeObject] = useState({});
  const [error, setError] = useState({ common: '' });
  // load get business unit details by id
  useEffect(() => {
    //----to get employer id from local storage---
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user && user.employer_id) {
        setBusinessunit(prevstate => ({
          ...prevstate,
          employer_id: user.employer_id,
        }));
      }
      if (user && user.role == 1) {
        setisadmin(true);
      }
    }
    if (props && props.employer_id) {
      businessunit.employer_id = props.employer_id;
    }
    if (id !== '0' && !props.isAdd && id != undefined) {
      query._id = id;
      setQuery(query);
      getAllBusinessunit(1, query);
    }
    /**
     * Edit data using  child component property
     */
    if (
      props.selectedId &&
      props.selectedId !== 0 &&
      props.selectedId !== '0'
    ) {
      query._id = props.selectedId;
      setQuery(query);
      getAllBusinessunit(1, query);
    }
  }, []);
  // load address in location by postcode
  useEffect(() => {
    if (businessunit._id) {
      loadaddress();
    }
  }, [businessunit.postcode]);
  // load address by postcode
  const loadaddress = async () => {
    console.log('postcodeiiii', businessunit.postcode);
    const res = businessunit.postcode.replace(/ /g, '');
    if (valid_postcode(res)) {
      let posstcodeurl = `https://api.getaddress.io/find/${res}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        // results geometry location
        let addresses_rows = [];
        let regex = /\s+,/g;
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, ' ')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
      }
    }
  };
  // hide error
  const hideError = () => {
    setTimeout(() => {
      setError({ common: '' });
    }, 10000);
  };
  /**
   * Get all businessunits
   * */
  const getAllBusinessunit = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(businessunitActions.businessunitList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (res && res.data.rows && res.data.rows.length > 0) {
          setBusinessunit(res.data.rows[0]);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // event handler to set details
  const handleChange = event => {
    const { name, value } = event.target;
    setBusinessunit({
      ...businessunit,
      [name]: value,
    });
  };
  // postcode change handler
  const handleChangepostcode = async event => {
    event.preventDefault();
    let { name, value } = event.target;
    let post_code = value.replace(/ /g, '');
    setBusinessunit({
      ...businessunit,
      [name]: post_code,
    });
    setPostcodeObject({
      isValied: false,
      addresses: [],
    });
    if (valid_postcode(value)) {
      let posstcodeurl = `https://api.getaddress.io/find/${value}?api-key=${apiConstants.postal_key}`;
      let response = await fetch(posstcodeurl);
      response = await response.json();
      if (response && response.latitude && response.longitude) {
        let newarray = response.addresses[0].split(',');
        let regex = /\s+,/g;
        let addresses_rows = [];
        for (let s = 0; s < response.addresses.length; s++) {
          addresses_rows.push(
            <option>{response.addresses[s].replace(regex, '')}</option>,
          );
        }
        setPostcodeObject({
          isValied: true,
          addresses: addresses_rows,
        });
        setBusinessunit({
          ...businessunit,
          lat: response.latitude,
          long: response.longitude,
          county: newarray[6],
          city: newarray[5],
          postcode: post_code,
        });
      }
    } else if (businessunit._id) {
      setBusinessunit({
        ...businessunit,
        [name]: value,
      });
    }
  };
  // ---check valid postcode or not---------
  const valid_postcode = str_postcode => {
    let postcode = str_postcode.toUpperCase();
    postcode = postcode.toString().replace(/\s/g, '');
    var regex =
      /^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
    return regex.test(postcode);
  };
  // submit action for add business unit
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitted(true);
    const { dispatch } = props;
    if (
      businessunit.name &&
      businessunit.postcode &&
      businessunit.address &&
      businessunit.email &&
      businessunit.employer_id
    ) {
      if (!businessunit?._id || !id) {
        if (!businessunit.password) {
          setError({ common: 'password is required' });
        }
      } else {
        delete businessunit.password;
      }

      let postcode = valid_postcode(businessunit.postcode);
      if (postcode == false) {
        setError({ common: 'postcode not valid' });
        return;
      }
      setIsLoading(true);
      dispatch(businessunitActions.businessunitAdd(businessunit)).then(
        () => {
          setIsLoading(false);
          if (props.setbunitaddModal) {
            props.setbunitaddModal(false);
          } else if (props.closeBusinessunitAddModal) {
            props.closeBusinessunitAddModal(false);
          } else {
            if (isadmin) {
              navigate('/businessunit');
            } else {
              navigate('/businessunitlist');
            }
          }
        },
        err => {
          console.log(err);
          setError({
            common: err ? err : alertConstants.SERVER_ERROR,
          });
          hideError();
          setIsLoading(false);
        },
      );
    } else {
      setError({
        common: 'please complete the form',
      });
    }
  };
  const selectedemployer = (selectedOption, event) => {
    let name = event.name;
    setBusinessunit(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
    }));
  };
  //----------get employerlist ----------
  const getEmployerList = inputValue =>
    new Promise((resolve, reject) => {
      fetch(apiConstants.apiUrl + '/getemployerlistv2', {
        method: 'post',
        headers: authHeader(),
        body: JSON.stringify({
          searchKey: inputValue,
          status: 1,
          active_status: 1,
          // parent_id: parent_id,
        }),
      })
        .then(response =>
          response.json().then(({ data }) => {
            resolve(
              data.rows.map(({ _id, company_name, city, break_time }) => ({
                value: _id,
                label: company_name,
                city: city,
                break_time: break_time,
              })),
            );
          }),
        )
        .catch(reject);
    });

  return (
    <div className="BusinessunitAdd d-flex justify-content-center">
      <div className="col-md-10 col-md-offset-2">
        {isLoading && <LoadingModal />}
        <div className="card">
          <div className=" page-header ">
            <div className="page-title-content">
              <p className="card-title">
                {!businessunit?._id || !id
                  ? 'Add Business unit'
                  : 'Edit Business unit'}
              </p>
            </div>
          </div>
          <div className="card-body">
            <form name="form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !businessunit.name ? ' has-error' : '')
                    }
                  >
                    <input
                      type="name"
                      className="form-control"
                      name="name"
                      placeholder="Name"
                      value={
                        businessunit && businessunit.name && businessunit.name
                      }
                      onChange={handleChange}
                    />
                    {submitted && !businessunit.name && (
                      <div className="help-block">Name is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !businessunit.email ? ' has-error' : '')
                    }
                  >
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email"
                      value={
                        businessunit && businessunit.email && businessunit.email
                      }
                      onChange={handleChange}
                    />
                    {submitted && !businessunit.email && (
                      <div className="help-block">Email is required</div>
                    )}
                  </div>
                </div>
              </div>
              {isadmin && (
                <div className="row">
                  <div className="col-md-12 pr-1">
                    <div className={'form-group '}>
                      <div
                        className={
                          'form-group ' +
                          (submitted && !businessunit.employer_id
                            ? ' has-error'
                            : '')
                        }
                      >
                        {businessunit._id && isEditemployer && (
                          <AsyncSelect
                            className="employerselect"
                            name="employer_id"
                            onChange={selectedemployer}
                            defaultInputValue={
                              businessunit &&
                              businessunit['Employer.company_name'] &&
                              businessunit['Employer.company_name']
                            }
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerList}
                          />
                        )}
                        {!businessunit._id && !isEditemployer && (
                          <AsyncSelect
                            className="employerselect"
                            name="employer_id"
                            onChange={selectedemployer}
                            placeholder="Select Employer"
                            defaultInputValue={
                              businessunit &&
                              businessunit['Employer.company_name'] &&
                              businessunit['Employer.company_name']
                            }
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerList}
                          />
                        )}
                        {businessunit._id && !isEditemployer && (
                          <div className="col">
                            <div className="row">
                              <div className=" col-md-11 select_box1">
                                {' '}
                                {businessunit['Employer.company_name']}
                              </div>
                              <div
                                className="edit_text"
                                onClick={() => {
                                  setisEditemployer(true);
                                }}
                              >
                                Edit
                              </div>
                            </div>
                          </div>
                        )}
                        {submitted && !businessunit.employer_id && (
                          <div className="help-block">Employer is required</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {/* //password---------------- */}
                <div className="col-md-12 pr-1">
                  <div className={'form-group '}>
                    <div
                      className={
                        'form-group ' +
                        (submitted &&
                        !businessunit.password &&
                        (!businessunit?._id || !id)
                          ? ' has-error'
                          : '')
                      }
                    >
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        value={businessunit.password}
                        onChange={handleChange}
                      />
                      {submitted &&
                        !businessunit.password &&
                        (!businessunit?._id || !id) && (
                          <div className="help-block">Password is required</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !businessunit.postcode ? ' has-error' : '')
                    }
                  >
                    <input
                      type="postcode"
                      className="form-control"
                      name="postcode"
                      placeholder="Postcode"
                      value={
                        businessunit &&
                        businessunit.postcode &&
                        businessunit.postcode
                      }
                      onChange={handleChangepostcode}
                    />
                    {submitted && !businessunit.postcode && (
                      <div className="help-block">Postcode is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !businessunit.address ? ' has-error' : '')
                    }
                  >
                    <select
                      className="form-control"
                      name="address"
                      placeholder="select address"
                      value={businessunit.address}
                      onChange={handleChange}
                    >
                      <option value={''} hidden="true">
                        Select address
                      </option>
                      {PostcodeObject.addresses}
                    </select>
                    {submitted && !businessunit.address && (
                      <div className="help-block">address is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <div
                    className={
                      'form-group ' +
                      (submitted && !businessunit.branch ? ' has-error' : '')
                    }
                  >
                    <select
                      className="select col-md-12 selectdesign"
                      name="branch"
                      value={businessunit.branch}
                      onChange={handleChange}
                    >
                      <option value="" hidden>
                        {' '}
                        Select branch
                      </option>
                      <option value={'Basildon'}>Basildon</option>
                      <option value={'Wales'}>Wales</option>
                    </select>
                    {submitted && !businessunit.branch && (
                      <div className="help-block">Branch is required</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 pr-1">
                  <span className="color-red">{error.common}</span>
                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-wd"
                      data-cy="addbunit"
                    >
                      Save
                      {props.requestStatus ===
                        reducerConstants.BUSINESSUNIT_REQUEST && (
                        <div className="lds-ring ml-2">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card-footer" />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, businessunitData } = state.businessunit;
  return {
    requestStatus,
    businessunitData,
  };
}

const connectedBusinessunitAdd = connect(mapStateToProps)(BusinessunitAdd);
export { connectedBusinessunitAdd as BusinessunitAdd };
