import { JobSelection } from './jobCopmponents/JobSelection';
import { PostAgency } from './jobCopmponents/PostAgency';
import { PostLive } from './jobCopmponents/PostLive';
import { RateCard } from './jobCopmponents/RateCard';
import { SampleCards } from './jobCopmponents/SampleCards';
import { ShiftFinal } from './jobCopmponents/ShiftFinal';
import { ShiftSummary } from './jobCopmponents/ShiftSummary';
export const JobPost = () => {
  const currentPage = 6;
  return (
    <>
      <div className="">
        {currentPage == 1 && <JobSelection />}

        {currentPage == 2 && <PostAgency />}

        {currentPage == 3 && <RateCard />}
        {currentPage == 4 && <ShiftFinal />}
        {currentPage == 5 && <SampleCards />}
        {currentPage == 6 && <ShiftSummary />}
        {currentPage == 7 && <PostLive />}
      </div>
    </>
  );
};
