/* eslint-disable no-undef */
let apiUrl = '';
let shiftpdfUrl = '';
let sharableurl = '';
let permurl = '';
// eslint-disable-next-line no-undef
const env = process.env.REACT_APP_ENV;
sharableurl = process.env.REACT_APP_SHAREABLE_URL;
// eslint-disable-next-line no-undef
// console.log(process.env, 'bellavoido');

const imgUrl = 'https://flexirecs.s3.us-east-2.amazonaws.com/';
const postal_key = 'a1s6ILYqIEqFkF82GY01SA36009';
if (env == 'production') {
  apiUrl = 'https://app.flexirecs.com/recstemp/api/v1';
  shiftpdfUrl = 'https://app.flexirecs.com/recstemp/public/pdf/';
  permurl = 'https://employer.flexirecs.com/';
} else if (env == 'test') {
  apiUrl = 'https://app.arniv.com/recstemp/api/v1';
  shiftpdfUrl = 'https://app.arniv.com/recstemp/public/pdf/';
  permurl = 'https://employer.arniv.com/';
} else if (env == 'stage') {
  apiUrl = 'https://app.arniv.com/recstemp/api/v1';
  shiftpdfUrl = 'https://app.arniv.com/recstemp/public/pdf/';
  permurl = 'https://employer.arniv.com/';
} else if (env == 'design') {
  apiUrl = 'https://app.flexirecs.com/recstemp/api/v1';
  shiftpdfUrl = 'https://app.flexirecs.com/recstemp/public/pdf/';
  permurl = 'https://employer.arniv.com/';
} else if (
  env == 'development' ||
  env == 'staging' ||
  env == 'production_aws'
) {
  apiUrl = process.env.REACT_APP_API_URL;
  shiftpdfUrl = process.env.REACT_SHIFT_API_URL;
} else {
  // apiUrl = 'https://arniv.com/recstemp/api/v1';
  // shiftpdfUrl = 'https://arniv.com/recstemp/public/pdf/';

  apiUrl = 'http://localhost:8787/recstemp/api/v1';
  shiftpdfUrl = 'http://localhost:8787/recstemp/public/pdf/';
  permurl = 'http://localhost:3006/';
}

// /console.log("",apiUrl);
// eslint-disable-next-line no-undef
//apiUrl = process.env.REACT_APP_API_URL;
//shiftpdfUrl = 'http://localhost:8787/recstemp/public/pdf/';
//sharableurl = 'http://localhost:3000/';
// apiUrl = 'https://app.arniv.com/recstemp/api/v1';
// shiftpdfUrl = 'https://app.arniv.com/recstemp/public/pdf/';
// sharableurl = 'http://13.41.242.35:7770/';

// if (process.env.REACT_APP_ENV == 'stage') {
//   apiUrl = process.env.REACT_APP_API_URL_STAGE;
//   shiftpdfUrl = 'https://arniv.com/recstemp/public/pdf/';
//   sharableurl = 'http://13.41.242.35:7770/';
// }
// if (process.env.REACT_APP_ENV == 'production') {
//   shiftpdfUrl = 'http://app.flexirecs.com/recstemp/public/pdf/';
//   sharableurl = 'https://app.flexirecs.com/';
// }

export const apiConstants = {
  apiUrl,
  shiftpdfUrl,
  imgUrl,
  sharableurl,
  postal_key,
  permurl,
};
