/* eslint-disable no-useless-escape */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { experienceActions } from '../_actions';
import { ExperienceAdd } from './ExperienceAdd';

const Experience = props => {
  const [experienceDataRows, setExperienceDataRows] = useState([]);

  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 15,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [experienceAddModal, setexperienceAddModal] = useState(false);
  const [selectedExperienceId, setselectedExperienceId] = useState(false);

  useEffect(() => {
    if (props && props.candidate_id) {
      query.candidate_id = props.candidate_id;
      console.log('educationlist --- 1234 99', props.candidate_id);

      console.log('educationlist --- 12346677', isLoading);
      setQuery(query);
      getExperience(1, query);
    }
  }, []);

  const hideaddexperiencemodal = () => {
    getExperience(1);
    setexperienceAddModal(false);
  };

  const getExperience = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;

    dispatch(experienceActions.experienceList(queryNew)).then(
      res => {
        setIsLoading(false);
        if (
          res &&
          res.data &&
          res.data.rows &&
          res.data.rows &&
          res.data.rows.length > 0
        ) {
          setExperiencelist(res.data.rows);
        }
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  const setExperiencelist = experiencedata => {
    let experiencelist = [];
    for (let k = 0; k < experiencedata.length; k = k + 1) {
      experiencelist.push(
        <div className="educard_border mt-3 p-2" data-cy="expcard">
          <div className="card-body">
            <div className="row d-flex justify-content-between">
              <div className=" experiencedetails">
                <div className="d-flex">
                  <div className="mr-10">
                    <p
                      className="collegename mb-0"
                      style={{ textTransform: 'capitalize' }}
                    >
                      {experiencedata[k].job_title}
                    </p>
                  </div>
                  <div className="" style={{ alignSelf: 'center' }}>
                    {' '}
                    {experiencedata[k].is_verified == 1 && (
                      <img
                        className=" float-right"
                        style={{ height: '10px', width: '10px' }}
                        src="/assets/img/Subtract.png"
                      />
                    )}
                  </div>
                </div>

                <p className="mb-0" style={{}}>
                  {experiencedata[k].description}
                </p>
                <span className="mb-0 " style={{ fontWeight: '500' }}>
                  {experiencedata[k].company_name}
                </span>
                <p className="stream">
                  {moment(experiencedata[k].from_date).format('MMM YYYY')} -{' '}
                  {experiencedata[k].to_date
                    ? moment(experiencedata[k].to_date).format('MMM YYYY')
                    : 'Present' +
                      '(' +
                      moment(experiencedata[k].from_date, 'YYYYMMDD').toNow() +
                      ')'}
                </p>
                <p className="stream">{experiencedata[k].location}</p>
                <p className="stream">
                  {experiencedata[k].reference_description}{' '}
                  {/* {experiencedata[k].reference_last_name} */}
                </p>
              </div>
              <div
                data-cy="editexp"
                className="AddBtn"
                onClick={() => {
                  setselectedExperienceId(experiencedata[k]._id),
                    setexperienceAddModal(true);
                }}
              >
                edit
              </div>
            </div>
          </div>
        </div>,
      );
    }

    setExperienceDataRows([experiencelist]);
  };

  return (
    <div className="CandidateAdd" data-cy="experienceview">
      <div className="row">
        <div className="col-md-12 ">
          {/* {isLoading && <LoadingModal />} */}
          {/* <div className="qualificationList"> */}
          <div className="">
            {experienceDataRows.length > 0 ? (
              experienceDataRows
            ) : (
              <p className="mb-0" style={{ textAlign: 'center' }}>
                No result found
              </p>
            )}
          </div>

          {/* //================= */}
          <div className="addBtnFooter">
            <div
              data-cy="addexp"
              className="AddBtn"
              onClick={() => {
                setexperienceAddModal(true), setselectedExperienceId(null);
              }}
            >
              Add
            </div>
          </div>
        </div>
        {/* //--------------qulaifiacation add modal section --------------- */}
        {experienceAddModal && (
          <div
            className="modal "
            style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
          >
            <div className="modal-dialog modal-dialog-90 ">
              <div className="modal-content ">
                <div className="modal-header">
                  <div className="close">
                    <div
                      onClick={() => {
                        setexperienceAddModal(false);
                      }}
                    >
                      <i className=" fa fa-times-circle red fs-18"></i>
                    </div>
                  </div>
                </div>
                <div className="modal-body">
                  <ExperienceAdd
                    candidate_id={props.candidate_id}
                    experience_id={selectedExperienceId}
                    hideaddexperiencemodal={hideaddexperiencemodal}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { requestStatus, candidateData } = state.candidate;
  return {
    requestStatus,
    candidateData,
  };
}

const connectedExperience = connect(mapStateToProps)(Experience);
export { connectedExperience as Experience };
