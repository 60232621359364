/* eslint-disable no-undef */
import { authHeader, authHeaderMulti } from '../_helpers';

import moment from 'moment';
import { apiConstants } from '../_constants';

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function getUrlVars() {
  var vars = {};
  // var parts = window.location.href.replace(
  //   /[?&]+([^=&]+)=([^&]*)/gi,
  //   function (m, key, value) {
  //     vars[key] = value;
  //   },
  // );
  return vars;
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    console.log(text);
    if (data.error_code !== 0) {
      if (response.status === 401) {
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.message;
      return Promise.reject(error);
    }
    return data;
  });
}

function logout() {
  localStorage.removeItem('user');
}

function fileupload(file, name, document_type, query) {
  console.log('############!!@@#$$$%%%', file, name, query);
  // console.log("66666666666666666",file);

  let data = new FormData();

  data.append('file', file);
  data.append('document', document_type);

  data.append('query', JSON.stringify(query));
  data.append('name', name);

  console.log(query, name, file);

  // data.getAll("user-naem")
  console.log('imager uplado consoel=====', data);

  console.log('HERE', data);
  const requestOptions = {
    method: 'POST',
    body: data,
    headers: authHeaderMulti(),
  };
  return fetch(apiConstants.apiUrl + '/fileUploads3', requestOptions).then(
    handleResponse,
  );
}

function s3frontEndUploadUrl(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/s3frontEndUploadUrl',
    requestOptions,
  ).then(handleResponse);
}

function countries() {
  const requestOptions = {
    method: 'GET',
  };

  return (
    fetch(apiConstants.apiUrl + '/country.json', requestOptions)
      //.then(handleResponse)
      .then(country => country.json())
      .then(data => {
        console.log(data);
        return data;
      })
  );
}

function getAllActiveCountry(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/api/v1/getcountryList',
    requestOptions,
  ).then(handleResponse);
}
function sharabletoken(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/sharabletoken', requestOptions).then(
    handleResponse,
  );
}
function shorturl(stringurl) {
  console.log(stringurl);

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ longUrl: stringurl }),
  };

  return fetch(
    ' https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyB0UktWR7xDNvmpdvZfHMk2Lw4EjYvDtak',
    requestOptions,
  ).then(data => {
    console.log(data);
    return data;
  });
}

function otpverification(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/api/v1/otpverify', requestOptions).then(
    handleResponse,
  );
}

function getdashbaorddata(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/api/v1/getdashbaorddata',
    requestOptions,
  ).then(handleResponse);
}

//var csv is the CSV file with headers
function csvJSON(csv) {
  console.log(csv);

  var lines = csv.split('\n');

  var result = [];

  var headers = lines[0].split(',');

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split(',');

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return JSON.stringify(result); //JSON
}

// function ConvertKeysToLowerCase(obj) {
//   console.log(obj, 'isolik');
//   if (obj instanceof Array) {
//     for (var i in obj) {
//       obj[i] = keysToLowerCase(obj[i]);
//     }
//   }
//   if (
//     !typeof obj === 'object' ||
//     typeof obj === 'string' ||
//     typeof obj === 'number' ||
//     typeof obj === 'boolean'
//   ) {
//     return obj;
//   }
//   var keys = Object.keys(obj);
//   var n = keys.length;
//   var lowKey;
//   while (n--) {
//     var key = keys[n];
//     if (key === (lowKey = key.toLowerCase())) continue;
//     obj[lowKey] = keysToLowerCase(obj[key]);
//     delete obj[key];
//   }
//   return obj;
// }

function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

function formatTime(date) {
  let d = new Date(date),
    hours = d.getHours(),
    minutes = '' + d.getMinutes(),
    a = ' AM';

  if (hours > 12) {
    hours = '' + hours - 12;
    a = ' PM';
  } else if (hours == 0) {
    hours = '12';
  } else {
    hours = '' + hours;
  }

  if (minutes.length < 2) minutes = '0' + minutes;

  return hours + ':' + minutes + a;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function getgraphData(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getgraphData', requestOptions).then(
    handleResponse,
  );
}

//---------get document/file url if http url return it else append url and return --------------
function documentUrl(document_name) {
  var firstFourChars = document_name.substring(0, 4); // or string.slice(0, 4);

  if (firstFourChars == 'http') {
    return document_name;
  } else {
    let doc_name = apiConstants.imgUrl + document_name;
    return doc_name;
  }
}

function getDashbordSharelink(data) {
  // const requestOptions = {
  //   method: 'POST',
  //   headers: { 'Content-Type': 'application/json' },
  //   body: JSON.stringify(data),
  // };
  let requestOptions = {
    method: 'POST',
    headers: {
      // 'x-access-token': data.token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return fetch(apiConstants.apiUrl + '/getsharelink', requestOptions)
    .then(handleResponse)
    .then(response => {
      console.log(response, '>>>efnano');
      // login successful if there's a jwt token in the response
      if (response.data) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response;
    });
}

function calculateDistance(lat1, lon1, lat2, lon2) {
  const earthRadiusMiles = 3958.8; // Radius of the Earth in miles

  // Convert latitude and longitude from degrees to radians
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lon1Rad = (lon1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const lon2Rad = (lon2 * Math.PI) / 180;

  // Calculate the differences between latitudes and longitudes
  const latDiff = lat2Rad - lat1Rad;
  const lonDiff = lon2Rad - lon1Rad;

  // Calculate the distance using the Haversine formula
  const a =
    Math.sin(latDiff / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lonDiff / 2) ** 2;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distanceMiles = earthRadiusMiles * c;

  return distanceMiles;
}

const time24to12 = time => {
  if (time) {
    const number = moment(time, ['HH:mm']).format('hh:mm a');

    return number;
  } else {
    return null;
  }
};
const getUTCTime = date => {
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

const emailVerify = email => {
  const emailRegex = /^[a-zA-Z]+[a-zA-Z0-9.]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;
  return emailRegex.test(email);
};

// const phoneVerify = phone => {
//   const numericValue = value.replace(/[^0-9]/g, '');
// }

export const miscService = {
  countries,
  shorturl,
  fileupload,
  otpverification,
  getdashbaorddata,
  sharabletoken,
  documentUrl,
  csvJSON,
  // ConvertKeysToLowerCase,
  getUrlVars,
  b64toBlob,
  getAllActiveCountry,
  formatDate,
  formatTime,
  getParameterByName,
  s3frontEndUploadUrl,
  getgraphData,
  getDashbordSharelink,
  calculateDistance,
  time24to12,
  getUTCTime,
  emailVerify,
};
