import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { AgencyCandidateProfile } from '../AgencyCandidate/AgencyCandidateProfile';
import { AttendaceBloackbooking } from '../Block_booking/AttendanceBloackbooking';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { InvoiceAdd } from '../Invoice';
import { PayoutAdd2 } from '../Payout';
import {
  businessunitActions,
  employerActions,
  shiftsActions,
} from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants, roleContant } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';
import { miscService, shiftsService } from '../_services';
import { ShiftEdit } from './ShiftEdit';
import { Shifts } from './Shifts';
import { ShiftsAdd } from './ShiftsAdd';

// eslint-disable-next-line no-unused-vars
//let shiftsRows = [];
let employer_id = '';
let user_role = '';
const UNVERIFIED = 'unverified';

const AllShiftsList = props => {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [shiftsData, setShiftsData] = useState({ rows: [] });
  const [shiftsRows, setShiftsRows] = useState([]);
  const [shiftverificationmodal, setshiftverificationmodal] = useState(false);
  const [showshiftAddmodal, setShowshiftAddmodal] = useState(false);
  const [timesheetmodal, setTimesheetModal] = useState(false);
  const [showshiftEditmodal, setShowshiftEditmodal] = useState(false);
  const [deleteconfirmationmodal, setDeleteConfirmationModal] = useState(false);
  const [cancelconfirmationmodal, setCancelConfirmationModal] = useState(false);
  const [restoreconfirmationmodal, setRestoreConfirmationModal] =
    useState(false);
  const [shiftid, setShiftid] = useState([]);
  const [restoredetails, setRestoreDetails] = useState([]);
  const [canceltype, setcanceltype] = useState();
  const [selectedShifts, setSelectedShifts] = useState({});
  const [allselectedpages, setallselectedpages] = useState([]);
  // const [shiftidsCount, setShiftidsCount] = useState(0);
  const [showInvoicemodal, setshowInvoicemodal] = useState(false);
  const [showPayoutmodal, setshowPayoutmodal] = useState(false);
  const [trashChecked, settrashChecked] = useState(false);
  const [cancelChecked, setcancelChecked] = useState(false);
  const [view_shift_id, Setview_shift_id] = useState();
  const [editShiftId, seteditShiftId] = useState();
  const [viewdetailsmodal, setviewdetailsmodal] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [isStaff, setisStaff] = useState(false);
  const [isBunit, setisBunit] = useState(false);
  const [isParentEmployer, setisParentEmployer] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isCancelLoading, setisCancelLoading] = useState(false);
  //const [AllSelected, setAllSelected] = useState(false);
  const [businessUnitFilterRows, setBusinessUnitFilterRows] = useState([]);
  const [organizationRows, setorganizationRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 20,
    },
  });
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [showAgencyCandidateprofileModal, setshowAgencyCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);

  //--- debouncing for searchkey only - start ---
  let isfirsttime = useRef(true);
  const debouncedSearchFromDate = useDebounce(query?.from_date, 500);
  const debouncedSearchToDate = useDebounce(query?.to_date, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearchFromDate || debouncedSearchToDate) {
        getAllShifts(1);
      }
    }
  }, [debouncedSearchFromDate, debouncedSearchToDate]);
  //--- debouncing end ---
  // effect for get all shifts according to query change
  useEffect(() => {
    if (id) {
      if (id == UNVERIFIED) {
        query.is_verified = 0;
      }
    }
    if (props && props.id) {
      query.candidate_id = props.id;
    }
    if (props && props.from_date) {
      query.from_date = props.from_date;
    }
    if (props && props.to_date) {
      query.to_date = props.to_date;
    }
    if (props && props.employer_id) {
      query.employer_id = props.employer_id;
    }
    if (props && props.candidate_id) {
      query.candidate_id = props.candidate_id;
    }
    if (props && props.agency_id) {
      query.agency_id = props.agency_id;
    }
    if (props && props.agency_candidate_id) {
      query.agency_candidate_id = props.agency_candidate_id;
    }
    if (props && props.is_verified) {
      query.is_verified = props.is_verified;
    }
    if (props && props.status) {
      query.status = props.status;
    }
    if (props && props.category) {
      query.category = props.category;
    }
    if (props && props.businessunit) {
      query.business_unit = props.businessunit;
    }
    getAllShifts(1);
  }, [
    query.employer_id,
    query.candidate_id,
    query.agency_id,
    query.agency_candidate_id,
    query.is_verified,
    query.status,
    query.category,
    query.business_unit,
    query.is_attended,
    query.invoice_generated,
    query.branch,
    query.business_unit,
    isAdmin,
    isStaff,
  ]);
  // effect for get all shifts according to selected shift id change
  useEffect(() => {
    getAllShifts(query.pageVo.pageNo);
  }, [/*selectedShifts.length,*/ query]);
  // effect for get employer and business unit
  //when change on isAdmin, isStaff, isBunit state change
  useEffect(() => {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      user_role = user.role;
      if (user && user.employer_id) {
        employer_id = user.employer_id;
        if (user.is_parent) {
          setisParentEmployer(true);
          getAllParentEmployer();
        }
        getAllBusinessUnit(user.employer_id);
      }
      if (user.role == roleContant.ADMIN) {
        setisAdmin(true);
      }
      if (user.role == roleContant.STAFF) {
        setisStaff(true);
      }
      if (user.role == roleContant.BUSINESS_UNIT) {
        setisBunit(true);
      }
    }
  }, [isAdmin, isStaff, isBunit]);
  // show invoice modal open or close function
  const showInvoiceModalfunction = value => {
    setshowInvoicemodal(value);
  };
  // show payout modal open or close function
  const showPayoutModalfunction = value => {
    setshowPayoutmodal(value);
  };
  // used for handling modal close or open function in AttendaceBloackbooking component
  const showTimesheetModalfunction = value => {
    setTimesheetModal(value);
    getAllShifts(query.pageVo.pageNo);
    setSelectedShifts({});
  };
  // get all employers for filter
  const getAllParentEmployer = () => {
    const { dispatch } = props;
    let newquery = {
      status: 1,
      is_parent: 0,
      getChildEmployers: true,
      select: ['_id', 'company_name'],
    };
    dispatch(employerActions.employerListv2(newquery)).then(
      res => {
        if (res) {
          let parentArray = [];
          for (let s = 0; s < res.data.rows.length; s++) {
            parentArray.push(
              <option value={res.data.rows[s]._id}>
                {res.data.rows[s].company_name}
              </option>,
            );
          }
          setorganizationRows(parentArray);
        }
      },
      err => {
        console.log(err);
      },
    );
  };

  // get shift list
  const getAllShifts = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    if (employer_id) {
      queryNew.employer_id = employer_id;
    }
    if (props?.organization_id) {
      queryNew.employer_id = props?.organization_id;
    }
    if (props && props.category) {
      query.organization_id = employer_id;
    }
    if (id == UNVERIFIED) {
      queryNew.is_verified = 0;
    }
    if (queryNew.organization_id && queryNew.organization_id == 'null') {
      delete query.organization_id;
    }
    if (queryNew.is_verified == '') {
      delete queryNew.is_verified;
    }
    if (queryNew.branch == '') {
      delete queryNew.branch;
    }
    if (query.business_unit == null || query.business_unit == '') {
      delete query.business_unit;
    }
    if (props?.parent_id) {
      queryNew.employer_id = props?.parent_id;
    }
    setQuery(query);
    setIsLoading(true);
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsList(queryNew)).then(
      res => {
        for (
          let s = 0;
          res.data && res.data.rows && s < res.data.rows.length;
          s++
        ) {
          res.data.rows[s].x = 1;
        }
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // shift comfirm action call
  const confirmshiftverification = () => {
    const { dispatch } = props;
    dispatch(
      shiftsActions.updateShift({
        _id: shiftverificationmodal.id,
        is_accepted: 1,
      }),
    ).then(function () {
      setshiftverificationmodal({ id: '', modal: false });
      getAllShifts(query.pageVo.pageNo);
    });
  };
  // to select employer in filter and a call for business unit
  const setEmployer = (selectedOption, event) => {
    let name = event.name;
    setSelectedShifts({});

    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      business_unit: null,
    }));
    getAllBusinessUnit(selectedOption.value);
  };
  // get all business unti for filter
  const getAllBusinessUnit = employer_id => {
    const { dispatch } = props;
    dispatch(
      businessunitActions.businessunitList({
        employer_id: employer_id,
        status: 1,
        select: ['_id', 'name'],
      }),
    ).then(
      res => {
        let bunit_rows = [];
        for (let s = 0; s < res.data.rows.length; s++) {
          bunit_rows.push(
            <option value={res.data.rows[s]._id}>
              {res.data.rows[s].name}
            </option>,
          );
        }
        setBusinessUnitFilterRows(bunit_rows);
      },
      err => {
        console.log(err);
      },
    );
  };
  // add shift modal open or close action
  const showAddshiftmodal = value => {
    setShowshiftAddmodal(value);
  };
  // to select candidate  from filter
  const selectedcandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      agency_id: 0,
    }));
  };
  // show delete shift modal show
  const deleteShiftModalShow = shift_id => {
    setShiftid(shift_id);
    setDeleteConfirmationModal(true);
  };
  // to cancel shift modal
  const cancelShiftModalShow = shift_id => {
    setShiftid(shift_id);
    setCancelConfirmationModal(true);
  };
  // restore shift modal
  const restoreShiftModalShow = restoredata => {
    setRestoreDetails({
      candidate_id: restoredata.candidate_id,
      section_type: restoredata.section_type,
      _id: restoredata._id,
      check_in_date_string: restoredata.check_in_date_string,
    });
    setRestoreConfirmationModal(true);
  };
  // delete shift function
  const deleteshift = () => {
    const { dispatch } = props;
    dispatch(shiftsActions.shiftsDelete({ _id: shiftid })).then(res => {
      if (res) {
        setDeleteConfirmationModal(false);
        getAllShifts(query.pageVo.pageNo);
      }
    });
  };
  // set all selected pages
  const checkAllHandle = isCheck => {
    if (isCheck) {
      setallselectedpages(prev => [...prev, query.pageVo.pageNo]);

      for (let c = 0; c < shiftsData.rows.length; c++) {
        console.log('shiftsData.rows[c]._id', shiftsData.rows[c]._id);
        if (shiftsData.rows[c]._id) {
          selectedShifts[shiftsData.rows[c]._id] = shiftsData.rows[c];
        }
      }
    } else {
      setallselectedpages(
        allselectedpages.filter(page => {
          return page !== query.pageVo.pageNo;
        }),
      );

      for (let c = 0; c < shiftsData.rows.length; c++) {
        console.log('shiftsData.rows[c]._id', shiftsData.rows[c]._id);
        if (selectedShifts[shiftsData.rows[c]._id]) {
          delete selectedShifts[shiftsData.rows[c]._id];
        }
      }
      //  deleteElements(new_selectedShiftIds);
    }
    // const count = Object.keys(selectedShifts).length;
    //setShiftidsCount(count);
    setSelectedShifts({ ...selectedShifts });
    console.log('selectedShifts', selectedShifts);
    setListAndPagination(shiftsData);
    //setAllSelected(isCheck);
  };

  const category_change = selectedOption => {
    setQuery(prevState => ({ ...prevState, category: selectedOption.value }));
  };
  // hander for agency filter selection
  const agency_change = selectedOption => {
    setQuery(prevState => ({
      ...prevState,
      agency_id: selectedOption.value,
      candidate_id: 0,
    }));
  };
  // to cancel shift confirmation action and a get all shifts call
  const cancelshift = () => {
    const { dispatch } = props;
    let newquery = {};
    setSubmitted(true);
    if (shiftid && canceltype) {
      setisCancelLoading(true);
      newquery._id = shiftid;
      newquery.cancel_type = canceltype;
      dispatch(shiftsActions.shiftsCancel(newquery)).then(res => {
        if (res) {
          setisCancelLoading(false);
          setCancelConfirmationModal(false);
          getAllShifts(query.pageVo.pageNo);
        }
      });
      setSubmitted(false);
    } else {
      setisCancelLoading(false);
    }
  };
  // to restore shift action and get all shifts call
  const restoreshift = () => {
    const { dispatch } = props;
    setSubmitted(true);
    if (restoredetails) {
      let newquery = restoredetails;
      dispatch(shiftsActions.shiftsRestore(newquery)).then(res => {
        if (res) {
          setRestoreConfirmationModal(false);
          getAllShifts(query.pageVo.pageNo);
        }
      });
      setSubmitted(false);
    }
  };
  // edit shift modal handler for ShiftEdit component
  const showEditshiftmodal = () => {
    setShowshiftEditmodal(false);
    getAllShifts(1);
  };
  // handler for candidate select
  const selectedallocatecandidate = (selectedOption, event) => {
    let name = event.name;
    setQuery(prevState => ({
      ...prevState,
      [name]: selectedOption.value,
      agency_id: 0,
    }));
  };
  // handle check select item in row
  const handleSelectShift = (shift_id, shift, shiftsData) => {
    if (selectedShifts[shift_id]) {
      delete selectedShifts[shift_id];
    } else {
      selectedShifts[shift_id] = shift;
    }
    // setShiftidsCount(Object.keys(selectedShifts).length);
    setSelectedShifts(selectedShifts);
    setListAndPagination(shiftsData);
  };

  const editShift = shift_id => {
    seteditShiftId(shift_id);
    setShowshiftEditmodal(true);
  };
  // set shift list and pagination
  const setListAndPagination = shiftsData => {
    let shiftsList = [];
    for (
      let j = 0;
      shiftsData && shiftsData.rows && j < shiftsData.rows.length;
      j = j + 1
    ) {
      shiftsList.push(
        <tr key={j} className="row-body2">
          {!props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.isHistory &&
            !props.businessunit &&
            !props.category &&
            !props.organization_id && (
              <td>
                {shiftsData.rows[j].status == 1 && (
                  <div
                    className="pointer"
                    onClick={() =>
                      handleSelectShift(
                        shiftsData.rows[j]._id,
                        shiftsData.rows[j],
                        shiftsData,
                      )
                    }
                  >
                    {selectedShifts[shiftsData.rows[j]._id] ? (
                      <img
                        className="check_style"
                        src="/assets/img/check1.png"
                      />
                    ) : (
                      <img
                        className="check_style"
                        src="/assets/img/un_check1.png"
                      />
                    )}
                  </div>
                )}
              </td>
            )}
          {!props.isHistory && (
            <td>
              {shiftsData.rows[j]['Candidate.name'] && (
                <div
                  onClick={() => {
                    setSelectedCandidateId(shiftsData.rows[j].candidate_id);
                    setshowCandidateprofileModal(true);
                  }}
                  className=""
                >
                  <div
                    style={{
                      color: 'black',
                      fontSize: '12px',
                      flexDirection: 'row',
                      display: 'flex',
                      cursor: 'pointer',
                    }}
                  >
                    {shiftsData.rows[j]['Candidate.profileimage'] ? (
                      <img
                        className="round-emp-image"
                        src={
                          apiConstants.imgUrl +
                          shiftsData.rows[j]['Candidate.profileimage']
                        }
                      />
                    ) : (
                      <img
                        className=" round-emp-image"
                        src="/assets/img/recsy-fav-icon.png"
                      />
                    )}
                    <p className="username">
                      {shiftsData.rows[j]['Candidate.name'] +
                      shiftsData.rows[j]['Candidate.last_name']
                        ? shiftsData.rows[j]['Candidate.name'].length > 8
                          ? (
                              shiftsData.rows[j]['Candidate.name'] +
                              ' ' +
                              (shiftsData.rows[j]['Candidate.last_name']
                                ? shiftsData.rows[j]['Candidate.last_name']
                                : '')
                            ).substring(0, 8) + '...'
                          : shiftsData.rows[j]['Candidate.name'] +
                            ' ' +
                            (shiftsData.rows[j]['Candidate.last_name']
                              ? shiftsData.rows[j]['Candidate.last_name']
                              : '')
                        : ''}
                    </p>
                  </div>
                </div>
              )}
              {shiftsData.rows[j].agency_candidate_id &&
                shiftsData.rows[j]['Agencycandidate.name'] && (
                  <div
                    onClick={() => {
                      setSelectedCandidateId(
                        shiftsData.rows[j].agency_candidate_id,
                      ),
                        setshowAgencyCandidateprofileModal(true);
                    }}
                    className=" pointer"
                  >
                    <div
                      style={{
                        color: 'black',
                        fontSize: '12px',
                        flexDirection: 'row',
                        display: 'flex',
                      }}
                    >
                      {shiftsData.rows[j]['Agencycandidate.profileimage'] ? (
                        <img
                          className="round-emp-image"
                          src={
                            apiConstants.imgUrl +
                            shiftsData.rows[j]['Agencycandidate.profileimage']
                          }
                        />
                      ) : (
                        <img
                          className=" round-emp-image"
                          src="/assets/img/recsy-fav-icon.png"
                        />
                      )}
                      {shiftsData.rows[j]['Agency.company_name'] &&
                      shiftsData.rows[j]['Agencycandidate.name']
                        ? shiftsData.rows[j]['Agencycandidate.name'].length > 8
                          ? shiftsData.rows[j][
                              'Agencycandidate.name'
                            ].substring(0, 8) + '...'
                          : shiftsData.rows[j]['Agencycandidate.name']
                        : ''}
                      <br />
                      {shiftsData.rows[j]['Agency.company_name'] &&
                      shiftsData.rows[j]['Agency.company_name'].length > 5
                        ? ' ( ' +
                          shiftsData.rows[j]['Agency.company_name'].substring(
                            0,
                            5,
                          ) +
                          '...)'
                        : ' ( ' +
                          shiftsData.rows[j]['Agency.company_name'] +
                          ')'}
                    </div>
                  </div>
                )}
            </td>
          )}
          <td>
            {moment(shiftsData.rows[j].check_in_date_string).format(
              'DD-MMM-YYYY',
            )}
          </td>
          {!props.isHistory && (
            <td>
              {moment(shiftsData.rows[j].check_out_date_string).format(
                'DD-MMM-YYYY',
              )}
            </td>
          )}
          <td>
            <div style={{ fontWeight: 'bold', color: '#183245' }}>
              {shiftsData.rows[j]['Section.section_type']}
              <br />
              {'(' + shiftsData.rows[j]['Section.name'] + ')'}
            </div>
            <div style={{ fontSize: '10px' }}>
              {miscService.time24to12(shiftsData.rows[j].check_in_time_string)}{' '}
              -{' '}
              {miscService.time24to12(shiftsData.rows[j].check_out_time_string)}
            </div>
          </td>
          {!props.isHistory && (
            <td>
              {miscService.time24to12(shiftsData.rows[j].attendance_check_in)}
            </td>
          )}
          {!props.isHistory && (
            <td>
              {miscService.time24to12(shiftsData.rows[j].attendance_check_out)}
            </td>
          )}
          <td>{shiftsData.rows[j].break_time}</td>
          <td>{shiftsData.rows[j].actual_hours_after_break_time}</td>
          {(user_role == 1 || user_role == 6) && (
            <td>{shiftsData.rows[j].price} </td>
          )}
          {(user_role == 1 || user_role == 6) && (
            <td> {shiftsData.rows[j].employer_to_pay}</td>
          )}
          {shiftsData.rows[j].is_attended != -1 && (
            <td className="">
              {' '}
              {shiftsData.rows[j].is_verified ? (
                <i className="fa fa-check"></i>
              ) : (
                <i className="fa fa-times"></i>
              )}
            </td>
          )}
          {shiftsData.rows[j].is_attended == -1 && (
            <td className="absent"> {'Absent'}</td>
          )}
          <td>
            {shiftsData.rows[j].invoice_id &&
            shiftsData.rows[j].invoice_generated == 1 ? (
              <i className="fa fa-check"></i>
            ) : (
              <i className="fa fa-times"></i>
            )}
          </td>
          <td style={{ textTransform: 'capitalize' }}>
            {shiftsData.rows[j]['Employer.company_name'] + '('}
            {shiftsData.rows[j]['Businessunit.name'] &&
              shiftsData.rows[j]['Businessunit.name'].slice(0, 15) + ')'}
            {shiftsData.rows[j]['Employer.Parent.company_name']
              ? shiftsData.rows[j]['Employer.Parent.company_name']
              : ''}
          </td>
          {!props.isHistory && (
            <td>
              {shiftsData &&
                shiftsData.rows &&
                shiftsData.rows[j].createdtime &&
                moment
                  .unix(shiftsData.rows[j].createdtime / 1000)
                  .format('DD MMM YYYY hh:mm A')}
            </td>
          )}
          {!props.isHistory && (
            <td>
              <div
                className="allshift-viewdt flx-allshift-view"
                data-cy="viewshift"
                onClick={() => {
                  Setview_shift_id(shiftsData.rows[j]._id),
                    setviewdetailsmodal(true);
                }}
              >
                View
              </div>
            </td>
          )}
          {!props.isHistory &&
            (isAdmin || isStaff) &&
            !trashChecked &&
            shiftsData.rows[j].status == 1 &&
            !cancelChecked &&
            !props.id &&
            !props.is_report &&
            !props.employer_id && (
              <td>
                {!shiftsData.rows[j]['Agencycandidate.name'] ? (
                  <div
                    className="allshift-viewdt flx-allshift-edit"
                    data-cy="editshift"
                    onClick={() => {
                      editShift(shiftsData.rows[j]._id);
                    }}
                  >
                    Edit
                  </div>
                ) : (
                  <div></div>
                )}
              </td>
            )}
          {(isAdmin || isStaff) &&
            !trashChecked &&
            !cancelChecked &&
            !props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.is_report &&
            !props.isHistory && (
              <td
                className="eventCell event Edit"
                style={{ cursor: 'pointer' }}
              >
                {shiftsData.rows[j].status == 1 &&
                  !props.id &&
                  !props.employer_id &&
                  !props.agency_id &&
                  (isAdmin || isStaff) && (
                    <div
                      onClick={() => {
                        deleteShiftModalShow(shiftsData.rows[j]._id);
                      }}
                    >
                      <img
                        data-cy="deleteshift"
                        src="/assets/img/delete.png"
                      ></img>
                    </div>
                  )}
              </td>
            )}
          {(isAdmin || isStaff) &&
            !trashChecked &&
            !props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.isHistory &&
            !props.is_report &&
            !cancelChecked && (
              <td
                className="eventCell event Edit"
                style={{ cursor: 'pointer', color: '#DD3A3A' }}
              >
                {shiftsData.rows[j].status == 1 &&
                  !props.id &&
                  !props.employer_id &&
                  !props.agency_id &&
                  (isAdmin || isStaff) && (
                    <div
                      data-cy="cancelshift"
                      onClick={() => {
                        cancelShiftModalShow(shiftsData.rows[j]._id);
                      }}
                    >
                      Cancel
                    </div>
                  )}
              </td>
            )}
          {(isAdmin || isStaff) &&
            trashChecked &&
            !props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.isHistory && (
              <td
                className="eventCell event Edit"
                style={{ cursor: 'pointer', color: 'rgb(58 116 221)' }}
              >
                {shiftsData.rows[j].status == 0 &&
                  !props.id &&
                  !props.employer_id &&
                  !props.agency_id &&
                  (isAdmin || isStaff) && (
                    <div
                      onClick={() => {
                        restoreShiftModalShow(shiftsData.rows[j]);
                      }}
                    >
                      Restore
                    </div>
                  )}
              </td>
            )}
        </tr>,
      );
    }
    setShiftsRows(shiftsList);
    setShiftsData(shiftsData);
  };
  // filter handler for sort order
  const handleSort = key => {
    setQuery(query => ({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order:
          query.pageVo.sort === key && query.pageVo.sort_order == 'asc'
            ? 'desc'
            : 'asc',
        sort: key,
      },
    }));
  };
  // handler for filters like from_date ,to_date ,is_verified ,is_attended ,
  // invoice_generated ,branch, business_unit,organization_id
  const handleChange = event => {
    const { name, value } = event.target;

    if (name === 'from_date' || name === 'to_date') {
      // Parse the dates to compare them
      const fromDate =
        name === 'from_date' ? new Date(value) : new Date(query.from_date);
      const toDate =
        name === 'to_date' ? new Date(value) : new Date(query.to_date);

      // Check if from_date is greater than to_date
      if (fromDate > toDate) {
        alert('From date cannot be greater than To date');
        setQuery({ ...query, to_date: '' });
        return; // Exit the function without updating the state
      }
    }
    isfirsttime.current = false;
    setQuery({ ...query, [name]: value });
  };
  // select shift cancel reason handler
  const shiftcancelreasonChange = event => {
    const { value } = event.target;
    setcanceltype(value);
  };
  // to view trashed items
  const trash_checked = value => {
    setcancelChecked(false);
    settrashChecked(value);
    if (value) {
      setQuery({ ...query, status: 0 });
    } else {
      setQuery({ ...query, status: 1 });
    }
  };
  // to view cancel checked items
  const cancelled_checked = value => {
    setcancelChecked(value);
    settrashChecked(false);
    if (value) {
      setQuery({ ...query, status: -1 });
    } else {
      setQuery({ ...query, status: 1 });
    }
  };
  // custom styles for select
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',
      whiteSpace: 'pre',
      lineHeight: '25px',
    }),
  };

  console.log('selectedShifts', selectedShifts);
  return (
    <div className="ShiftList">
      <div className="col-md-12">
        <div
          className=" regular-table-with-color"
          style={
            props.isHistory ? { marginLeft: '0px', marginRight: '0px' } : {}
          }
        >
          <div className=" page-header ">
            {!props.isHistory && (
              <div className="row mt-20">
                <div className="col-md-9">
                  <div className="page-title"> Shift list</div>
                </div>
              </div>
            )}
            {!props.id &&
              !props.employer_id &&
              !props.agency_id &&
              !props.employer_id &&
              !props.isHistory &&
              !props.businessunit &&
              !props.is_report &&
              !props.category &&
              !props.organization_id && (
                <div className="filter-row">
                  <div className=" filtero ">
                    <div className="filter-row">
                      <div className="filter-item">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            From
                          </label>
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          name="from_date"
                          placeholder=""
                          value={query.from_date}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="filter-item">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            To
                          </label>
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          name="to_date"
                          placeholder=""
                          value={query.to_date}
                          onChange={handleChange}
                        />
                      </div>
                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type">
                              Employer
                            </label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="employer_id"
                            onChange={setEmployer}
                            placeholder={'select employer'}
                            cacheOptions
                            defaultOptions
                            loadOptions={getEmployerlist}
                            className="employerselectt"
                          />
                        </div>
                      )}
                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type">
                              Candidate
                            </label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedcandidate}
                            placeholder={'select candidate'}
                            cacheOptions
                            defaultOptions
                            loadOptions={getcandidatelist}
                            className="candidateselectt"
                          />
                        </div>
                      )}
                      {user_role != 1 && user_role != 6 && (
                        <div className="filter-item">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type">
                              Candidate
                            </label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="candidate_id"
                            onChange={selectedallocatecandidate}
                            placeholder={'select candidate'}
                            cacheOptions
                            defaultOptions
                            className="allocatedcandiadte"
                            loadOptions={getshiftscandidatelist}
                          />
                        </div>
                      )}
                      {(user_role == 1 || user_role == 6) && (
                        <div className="filter-item">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type">
                              Jobrole
                            </label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="category"
                            onChange={category_change}
                            placeholder="select category"
                            cacheOptions
                            defaultOptions
                            className="categoryselectt"
                            // isMulti
                            loadOptions={getCategorylist}
                          />
                        </div>
                      )}
                      {
                        <div className="filter-item">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type">
                              Agency
                            </label>
                          </div>
                          <AsyncSelect
                            styles={customStyles}
                            name="agency_id"
                            onChange={agency_change}
                            placeholder="select agency"
                            cacheOptions
                            defaultOptions
                            className="agencyselectt"
                            // isMulti
                            loadOptions={getAgencylist}
                          />
                        </div>
                      }
                      {id != UNVERIFIED && (
                        <div className="">
                          <div className="">
                            {' '}
                            <label className="ml-10" htmlFor="type">
                              Verified
                            </label>
                          </div>
                          <select
                            className="select selectdesign  "
                            data-cy="isverifyy"
                            name="is_verified"
                            value={query.is_verified}
                            onChange={handleChange}
                          >
                            <option value={''}> ALL</option>
                            <option value={1}>Verified</option>
                            {user_role != 1 && (
                              <option value={0}>Not verified</option>
                            )}
                            {(user_role == 1 || user_role == 6) && (
                              <option value={0}>To verify</option>
                            )}
                          </select>
                        </div>
                      )}
                    </div>
                    <div className=" button-row  ">
                      <div className="">
                        <div
                          className="c btn btn-primary-blue  mt-1"
                          data-cy="assign_shift"
                          onClick={() => {
                            setShowshiftAddmodal(true);
                          }}
                        >
                          + Assign Shift
                        </div>
                      </div>
                      <div className="">
                        <div
                          data-cy="Add_timesheet"
                          className="attendance-div card-body2 mt-1 p-2 "
                          onClick={() => {
                            setTimesheetModal(true);
                          }}
                        >
                          <p className="f-10 fontwghtbold"> Add Timesheet</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {!props.isHistory && (
              <div className="">
                {query.from_date && (
                  <label>
                    Shift from{' '}
                    {moment(query.from_date, 'yyyy-MM-DD').format(
                      'DD-MMM-yyyy',
                    )}
                  </label>
                )}
              </div>
            )}
          </div>
          {!props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.employer_id &&
            !props.isHistory &&
            !props.businessunit &&
            !props.is_report &&
            !props.category &&
            !props.organization_id && (
              <div className="card-header page-header pl-0 pr-0">
                <div className="d-flex flex-1 align-items-center">
                  <div className="filter-row">
                    <div className="filter-item">
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Attended
                        </label>
                      </div>
                      <select
                        className="select col-md-12 selectdesign39"
                        name="is_attended"
                        value={query.is_attended}
                        onChange={handleChange}
                      >
                        <option value={''}> ALL</option>
                        <option value={1}>Attended</option>
                        <option value={-1}> Absent</option>
                      </select>
                    </div>
                    <div className="filter-item">
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Timesheet
                        </label>
                      </div>
                      <select
                        className="select col-md-12 selectdesign39"
                        name="is_verified"
                        data-cy="timesheet"
                        value={query.is_verified}
                        onChange={handleChange}
                      >
                        <option value={''}> ALL</option>
                        <option value={1}> timesheet generated</option>
                        <option value={0}> Not timesheet generated</option>
                      </select>
                    </div>
                    <div className="filter-item">
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Invoice
                        </label>
                      </div>
                      <select
                        className="select col-md-12 selectdesign39"
                        name="invoice_generated"
                        value={query.invoice_generated}
                        onChange={handleChange}
                      >
                        <option value={''}> ALL</option>
                        <option value={1}> invoice generated</option>
                        <option value={0}> Not invoice generated</option>
                      </select>
                    </div>
                    <div className="filter-item">
                      <div className="">
                        {' '}
                        <label className="ml-10" htmlFor="type">
                          Branch
                        </label>
                      </div>
                      <select
                        className="select col-md-12 selectdesing2"
                        name="branch"
                        value={query.branch}
                        onChange={handleChange}
                      >
                        <option value="77" hidden={true}>
                          {' '}
                          Select Branch
                        </option>
                        <option value=""> ALL</option>
                        <option value={'Basildon'}>Basildon</option>
                        <option value={'Wales'}>Wales</option>
                      </select>
                    </div>
                    {/* //------------ business unit filter ---------------- */}
                    {!isBunit && (
                      <div className="filter-item">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            Businessunit
                          </label>
                        </div>
                        <select
                          className="select col-md-12 selectdesing2 "
                          name="business_unit"
                          data-cy="business_unit"
                          value={query.business_unit}
                          onChange={handleChange}
                        >
                          <option value="77" hidden={true}>
                            {' '}
                            Select Business unit
                          </option>
                          <option value=""> ALL</option>
                          {businessUnitFilterRows}
                        </select>
                      </div>
                    )}
                    {/* //------------ parent child  filter ---------------- */}
                    {isParentEmployer && (
                      <div className="filter-item">
                        <div className="">
                          {' '}
                          <label className="ml-10" htmlFor="type">
                            Organization
                          </label>
                        </div>
                        <select
                          className="select col-md-12 selectdesing2 mt-1"
                          name="organization_id"
                          value={query.organization_id}
                          onChange={handleChange}
                        >
                          <option value="null"> ALL</option>
                          {organizationRows}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="download-items">
                    <a
                      className={
                        'btn-primary btn btn-downlaodpdf ' +
                        (Object.keys(selectedShifts).length
                          ? 'btn-active'
                          : 'btn-disabled')
                      }
                      disabled={!Object.keys(selectedShifts).length}
                      data-cy="printshifts"
                      href={
                        Object.keys(selectedShifts).length
                          ? `/shiftprint/0/${Object.keys(selectedShifts)}/0/0/0`
                          : '#'
                      }
                      target="blank"
                    >
                      <span
                        className="button-icon"
                        style={{
                          backgroundImage: `url(/assets/img/black-download.png)`,
                        }}
                      ></span>{' '}
                      Download Pdf
                    </a>

                    <CsvDownload
                      filename={`shifts-${miscService.getUTCTime(
                        new Date(),
                      )}.csv`}
                      disabled={!Object.keys(selectedShifts).length}
                      data={shiftsService.getShiftsCsvData(selectedShifts)}
                    >
                      <div
                        className={
                          'btn-primary btn btn-downlaodpdf ' +
                          (Object.keys(selectedShifts).length
                            ? 'btn-active'
                            : 'btn-disabled')
                        }
                        disabled={!Object.keys(selectedShifts).length}
                      >
                        <span
                          className="button-icon"
                          style={{
                            backgroundImage: `url(/assets/img/black-download.png)`,
                          }}
                        ></span>{' '}
                        Download CSV
                      </div>
                    </CsvDownload>
                  </div>

                  {/* 
                  <div className="">
                    <div
                      className={
                        'downloadbtn-div card-body2 mt-1 p-2 ' +
                          Object.keys(selectedShifts).length >
                        0
                          ? 'btn-disabled'
                          : 'btn-active'
                      }
                    >
                      <a
                        data-cy="printshifts"
                        href={`/shiftprint/0/${Object.keys(
                          selectedShifts,
                        )}/0/0/0`}
                        target="blank"
                      >
                        <p className="f-12 fontwghtbold"> print shifts </p>
                      </a>
                      <img
                        style={{
                          height: 17,
                          width: 17,
                          marginLeft: '5px',
                        }}
                        src="/assets/img/black-download.png"
                      />
                    </div>
                  </div>

                  <div
                    className={
                      'btnDownloadCsv ' + Object.keys(selectedShifts).length > 0
                        ? 'btn-disabled'
                        : 'btn-active'
                    }
                  >
                    <CsvDownload
                      style={{
                        border: 'none',
                        padding: '4px',
                        backgroundColor: '#f7f9fb',
                      }}
                      data={shiftsService.getShiftsCsvData(selectedShifts)}
                    >
                      Download CSV
                    </CsvDownload>
                    <div>
                      <img
                        style={{ height: 15, width: 15 }}
                        src="/assets/img/csv1.png"
                      />
                    </div>
                  </div> */}

                  {!isBunit && (
                    <div className="button-row ">
                      <div
                        data-cy="Trash"
                        className={
                          trashChecked
                            ? 'trashButtonActive'
                            : 'trashButtonInActive'
                        }
                        onClick={() => {
                          trash_checked(!trashChecked);
                        }}
                      >
                        Trash
                      </div>
                      <div
                        data-cy="Cancelled"
                        className={
                          cancelChecked
                            ? 'trashButtonActive ml-2'
                            : 'trashButtonInActive ml-2'
                        }
                        onClick={() => {
                          cancelled_checked(!cancelChecked);
                        }}
                      >
                        Cancelled
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          {!props.id &&
            !props.employer_id &&
            !props.agency_id &&
            !props.employer_id &&
            !props.isHistory &&
            !props.businessunit &&
            !props.is_report &&
            !props.category &&
            !props.organization_id && (
              <div className="d-flex">
                <div className="filter-item fontwghtbold">
                  Shifts Selected : {Object.keys(selectedShifts).length}
                </div>
                <div className="fontwghtbold">
                  Fully Selected Pages : {''}
                  {allselectedpages.join(', ')}
                </div>
              </div>
            )}

          <div
            className="  table-responsive main-card-body "
            style={{ paddingTop: 0 }}
          >
            {!isLoading && (
              <table className="table table-hover flx-custom-table">
                <thead>
                  <tr>
                    {!props.id &&
                      !props.employer_id &&
                      !props.agency_id &&
                      !props.isHistory &&
                      !props.businessunit &&
                      !props.category &&
                      !props.organization_id && (
                        <th className="border-top1">
                          <div
                            data-cy="selectshift"
                            onClick={() => {
                              allselectedpages.includes(query.pageVo.pageNo)
                                ? checkAllHandle(false)
                                : checkAllHandle(true);
                            }}
                          >
                            {allselectedpages.includes(query.pageVo.pageNo) ? (
                              <img
                                className="check_style"
                                src="/assets/img/check1.png"
                              />
                            ) : (
                              <img
                                className="check_style"
                                src="/assets/img/un_check1.png"
                              />
                            )}
                          </div>
                        </th>
                      )}
                    {!props.isHistory && (
                      <th
                        onClick={() => handleSort('candidate_name')}
                        className="sortHead"
                      >
                        Name{' '}
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'candidate_name' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'candidate_name' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                    )}
                    <th
                      onClick={() => handleSort('check_in_date_string')}
                      className="sortHead"
                    >
                      From
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'check_in_date_string' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'check_in_date_string' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    {!props.isHistory && (
                      <th
                        onClick={() => handleSort('check_out_date_string')}
                        className="sortHead"
                      >
                        To
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'check_out_date_string' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'check_out_date_string' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                    )}
                    <th>Shift</th>
                    {!props.isHistory && <th>Check in</th>}
                    {!props.isHistory && <th>Check out</th>}
                    <th>Break time</th>
                    <th>Actual hours</th>
                    {(user_role == 1 || user_role == 6) && <th>Price</th>}
                    {(user_role == 1 || user_role == 6) && (
                      <th>Employer pay</th>
                    )}
                    <th>Timesheet</th>
                    <th>Invoice generated</th>
                    <th
                      onClick={() => handleSort('employer_name')}
                      className="employerHead"
                    >
                      Employer
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'employer_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'employer_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    {!props.isHistory && (
                      <th
                        onClick={() => handleSort('createdtime')}
                        className="sortHead"
                      >
                        Created
                        <span className={'sortBtn '}>
                          {' '}
                          <i
                            className={
                              'fa fa-sort-up ' +
                              (query.pageVo.sort == 'createdtime' &&
                              query.pageVo.sort_order != 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>{' '}
                          <i
                            className={
                              'fa fa-sort-down ' +
                              (query.pageVo.sort == 'createdtime' &&
                              query.pageVo.sort_order == 'desc'
                                ? ' active'
                                : '')
                            }
                          ></i>
                        </span>
                      </th>
                    )}
                    {!props.isHistory && <th></th>}
                    {!trashChecked &&
                      !cancelChecked &&
                      !props.id &&
                      !props.is_report &&
                      !props.employer_id &&
                      !props.agency_id &&
                      (isAdmin || isStaff) &&
                      !props.isHistory && <th></th>}
                    {!trashChecked &&
                      !cancelChecked &&
                      !props.id &&
                      !props.is_report &&
                      !props.employer_id &&
                      !props.agency_id &&
                      (isAdmin || isStaff) &&
                      !props.isHistory && <th></th>}
                    {trashChecked &&
                      !cancelChecked &&
                      !props.id &&
                      !props.is_report &&
                      !props.employer_id &&
                      !props.agency_id &&
                      (isAdmin || isStaff) &&
                      !props.isHistory && <th></th>}
                    {!props.isHistory &&
                      isAdmin &&
                      !props.is_report &&
                      !trashChecked &&
                      !cancelChecked && <th className="border-top2"></th>}
                  </tr>
                </thead>
                <tbody>{shiftsRows}</tbody>
              </table>
            )}
            {isLoading && <LoadingImg />}
            {shiftsRows &&
              shiftsRows &&
              shiftsRows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer hidescroll center-white">
            <Pagination
              pageOnClick={data => {
                getAllShifts(data);
              }}
              paginationData={shiftsData}
            />
          </div>
        </div>
      </div>
      {viewdetailsmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-70 ">
            <div className="modal-content ">
              <div className="modal-header border-bot-0">
                <div className="   ">
                  <div className="close">
                    <a
                      data-cy="closemodal"
                      onClick={() => setviewdetailsmodal(false)}
                    >
                      <i className=" fa fa-times-circle close-button-modal "></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <Shifts id={view_shift_id} />
              </div>
            </div>
          </div>
        </div>
      )}
      {shiftverificationmodal.modal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className=" modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setshiftverificationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Confirm Verification ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setshiftverificationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          className="button_delete_close width_100"
                          onClick={() => confirmshiftverification()}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showshiftAddmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setShowshiftAddmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftsAdd
                  allocateshift={false}
                  isSelectUsershow={true}
                  showAddshiftmodal={showAddshiftmodal}
                  isMultipleDays={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* //----------edit shift modal -------------------/ */}
      {showshiftEditmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a onClick={() => setShowshiftEditmodal(false)}>
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <ShiftEdit
                  isSelectUsershow={true}
                  showEditshiftmodal={showEditshiftmodal}
                  _id={editShiftId}
                  isMultipleDays={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setDeleteConfirmationModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <div className="d-flex justify-content-center">
                        <img
                          className="delete-icon"
                          src="/assets/img/bin.png"
                        ></img>
                      </div>
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure you want to delete shift?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setDeleteConfirmationModal(false);
                          }}
                        >
                          Close
                        </button>

                        <button
                          className="button_delete_close width_100"
                          data-cy="deletebutton"
                          onClick={() => deleteshift()}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cancelconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setCancelConfirmationModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body ml-30">
                <div className="bag">
                  <div className="row d-flex ">
                    <div className="col-md-12 d-flex flex-column ml-20">
                      <div className="d-flex mb-10">
                        <div className="p-2">
                          <img
                            style={{ height: '30px', width: '30px' }}
                            className="delete-icon"
                            src="/assets/img/warning.png"
                          ></img>
                        </div>
                        <div
                          className="align-items-center"
                          style={{ lineHeight: '23px', padding: '10px' }}
                        >
                          <h4 className="font-weight-bold">Cancel Shift</h4>
                          <p style={{ color: '#a2a2a2' }}>
                            Are you sure you want cancel the shift? this shift
                            wont be considered anymore and this action cannot be
                            undone
                          </p>
                        </div>
                      </div>
                      {(isAdmin || isStaff) && (
                        <div className="mb-30">
                          <small>* Select reason for cancellation </small>
                          <select
                            className="select col-md-10 selectdesign"
                            name="cancel_type"
                            value={query.cancel_type}
                            onChange={shiftcancelreasonChange}
                          >
                            <option value={''}>Select</option>
                            <option value={'Accidentally added shift'}>
                              Accidentally added shift{' '}
                            </option>
                            <option value={'Emergency cancellation'}>
                              Emergency cancellation
                            </option>
                            <option
                              value={'Shift cannot be attended on this date'}
                            >
                              Shift cannot be attended on this date
                            </option>
                            <option value="Other"> Other</option>
                          </select>
                        </div>
                      )}
                      {!canceltype && submitted && (
                        <div className="help-block">
                          Cancel type is required
                        </div>
                      )}
                      <div className="mb-30 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center ">
                          <button
                            style={{ margin: '0px' }}
                            className="button_delete width_100"
                            onClick={() => {
                              setCancelConfirmationModal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_close width_100"
                            data-cy="cancelshiftbutton"
                            onClick={() => cancelshift()}
                          >
                            Ok
                            {isCancelLoading && (
                              <div className="lds-ring ml-2">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {restoreconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header ">
                <div className="close">
                  <a
                    onClick={() => {
                      setRestoreConfirmationModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body ml-30">
                <div className="bag">
                  <div className="row d-flex ">
                    <div className="col-md-12 d-flex flex-column ml-20">
                      <div className="d-flex mb-10">
                        <div className="p-2">
                          <img
                            style={{ height: '40px', width: '40px' }}
                            className="delete-icon"
                            src="/assets/img/restoreicon.png"
                          ></img>
                          {/* <FontAwesomeIcon icon="fas fa-sync-alt" /> */}
                        </div>
                        <div
                          className="align-items-center"
                          style={{ lineHeight: '23px', padding: '10px' }}
                        >
                          <h4 className="font-weight-bold">Restore Shift</h4>
                          <p style={{ color: '#a2a2a2' }}>
                            Are you sure you want to restore the shift?
                          </p>
                        </div>
                      </div>
                      <div className="mb-30 d-flex align-items-center justify-content-center">
                        <div className="d-flex align-items-center ">
                          <button
                            style={{ margin: '0px' }}
                            className="button_delete width_100"
                            onClick={() => {
                              setRestoreConfirmationModal(false);
                            }}
                          >
                            Close
                          </button>
                          <button
                            className="button_delete_restore width_100"
                            onClick={() => restoreshift()}
                          >
                            Restore
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {showAgencyCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setshowAgencyCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <AgencyCandidateProfile
                  agency_candidate_id={SelectedCandidateId}
                ></AgencyCandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
      {showInvoicemodal && (
        <div
          className="modal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-large ">
            <div className="modal-content ">
              <div className="modal-body">
                <div className="bag">
                  <InvoiceAdd
                    showInvoiceModal={showInvoiceModalfunction}
                    shifts_Ids={Object.keys(selectedShifts)}
                  ></InvoiceAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPayoutmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-large ">
            <div className="modal-content ">
              <div className="modal-body">
                <div className="bag">
                  <PayoutAdd2
                    showPayoutModal={showPayoutModalfunction}
                    shifts_Ids={Object.keys(selectedShifts)}
                    csvDownloadBulk={true}
                  ></PayoutAdd2>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {timesheetmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-medium">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    onClick={() => {
                      setTimesheetModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              {Object.keys(selectedShifts).length > 0 ? (
                <div className="modal-body">
                  <div className="bag">
                    <AttendaceBloackbooking
                      hidefilter={1}
                      showTimesheetModal={showTimesheetModalfunction}
                      shifts_Ids={Object.keys(selectedShifts)}
                    ></AttendaceBloackbooking>
                  </div>
                </div>
              ) : (
                <div className="modal-body">
                  <div className="bag">
                    <AttendaceBloackbooking
                      showTimesheetModal={showTimesheetModalfunction}
                    ></AttendaceBloackbooking>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// loader for employer list in async select
const getEmployerlist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        active_status: 1,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for candidate list in async select
const getcandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getcandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue, isverified: 1 }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          for (let d = 0; d < data.rows.length; d++) {
            data.rows[d].cat_name = data.rows[d]['Category.name'];
          }
          // add a default all value in filter option
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name, last_name, cat_name }) => ({
              value: _id,
              label:
                name +
                ' ' +
                (last_name ? last_name : '') +
                ' ' +
                (cat_name ? '(' + cat_name + ')' : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for shift candidate list  in async select
const getshiftscandidatelist = inputvalue =>
  new Promise((resolve, reject) => {
    let employer_id;
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      employer_id = user.employer_id;
    }
    fetch(apiConstants.apiUrl + '/getshiftscandidatelist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        searchKey: inputvalue,
        employer_id: employer_id,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // add a default all value in filter option
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(i => ({
              value: i['_id'],
              label:
                i['name'] +
                (i['last_name'] ? i['last_name'] : '') +
                (i['Category'] || i['city'] ? '(' : '') +
                (i['Category'] ? i['Category']['name'] : '') +
                (i['Category'] && i['city'] ? '-' : '') +
                (i['city'] ? i['city'] : '') +
                (i['Category'] || i['city'] ? ')' : ''),
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for category in async select
const getCategorylist = inputvalue =>
  new Promise((resolve, reject) => {
    console.log(inputvalue);
    fetch(apiConstants.apiUrl + '/getcategorylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          let AllElement = { _id: 0, name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, name }) => ({
              value: _id,
              label: name,
            })),
          );
        }),
      )
      .catch(reject);
  });
// loader for agency list in async select
const getAgencylist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getagencylist', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({
        status: 1,
        select: ['_id', 'company_name'],
        searchKey: inputvalue,
      }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          // add an all select option
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, shiftsData } = state.shifts;
  return { requestStatus, shiftsData };
}

const connectedAllShiftsList = connect(mapStateToProps)(AllShiftsList);
export { connectedAllShiftsList as AllShiftsList };
