import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { CandidateProfile } from '../Candidate/CandidateProfile';
import { allocatecandidateActions } from '../_actions';
import { LoadingImg, Pagination } from '../_components';
import { apiConstants } from '../_constants';
import { authHeader } from '../_helpers';
import useDebounce from '../_helpers/useDebounce';
import { CandidateemployerallocationAdd } from './CandidateemployerallocationAdd';

const CandidateemployerallocationList = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [showCandidateprofileModal, setshowCandidateprofileModal] =
    useState(false);
  const [SelectedCandidateId, setSelectedCandidateId] = useState([]);
  const [allocatecandidateData, setAllocatecandidateData] = useState({
    rows: [],
  });
  const [allocatecandidateRows, setAllocatecandidateRows] = useState([]);
  const [query, setQuery] = useState({
    pageVo: {
      pageNo: 1,
      noOfItems: 8,
    },
  });
  const [showmodal, setshowmodal] = useState(false);
  const [deleteconfirmationmodal, setdeleteconfirmationmodal] = useState(false);
  const [allocatecandidateid, setallocatecandidateid] = useState([]);
  // modal open or close
  const showsavemodal = value => {
    setshowmodal(value);
    getAllAllocatecandidate(1);
  };
  //--- debouncing feature start ---
  const isfirsttime = useRef(true);
  const debouncedSearch = useDebounce(query?.searchKey, 500);
  const debouncedSearchEmployee = useDebounce(query?.employer_id, 500);
  useEffect(() => {
    if (!isfirsttime.current) {
      if (debouncedSearch || debouncedSearchEmployee)
        getAllAllocatecandidate(1);
    }
  }, [debouncedSearch, debouncedSearchEmployee]);
  //--- debouncing feature end ---
  // effect to load candidates
  useEffect(() => {
    getAllAllocatecandidate(1);
  }, []);
  // handler for event
  const handleChange = event => {
    const { name, value } = event.target;
    console.log(name, value, 'skds');
    //-----------search query--------
    isfirsttime.current = false;
    if (name == 'searchKey') {
      setQuery(prevstate => ({ ...prevstate, searchKey: value }));
    }
  };
  // handler for select carehome
  const selectedcarehome = (selectedOption, event) => {
    let name = event.name;
    isfirsttime.current = false;
    setQuery(prevState => ({ ...prevState, [name]: selectedOption.value }));
  };
  /**
   * Get all allocatecandidates
   **/
  const getAllAllocatecandidate = (pageNo, queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;
    queryNew.pageVo.pageNo = pageNo;
    setIsLoading(true);
    const { dispatch } = props;
    queryNew.status = 1;
    queryNew.pageVo.Allcandidate = true; //---------------to get list of all verified and not_verified candiates--------
    dispatch(allocatecandidateActions.allocatecandidateList(queryNew)).then(
      res => {
        setIsLoading(false);
        setListAndPagination(res.data);
      },
      () => {
        setIsLoading(false);
      },
    );
  };
  // handler for sort list
  const handleSort = key => {
    setQuery({
      ...query,
      pageVo: {
        ...query.pageVo,
        sort_order: query.pageVo.sort_order == 'asc' ? 'desc' : 'asc',
        sort: key,
      },
    });
  };
  // delete allocated candidate
  const deleteallocatecandidate = () => {
    const { dispatch } = props;
    dispatch(
      allocatecandidateActions.allocatecandidateDelete(allocatecandidateid),
    );
    setdeleteconfirmationmodal(false);
    getAllAllocatecandidate(query.pageVo.pageNo);
  };
  // to split date
  const datesplitter = datetime => {
    let date_splitted = datetime.split('T');
    return date_splitted[0];
  };
  // list and paginate the allocated candidate
  const setListAndPagination = allocatecandidateData => {
    console.log(allocatecandidateData);
    let allocatecandidateList = [];
    for (
      let j = 0;
      allocatecandidateData &&
      allocatecandidateData.rows &&
      j < allocatecandidateData.rows.length;
      j = j + 1
    ) {
      allocatecandidateList.push(
        <tr key={j}>
          <td>CNA0{allocatecandidateData.rows[j]._id}</td>
          <td>
            <div
              style={{ cursor: 'pointer', textTransform: 'capitalize' }}
              data-cy="viewcandiadteprofile"
              onClick={() => {
                setSelectedCandidateId(
                  allocatecandidateData.rows[j]['Candidate._id'],
                ),
                  setshowCandidateprofileModal(true);
              }}
            >
              {allocatecandidateData &&
              allocatecandidateData.rows &&
              allocatecandidateData.rows[j]['Candidate.profileimage'] ? (
                <img
                  className="staff_img"
                  src={
                    apiConstants.imgUrl +
                    allocatecandidateData.rows[j]['Candidate.profileimage']
                  }
                />
              ) : (
                <img className="staff_img" src="/assets/img/un-avatar.png" />
              )}
              {allocatecandidateData.rows[j]['Candidate.name'] + ' '}
              {allocatecandidateData.rows[j]['Candidate.last_name']
                ? allocatecandidateData.rows[j]['Candidate.last_name']
                : ''}
            </div>
          </td>
          <td style={{ textTransform: 'capitalize' }}>
            {allocatecandidateData.rows[j]['Candidate.Category.name'] &&
              allocatecandidateData.rows[j]['Candidate.Category.name']}
          </td>
          <td>{datesplitter(allocatecandidateData.rows[j].updatedtime)}</td>
          <td style={{ textTransform: 'capitalize' }}>
            {allocatecandidateData.rows[j]['Employer.company_name']}{' '}
          </td>
          <td
            className={
              allocatecandidateData.rows[j]['Candidate.isverified'] == 1
                ? 'verified_txt'
                : allocatecandidateData.rows[j]['Candidate.isverified'] == -1
                ? 'rejected_txt'
                : 'unverified'
            }
          >
            {allocatecandidateData.rows[j]['Candidate.isverified'] == 1
              ? 'Verified'
              : allocatecandidateData.rows[j]['Candidate.isverified'] == -1
              ? 'Rejected'
              : 'Unverified'}{' '}
          </td>
          <td className="" style={{ width: '250px' }}>
            <div className="d-flex justify-content-between">
              <div className=" eventCell eventView">
                <a
                  data-cy="viewdetails"
                  style={{ textDecoration: 'none' }}
                  href={
                    '/candidate/' + allocatecandidateData.rows[j].candidate_id
                  }
                >
                  view
                </a>
              </div>
              <div>
                <a
                  data-cy="remove"
                  className="notpaid_div cursorPoint"
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => {
                    deleteallocatecandidatemodal(
                      allocatecandidateData.rows[j]._id,
                    );
                  }}
                >
                  Remove
                </a>
              </div>
            </div>
          </td>
        </tr>,
      );
    }
    setAllocatecandidateRows(allocatecandidateList);
    setAllocatecandidateData(allocatecandidateData);
  };
  // delete candidate modal open
  const deleteallocatecandidatemodal = id => {
    setdeleteconfirmationmodal(true);
    setallocatecandidateid({ _id: id });
  };
  // custom style for select box
  const customStyles = {
    control: provided => ({
      ...provided,
      border: '1px solid #ccc',
      padding: '0px',
      paddingBottom: '6px',
      height: '26px',

      lineHeight: '25px',
    }),
  };
  return (
    <div className="AllocatecandidateList">
      <div className="col-md-12">
        <div className="card regular-table-with-color">
          <div className="card-header page-header ">
            <div className="row mt-20">
              <div className="col-md-11">
                <h4 className="font-weight-bold">
                  {' '}
                  Allocate user for block booking
                </h4>
              </div>
              <div className="col-md-1">
                <div
                  data-cy="allocatecandidate"
                  className="btn btn-primary-blue float-right"
                  onClick={() => setshowmodal(true)}
                >
                  <span> Allocate</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card-header">
            <div className="row ">
              <div className="col-md-6 d-flex  ">
                <div className="col-md-6 p-0">
                  <div className="">
                    <div>
                      <AsyncSelect
                        styles={customStyles}
                        name="employer_id"
                        onChange={selectedcarehome}
                        placeholder={'select carehome'}
                        cacheOptions
                        defaultOptions
                        className="employerselectt"
                        loadOptions={getcarehomelist}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-md-6 input-outer-div">
                <input
                  className="search-input-div"
                  value={query.searchKey}
                  onChange={handleChange}
                  type="text"
                  name="searchKey"
                  aria-label="Search"
                  placeholder="Search here..."
                />
                <a className="search_bar">
                  <i className="fa fa-search in_bar" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="card-body  table-responsive main-card-body ">
            {!isLoading && (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th
                      style={{ width: '200px' }}
                      onClick={() => handleSort('candidate_name')}
                      className="sortHead"
                    >
                      User{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'candidate_name' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Jobrole</th>
                    <th
                      onClick={() => handleSort('updatedtime')}
                      className="sortHead"
                    >
                      date{' '}
                      <span className={'sortBtn '}>
                        {' '}
                        <i
                          className={
                            'fa fa-sort-up ' +
                            (query.pageVo.sort == 'updatedtime' &&
                            query.pageVo.sort_order != 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>{' '}
                        <i
                          className={
                            'fa fa-sort-down ' +
                            (query.pageVo.sort == 'updatedtime' &&
                            query.pageVo.sort_order == 'desc'
                              ? ' active'
                              : '')
                          }
                        ></i>
                      </span>
                    </th>
                    <th>Carehome</th>
                    <th>Status</th>
                    <th className="eventCell  eventView">Actions</th>
                  </tr>
                </thead>
                <tbody>{allocatecandidateRows}</tbody>
              </table>
            )}
            {!!isLoading && <LoadingImg />}
            {allocatecandidateData &&
              allocatecandidateData.rows &&
              allocatecandidateData.rows.length === 0 &&
              !isLoading && (
                <p className="margin-top-20 text-center"> No result found </p>
              )}
          </div>
          <div className="card-footer">
            <Pagination
              pageOnClick={data => {
                getAllAllocatecandidate(data);
              }}
              paginationData={allocatecandidateData}
            />
          </div>
        </div>
      </div>
      {showmodal && (
        <div
          className="modal allocatemodal"
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog  modal-dialog-sm ">
            <div className="modal-content ">
              <div className="modal-header border-bottom-0">
                <div className="close">
                  <a
                    data-cy="close-add-modal"
                    onClick={() => setshowmodal(false)}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <CandidateemployerallocationAdd
                    showsavemodal={showsavemodal}
                  ></CandidateemployerallocationAdd>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {deleteconfirmationmodal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-xsm">
            <div className="modal-content">
              <div className="modal-header">
                <div className="close">
                  <a
                    onClick={() => {
                      setdeleteconfirmationmodal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </a>
                </div>
              </div>
              <div className="modal-body">
                <div className="bag">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-8">
                      <p className="delete_string" style={{ color: 'black' }}>
                        Are you sure ?{' '}
                      </p>
                      <div className="d-flex justify-content-center">
                        <button
                          className="button_delete width_100"
                          onClick={() => {
                            setdeleteconfirmationmodal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          data-cy="remove_button"
                          className="button_delete_close width_100"
                          onClick={() => deleteallocatecandidate()}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showCandidateprofileModal && (
        <div
          className="modal "
          style={{ display: 'block', background: ' rgb(10 11 11 / 50%)' }}
        >
          <div className="modal-dialog modal-dialog-90 ">
            <div className="modal-content ">
              <div className="modal-header">
                <div className="close">
                  <div
                    data-cy="closemodal"
                    onClick={() => {
                      setshowCandidateprofileModal(false);
                    }}
                  >
                    <i className=" fa fa-times-circle red fs-18"></i>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <CandidateProfile
                  candidate_id={SelectedCandidateId}
                ></CandidateProfile>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
// loader for carehome list
const getcarehomelist = inputvalue =>
  new Promise((resolve, reject) => {
    fetch(apiConstants.apiUrl + '/getemployerlistv2', {
      method: 'post',
      headers: authHeader(),
      body: JSON.stringify({ status: 1, searchKey: inputvalue }),
    })
      .then(response =>
        response.json().then(({ data }) => {
          //---add a default all value in filter option   -----
          let AllElement = { _id: 0, company_name: 'ALL' };
          let newArray = [AllElement].concat(data.rows);
          resolve(
            newArray.map(({ _id, company_name }) => ({
              value: _id,
              label: company_name,
            })),
          );
        }),
      )
      .catch(reject);
  });

function mapStateToProps(state) {
  const { requestStatus, allocatecandidateData } =
    state.candidateemployerallocation;
  return {
    requestStatus,
    allocatecandidateData,
  };
}

const connectedCandidateemployerallocationList = connect(mapStateToProps)(
  CandidateemployerallocationList,
);
export { connectedCandidateemployerallocationList as CandidateemployerallocationList };
